import * as React from 'react';
import { Helmet } from 'react-helmet-async';
import { useDispatch, useSelector } from 'react-redux';
import { SchedulerEventEditHandler, ViewModeChangeHandler } from './Scheduler';
import { useSchedulerSlice } from './Scheduler/slice';
import {
  selectSchedulerParseEvents,
  selectNonWorkingHours,
  selectSchedulerServices,
  selectSchedulerViewState,
  selectSelectedReservationEvents,
  selectLoadedServices,
  selectTutoringHours,
} from './Scheduler/slice/selectors';
import { IBasePageProps } from '../IBasePageProps';
import { INewEvent, ViewTypes } from './Scheduler/slice/types';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { generatePath } from 'react-router';
import { useGlobalServiceGroupsFilter } from 'app/hooks/useGlobalServiceGroupsFilter';
import { BasicTableProps } from 'app/components/BasicTable';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { ScreensId } from 'enums/ConfigurableTypes';
import { SchedulerConnectedWithFilters } from './SchedulerWithFiltersProps';
import { useProfileSetting } from 'app/components/BasicTable/useProfileSetting/index';
import { parseCalendarPageParams as parseViewState } from './parseCalendarPageParams';
import { DetectIsMobile } from 'utils/mobileDetect';
import { undefinedIfIsEmpty } from 'app/components/BasicTable/useProfileSetting/parseProfileUrl';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { SchedulerDatePositionUnion } from 'app/components/BasicTable/Filters/DatesPeriodFilter';
import { tryParseInt } from 'utils/string-utils';
import { URLSearchParamsCI } from 'app/components/BasicTable/types/FilterParam';
import { Messages } from 'app/components/Messages';
export { CalendarPageDefaultRedir } from './CalendarPageDefaultRedir';

export interface CalendarPageParams {
  type: ViewTypes;
  period: SchedulerDatePositionUnion;
}

export const calendarPageDefaultPath = '/schedule';
export const calendarPageFullPath = '/schedule/:type';
export const CalendarPagePaths = [
  calendarPageFullPath,
  calendarPageDefaultPath,
];
export interface CalendarPageProps
  extends IBasePageProps<CalendarPageParams>,
    Pick<
      BasicTableProps<ICalendarReservationDto>,
      'filterOpen' | 'onFilterChange' | 'appliedFilters' | 'availableFilters'
    > {}

export const calendarPathUserProfileKey = calendarPageDefaultPath;
export function CalendarPage({ match, location, ...props }: CalendarPageProps) {
  // show the global service groups filter on the page
  useGlobalServiceGroupsFilter(true);
  const isMobile = DetectIsMobile();
  const dispatch = useDispatch();
  const { updateValue: updateX } = useProfileSetting({
    profileSettingKey: calendarPathUserProfileKey,
  });
  const { newDate } = useSystemDate();
  const viewStateSearchParams = React.useMemo(() => {
    return parseViewState(match, location.search, isMobile, newDate());
  }, [match, location.search, isMobile, newDate]);

  // search params that not affect the viewState
  const searchParams = React.useMemo(() => {
    const purlSearchParams = new URLSearchParamsCI(location.search);
    return {
      SampleRunId: tryParseInt(purlSearchParams.get('sampleRunId')),
      duration: tryParseInt(purlSearchParams.get('duration')),
    };
  }, [location.search]);

  React.useEffect(() => {
    if (isMobile) {
      return;
    }
    const pathName = generatePath(calendarPageFullPath, {
      type: viewStateSearchParams.viewType,
    });
    // the "saved filter" url setting have to have the search part in the URL
    // as it's primarily used for it's query string value in the filters component
    // see parseProfileURL.ts
    const url = pathName + (undefinedIfIsEmpty(location.search) ?? '?');
    updateX(url);
  }, [location.search, viewStateSearchParams.viewType, updateX, isMobile]);

  const { actions } = useSchedulerSlice();
  const events = useSelector(selectSchedulerParseEvents);
  const nonWorkingHours = useSelector(selectNonWorkingHours);
  const tutoringHours = useSelector(selectTutoringHours);
  const schedulerServices = useSelector(selectSchedulerServices);

  const viewState = useSelector(selectSchedulerViewState);
  const selectedMultipleEvents = useSelector(selectSelectedReservationEvents);

  useEffectOnMount(() => {
    dispatch(actions.init(viewStateSearchParams));
  });

  const handleViewModeChange: ViewModeChangeHandler = p => {
    dispatch(actions.changeViewMode(p));
  };

  const handleEdit: SchedulerEventEditHandler = React.useCallback(
    event => {
      dispatch(actions.edit(event));
    },
    [actions, dispatch],
  );
  const handleCreate = React.useCallback(
    (event: INewEvent | undefined) => {
      dispatch(
        actions.create(
          event === undefined
            ? undefined
            : {
                ...event,
                sample_run_id: searchParams.SampleRunId,
              },
        ),
      );
    },
    [actions, dispatch, searchParams.SampleRunId],
  );
  const selectedEquipment = useSelector(selectLoadedServices);

  if (viewState === undefined) {
    return <></>;
  }

  return (
    <>
      <Helmet>
        <meta name="description" content="Calendar" />
      </Helmet>

      <SchedulerConnectedWithFilters
        screenId={ScreensId.Schedule}
        services={schedulerServices}
        events={events}
        defaultDuration={searchParams.duration}
        nonWorkingHours={nonWorkingHours}
        tutoringHours={tutoringHours}
        date={viewState.date}
        viewType={viewState.viewType}
        viewLength={viewState.viewLength}
        presetPosition={viewState.preset}
        search={viewState.search}
        onDataUpdated={() => void 0}
        onViewModeChange={handleViewModeChange}
        onEdit={handleEdit}
        onCreate={handleCreate}
        reservationEditEvents={selectedMultipleEvents}
        savedListSettings={{
          enableSavedLists: true,
          savedListRelatedPickerName: 'EquipmentId',
          savedListSerializedKey: 'eid',
        }}
      />
      {selectedEquipment !== undefined && (
        <Messages pageName="calendar" selectedEquipment={selectedEquipment} />
      )}
    </>
  );
}
