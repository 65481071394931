import { useTranslation } from 'react-i18next';
import { SelectFile } from './Components/SelectFile';
import { translations } from 'locales/translations';
import React from 'react';
import { Body } from '../Typography';
import BasicTypography from '../Typography/BasicTypography';
import { useSelector } from 'react-redux';
import { selectglobalSettings } from 'app/slice/selectors';
import { AllowedSettings } from 'utils/globalSettings';

export interface LargeFilesUpdateProps {
  maxFilesCount?: number;
}
export function LargeFilesUpdate({ maxFilesCount }: LargeFilesUpdateProps) {
  const { t } = useTranslation();
  const systemSettings = useSelector(selectglobalSettings);
  const AllowLargeFilesUpload = systemSettings.GetBooleanByKey(
    AllowedSettings.AllowLargeFilesUpload,
  );
  const fileSizeMessage = React.useMemo(() => {
    const res = AllowLargeFilesUpload
      ? t(translations.MaxLargeFileSize)
      : t(translations.MaxUploadFileSize);
    return res.replace('{0}', AllowLargeFilesUpload ? '2' : '20');
  }, [AllowLargeFilesUpload, t]);
  return (
    <>
      <div>
        <BasicTypography>{t(translations.Attachments)}</BasicTypography>
        <SelectFile
          name="SelectFile"
          multiline={true}
          fullWidth
          placeholder={t(translations.Template)}
          label={t(translations.Template)}
          disabled={false}
          accept={'.*'}
          filesPlaceholder={
            <>
              <Body bold={true}>{t(translations.DragTheFilesOrBrowse)}</Body>
              <Body size="small"> {fileSizeMessage} </Body>
            </>
          }
          maxFilesCount={maxFilesCount}
          maxSize={
            AllowLargeFilesUpload ? 2 * 1024 * 1024 * 1024 : 20 * 1024 * 1024
          }
        />
      </div>
    </>
  );
}
