import { PayloadAction } from '@reduxjs/toolkit';
import {
  call,
  put,
  select,
  takeEvery,
  takeLatest,
  debounce,
  all,
} from 'redux-saga/effects';
import { schedulerActions as actions } from '.';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import {
  ReservationDetailsState,
  ReservationQueryStringParameters,
} from 'app/pages/ReservationDetails/Details/slice/types';
import { appSettingsActions } from 'app/slice';
import {
  ChangeServicesAction,
  ChangeViewModeAction,
  GetServicesAction,
  IEditEvent,
  INewEvent,
  ISchedulerService,
  ISerializableFilterSettings,
  IViewState,
  SetFilterAction,
} from './types';
import { httpClient } from 'api/HttpClient';
import {
  selectSchedulerViewStartDate,
  selectSchedulerViewEndDate,
  selectSchedulerPreviousViewState,
  selectSchedulerFilters,
  selectMultipleModeState,
  selectNotUseLocation,
  selectEmptySlotAdminClick,
  selectSchedulerNewEventServices,
  selectLoadedServices,
  selectCalendarReservationColumns,
  selectWithReservationsOnly,
  selectSchedulerViewState,
} from './selectors';

import {
  Condition,
  ODataFilterBuilder,
  ODataOperators,
  withisInversed,
} from 'api/odata/ODataFilter';
import {
  RenderPageType,
  SidePanelState,
} from 'app/Layout/FrontendLayout/slice/type';
import { ReservationDetailsProps } from 'app/pages/ReservationDetails/Details';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import {
  selectAppSettings,
  selectAuthenticatedUser,
  selectGlobalServiceGroupFilter,
  selectGlobalSetting,
  selectglobalSettings,
  selectUserProfileSettings,
} from 'app/slice/selectors';
import { getLogger } from 'utils/logLevel';
import { routerActions } from 'connected-react-router';
import { chunk, isArray, isEqual, omit, uniq } from 'lodash';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { assertExhaustive } from 'utils/assertExhaustive';
import { IServiceFilterDto } from 'api/odata/generated/entities/IServiceFilterDto';
import { selectSidePanelState } from 'app/Layout/FrontendLayout/slice/selectors';
import { selectReservationData } from 'app/pages/ReservationDetails/Details/slice/selectors';
import { reservationActions } from 'app/pages/ReservationDetails/Details/slice';
import { UserProfileSettings } from 'types/UserProfileSettings';
import { UserProfile } from 'utils/userProfileSettings';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import { dateUtils } from 'utils/date-utils';
import { IWorkingHoursDto } from 'api/odata/generated/entities/IWorkingHoursDto';
import { PartOfDay } from 'enums/WorkingHoursTypes';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import {
  WorkOrderDetailsState,
  WorkOrderQueryStringParameters,
} from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details/slice/types';
import { GetCalendarPagePath } from '../../GetCalendarPagePath';
import { ITrainingSessionSlotDto } from 'api/odata/generated/entities/ITrainingSessionSlotDto';
import { EmptySlotAdminClick } from 'enums/EmptySlotAdminClick';
import { Entity } from 'types/common';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { ICalendarReservationDto } from 'api/odata/generated/entities/ICalendarReservationDto';
import { workOrderActions } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details/slice';
import { tryParseInt } from 'utils/string-utils';
import { undefinedIfIsEmpty } from 'app/components/BasicTable/useProfileSetting/parseProfileUrl';
import { ISavedViewDto } from 'api/odata/generated/entities/ISavedViewDto';
import { selectSelectedSavedView } from 'app/pages/SavedViewsPage/SavedViewPage/slice/selectors';
import { savedViewActions } from 'app/pages/SavedViewsPage/SavedViewPage/slice';
import { ReservationOrDowntimeEnum } from 'enums/ReservationOrDowntimeEnum';
import { FilterValue } from 'react-table';
import { AppSettings } from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import {
  groupByFilterFieldName,
  periodFilterFieldName,
} from '../../parseCalendarPageParams';
import { URLSearchParamsCI } from 'app/components/BasicTable/types/FilterParam';
import { IEquipmentTimeSlot } from 'api/odata/generated/complexTypes/IEquipmentTimeSlot';

const log = getLogger('SchedulerSaga');
function* doChangeViewMode(action: ChangeViewModeAction) {
  const previous: IViewState | undefined = yield select(
    selectSchedulerPreviousViewState,
  );
  const { current } = action.payload;
  log.debug('doChangeViewMode', previous, current);
  const notUseLocation = yield select(selectNotUseLocation);
  if (!notUseLocation) {
    yield* changeLocation(current, previous);
  }
  // skip unnecessary data reload during calendar/timeline switch
  // fool proofing against duplicate calls to change view state of the scheduler
  const isSameState = isStateEqual(previous, current);
  if (!isSameState || action.payload.forceEvents) {
    yield put(actions.getEvents());
  }
  yield put(actions.setPreviousViewState(current));
}

function* doChangeViewModePartial(action: PayloadAction<Partial<IViewState>>) {
  const current: IViewState = yield select(selectSchedulerViewState);
  yield* doChangeViewMode({
    type: action.type,
    payload: {
      current,
    },
  });
}

/**
 * Determines if the viewstate have changed from the perspective of the scheduler saga
 * Used in order to fool proof the doChangeViewMode from rouge duplicate calls to change the viewstate causing to duplicate calls to fetch events
 * @param previous
 * @param current
 * @returns
 */
function isStateEqual(previous: IViewState | undefined, current: IViewState) {
  try {
    const pickViewState = (a?: IViewState) => ({
      viewLength: a?.viewLength,
      // guards from one second changes to the viewstate regardless of their origin
      // lowest denominator for the purpose of fetching events is one day currently
      // in future, if the scheduler resolution will be lowered to > 1 day, this will need to be adjusted accordingly
      date:
        a?.date === undefined
          ? undefined
          : dateUtils.formatISO(
              dateUtils.startOfDay(dateUtils.parseISO(a.date)),
            ),
    });
    const isSameState = isEqual(
      pickViewState(previous),
      pickViewState(current),
    );
    return isSameState;
  } catch (error) {
    return false;
  }
}
export function applyStateSearchParams(current: IViewState) {
  const params = new URLSearchParamsCI(current.search);
  if (current.preset === null) {
    params.delete(periodFilterFieldName);
  } else {
    params.set(periodFilterFieldName, current.preset);
  }

  params.set(groupByFilterFieldName, current.groupBy);

  return params.toString();
}

function* changeLocation(
  current: IViewState,
  previous: IViewState | undefined,
) {
  const currentMode = {
    groupBy: current.groupBy,
    type: current.viewType,
    search: current.search,
  };
  const previousMode = {
    groupBy: previous?.groupBy,
    type: previous?.viewType,
    search: previous?.search,
  };

  if (!isEqual(previousMode, currentMode)) {
    const path = GetCalendarPagePath(current);

    yield put(
      routerActions.push({ pathname: path, search: currentMode.search }),
    );
  }
}

const reservationHistoryAPIURL = '/api/odata/v4/CalendarReservations';

function eventsParams({
  start,
  end,
  allFilters,
  selectedServices,
  serviceGroups,
}: {
  start: Date | undefined;
  end: Date | undefined;
  allFilters: ISerializableFilterSettings | undefined;
  selectedServices: Array<ISchedulerService> | undefined;
  serviceGroups: Array<Entity<number>> | undefined;
}) {
  const { events: eventFilters, training } = splitFilter(allFilters);
  const ReservationOrDowntime =
    allFilters?.['ReservationOrDowntime']?.value?.Id;

  // filters shared across all type of events reservations/working hours/training sessions/etc.
  // this filter can be sources either from the "Filter" or from scheduler state
  const sharedFilter: Array<Condition<ICalendarReservationDto>> = [
    new Condition<ICalendarReservationDto>(
      'StartTime',
      ODataOperators.LessThan,
      end as FilterValue,
    ),
    new Condition<ICalendarReservationDto>(
      'EndTime',
      ODataOperators.GreaterThan,
      start as FilterValue,
    ),
  ];

  const servicesFilter: Array<Condition<ICalendarReservationDto>> = [];

  if (selectedServices !== undefined && selectedServices.length > 0) {
    const equipmentIdArray = selectedServices.map(f => f.Id);
    servicesFilter.push(
      new Condition<ICalendarReservationDto>(
        'EquipmentId',
        ODataOperators.Includes,
        equipmentIdArray,
      ),
    );
  }

  if (serviceGroups !== undefined && serviceGroups.length > 0) {
    servicesFilter.push(
      new Condition<ICalendarReservationDto>(
        'ServiceGroupId',
        ODataOperators.Includes,
        serviceGroups,
      ),
    );
  }
  return {
    ReservationOrDowntime,
    training,
    sharedFilter,
    servicesFilter,
    eventFilters,
  };
}

function* doGetEvents(action: PayloadAction) {
  const start: Date | undefined = yield select(selectSchedulerViewStartDate);
  const end: Date | undefined = yield select(selectSchedulerViewEndDate);
  const allFilters: ISerializableFilterSettings | undefined = yield select(
    selectSchedulerFilters,
  );
  const selectedServices: Array<ISchedulerService> | undefined = yield select(
    selectLoadedServices,
  );
  const serviceGroups: Array<Entity<number>> | undefined = yield select(
    selectGlobalServiceGroupFilter,
  );
  const withReservationsOnly = yield select(selectWithReservationsOnly);
  const {
    ReservationOrDowntime,
    training,
    sharedFilter,
    servicesFilter,
    eventFilters,
  } = eventsParams({
    start,
    end,
    allFilters,
    selectedServices,
    serviceGroups,
  });

  // track fetched services
  const fetchedServices: number[] = [];

  const reservations_fields = yield select(selectCalendarReservationColumns);
  const effects: Array<unknown> = [
    fetchEvents(
      ReservationOrDowntime,
      sharedFilter,
      servicesFilter,
      eventFilters,
      reservations_fields,
    ),
    fetchTrainingSessions(
      ReservationOrDowntime,
      training,
      sharedFilter,
      servicesFilter,
      selectedServices,
      fetchedServices,
    ),
  ];
  const noServicesSelected =
    selectedServices === undefined || selectedServices.length === 0;
  const loadWorkingHoursForFetchedEvents = !(
    noServicesSelected && withReservationsOnly
  );

  // working hours can be fetched early if they don't depend on fetched events
  if (loadWorkingHoursForFetchedEvents) {
    effects.push(
      fetchWorkingHours(
        ReservationOrDowntime,
        sharedFilter,
        servicesFilter,
        eventFilters,
      ),
    );
  }
  try {
    const [
      fetchedEventsEffect,
      fetchedTrainingSessionsEffect,
      fetchWorkingHoursEffect,
    ] = yield all(effects);
    const fetchedEvents =
      fetchedEventsEffect as IODataQueryResponse<ICalendarReservationDto> | null;
    if (noServicesSelected && fetchedEvents != null) {
      fetchedServices.push(
        ...uniq(fetchedEvents.value.map(f => f.EquipmentId)),
      );
    }

    const fetchedTrainingSessions =
      fetchedTrainingSessionsEffect as IODataQueryResponse<ITrainingSessionSlotDto> | null;
    if (noServicesSelected && fetchedTrainingSessions != null) {
      fetchedServices.push(
        ...uniq(fetchedTrainingSessions.value.map(f => f.EquipmentId)),
      );
    }

    const fetchedWorkingHours =
      fetchWorkingHoursEffect as IODataQueryResponse<IWorkingHoursDto> | null;

    yield put(
      actions.getEvents_Success({
        reservations: fetchedEvents?.value ?? [],
        trainingSessions: fetchedTrainingSessions?.value ?? [],
        offlineHours: fetchedWorkingHours?.value ?? [],
      }),
    );

    if (loadWorkingHoursForFetchedEvents) {
      if (noServicesSelected && fetchedWorkingHours != null) {
        fetchedServices.push(
          ...uniq(fetchedWorkingHours.value.map(f => f.EquipmentId)),
        );
      }
    } else {
      const offlineServicesFilter =
        withReservationsOnly === true
          ? [
              new Condition<IWorkingHoursDto>(
                'EquipmentId',
                ODataOperators.Includes,
                [
                  ...new Set(
                    (fetchedEvents?.value ?? []).map(f => f.EquipmentId),
                  ),
                ],
              ),
            ]
          : servicesFilter;
      // fetch offline hours (alerts) for all displayed services
      var result: IODataQueryResponse<IWorkingHoursDto> =
        yield fetchWorkingHours(
          ReservationOrDowntime,
          sharedFilter,
          offlineServicesFilter,
          eventFilters,
        );
      fetchedServices.push(...uniq(result.value.map(f => f.EquipmentId)));
      yield put(actions.getOfflineHours_Success(result.value));
    }

    if (noServicesSelected) {
      const distinctEquipmentIds = uniq(fetchedServices);
      yield put(actions.getDerivedServices(distinctEquipmentIds));
    }

    // fetch non working hours only if a service has been selected
    if (
      selectedServices !== undefined &&
      selectedServices.length > 0 &&
      start !== undefined &&
      end !== undefined
    ) {
      try {
        const nonWorking = yield call(
          getNonWorkingHours,
          selectedServices.map(f => f.Id),
          start,
          end,
        );
        yield put(actions.getNonWorkingHours_Success(nonWorking));
      } catch {
        yield put(actions.getNonWorkingHours_Success([]));
      }
      try {
        const tutoring = yield call(
          getTutoringWorkingHours,
          selectedServices.map(f => f.Id),
          start,
          end,
        );
        yield put(actions.getTutoringWorkingHours_Success(tutoring));
      } catch {
        yield put(actions.getTutoringWorkingHours_Success([]));
      }
    }
  } catch (error) {
    log.error(error);
  }
}

function fetchWorkingHours(
  ReservationOrDowntime: any,
  sharedFilter: Condition<ICalendarReservationDto>[],
  offlineServicesFilter:
    | Condition<ICalendarReservationDto>[]
    | Condition<IWorkingHoursDto>[],
  eventFilters: string[],
) {
  if (ReservationOrDowntime === ReservationOrDowntimeEnum.ReservationsOnly) {
    return call(() => null);
  } else {
    const url = `/api/odata/v4/WorkingHours`;
    // when the "With reservations only" is enabled - the offline hours need to be fetched only for those services that have reservations on them
    // so in this case reservations fetched here are used to extract the service ids and filter by them
    // otherwise the standard shared servicesFilter is used so that offline hours & reservations will be fetched on the same services
    const b = new ODataFilterBuilder<IWorkingHoursDto>({
      predicates: [
        new Condition<IWorkingHoursDto>(
          'PeriodTypeId',
          ODataOperators.Equals,
          PartOfDay.OffLine,
        ).toString() || '',
        ...sharedFilter,
        ...offlineServicesFilter.map(f => f.toString()),
        ...Object.entries(eventFilters ?? {})
          // exclude the EquipmentId & ServiceGroupId filters applied separately in the shared filters
          .filter(([key]) => !['service', 'period'].includes(key))
          // exclude filters not applicable to the offline hours like the BudgetManager
          .filter(([key]) => !['BudgetManager'].includes(key))
          .map(([, value]) => value),
      ],
      stringColumns: [],
      globalServiceGroupFilter: [],
      isOptionalServiceGroup: true,
    });
    return call(httpClient.get, url, { $filter: b.toString() });
  }
}

function fetchTrainingSessions(
  ReservationOrDowntime: any,
  training: string[],
  sharedFilter: Condition<ICalendarReservationDto>[],
  servicesFilter: Condition<ICalendarReservationDto>[],
  selectedServices: IServiceFilterDto[] | undefined,
  fetchedServices: number[],
) {
  if (ReservationOrDowntime !== ReservationOrDowntimeEnum.DownTimeOnly) {
    const ff = Object.keys(training || {}).includes('BudgetManager');
    if (ff) {
      return call(() => null);
    } else {
      const trainingFilterBuilder =
        new ODataFilterBuilder<ICalendarReservationDto>({
          predicates: [
            ...sharedFilter,
            ...servicesFilter,
            ...Object.entries(training ?? {})
              // exclude the EquipmentId & ServiceGroupId filters applied separately in the shared filters
              .filter(([key]) => !['service', 'period'].includes(key))
              .map(([, value]) => value),
          ],
          stringColumns: [],
          globalServiceGroupFilter: [],
          isOptionalServiceGroup: true,
        });
      return call(httpClient.get, '/api/odata/v4/TrainingSessionSlots', {
        $filter: trainingFilterBuilder.toString(),
      });
    }
  }
}

function fetchEvents(
  ReservationOrDowntime: any,
  sharedFilter: Condition<ICalendarReservationDto>[],
  servicesFilter: Condition<ICalendarReservationDto>[],
  eventFilters: string[],
  reservations_fields: string[],
) {
  if (ReservationOrDowntime !== ReservationOrDowntimeEnum.DownTimeOnly) {
    const b = new ODataFilterBuilder<ICalendarReservationDto>({
      predicates: [
        ...sharedFilter,
        ...servicesFilter,
        ...Object.entries(eventFilters ?? {})
          // exclude the EquipmentId & ServiceGroupId filters applied separately in the shared filters
          .filter(([key]) => !['service', 'period'].includes(key))
          .map(([, value]) => value),
      ],
      stringColumns: [],
      globalServiceGroupFilter: [],
      isOptionalServiceGroup: true,
    });

    return call(httpClient.get, reservationHistoryAPIURL, {
      $filter: b.toString(),
      $select: reservations_fields.join(','),
    });
  }
  return call(() => null);
}

async function getNonWorkingHours(
  selectedServices: Array<number>,
  start: Date,
  end: Date,
): Promise<Array<IEquipmentTimeSlot>> {
  const startParameter = start;
  const endParameter = dateUtils.addDays(
    dateUtils.startOfDay(dateUtils.parseISO(end)),
    1,
  );

  const x = await Promise.all(
    chunk(selectedServices, 100)
      .map(
        s =>
          `/api/odata/v4/WorkingHours/GetNonWorking(ServiceId=${JSON.stringify(
            s,
          )},Start=${prepareDateParameter(
            startParameter,
          )},End=${prepareDateParameter(endParameter)})`,
      )
      .map(s => httpClient.get<IODataQueryResponse<IEquipmentTimeSlot>>(s)),
  );
  const y = x.flatMap(f => f.value);
  return y;
}
async function getTutoringWorkingHours(
  selectedServices: Array<number>,
  start: Date,
  end: Date,
) {
  const startParameter = prepareDateParameter(start);
  const endParameter = prepareDateParameter(
    dateUtils.addDays(dateUtils.startOfDay(dateUtils.parseISO(end)), 1),
  );
  var x = await Promise.all(
    chunk(selectedServices, 100)
      .map(
        serviceIdParameter =>
          `/api/odata/v4/WorkingHours/GetTutoringWorking(ServiceId=${JSON.stringify(
            serviceIdParameter,
          )},Start=${startParameter},End=${endParameter})`,
      )
      .map(url => httpClient.get(url)),
  );
  return x.flatMap(f => f.value);
}
async function getServiceDetails(ids: Array<number>) {
  const result: IODataQueryResponse<IServiceFilterDto> = await httpClient.get(
    '/api/odata/v4/ServiceFilter/ReservableEquipments',
    {
      $filter: new Condition<IServiceFilterDto>(
        'Id',
        ODataOperators.Includes,
        ids.filter(f => f !== undefined),
      ).toString(),
      $expand: 'WorkingHours',
    },
  );
  return result.value;
}

function* doGetServices(action: GetServicesAction) {
  try {
    if (action.payload === undefined) {
      yield put(actions.getServices_Success([]));
      return;
    }
    if (action.payload.length === 0) {
      yield put(actions.getServices_Success([]));
      return;
    }
    const needMoreProps = needMoreServiceProps({ services: action.payload });
    if (!needMoreProps) {
      yield put(
        actions.getServices_Success(action.payload as Array<IServiceFilterDto>),
      );
    } else {
      try {
        // fetch service details only for services that haven't been loaded previously

        const servicesResponse: Array<IServiceFilterDto> = yield call(
          getServiceDetails,
          action.payload.map(f => f.Id),
        );

        yield put(actions.getServices_Success(servicesResponse));
      } catch (error) {
        log.error('doGetServices', action, error);
      }
    }
  } catch (error) {
    log.error(error);
  }
  yield put(actions.getEvents());
}

function needMoreServiceProps(props: {
  services: IServiceFilterDto[] | undefined;
}) {
  const requiredProps: Array<keyof IServiceFilterDto> = [
    'Id',
    'Name',
    'ServiceGroupId',
    'ServiceTypeId',
    'Color',
    'UseServiceGroupTrainingLevels',
    'ServiceGroupName',
    'RestrictDurationUnitsAmount',
    'RestrictDurationUnitType',
    'ImageName',
    'DefaultDuration',
    'AssemblyId',
    'TopAssemblyId',
    'AssetCatId',
    'AssetCatName',
    'RestrictReservationToTimeSlots',
    'MinOrderHours',
    'WorkingHours',
  ];
  const needMoreProps = props.services?.some(f => {
    const actualProps = Object.keys(f).sort();
    const iss = isEqual(actualProps, requiredProps.sort());
    return !iss;
  });
  return needMoreProps;
}

function* doGetDerivedServices(action: ChangeServicesAction) {
  try {
    if (action.payload === undefined) {
      return undefined;
    }
    const servicesResponse: Array<IServiceFilterDto> = yield call(
      getServiceDetails,
      action.payload,
    );

    yield put(actions.getDerivedServices_Success(servicesResponse));
  } catch (error) {
    log.error('doGetServices', action, error);
  }
}
function* doEdit(action: PayloadAction<IEditEvent>) {
  if (action.payload.type === undefined) {
    return;
  }
  switch (action.payload.type) {
    case 'offline':
      if (action.payload.alert_id !== undefined) {
        yield* doEditAlert(action);
      }
      break;
    case 'reservation':
      yield* doEditReservation(action);
      break;
    case 'trainingsession':
      if (action.payload.reservation_id === undefined) {
        yield* doEditTrainingSession(action);
      } else {
        yield doEditReservation(action);
      }
      break;
    default:
      assertExhaustive(action.payload.type);
  }
}
function* doEditTrainingSession(action: PayloadAction<IEditEvent>) {
  const trainingSession = action.payload.original as ITrainingSessionSlotDto;
  const authenticatedUser: AuthenticatedUser = yield select(
    selectAuthenticatedUser,
  );

  const queryParams: ReservationQueryStringParameters = {
    // reservation details does not understand the ISO time, the value must be without the "z"
    Start: dateUtils.formatQueryStringDate(
      dateUtils.parseISO(trainingSession.StartTime),
    ),
    // reservation details does not understand the ISO time, the value must be without the "z"
    End: dateUtils.formatQueryStringDate(
      dateUtils.parseISO(trainingSession.EndTime),
    ),
    selectedIds: String(action.payload.service_id),
    un: authenticatedUser.Id,
    tsid: String((action.payload as any).trainingsession_id),
  };
  // undefined can't be processed by the reservation details
  if (action.payload.reservation_id !== undefined) {
    queryParams.id = String(action.payload.reservation_id);
  }
  // const x: SidePanelState | undefined = yield select(selectSidePanelState);
  // const reservationSidePanelOpened =
  //   x?.sidePanelOpen && x?.pageType === RenderPageType.ReservationDetails;

  // if (reservationSidePanelOpened) {
  //   if (action.payload.start_date !== undefined) {
  //     yield put(reservationActions.setStartDate(action.payload.start_date));
  //   }
  //   if (action.payload.end_date !== undefined) {
  //     yield put(reservationActions.setEndDate(action.payload.end_date));
  //   }
  // } else {

  // }
  yield put(
    layoutActions.openSidePanel({
      type: RenderPageType.ReservationDetails,
      props: {
        useSidePanel: true,
        queryParams,
      } as ReservationDetailsProps,
    }),
  );
}
function* doEditReservation(action: PayloadAction<IEditEvent>) {
  if (action.payload.reservation_id === undefined) {
    log.error('doEditReservation: ReservationId not found', action.payload);
    return;
  }
  const queryParams: ReservationQueryStringParameters = {
    id: String(action.payload.reservation_id),
    Start: action.payload.start_date,
    End: action.payload.end_date,
  };
  const sidePanelState: SidePanelState | undefined = yield select(
    selectSidePanelState,
  );
  const reservationDetailsState: ReservationDetailsState | undefined =
    yield select(selectReservationData);
  const reservationSidePanelOpened =
    sidePanelState?.sidePanelOpen &&
    sidePanelState?.pageType === RenderPageType.ReservationDetails;
  if (reservationSidePanelOpened) {
    if (action.payload.reservation_id !== reservationDetailsState?.Id) {
      if (reservationDetailsState?.Id !== undefined) {
        // reset previously opened reservation/alert details before showing new one
        yield put(layoutActions.resetSidePanel());
      }
    }
  }

  if (
    reservationSidePanelOpened &&
    action.payload.reservation_id === reservationDetailsState?.Id
  ) {
    if (action.payload.start_date !== undefined) {
      yield put(reservationActions.setStartDate(action.payload.start_date));
    }
    if (action.payload.end_date !== undefined) {
      yield put(reservationActions.setEndDate(action.payload.end_date));
    }
  } else {
    yield put(
      layoutActions.openSidePanel({
        type: RenderPageType.ReservationDetails,
        props: {
          useSidePanel: true,
          queryParams,
        } as ReservationDetailsProps,
      }),
    );
  }
}
function* doEditAlert(action: PayloadAction<IEditEvent>) {
  const queryParams: WorkOrderQueryStringParameters = {
    id: String(action.payload.alert_id),
    offStart: action.payload.start_date,
    offEnd: action.payload.end_date,
  };
  const props: WorkOrderDetailsProps = {
    queryParams: queryParams,

    useSidePanel: true,
  };

  const sidePanelState: SidePanelState | undefined = yield select(
    selectSidePanelState,
  );

  if (sidePanelState?.sidePanelOpen && action.payload.alert_id === undefined) {
    yield put(
      workOrderActions.setOfflineEventStart(
        dateUtils.formatQueryStringDate(
          dateUtils.parseISO(action.payload.start_date),
        ),
      ),
    );

    yield put(
      workOrderActions.setOfflineEventEnd(
        dateUtils.formatQueryStringDate(
          dateUtils.parseISO(action.payload.end_date),
        ),
      ),
    );
  } else {
    yield put(
      layoutActions.openSidePanel({
        type: RenderPageType.WorkOrderDetails,
        props: props,
      }),
    );
  }
}
function* doCreate(action: PayloadAction<INewEvent | undefined>) {
  if (action.payload === undefined) {
    return;
  }
  const authenticatedUser: AuthenticatedUser = yield select(
    selectAuthenticatedUser,
  );
  const requestedServices = yield select(selectSchedulerNewEventServices);
  const defaultMulti: boolean | undefined = yield select(
    selectMultipleModeState,
  );
  const defaultMultiModeBehavior = action.payload.defaultMulti ?? defaultMulti;
  const emptySlotAdminClick: EmptySlotAdminClick = yield select(
    selectEmptySlotAdminClick,
  );
  const isAdmin = authenticatedUser.IsAllGroupOrLabTechAdmin(
    requestedServices.map(f => f.ServiceGroupId),
    requestedServices.map(f => {
      return {
        Id: f.Id,
        Name: f.Name,
        ServiceTypeId: f.ServiceTypeId,
      } as IServiceTypeFilterDto;
    }),
  );
  const sidePanelState: SidePanelState | undefined = yield select(
    selectSidePanelState,
  );
  const reservationDetailsState: ReservationDetailsState | undefined =
    yield select(selectReservationData);
  const reservationSidePanelOpened =
    sidePanelState?.sidePanelOpen &&
    sidePanelState?.pageType === RenderPageType.ReservationDetails;
  if (reservationSidePanelOpened) {
    if (reservationDetailsState?.Id !== 0) {
      // reset previously opened reservation/alert details before showing new one
      yield put(layoutActions.resetSidePanel());
    }
  }

  // equipment is changed here when a new reservation is dragged to another service on the timeline
  const equipmentChanged =
    action.payload.service_id !== undefined &&
    reservationDetailsState?.EquipmentsData?.some(
      equipment => equipment.Id === action.payload?.service_id,
    ) === false;

  if (reservationSidePanelOpened && reservationDetailsState?.Id === 0) {
    if (!equipmentChanged) {
      if (action.payload.start_date !== undefined) {
        yield put(reservationActions.setStartDate(action.payload.start_date));
      }
      if (action.payload.end_date !== undefined) {
        yield put(reservationActions.setEndDate(action.payload.end_date));
      }
      return;
    } else {
      yield put(layoutActions.resetSidePanel());
    }
  }
  const settings: AppSettings = yield select(selectAppSettings);
  if (settings.Modules.includes(KnownModules.EquipmentAssemblies)) {
  }

  /**
   * service_id from the payload might be:
   * * number if reservation is placed on the service slot (timeline/unit view)
   * * or an array of numbers (where from?)
   * */

  const payloadServiceIds = isArray(action.payload.service_id)
    ? action.payload.service_id
    : [action.payload.service_id];

  var requestedServicesAssemblyIds = uniq(
    requestedServices.map(
      (f: Pick<IServiceFilterDto, 'Id' | 'TopAssemblyId'>) =>
        f.TopAssemblyId ?? f.Id,
    ),
  );

  const useRequestedServices = requestedServices.length > 0;

  if (useRequestedServices) {
    if (
      requestedServices.some(
        (service: IServiceFilterDto) => service.AssemblyId !== null,
      ) &&
      settings.Modules.includes(KnownModules.EquipmentAssemblies)
    ) {
      yield put(
        appSettingsActions.addNotification({
          key: 'Scheduler_AssemblyPartSelected_InfoMessage',
          variant: 'info',
          message: i18next.t(
            translations.Scheduler_AssemblyPartSelected_InfoMessage,
          ),
        }),
      );
    }
  }

  // push all selected services if event was not created on dedicated service strip, e.g. on weekly calendar with no separate headers for each service
  const serviceIds = useRequestedServices
    ? requestedServicesAssemblyIds
    : payloadServiceIds;

  /**
   * Reservation side panel expects the service ids to be a csv e.g. "1,2,3"
   */
  const reservationSidePanelServiceId = String(serviceIds.join(','));
  const queryParams: ReservationQueryStringParameters = {
    selectedIds: reservationSidePanelServiceId,

    un: authenticatedUser?.Id,
    ug: authenticatedUser?.ActiveUserGroup?.Id,
    // reservation details does not understand the ISO time, the value must be without the "z"
    Start: dateUtils.formatQueryStringDate(
      dateUtils.parseISO(action.payload.start_date),
    ),
    // reservation details does not understand the ISO time, the value must be without the "z"
    End: dateUtils.formatQueryStringDate(
      dateUtils.parseISO(action.payload.end_date),
    ),
    defaultMulti:
      defaultMultiModeBehavior === undefined
        ? undefined
        : defaultMultiModeBehavior.toString(),
    sampleRunId: action.payload.sample_run_id?.toString(),
  };
  const alertQueryParams: WorkOrderQueryStringParameters = {
    eqid: reservationSidePanelServiceId,
    down: 'true',
    offH: 'true',
    // reservation details does not understand the ISO time, the value must be without the "z"
    eStart: dateUtils.formatQueryStringDate(
      dateUtils.parseISO(action.payload.start_date),
    ),
    // reservation details does not understand the ISO time, the value must be without the "z"
    offStart: dateUtils.formatQueryStringDate(
      dateUtils.parseISO(action.payload.start_date),
    ),
    // reservation details does not understand the ISO time, the value must be without the "z"
    offEnd: dateUtils.formatQueryStringDate(
      dateUtils.parseISO(action.payload.end_date),
    ),
    defaultMulti:
      defaultMultiModeBehavior === undefined
        ? undefined
        : defaultMultiModeBehavior.toString(),
    aType:
      action.payload.alert_type === undefined
        ? undefined
        : action.payload.alert_type.toString(),
  };
  // workaround to skip side panel close confirmation
  // otherwise a reset is called after confirmation causing the reservation/event to disappear from the scheduler
  yield put(layoutActions.setNotSavedChanges(false));
  if (
    isAdmin &&
    EmptySlotAdminClick[emptySlotAdminClick.toString()] ===
      EmptySlotAdminClick.DownTimeOnly
  ) {
    let params = {
      useSidePanel: true,
      useSwithButtons: true,
      queryParams: alertQueryParams,
    } as WorkOrderDetailsProps;
    yield put(
      layoutActions.openSidePanel({
        type: RenderPageType.WorkOrderDetails,
        props: params,
      }),
    );
  } else {
    let params = {
      useSidePanel: true,
      useSwitchButtons: isAdmin,
      queryParams,
    } as ReservationDetailsProps;
    yield put(
      layoutActions.openSidePanel({
        type: RenderPageType.ReservationDetails,
        props: params,
      }),
    );
  }
}
function* doReset(action: PayloadAction<unknown>) {
  yield put(actions.create());
}
function* doUpdateMultipleMode(action: PayloadAction<boolean | undefined>) {
  const authenticatedUser: AuthenticatedUser = yield select(
    selectAuthenticatedUser,
  );
  const selectedSavedView: ISavedViewDto | undefined = yield select(
    selectSelectedSavedView,
  );
  if (selectedSavedView === undefined) {
    yield put(
      appSettingsActions.updateUserProfileSettings({
        key: 'BookMultipleInstruments',
        model: {
          UserName: authenticatedUser?.Id,
          Key: 'BookMultipleInstruments',
          IsMultiple: action.payload === undefined ? null : action.payload,
        } as UserProfileSettings,
      }),
    );
  } else {
    yield put(
      savedViewActions.patch({
        Id: selectedSavedView.Id,
        Multiple: action.payload,
      }),
    );
  }
  yield put(actions.updateMultiMode_Success(action.payload));
}

function* doUpdateEmptySlotClick(action: PayloadAction<EmptySlotAdminClick>) {
  const authenticatedUser: AuthenticatedUser = yield select(
    selectAuthenticatedUser,
  );
  yield put(
    appSettingsActions.updateUserProfileSettings({
      key: 'EmptySlotAdminClick',
      model: {
        UserName: authenticatedUser?.Id,
        Key: 'EmptySlotAdminClick',
        Value: action.payload.toString(),
      } as UserProfileSettings,
    }),
  );
  yield put(actions.updateEmptySlotSettings_success(action.payload));
}
function* doGetEmptySlotAdminClick() {
  const sett: UserProfile = yield select(selectUserProfileSettings);
  const gsettings: GlobalSettings = yield select(selectglobalSettings);
  const defaultEmptySlotSetting = gsettings.GetNullableByKey(
    AllowedSettings.CalendarTimelineEmptySlotClickSettings,
  );
  const defaultEmptySlotClick =
    defaultEmptySlotSetting === null
      ? EmptySlotAdminClick.ReservationsOnly
      : EmptySlotAdminClick[defaultEmptySlotSetting] ===
        EmptySlotAdminClick.WhatToAdd
      ? EmptySlotAdminClick.ReservationsOnly
      : EmptySlotAdminClick[defaultEmptySlotSetting];
  const userEmptySlotAdminClick = sett.GetSettingValueBy(
    (f: { Key: string }) =>
      f.Key.toLowerCase() === 'EmptySlotAdminClick'.toLowerCase(),
  )?.Value;
  const userSetting =
    userEmptySlotAdminClick === undefined || userEmptySlotAdminClick === null
      ? defaultEmptySlotClick
      : isNaN(parseInt(userEmptySlotAdminClick))
      ? EmptySlotAdminClick[userEmptySlotAdminClick]
      : EmptySlotAdminClick[parseInt(userEmptySlotAdminClick)];
  yield put(actions.updateEmptySlotSettings_success(userSetting));
}

/**
 * Splits scheduler filters into 2 groups - one to be applied to the services the other one - to the events
 * @param filters scheduler filters
 * @returns
 */
function splitFilter(filters?: ISerializableFilterSettings) {
  const result: {
    events: Array<string>;
    service: Array<string>;
    training: Array<string>;
  } = {
    service: [],
    events: [],
    training: [],
  };
  if (filters === undefined) {
    return result;
  }
  const getPredicate = (filters: ISerializableFilterSettings, key: string) =>
    withisInversed<string>(
      String(filters[key].predicate),
      filters[key].predicate['isInversed'],
    );
  return Object.keys(filters).reduce((acc, key) => {
    switch (key) {
      case 'BudgetManager':
      case 'BookedById':
      case 'BudgetId':
        acc.events.push(getPredicate(filters, key));
        acc.events.push(getPredicate(filters, key));
        break;

      case 'service':
      case 'campus':
      case 'AssetRoomId':
      case 'AssetBuildingId':
      case 'AssetGroupId':
      case 'AssetCatId':
      case 'AvailabilityTypeId':
        acc.service.push(getPredicate(filters, key));
        break;
      case 'TrainingStateId':
        acc.training.push(getPredicate(filters, key));
        break;
      case 'WithReservationsOnly':
        // noop - WithReservationsOnly is not applied to any API and only affects the services displayed based on the fetched services and reservations
        break;
      default:
        log.warn(`splitFilter: missing case for ${key}`, filters[key]);
        break;
    }
    return acc;
  }, result);
}
function* doSetFilters(action: SetFilterAction) {
  try {
    const serviceGroups: Array<Entity<number>> | undefined = yield select(
      selectGlobalServiceGroupFilter,
    );
    // handle the global service groups filter
    const predicates: Array<string | Condition<IServiceFilterDto>> = [];
    if (serviceGroups !== undefined && serviceGroups.length > 0) {
      predicates.push(
        new Condition<IServiceFilterDto>(
          'ServiceGroupId',
          ODataOperators.Includes,
          serviceGroups,
        ),
      );
    }
    // apply the rest of the "filters" applicable to the services here
    if (action.payload !== undefined) {
      // The Service filter can't be applied directly as it filters by the EquipmentId column instead of the Id
      const serviceRelatedFilters = splitFilter(
        omit(action.payload, 'service'),
      );
      predicates.push(...serviceRelatedFilters.service);

      // service selected in the main filter need some special treatment
      if (action.payload.service !== undefined) {
        const filteredServices = action.payload?.service?.value?.map(f => f.Id);
        if (filteredServices?.length > 0) {
          predicates.push(
            new Condition<IServiceFilterDto>(
              'Id',
              ODataOperators.Includes,
              filteredServices,
            ),
          );
        }
      }
    }

    var serviceFilterValue = action.payload?.['service']?.value;
    const servicesLoaded =
      Array.isArray(serviceFilterValue) &&
      needMoreServiceProps({ services: serviceFilterValue }) === true;

    if (!servicesLoaded) {
      // max number of services allowed to be shown on the timeline
      const maxTimelineServices = yield select(state =>
        selectGlobalSetting(state, AllowedSettings.MaximumTimelineRowsLimit),
      );
      // max number of services allowed to be shown on the calendar
      const maxCalendarServices = yield select(state =>
        selectGlobalSetting(
          state,
          AllowedSettings.CalendarServicesPickerCapacity,
        ),
      );
      // take max + 1 in order to determine that the maximum limit has been reached for calendar/timeline
      const maxServices =
        Math.max(
          tryParseInt(maxTimelineServices) ?? 50,
          tryParseInt(maxCalendarServices) ?? 10,
        ) + 1;

      const x: IODataQueryResponse<IServiceFilterDto> = yield call(
        httpClient.get,
        '/api/odata/v4/ServiceFilter/ReservableEquipments',
        {
          $filter: undefinedIfIsEmpty(predicates.join(' and ')),
          $orderby: 'Name',
          $top: maxServices + 1,
        },
      );

      yield put(actions.getServices_Success(x.value));
    } else {
      yield put(actions.getServices_Success(serviceFilterValue));
    }

    yield put(actions.getEvents());
  } catch (error) {
    log.error(error);
  }
}
function* doResetWorkOrder(
  action: PayloadAction<WorkOrderDetailsState | undefined>,
) {
  // todo: use the payload/state to reset the start/end time of the offline/alert that was edited by drag/resize instead of refetching all events
  yield put(actions.create());
  yield put(actions.getEvents());
}
export function* schedulerSaga() {
  yield debounce(100, actions.getEvents.type, doGetEvents);
  yield takeLatest(actions.changeViewMode.type, doChangeViewMode);
  yield takeLatest(actions.changeViewModePartial.type, doChangeViewModePartial);
  yield takeLatest(actions.setDate.type, doGetEvents);
  yield takeLatest(actions.edit.type, doEdit);

  yield takeLatest(actions.create.type, doCreate);
  // refresh after reservation has been created
  yield takeEvery(
    reservationActions.createReservation_Success.type,
    doGetEvents,
  );
  // refresh after reservation has been updated
  yield takeEvery(
    reservationActions.updateReservation_Success.type,
    doGetEvents,
  );
  yield takeEvery(
    reservationActions.updateMultiReservations_completed.type,
    doGetEvents,
  );
  yield takeEvery(reservationActions.resetDetailsState.type, doGetEvents);
  // process change in the service groups global filter - refresh events shown
  yield takeLatest(
    appSettingsActions.saveGlobalServiceGroupFilter.type,
    doGetEvents,
  );
  // removes temporary "new event" from the scheduler on workorder creation
  yield takeEvery(workOrderActions.createWorkOrder_Success.type, doReset);
  // refresh after alert was created
  yield takeEvery(workOrderActions.createWorkOrder_Success.type, doGetEvents);
  // refresh after alert was updated
  yield takeEvery(workOrderActions.updateWorkOrder_Success.type, doGetEvents);

  yield takeLatest(reservationActions.resetDetailsState, doReset);
  yield takeLatest(
    workOrderActions.resetUpdateWorkOrderState.type,
    doResetWorkOrder,
  );
  yield takeLatest(
    workOrderActions.resetCreateWorkOrderState.type,
    doResetWorkOrder,
  );

  yield takeLatest(actions.getDerivedServices.type, doGetDerivedServices);
  yield takeLatest(actions.getServices.type, doGetServices);
  yield takeLatest(actions.setFilters.type, doSetFilters);

  yield takeLatest(actions.updateMultiMode.type, doUpdateMultipleMode);

  yield takeLatest(
    actions.updateEmptySlotSettings.type,
    doUpdateEmptySlotClick,
  );
  yield takeLatest(
    actions.getEmptySlotAdminClick.type,
    doGetEmptySlotAdminClick,
  );
}
function prepareDateParameter(date: Date) {
  return date.toISOString().substr(0, 19) + 'Z';
}
