import { Theme } from '@material-ui/core';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

export const AnnoncementsRoot = styled.span<{ theme: Theme }>`
  &.annoncement-root {
    & > div {
      z-index: ${props => props.theme.zIndex.modal};
    }
    & [class*='SnackbarItem-collapseWrapperInner'] {
      width: 100%;
    }
    & .annoncement-item {
      background-color: ${props =>
        props.theme.palette.background.paper} !important;
      color: ${props => props.theme.palette.text.primary} !important;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1),
        0px 1px 2px rgba(16, 24, 40, 0.1);
      border-radius: 8px;
      padding: 0;
      &.annoncement-item-dark {
        background-color: ${bookitColors.base.black} !important;
        color: ${bookitColors.base.white} !important;
      }
      & > [class*='SnackbarItem-message'] {
        padding: 0;
        width: 100%;
        & .annoncement-content-item {
          width: 100%;
        }
      }
    }
  }
`;
/* Close Button=True, Icon=True, Title=False, Color=White, Buttons=False, Show More Text=Expanded */

/* Auto layout */
// display: flex;
// flex-direction: column;
// align-items: center;
// padding: 16px 12px 16px 16px;
// gap: 16px;

// /* Bkt/Grayscale/White - c0 */
// background: #FFFFFF;
// /* Shadow/box-shadow-xs */
// box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.1);
// border-radius: 8px;
