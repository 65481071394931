import { createGlobalStyle } from 'styled-components';

export const FontsStyle = createGlobalStyle`
  
/* heebo-300 - latin_hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 300;
  src: local(''),
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* heebo-regular - latin_hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 400;
  src: local(''),
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* heebo-500 - latin_hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 500;
  src: local(''),
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* heebo-700 - latin_hebrew */
@font-face {
  font-family: 'Heebo';
  font-style: normal;
  font-weight: 700;
  src: local(''),
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('${process.env.PUBLIC_URL}/fonts/heebo-v10-latin_hebrew-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

   /* open-sans-regular - latin */
   @font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('${process.env.PUBLIC_URL}/fonts/open-sans-v20-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
        url('${process.env.PUBLIC_URL}/fonts/open-sans-v20-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
  }
/* open-sans-hebrew */
  @font-face {
    font-family: 'Open Sans Hebrew', serif;
    font-style: normal;
    font-weight: 400;
    src: local(''),
        url('${process.env.PUBLIC_URL}/fonts/OpenSansHebrew-Regular.woff') format('woff'),
        url('${process.env.PUBLIC_URL}/fonts/OpenSansHebrew-Regular-1.woff') format('woff'), 
        url('${process.env.PUBLIC_URL}/fonts/OpenSansHebrew-Bold.woff') format('woff'),
        url('${process.env.PUBLIC_URL}/fonts/OpenSansHebrew-ExtraBold.woff') format('woff'),
        url('${process.env.PUBLIC_URL}/fonts/OpenSansHebrew-Light.woff') format('woff');
  }
`;
export const LatoStyle = createGlobalStyle`
@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-BoldItalic.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-BlackItalic.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-BlackItalic.woff') format('woff');
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Black.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Black.woff') format('woff');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Bold.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Light.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-LightItalic.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-LightItalic.woff') format('woff');
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Lato', sans-serif;
  src: url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Italic.woff2') format('woff2'),
      url('${process.env.PUBLIC_URL}/fonts/Lato/Lato-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}
`;
export const GlobalStyle = createGlobalStyle`
body {
  margin-bottom: env(safe-area-inset-bottom);
}
#root {
  @media (max-width: 960px) {
    margin-bottom: env(safe-area-inset-bottom, 50px);
  }
  & .info-root {
    cursor: pointer;
    z-index: 1;
    &.tableInfo {
      position: absolute;
      top: 20%;
      left: 0px;
     }
  }
  & .base-progress {
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 1;
  }
}
`;
