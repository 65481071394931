import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { OtherServicesApi } from 'api/OtherServicesApi';
import { FormFieldsSection } from 'app/components/Forms/FormsLayout';
import {
  loadOfflineServiceData,
  ReservationsRelatedFilter,
} from 'app/components/pickers/AutocompletePickers/OfflineServiceTypePicker';
import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { usePromise } from 'app/hooks/usePromise';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { BookQuickServices } from 'app/pages/OtherServiceDetails/Details/components/BookQuickServices';
import { useOfflineServiceStateSlice } from 'app/pages/OtherServiceDetails/Details/slice';
import { selectSavedReservationService } from 'app/pages/OtherServiceDetails/Details/slice/selectors';
import {
  ErrorServices,
  OfflineServicesResponse,
} from 'app/pages/OtherServiceDetails/Details/slice/types';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { useAppSettingsSlice } from 'app/slice';
import { AxiosError } from 'axios';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
import { dateUtils } from 'utils/date-utils';
import { useReservationSlice } from '../slice';
import { combineMandatoryOfflineServices } from '../slice/combineOfflineServiceTypes';
import {
  selectCloneReservations,
  selectSavedOfflineServices,
} from '../slice/selectors';
import {
  ReservationDetailsState,
  ReservationSettingsState,
  ServiceSettingsState,
} from '../slice/types';
import { GlobalSettingsType } from './useGlobalSettingsHook';

export interface ReservationOfflineServicesProps {
  values: ReservationDetailsState;
  reservationSettings?: ReservationSettingsState;
  equipmentSettings: ServiceSettingsState | undefined;
  globalSettings: GlobalSettingsType;
  isEdit: boolean;
  title?: React.ReactNode;
  hanleUpdateLocalServices: (
    services: IOtherServices[],
    operation: 'add' | 'remove' | 'update',
  ) => void;
  onServiceClick?: (item: IOtherServices) => void;
  disabled?: boolean;
  onAddBatchClick: (
    batch: IInventoryBatchDto | null,
    serviceTypeId: number,
  ) => void;
  setError?: (error: ErrorServices) => void;
}

export const ReservationOfflineServices = React.memo(
  function ReservationOfflineServices(props: ReservationOfflineServicesProps) {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const isCloned = useSelector(selectCloneReservations);
    const savedServices = useSelector(selectSavedOfflineServices);
    const savedServiceEditable = useSelector(selectSavedReservationService);
    const { actions: notifyActions } = useAppSettingsSlice();
    const { actions: offActions } = useOfflineServiceStateSlice();
    const { actions } = useReservationSlice();
    const { newDate } = useSystemDate();

    const {
      isEdit,
      values,
      equipmentSettings,
      reservationSettings,
      hanleUpdateLocalServices,
      onServiceClick,
      title,
      disabled,
      onAddBatchClick,
      setError,
    } = props;

    const optionalServiceTypes = React.useMemo(() => {
      const fooBar =
        !props.globalSettings.MandatoryOfflineServicesOnTrainingSignUps &&
        !values.TrainingSignUp;

      const result = (
        reservationSettings?.OfflineOptional?.map(f => ({
          Id: f.Id,
          Mandatory: f.Mandatory,
          HideProject: f.HideProjects,
        })) ??
        equipmentSettings?.OfflineServiceTypes?.map(f => ({
          Id: f.Id,
          Mandatory: f.Mandatory,
          HideProject: f.HideProject,
        })) ??
        []
      ).filter(f => !(f.Mandatory && fooBar));
      return result;
    }, [
      equipmentSettings?.OfflineServiceTypes,
      props.globalSettings.MandatoryOfflineServicesOnTrainingSignUps,
      reservationSettings?.OfflineOptional,
      values.TrainingSignUp,
    ]);
    const budgetServiceGroupServicesAllowed = React.useMemo(() => {
      return values.Budget === null || values.Budget === undefined
        ? undefined
        : (values.Budget as IBudgetFilterDto).ServiceGroupServicesAllowed;
    }, [values.Budget]);
    const fetchServiceType = async (searchTerm: string | null) => {
      try {
        const data = await loadOfflineServiceData(
          ReservationsRelatedFilter(
            equipmentSettings?.Groups || [],
            optionalServiceTypes,
            equipmentSettings?.HasHideProjects,
            equipmentSettings?.AllOffServicesAllowed ?? undefined,
            values.Budget?.Id,
            budgetServiceGroupServicesAllowed,
          ),
          'public',
          [],
          undefined,
          'InventoryBatches',
        )(searchTerm, undefined);
        return data.value;
      } catch {
        return [];
      }
    };
    const sortFunc = (a: IInventoryBatchDto, b: IInventoryBatchDto) => {
      return b.Id - a.Id;
    };
    const prepareService = (
      serviceType: IOfflineServiceFilterDto,
      reservation: ReservationDetailsState,
    ) => {
      let batch = serviceType.InventoryBatchesEnabled
        ? !!serviceType.InventoryBatches &&
          serviceType.InventoryBatches.length > 0
          ? serviceType.InventoryBatches.filter(
              f =>
                (f.ExpirationDate === null || f.ExpirationDate > newDate()) &&
                (serviceType.NotLessThanZero ? f.Inventory > 0 : true) &&
                f.StokRenewed === true,
            ).sort(sortFunc)[0]
          : null
        : null;
      let data = {
        Id: 0,
        BookedBy: reservation.BookedBy?.Id,
        UserDisplayName: reservation.BookedBy?.Name,
        BudgetID: reservation.Budget?.Id,
        Budget: reservation.Budget?.Name,
        BudgetExperimentId: reservation.BudgetExperiment?.Id,
        BudgetExperimentName: reservation.BudgetExperiment?.Name,
        Quantity: serviceType.DefaultQuantity,
        DefaultQuantity: serviceType.DefaultQuantity,
        ExternalCustomerId: reservation.ExternalCustomer?.Id,
        ExternalCustomerName: reservation.ExternalCustomer?.Name,
        FundingType: reservation.FundingType?.Id,
        IntQuantityOnly: serviceType.IntQuantityOnly,
        DiscountFactor: 1,
        InstituteProjectId: reservation.InstituteProject?.Id,
        InstituteProjectName: reservation.InstituteProject?.Name,
        PurchaseOrder: reservation.PurchaseOrder,
        Reference: reservation.Reference,
        Mandatory: false,
        Remarks:
          reservation.Id > 0
            ? (t(
                translations.ReservationOfflineServiceDefaultRemarks,
              ) as string)
                .replace('{0}', reservation.Id.toString())
                .replace('{1}', reservationSettings?.BaseEquipment?.Name || '')
            : null,
        ReservationId: reservation.Id,
        ServiceDate: dateUtils.formatISO(
          dateUtils.dateOrStringToDate(reservation.StartTime),
        ),
        ServiceTypeID: serviceType.Id,
        ServiceType: serviceType.Name,
        ServiceGroupId: serviceType.ServiceGroupId,
        Units: serviceType.UnitTypeName,
        UserGroup: reservation.ADGroup?.Id,
        UserGroupName: reservation.ADGroup?.Name,
        AllowToUser: serviceType.AllowToUser,
        BudgetsTurnedOn: serviceType.BudgetsTurnedOn,
        StaffOnly: serviceType.StaffOnly,
        TrackInventory: serviceType.TrackInventory,
        NotLessThanZero: serviceType.NotLessThanZero,
        InventoryBatchesEnabled: serviceType.InventoryBatchesEnabled,
        InventoryBatchId: batch === null ? null : batch.Id,
        InventoryBatchName: batch === null ? null : batch.Name,
        InventoryBatchAmount: batch === null ? null : batch.Inventory,
      } as IOtherServices;
      return data;
    };
    const createService = async (
      serviceType: IOfflineServiceFilterDto,
    ): Promise<IOtherServices | undefined> => {
      return new Promise(async (resolve, reject) => {
        let staticService = prepareService(serviceType, values);
        hanleUpdateLocalServices([staticService], 'add');
        resolve(staticService);
      });
    };
    const initServices = async (): Promise<IOtherServices[]> => {
      return new Promise(async resolve => {
        if (savedServices.length < 1) {
          if (isEdit) {
            try {
              const result = await OtherServicesApi.getReservationOfflineServices(
                values.Id,
                reservationSettings?.Repeat,
              );
              resolve(result.value);
              hanleUpdateLocalServices(result.value as IOtherServices[], 'add');
            } catch {
              resolve([]);
            }
          } else if (isCloned) {
            try {
              const result = await OtherServicesApi.getReservationOfflineServices(
                values.Id,
                reservationSettings?.Repeat,
              );
              resolve(result.value);
              hanleUpdateLocalServices(result.value as IOtherServices[], 'add');
            } catch {
              resolve([]);
            }
          } else {
            //reduce an offline services to select only distinct services in case when several equipments have the same offline services.
            let servicesFiltered = !!reservationSettings?.OfflineMandatory
              ? combineMandatoryOfflineServices(
                  reservationSettings?.OfflineMandatory,
                ) || []
              : [];
            resolve(servicesFiltered);
            hanleUpdateLocalServices(servicesFiltered, 'add');
          }
        } else {
          resolve(savedServices);
        }
      });
    };
    const onRemoveService = async (service: IOtherServices) => {
      return new Promise(async (resolve, reject) => {
        if (isEdit) {
          if (service.Id > 0) {
            try {
              const responseDel = await OtherServicesApi.deleteService(
                service.Id,
              );
              let response = responseDel as OfflineServicesResponse;
              if (response.ErrorMessages.length > 0) {
                dispatch(
                  notifyActions.addNotifications(
                    response.ErrorMessages.map(item => {
                      return {
                        key: 'offlineServiceDeleteError',
                        message: item,
                        variant: 'error',
                      };
                    }),
                  ),
                );
                reject(response.ErrorMessages[0]);
              } else {
                dispatch(
                  notifyActions.addNotification({
                    key: 'offlineServiceDeleteSuccess',
                    message: response.SuccessMessages[0],
                    variant: 'success',
                  }),
                );
                hanleUpdateLocalServices([service], 'remove');
                resolve(response.SuccessMessages[0]);
              }
            } catch (error: unknown) {
              const message =
                (error as AxiosError)?.response?.data?.error?.innererror
                  ?.message ??
                ((error as AxiosError)?.response?.status === 403
                  ? t(translations.Forbidden)
                  : undefined) ??
                t(translations.errormessage);
              dispatch(
                notifyActions.addNotification({
                  key: 'offlineServiceDeleteError',
                  message: message,
                  variant: 'error',
                }),
              );
              reject(message);
            }
          } else {
            hanleUpdateLocalServices([service], 'remove');
            resolve(service);
          }
        } else {
          hanleUpdateLocalServices([service], 'remove');
          resolve(service);
        }
      });
    };
    const onUpdateService = (service: IOtherServices) => {
      return new Promise(async (resolve, reject) => {
        if (isEdit) {
          hanleUpdateLocalServices([service], 'update');
          // if (
          //   service.Id > 0 &&
          //   service.Quantity !== null &&
          //   service.Quantity > 0
          // ) {
          //   try {
          //     const responseUp = await OtherServicesApi.updateOtherService(
          //       service,
          //       service.Id,
          //     );
          //     let response = responseUp as OfflineServicesResponse;
          //     if (response.ErrorMessages.length > 0) {
          //       dispatch(
          //         notifyActions.addNotifications(
          //           response.ErrorMessages.map(item => {
          //             return {
          //               key: 'offlineServiceUpdateError',
          //               message: item,
          //               variant: 'error',
          //             };
          //           }),
          //         ),
          //       );
          //       reject(response.ErrorMessages[0]);
          //     } else {
          //       dispatch(
          //         notifyActions.addNotification({
          //           key: 'offlineServiceUpdateSuccess',
          //           message: response.SuccessMessages[0],
          //           variant: 'success',
          //         }),
          //       );
          //       resolve(service);
          //     }
          //   } catch (error: unknown) {
          //     const message =
          //       (error as AxiosError)?.response?.data?.error?.innererror
          //         ?.message ??
          //       ((error as AxiosError)?.response?.status === 403
          //         ? t(translations.Forbidden)
          //         : undefined) ??
          //       t(translations.errormessage);
          //     dispatch(
          //       notifyActions.addNotification({
          //         key: 'offlineServiceUpdateError',
          //         message: message,
          //         variant: 'error',
          //       }),
          //     );
          //     reject(message);
          //   }
          // }
          resolve(service);
        } else {
          hanleUpdateLocalServices([service], 'update');
          resolve(service);
        }
      });
    };

    //check for any services exist to decide if show offline services component
    const fetchAnyServices = async (): Promise<number> => {
      return new Promise(async resolve => {
        try {
          let data = await initServices();
          if (data.length > 0) {
            resolve(data.length);
          } else {
            let potentialData = await fetchServiceType(null);
            if (potentialData.length > 0) {
              resolve(potentialData.length);
            } else {
              resolve(0);
            }
          }
        } catch {
          resolve(0);
        }
      });
    };
    const [hasAnyServices, setHasAnyServices] = useAsyncExtendedState<number>(
      0,
    );
    const [fetchAnyServicesState, fetchAnyServicesPromise] = usePromise(
      fetchAnyServices,
    );
    React.useEffect(() => {
      if (
        !fetchAnyServicesState.status ||
        (fetchAnyServicesState.status !== 'pending' &&
          fetchAnyServicesState.status !== 'resolved')
      ) {
        setHasAnyServices(fetchAnyServicesPromise());
      }
      return () => {};
    }, [
      fetchAnyServicesPromise,
      fetchAnyServicesState.status,
      setHasAnyServices,
    ]);
    React.useEffect(() => {
      if (
        !!savedServiceEditable &&
        savedServices.some(
          f => f.ServiceTypeID === savedServiceEditable.ServiceTypeID,
        )
      ) {
        hanleUpdateLocalServices([savedServiceEditable], 'update');
        dispatch(offActions.setReservationService(undefined));
        dispatch(actions.setRefreshOfflineServices(true));
      }
    }, [
      actions,
      dispatch,
      hanleUpdateLocalServices,
      offActions,
      savedServiceEditable,
      savedServices,
    ]);
    return hasAnyServices > 0 ? (
      <FormFieldsSection titleSection={title}>
        <BookQuickServices
          loadSeviceTypes={fetchServiceType}
          createServiceFromServiceType={createService}
          initServicesFunc={initServices}
          onServiceRemove={onRemoveService}
          onServiceUpdate={onUpdateService}
          onServiceClick={onServiceClick}
          isEditMode={isEdit}
          disabled={disabled}
          onAddBatchClick={onAddBatchClick}
          setError={setError}
        />
      </FormFieldsSection>
    ) : (
      <React.Fragment></React.Fragment>
    );
  },
);
