import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import useSidePanelState, {
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { ReservationDetailsProps } from 'app/pages/ReservationDetails/Details';
import { ReservationQueryStringParameters } from 'app/pages/ReservationDetails/Details/slice/types';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { buildURL } from 'utils/url-utils';
import { Link as MuiLink } from '@material-ui/core';

export const ReservationLink = (props: {
  id: number;
  children: React.ReactNode;
  title?: string;
  useSidePanel?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}) => {
  const { t } = useTranslation();
  const openDetails = useOpenReservationPopUp();
  const getLink = useReservationLinkUrl();
  const User = useSelector(selectAuthenticatedUser);
  const link = getLink({ id: props.id.toString(), un: User?.Id });
  const open = () => {
    openDetails(
      {
        id: props.id,
        useSidePanel: props.useSidePanel,
      },
      props.openPanelWithCover,
    );
  };
  return (
    <Tooltip
      title={props.title ?? t(translations.ClickToSeeReservationDetails)}
    >
      <MuiLink
        onClick={event => {
          if (!!link) {
            if (!event.ctrlKey) {
              event.preventDefault();
              open();
            }
          } else {
            open();
          }
        }}
        color="textPrimary"
        href={link}
      >
        {props.children}
      </MuiLink>
    </Tooltip>
  );
};

export const useOpenReservationPopUp = () => {
  const { openPanel } = useSidePanelState();
  const User = useSelector(selectAuthenticatedUser);
  return React.useCallback(
    (
      props: { id: number; useSidePanel?: boolean; useSwitchButtons?: boolean },
      openPanelWithCover?: (state: SidePanelOpenState) => void,
    ) => {
      const params = {
        id: String(props.id),
        un: User?.Id,
      } as ReservationQueryStringParameters;
      let resProps = {
        queryParams: params,
        useSidePanel: true,
        useSwitchButtons: props.useSwitchButtons,
      } as ReservationDetailsProps;

      const panelState = {
        renderPageType: RenderPageType.ReservationDetails,
        renderPageProps: resProps,
        expanded: false,
        useSidePanel: props.useSidePanel,
        isCover: props.useSidePanel,
      } as SidePanelOpenState;
      if (!!openPanelWithCover) {
        openPanelWithCover(panelState);
      } else {
        openPanel({
          renderPageType: RenderPageType.ReservationDetails,
          renderPageProps: resProps,
          expanded: false,
          useSidePanel: false,
          isCover: false,
        } as SidePanelOpenState);
      }
    },
    [User?.Id, openPanel],
  );
};
export const useReservationLinkUrl = () => {
  const publicUrl = useSelector(selectPublicUrl);

  return React.useCallback(
    (params: ReservationQueryStringParameters) => {
      return buildURL(publicUrl + 'reservations/details', params);
    },
    [publicUrl],
  );
};
