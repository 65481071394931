import { IRepairCodeDto } from 'api/odata/generated/entities/IRepairCodeDto';
import { ISymptomCodeDto } from 'api/odata/generated/entities/ISymptomCodeDto';
import { IWorkOrderDetailsDto } from 'api/odata/generated/entities/IWorkOrderDetailsDto';
import { AsyncDataState, Entity } from 'types/common';
import { toEntity } from 'utils/entity-utils';
import { GetIntervals, IRepetitiveDto } from 'types/IRepetitiveDto';
import { getSingleReminder } from 'app/components/pickers/StaticOptionsPickers/RemindBeforePicker';
import { IResponseType } from 'types/ResponseType';
import { RepetitiveOptions } from 'enums/RepetitiveOptions';
import { RemindBefore } from 'enums/RemindBefore';
import { dateUtils } from 'utils/date-utils';
import { IAssetDto } from 'api/odata/generated/entities/IAssetDto';
import { isNullOrUndefined } from 'utils/typeUtils';
import { IUserFilterDto } from 'api/odata/generated/entities/IUserFilterDto';
import { Guid } from 'guid-typescript';
import { IFormValueDto } from 'api/odata/generated/entities/IFormValueDto';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import { FieldHandlerProps } from 'app/components/Forms/FormRender/FormRenderer';
import { Timestamp } from 'types/Timestamp';
import {
  PageTypeProps,
  RenderPageType,
} from 'app/Layout/FrontendLayout/slice/type';
//import { FieldHandlerProps } from 'app/components/Forms/FormRender/FormRenderer';

/* --- STATE --- */
export interface WorkOrderState {
  createState: AsyncDataState<WorkOrderDetailsState>;
  updateState: AsyncDataState<WorkOrderDetailsState>;
  reservationOffline: AsyncDataState<ReservationsOfflineData[]>;
  processing: boolean;
  sendEmailOnOffline: boolean;
  readonlyState?: WorkOrderReadonlyState;
  updateAllRecurrent: boolean;
  valueHandler?: FieldHandlerProps<WorkOrderDetailsState>;
  linksProcessing?: boolean;
  /**
   * Value used to change the offline event start from outside of the workorder details
   */
  OfflineEventStart?: Date | Timestamp | null;
  /**
   * Value used to change the offline event end from outside of the workorder details
   */
  OfflineEventEnd?: Date | Timestamp | null;
  doSaveNewComments?: boolean;
  createdOrders: number[];
  savedLinkedOrders: IWorkOrderDetailsDto[];
  calibrationsChanged?: boolean;
  downtimeOverlaps?: DowntimeOverlaps[];
}
export interface WorkOrderResponse extends IResponseType {
  Id?: number;
  sendEmailOnOffline: boolean;
  RecurringAssetId: number | null;
  RecurringGroupId: number | null;
  WorkOrderTypeId: number | null;
  ReservationOffline: ReservationsOfflineData[];
  AutoCreatedOrders: AutoCreateResult[];
}
export interface WorkOrderGlobalState {
  workOrderDetails: WorkOrderDetailsState;
  RepetitiveOptions: IRepetitiveDto | null;
}
export interface WorkOrderDetailsState {
  Id: number;
  Assets: Entity<number>[];
  WorkOrderType: Entity<number> | null;
  WorkOrderTypeStatus: Entity<number> | null;
  SymptomCodes: Entity<number>[];
  RepairCodes: Entity<number>[];
  RemindBefore: Entity<number> | null;
  EventStart: Date | Timestamp;
  EventEnd?: Date | Timestamp | null;
  AlertStart?: Date | Timestamp | null;
  AssignUsers: Entity<string>[];
  Description?: string | null;
  Downtime?: boolean;
  ToDown: boolean;
  OfflineEventStart?: Date | Timestamp | null;
  OfflineEventEnd?: Date | Timestamp | null;
  ReasonType?: Entity<number> | null;
  OfflineRemarks?: string | null;
  AlertTitle?: string | null;
  AutoCreateTitle?: string | null;
  RecurringAssetId: number | null;
  RecurringGroupId: number | null;
  SubmissionFormValues: IFormValueDto[];
  StaffFormValues: IFormValueDto[];
  SubmissionFormFiles: IFormFileValue[];
  StaffFormFiles: IFormFileValue[];
  StuffCustomForm: Entity<number> | null;
  HasAletForm: boolean;
  IsClosed: boolean;
  ClosedStatuses: Entity<number>[];
  ReportedBy?: string | null;
  ShowOnCalibrationHistory: boolean;
  NotEditable: boolean;
  TitleEnabled: boolean;
  EventTimeEnabled: boolean;
  DowntimeEnabled: boolean;
  AssigneeEnabled: boolean;

  ReservationId: number | null;
}
export interface SendEmailOnOffline {
  cancel: boolean;
  deleted: boolean;
  insert: boolean;
  sendData: ReservationsOfflineData[];
}
export interface ReservationsOfflineData {
  AlertId: number;
  offStart: Date | string;
  offEnd: Date | string;
  ReasonName: string;
  Remarks: string;
  reservationIds: string;
}
export interface AutoCreateResult {
  AlertId: number;
  Date: Date;
}
export interface WorkOrderReadonlyState {
  AlertType: string | null;
  UpdateUser: IUserFilterDto;
  StatusName: string | null;
  ReportedBy: string | null;
  ReportedByDisplayName: string | null;
  LastUpdated: Date | Timestamp | null;
  Reservation: number | null;
  LastComment: string | null;
  LastCommentBy: string | null;
  AssetCatName: string | null;
  AssetCatGroupName: string | null;
  IsClosed: boolean | null;
  ReservationBookedBy: string;
  ReservationUserGroup: string;
  ReservationInstituteId: number | null;
  DoneDate: Date | Timestamp | null;
  DoneBy: string | null;
  InternalServiceAvaliable: boolean;
  CreateDate: Date | Timestamp | null;
  LinkedAlerts: Entity<number>[];
}
export interface WorkOrderQueryStringParameters {
  id?: string;
  aType?: string;
  down?: string;
  source?: string;
  Up?: string;
  title?: string;
  eStart?: string;
  sympt?: string;
  aStatus?: string;
  aStart?: string;
  desc?: string;
  assetId?: string;
  offH?: string;
  offStart?: string;
  offEnd?: string;
  reason?: string;
  eqid?: string;
  sid?: string;
  defaultMulti?: string;
  continueTo?: string;
  reservationId?: string;
}
export interface WorkOrderDates {
  start: Date[];
  end: Date[];
  alert: Date[];
  offlineStart: Date[];
  offlineEnd: Date[];
}

export interface ContinueToState {
  pageType?: RenderPageType;
  pageProps?: PageTypeProps;
  continueToLink?: string;
}
export interface DowntimeOverlaps {
  Id: number;
  AssetId: number;
  ServiceId: number;
}
export function ConvertEntityToModel(
  entity: IWorkOrderDetailsDto,
  toDown: boolean | undefined,
): WorkOrderDetailsState | undefined {
  if (!entity) {
    return undefined;
  } else {
    const result: WorkOrderDetailsState = {
      Id: entity.Id,
      AlertStart: entity.AlertStart,
      Assets: entity.Assets,
      AssignUsers: entity.AssignedUsers,
      Description: entity.AlertDescription,
      Downtime: entity.InstrumentWasDown,
      EventEnd: entity.EventEnd,
      EventStart: entity.EventStart,
      // convert dates (timestamps) received from the API to Date objects
      OfflineEventEnd: entity.OfflineEventEnd,
      OfflineEventStart: entity.OfflineEventStart,
      OfflineRemarks: entity.OfflineRemarks,

      ReasonType: toEntity(entity.ReasonTypeId, entity.ReasonTypeName),
      RemindBefore: getSingleReminder(entity.RemindMeBefore),
      WorkOrderType: entity.WorkOrderType, //toEntity(entity.AlertTypeId, entity.AlertType),
      WorkOrderTypeStatus: toEntity(entity.StatusId, entity.StatusName),
      RepairCodes: entity.RepairCodes as IRepairCodeDto[],
      SymptomCodes: entity.SymptomCodes as ISymptomCodeDto[],
      AlertTitle: entity.AlertTitle,
      ToDown: toDown || false,
      RecurringAssetId: entity.RecurringAssetId,
      RecurringGroupId: entity.RecurringGroupId,
      AutoCreateTitle: entity.AutoCreateTitle,
      StaffFormValues: entity.FormValues.filter(
        fv => fv.AlertId !== null && fv.AlertTypeId === null,
      ),
      SubmissionFormValues: entity.FormValues.filter(
        fv => fv.AlertId !== null && fv.AlertTypeId !== null,
      ),
      StaffFormFiles: [] as IFormFileValue[],
      SubmissionFormFiles: [] as IFormFileValue[],
      StuffCustomForm: entity.CustomForm as Entity<number> | null,
      HasAletForm: entity.HasAletForm,
      IsClosed: entity.IsClosed ?? false,
      ClosedStatuses: entity.ClosedStatuses,
      ReportedBy: entity.ReportedBy,
      ShowOnCalibrationHistory: (entity.WorkOrderType as IWorkOrderTypeDto)
        .ShowOnCalibrationHistory,
      NotEditable: (entity.WorkOrderType as IWorkOrderTypeDto).NotEditable,
      TitleEnabled: (entity.WorkOrderType as IWorkOrderTypeDto).TitleEnabled,
      EventTimeEnabled: (entity.WorkOrderType as IWorkOrderTypeDto)
        .EventTimeEnabled,
      DowntimeEnabled: (entity.WorkOrderType as IWorkOrderTypeDto)
        .DowntimeEnabled,
      AssigneeEnabled: (entity.WorkOrderType as IWorkOrderTypeDto)
        .AssigneeEnabled,
      ReservationId: entity.Reservation,
    } as WorkOrderDetailsState;
    return result;
  }
}
export function parseRepetitive(
  model: WorkOrderDetailsState,
  repetitive: IRepetitiveDto,
): WorkOrderDates {
  const dates: WorkOrderDates = {
    start: [],
    end: [],
    alert: [],
    offlineStart: [],
    offlineEnd: [],
  };
  let start = dateUtils.dateOrStringToDate(model.EventStart);
  dates.start = GetIntervals(start, repetitive);
  if (!isNullOrUndefined<Date | Timestamp>(model.EventEnd)) {
    let end = dateUtils.dateOrStringToDate(model.EventEnd);
    dates.end = dates.start.map(s => {
      if (s === start) {
        return end;
      } else {
        let dur = dateUtils.intervalToDuration({
          start: start,
          end: s,
        });
        return dateUtils.add(end, dur);
      }
    });
  }
  if (!isNullOrUndefined<Date | Timestamp>(model.AlertStart)) {
    // start = dateUtils.dateOrStringToDate(model.AlertStart);
    // dates.alert = GetIntervals(start, repetitive);
    let alertStart = dateUtils.dateOrStringToDate(model.AlertStart);
    // let alertDuration = dateUtils.intervalToDuration({
    //   start: start,
    //   end: alertStart,
    // });
    dates.alert = dates.start.map(s => {
      if (s === start) {
        return alertStart;
      } else {
        let dur = dateUtils.intervalToDuration({
          start: start,
          end: s,
        });
        return dateUtils.add(alertStart, dur);
      }
    });
  }
  if (!isNullOrUndefined<Date | Timestamp>(model.OfflineEventStart)) {
    // start = dateUtils.dateOrStringToDate(model.OfflineEventStart);
    // dates.offlineStart = GetIntervals(start, repetitive);
    let offStart = dateUtils.dateOrStringToDate(model.OfflineEventStart);
    dates.offlineStart = dates.start.map(s => {
      if (s === start) {
        return offStart;
      } else {
        let dur = dateUtils.intervalToDuration({
          start: start,
          end: s,
        });
        return dateUtils.add(offStart, dur);
      }
    });
  }
  if (!isNullOrUndefined<Date | Timestamp>(model.OfflineEventEnd)) {
    let offend = dateUtils.dateOrStringToDate(model.OfflineEventEnd);
    dates.offlineEnd = dates.start.map(s => {
      if (s === start) {
        return offend;
      } else {
        let dur = dateUtils.intervalToDuration({
          start: start,
          end: s,
        });
        return dateUtils.add(offend, dur);
      }
    });
  }
  return dates;
}
export function ConvertNewWorkOrdersToModel(
  model: WorkOrderGlobalState,
  user: AuthenticatedUser | undefined,
): IWorkOrderDetailsDto[] {
  let entities = [] as IWorkOrderDetailsDto[];
  let workModel = model.workOrderDetails;
  let repeat =
    model.RepetitiveOptions !== null &&
    model.RepetitiveOptions.RecurringEndDate !== null &&
    model.RepetitiveOptions.RecurringEvents != null &&
    model.RepetitiveOptions.RecurringEvents.Id !== RepetitiveOptions.DoNotRepeat
      ? model.RepetitiveOptions
      : null;
  let repeatAsset = workModel.Assets.length > 1;
  let times = repeat ? parseRepetitive(model.workOrderDetails, repeat) : null;
  let assetGuid: Guid | null =
    workModel.Assets.length > 1 ? Guid.create() : null;
  workModel.Assets.forEach(ass => {
    let asset = ass as IAssetDto;
    if (repeat !== null && times !== null) {
      let timesGuid = Guid.create();
      times.start.forEach((date, idx) => {
        entities.push(({
          Id: -1,
          EventStart: dateUtils.formatISO(date),
          EventEnd: isNullOrUndefined<Date>(times?.end[idx])
            ? null
            : dateUtils.tryFormatIso(times?.end[idx]),
          AlertDescription: workModel.Description || null,
          AlertStart: isNullOrUndefined<Date>(times?.alert[idx])
            ? null
            : dateUtils.tryFormatIso(times?.alert[idx]),

          AlertTitle: workModel.AlertTitle,
          AlertTypeId: workModel.WorkOrderType?.Id ?? -1,
          StatusId: workModel.WorkOrderTypeStatus?.Id,
          AssetId: asset.Id,
          ServiceId: asset.ServiceId,
          ServiceTypeId: asset.ServiceTypeId,
          ServiceGroupId: asset.ServiceGroupId,
          AssignedUsers: workModel.AssignUsers as IUserFilterDto[],
          OfflineEventStart: isNullOrUndefined<Date>(times?.offlineStart[idx])
            ? null
            : dateUtils.tryFormatIso(times?.offlineStart[idx]),
          OfflineEventEnd:
            workModel.OfflineEventStart !== null &&
            workModel.Downtime &&
            isNullOrUndefined<Date | Timestamp>(workModel.OfflineEventEnd)
              ? dateUtils.formatISO(MaxDate)
              : dateUtils.tryFormatIso(times?.offlineEnd[idx]),
          OfflineRemarks: workModel.OfflineRemarks,
          ReasonTypeId: workModel.ReasonType?.Id,
          ReasonTypeName: workModel.ReasonType?.Name,
          InstrumentWasDown: workModel.Downtime ?? false,
          Repeat: true,
          RepeatAssets: repeatAsset,
          RepeatEndTime: repeat?.RecurringEndDate,
          RepairCodes: workModel.RepairCodes as IRepairCodeDto[],
          SymptomCodes: workModel.SymptomCodes as ISymptomCodeDto[],
          RecurringAssetUniq: assetGuid?.toString() ?? null,
          RecurringGroupUniq: timesGuid?.toString() ?? null,
          FormValues: workModel.SubmissionFormValues.concat(
            workModel.StaffFormValues,
          ),
          Reservation: workModel.ReservationId,
          ReportedBy: user?.Id,
          StaffOnly: (workModel.WorkOrderType as IWorkOrderTypeDto).StaffOnly,
          ShowOnCalibrationHistory: (workModel.WorkOrderType as IWorkOrderTypeDto)
            .ShowOnCalibrationHistory,
          NotEditable: (workModel.WorkOrderType as IWorkOrderTypeDto)
            .NotEditable,
          TitleEnabled: (workModel.WorkOrderType as IWorkOrderTypeDto)
            .TitleEnabled,
          EventTimeEnabled: (workModel.WorkOrderType as IWorkOrderTypeDto)
            .EventTimeEnabled,
          DowntimeEnabled: (workModel.WorkOrderType as IWorkOrderTypeDto)
            .DowntimeEnabled,
          AssigneeEnabled: (workModel.WorkOrderType as IWorkOrderTypeDto)
            .AssigneeEnabled,
        } as unknown) as IWorkOrderDetailsDto);
      });
    } else {
      entities.push({
        Id: -1,
        EventStart: dateUtils.tryFormatIso(workModel.EventStart),
        AlertDescription: workModel.Description || null,
        AlertStart: isNullOrUndefined<Date | Timestamp>(workModel.AlertStart)
          ? null
          : dateUtils.tryFormatIso(workModel.AlertStart),
        AlertTitle: workModel.AlertTitle,
        AlertTypeId: workModel.WorkOrderType?.Id ?? -1,
        StatusId: workModel.WorkOrderTypeStatus?.Id,
        AssetId: ass.Id,
        ServiceId: asset.ServiceId,
        ServiceTypeId: asset.ServiceTypeId,
        ServiceGroupId: asset.ServiceGroupId,
        AssignedUsers: workModel.AssignUsers as IUserFilterDto[],
        OfflineEventStart: isNullOrUndefined<Date | Timestamp>(
          workModel.OfflineEventStart,
        )
          ? null
          : dateUtils.tryFormatIso(workModel.OfflineEventStart),
        OfflineEventEnd:
          workModel.OfflineEventStart !== null &&
          workModel.Downtime &&
          isNullOrUndefined<Date | Timestamp>(workModel.OfflineEventEnd)
            ? dateUtils.formatISO(MaxDate)
            : isNullOrUndefined<Date | Timestamp>(workModel.OfflineEventEnd)
            ? null
            : dateUtils.tryFormatIso(workModel.OfflineEventEnd),
        OfflineRemarks: workModel.OfflineRemarks,
        ReasonTypeId: workModel.ReasonType?.Id,
        ReasonTypeName: workModel.ReasonType?.Name,
        InstrumentWasDown: workModel.Downtime ?? false,
        Repeat: false,
        RepeatAssets: repeatAsset,
        RepairCodes: workModel.RepairCodes as IRepairCodeDto[],
        SymptomCodes: workModel.SymptomCodes as ISymptomCodeDto[],
        RecurringAssetUniq: assetGuid?.toString() ?? null,
        RecurringGroupUniq: null,
        FormValues: workModel.SubmissionFormValues.concat(
          workModel.StaffFormValues,
        ),
        Reservation: workModel.ReservationId,
        ReportedBy: user?.Id,
        StaffOnly: (workModel.WorkOrderType as IWorkOrderTypeDto).StaffOnly,
        ShowOnCalibrationHistory: (workModel.WorkOrderType as IWorkOrderTypeDto)
          .ShowOnCalibrationHistory,
        NotEditable: (workModel.WorkOrderType as IWorkOrderTypeDto).NotEditable,
        TitleEnabled: (workModel.WorkOrderType as IWorkOrderTypeDto)
          .TitleEnabled,
        EventTimeEnabled: (workModel.WorkOrderType as IWorkOrderTypeDto)
          .EventTimeEnabled,
        DowntimeEnabled: (workModel.WorkOrderType as IWorkOrderTypeDto)
          .DowntimeEnabled,
        AssigneeEnabled: (workModel.WorkOrderType as IWorkOrderTypeDto)
          .AssigneeEnabled,
      } as IWorkOrderDetailsDto);
    }
  });
  return entities;
}
export function ConvertModelToEntity(
  model: WorkOrderDetailsState,
  original: WorkOrderDetailsState | undefined,
): IWorkOrderDetailsDto {
  let entity = {
    Id: original?.Id === undefined ? model.Id : original.Id,
    AlertDescription: model.Description,
    AlertStart: isNullOrUndefined<Date | Timestamp>(model.AlertStart)
      ? null
      : dateUtils.formatISO(dateUtils.dateOrStringToDate(model.AlertStart)),
    AlertTitle: model.AlertTitle,
    AlertTypeId: model.WorkOrderType?.Id ?? -1,
    AlertType: model.WorkOrderType?.Name,
    StatusId: model.WorkOrderTypeStatus?.Id,
    StatusName: model.WorkOrderTypeStatus?.Name,
    AssetId: model.Assets[0].Id,
    AssetName: model.Assets[0].Name,
    AssignedUsers: model.AssignUsers as IUserFilterDto[],
    EventStart: isNullOrUndefined<Date | Timestamp>(model.EventStart)
      ? null
      : dateUtils.formatISO(dateUtils.dateOrStringToDate(model.EventStart)),
    OfflineEventStart: isNullOrUndefined<Date | Timestamp>(
      model.OfflineEventStart,
    )
      ? null
      : dateUtils.formatISO(
          dateUtils.dateOrStringToDate(model.OfflineEventStart),
        ),
    OfflineEventEnd:
      !isNullOrUndefined<Date | Timestamp>(model.OfflineEventStart) &&
      model.Downtime
        ? isNullOrUndefined<Date | Timestamp>(model.OfflineEventEnd)
          ? dateUtils.formatISO(MaxDate)
          : dateUtils.formatISO(
              dateUtils.dateOrStringToDate(model.OfflineEventEnd),
            )
        : null,
    OfflineRemarks: model.OfflineRemarks,
    RemindMeBefore: model.RemindBefore?.Id,
    InstrumentWasDown: model.Downtime || false,
    ReasonTypeId: model.ReasonType?.Id,
    ReasonTypeName: model.ReasonType?.Name,
    RepairCodes: model.RepairCodes as IRepairCodeDto[],
    SymptomCodes: model.SymptomCodes as ISymptomCodeDto[],
    ServiceGroupId: (model.Assets[0] as IAssetDto).ServiceGroupId,
    ServiceId: (model.Assets[0] as IAssetDto).ServiceId,
    ServiceTypeId: (model.Assets[0] as IAssetDto).ServiceTypeId,
    RecurringAssetId: model.RecurringAssetId,
    RecurringGroupId: model.RecurringGroupId,
    Repeat: model.RecurringGroupId !== null,
    RepeatAssets: model.RecurringAssetId !== null,
    FormValues: model.SubmissionFormValues.concat(model.StaffFormValues),
    ReportedBy: model.ReportedBy,
    Reservation: model.ReservationId,
    ShowOnCalibrationHistory: (model.WorkOrderType as IWorkOrderTypeDto)
      .ShowOnCalibrationHistory,
    NotEditable: (model.WorkOrderType as IWorkOrderTypeDto).NotEditable,
    TitleEnabled: (model.WorkOrderType as IWorkOrderTypeDto).TitleEnabled,
    EventTimeEnabled: (model.WorkOrderType as IWorkOrderTypeDto)
      .EventTimeEnabled,
    DowntimeEnabled: (model.WorkOrderType as IWorkOrderTypeDto).DowntimeEnabled,
    AssigneeEnabled: (model.WorkOrderType as IWorkOrderTypeDto).AssigneeEnabled,
  } as IWorkOrderDetailsDto;

  return entity;
}
export const getAlertStart = (
  remindBefore: number,
  Start: Date | Timestamp,
): Date | Timestamp | null => {
  let alertStart: Date | null = new Date();
  switch (remindBefore as RemindBefore) {
    case RemindBefore.doNotNotify:
      alertStart = null;
      break;
    case RemindBefore.oneday:
      alertStart = dateUtils.addDays(dateUtils.dateOrStringToDate(Start), -1);
      break;
    case RemindBefore.twodays:
      alertStart = dateUtils.addDays(dateUtils.dateOrStringToDate(Start), -2);
      break;
    case RemindBefore.threedays:
      alertStart = dateUtils.addDays(dateUtils.dateOrStringToDate(Start), -3);
      break;
    case RemindBefore.oneweek:
      alertStart = dateUtils.addDays(dateUtils.dateOrStringToDate(Start), -7);
      break;
    case RemindBefore.onemonth:
      alertStart = dateUtils.addMonths(dateUtils.dateOrStringToDate(Start), -1);
      break;
    default:
      alertStart = dateUtils.addDays(dateUtils.dateOrStringToDate(Start), -1);
      break;
  }
  return alertStart;
};

export const MaxDate: Date = dateUtils.set(new Date(), {
  year: 9999,
  month: 11,
  date: 31,
  hours: 0,
  minutes: 0,
  seconds: 0,
});
