import { Formik, FormikProps } from 'formik';
import * as React from 'react';

import { translations } from 'locales/translations';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import { useDispatch, useSelector } from 'react-redux';

import { isEmpty } from 'lodash';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRightSection,
  FormRow,
  StyledForm,
} from 'app/components/Forms/FormsLayout';
import { IndexNode } from 'app/components/Forms/FormsLayout/StyledForm';
import {
  FieldHandler,
  FieldValue,
  FormListener,
  SubmittingHandler,
} from 'app/components/Forms/FormRender/FormRenderer';
import { FormUserPicker } from 'app/components/Forms/FormUserPicker';
import { FormBudgetPicker } from 'app/components/Forms/FormBudgetPicker';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { ServicesFilter } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { dateUtils } from 'utils/date-utils';
import { FormRestrictedUserGroupPicker } from 'app/components/Forms/FormRestrictedUserGroupPicker';
import { RestrictedFilter } from 'app/components/pickers/AutocompletePickers/UserGroupPicker';
import { restrictedBudgetFilter } from 'app/components/pickers/AutocompletePickers/BudgetPicker';
import { FormFundingTypePicker } from 'app/components/Forms/FormFundingTypePicker';
import { FormLabelText } from 'app/components/Forms/FormLabelText';
import { Box } from '@material-ui/core';

import { Entity } from 'types/common';
import { EntityNumberSchema } from 'app/components/Forms/Schemas';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';

import { FormSwitch } from 'app/components/Forms/Switch';
import { FormRichTextField } from 'app/components/Forms/FormRichTextField';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';

import { FormAccompaniedServicesPicker } from 'app/components/Forms/FormAccompaniedServicesPicker';
import { ReservationsAccServicesFilter } from 'app/components/pickers/MultiSelectPickers/AccompaniedServicesPicker';

import { useSystemDate } from 'app/hooks/useSystemDate';
import { UserProfileLink } from 'app/pages/UserDetails/OpenUserDetails';
import produce from 'immer';
import { FormReservationBudgetExperimentPicker } from 'app/components/Forms/ReservationBudgetExperimentPicker';

import { useAppSettingsSlice } from 'app/slice';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import {
  ListMessagesProps,
  SingleMessagesProps,
} from 'app/Layout/FrontendLayout/components/Snackbar/Actions';
import { ErrorServices } from 'app/pages/OtherServiceDetails/Details/slice/types';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import {
  IUsageEquipmentDto,
  UsageChangeStateParameters,
  UsageDetailsState,
  UsageQueryStringParameters,
} from '../../slice/types';
import { useUsageStateSlice } from '../../slice';
import {
  selectAsyncChangeLoading,
  selectCreateManually,
  selectFieldHandler,
  selectIsEdit,
  selectRequestedValueFor,
  selectSavedOfflineServices,
  selectUsageChangesState,
  selectUsageCompleted,
  selectUsageHasError,
  selectUsageProcessing,
  selectUsageSettings,
} from '../../slice/selectors';
import { FormBookitDateTimePicker } from 'app/components/Forms/FormBookitDateTimePicker';
import { UsageOfflineServices } from '../UsageOfflineServices';
import { otheMessagesHandler } from 'app/pages/ReservationDetails/Details/ReservationForm/handlers';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { PurchaseOrderOptions } from 'api/odata/generated/enums/PurchaseOrderOptions';
import { FormTextField } from 'app/components/Forms/FormTextField';
import { BudgetListener } from 'app/pages/ReservationDetails/Details/components/FormFieldListeners/BudgetListener';
import { UserGroupListener } from 'app/pages/ReservationDetails/Details/components/FormFieldListeners/UserGroupListener';
import { UserNameListener } from 'app/pages/ReservationDetails/Details/components/FormFieldListeners/UserNameListener';
import { FormUsageEquipmentsPicker } from 'app/components/Forms/FormUsageEquipmentPicker';
import { TransformDate } from 'app/components/Forms/Schemas/SchemaMethods';
import { AssetLink } from 'app/pages/AssetPopUp/OpenAssetDetails';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { UsageReservationDetails } from '../UsageReservationDetails';
import { FormAdminSingleUsersPicker } from 'app/components/Forms/FormAdminUserPicker';
import { quoteODataValue } from 'api/odata/ODataFilter';

export interface UsageFormProps {
  onSubmit: (item: UsageDetailsState) => void;
  initialValues: UsageDetailsState;
  processing?: boolean;
  bindSubmitForm: any;
  isEdit: boolean;
  isAdmin: boolean;
  user?: AuthenticatedUser;
  globalSettings: GlobalSettingsType;
  formRef: React.MutableRefObject<FormikProps<UsageDetailsState> | null>;
  onEquipmentClicked?: (equipment: Entity<number>) => void;
  onRestrictionsClick?: (equipment: Entity<number>) => void;
  onOtherSrviceClick?: (service: IOtherServices) => void;
  onOfflineServiceClick?: (service: IOtherServices) => void;
  onAddBatchClick: (
    batch: IInventoryBatchDto | null,
    serviceTypeId: number,
  ) => void;
  editable?: boolean;
  useSidePanel: boolean;
  offlineServicesErrors: ErrorServices[];
  setError?: (error: ErrorServices) => void;
  isCover?: boolean;
  openPanelWithCover?: (state: SidePanelOpenState) => void;
}

export const UsageForm = React.memo(function UsageForm({
  onSubmit,
  processing,
  initialValues,
  bindSubmitForm,
  isAdmin,
  user,
  globalSettings,
  formRef,
  onEquipmentClicked,
  onRestrictionsClick,
  onOfflineServiceClick,
  editable,
  useSidePanel,
  onAddBatchClick,
  offlineServicesErrors,
  openPanelWithCover,
  setError,
  isCover,
}: UsageFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useUsageStateSlice();
  const { actions: appActions } = useAppSettingsSlice();
  const usageSettings = useSelector(selectUsageSettings);
  const asyncChangeLoading = useSelector(selectAsyncChangeLoading);
  const usageProcessing = useSelector(selectUsageProcessing);
  const fieldHandler = useSelector(selectFieldHandler);
  const requestedFor = useSelector(selectRequestedValueFor);
  const savedOfflineServices = useSelector(selectSavedOfflineServices);
  const hasError = useSelector(selectUsageHasError);
  const createMnually = useSelector(selectCreateManually);
  const insertUpdateCompleted = useSelector(selectUsageCompleted);
  const isEdit = useSelector(selectIsEdit);
  const usageChangeState = useSelector(selectUsageChangesState);
  const [submitting, setSubmitting] = React.useState<boolean | undefined>(
    undefined,
  );
  const { newDate } = useSystemDate();
  const resetSubmitting = () => {
    setSubmitting(true);
  };
  const hasLoadProcessing = React.useMemo(() => {
    return asyncChangeLoading || usageProcessing;
  }, [asyncChangeLoading, usageProcessing]);
  const [otherErrors, setOtherErrors] = React.useState<IndexNode[]>([]);
  const [otherWarnings, setOtherWarnings] = React.useState<IndexNode[]>([]);
  // const [otherInfo, setOtherInfo] = React.useState<IndexNode[]>([]);
  // const [otherSuccess, setOtherSuccess] = React.useState<IndexNode[]>([]);
  const errorMessages = React.useMemo(() => {
    return [...otherErrors];
  }, [otherErrors]);

  const resetAllErrors = () => {
    setOtherErrors([]);
    setOtherWarnings([]);
    // setOtherInfo([]);
    // setOtherSuccess([]);
  };
  const handleSubmit = (values: UsageDetailsState) => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(values);
    }
  };
  const resetFieldHandler = () => {
    dispatch(actions.resetValueHandler());
  };
  const reserValueKey = () => {
    dispatch(actions.resetRequestedFor());
  };
  const saveRequestedValue = (value: any) => {
    dispatch(actions.getAnyValueSuccess(value));
  };
  /// func Help ///
  const addOtheMessages = React.useCallback(
    (data: string[], type: 'warning' | 'error' | 'info' | 'success') => {
      if (data.length > 0) {
        otheMessagesHandler(data, type, (nodes: IndexNode[]) => {
          switch (type) {
            case 'error':
              setOtherErrors(() => nodes);
              break;
            case 'warning':
              setOtherWarnings(() => nodes);
              break;
            // case 'info':
            //   setOtherInfo(() => nodes);
            //   break;
            // case 'success':
            //   setOtherSuccess(() => nodes);
            //   break;
          }
        });
      }
    },
    [],
  );
  /// Local State ///
  const [userGroupChanged, setUserGroupChanged] = React.useState(false);
  const [bookedbyChanged, setBookedByChanged] = React.useState(false);
  const [serviceChanged, setServiceChanged] = React.useState(false);
  const [accServiceChanged, setAccServiceChanged] = React.useState(false);
  const [budgetChanged, setBudgetChanged] = React.useState(false);

  const [startTimeChanged, setStartTimeChanged] = React.useState(false);
  const [endTimeChanged, setEndTimeChanged] = React.useState(false);
  const [tutoringChanged, setTutoringChanged] = React.useState(false);

  const changeOfflineServices = React.useCallback(
    (services: IOtherServices[], operation: 'add' | 'remove' | 'update') => {
      if (operation === 'add') {
        let addedServ = services.filter(
          f =>
            !savedOfflineServices.some(
              s => s.ServiceTypeID === f.ServiceTypeID,
            ),
        );
        dispatch(
          actions.setSavedOfflineServices([
            ...savedOfflineServices,
            ...addedServ,
          ]),
        );
      }
      if (operation === 'remove') {
        dispatch(
          actions.setSavedOfflineServices(
            savedOfflineServices.filter(f => {
              return !services.some(a => a.ServiceTypeID === f.ServiceTypeID);
            }),
          ),
        );
      }
      if (operation === 'update') {
        dispatch(
          actions.setSavedOfflineServices(
            savedOfflineServices.map(f => {
              let service = services.find(
                s => s.ServiceTypeID === f.ServiceTypeID,
              );
              if (!!service && service !== null) {
                return Object.assign({}, f, service);
              } else {
                return f;
              }
            }),
          ),
        );
      }
    },
    [actions, dispatch, savedOfflineServices],
  );
  /// memo ///
  const isServiceAdmin = React.useMemo(() => {
    return usageSettings?.IsAllAdmin;
  }, [usageSettings?.IsAllAdmin]);
  const hasReservation = React.useMemo(() => {
    return (
      !!usageSettings &&
      usageSettings.ReservationId !== null &&
      usageSettings.ReservationId !== undefined
    );
  }, [usageSettings]);
  const fundingTypeVisible = React.useMemo(() => {
    return (
      globalSettings.purchaseOrderBudgetEnabled &&
      globalSettings.fundingTypeBudgetEnabled
    );
  }, [globalSettings]);
  const ReservationUserGroupGroupByBudgetUserGroup = React.useMemo(() => {
    return !!usageSettings
      ? globalSettings.reservationUserGroupGroupByBudgetUserGroup &&
          !usageSettings.HasHideProjects &&
          usageSettings.BudgetsTurnedOn
      : globalSettings.reservationUserGroupGroupByBudgetUserGroup;
  }, [
    usageSettings,
    globalSettings.reservationUserGroupGroupByBudgetUserGroup,
  ]);

  const userGroupVisible = React.useMemo(() => {
    return (
      isServiceAdmin &&
      !isEdit &&
      !ReservationUserGroupGroupByBudgetUserGroup &&
      !hasReservation
    );
  }, [
    isServiceAdmin,
    isEdit,
    ReservationUserGroupGroupByBudgetUserGroup,
    hasReservation,
  ]);

  const userVisible = React.useMemo(() => {
    return isServiceAdmin && !isEdit && !hasReservation;
  }, [isServiceAdmin, isEdit, hasReservation]);
  const remarksVisible = React.useMemo(() => {
    return (
      !isEdit || (isEdit && isServiceAdmin && !usageSettings?.OngoingUsage)
    );
  }, [isServiceAdmin, isEdit, usageSettings?.OngoingUsage]);
  const accServiceEditable = React.useMemo(() => {
    return !isEdit || (isEdit && isServiceAdmin && usageSettings?.PastUsage);
  }, [isEdit, isServiceAdmin, usageSettings?.PastUsage]);

  const startTimeEditable = React.useMemo(() => {
    return (
      isServiceAdmin &&
      ((isEdit && usageSettings?.PastUsage) || (!isEdit && createMnually))
    );
  }, [createMnually, isEdit, isServiceAdmin, usageSettings?.PastUsage]);

  const endTimeEditable = React.useMemo(() => {
    return (
      isServiceAdmin &&
      ((isEdit && usageSettings?.PastUsage) || (!isEdit && createMnually))
    );
  }, [createMnually, isEdit, isServiceAdmin, usageSettings?.PastUsage]);

  const budgetEditable = React.useMemo(() => {
    return (
      (!isEdit && !hasReservation) ||
      (isEdit && isServiceAdmin && usageSettings?.PastUsage)
    );
  }, [hasReservation, isEdit, isServiceAdmin, usageSettings?.PastUsage]);

  const tutoringVisible = React.useMemo(() => {
    return (
      globalSettings.assistedReservationsEnabled &&
      globalSettings.tutoringEnabled &&
      (isEdit || createMnually)
    );
  }, [
    createMnually,
    globalSettings.assistedReservationsEnabled,
    globalSettings.tutoringEnabled,
    isEdit,
  ]);
  const tutoringEditable = React.useMemo(() => {
    return isServiceAdmin && (!isEdit || (isEdit && usageSettings?.PastUsage));
  }, [isEdit, isServiceAdmin, usageSettings?.PastUsage]);
  const budgetExperimentVisible = React.useMemo(
    () => usageSettings?.budgetExperimentVisible,
    [usageSettings?.budgetExperimentVisible],
  );

  const warningMessages: IndexNode[] = React.useMemo(() => {
    let nodes: IndexNode[] = [];
    return [...nodes, ...otherWarnings];
  }, [otherWarnings]);
  // const infoMessages = React.useMemo(() => {
  //   let nodes: IndexNode[] = [];
  //   if (
  //     globalSettings.assistedReservationsEnabled &&
  //     !usageSettings?.IsAllAdmin &&
  //     usageSettings?.ForceTutoring &&
  //     !isEdit
  //   ) {
  //     nodes.push({
  //       index: 'asiistedTutorInfo',
  //       node: (
  //         <Box
  //           style={{
  //             display: 'flex',
  //             flexDirection: 'column',
  //             gap: 8,
  //           }}
  //         >
  //           <Body size="small" bold={true}>{`${
  //             t(translations.TutoringAssistedReservation) as string
  //           }`}</Body>
  //           <Caption>
  //             {
  //               t(
  //                 translations.AssistedReservationsPendingApprovalInfo,
  //               ) as string
  //             }
  //           </Caption>
  //         </Box>
  //       ),
  //       variant: 'outlined',
  //     });
  //   }

  //   return [...nodes, ...otherInfo];
  // }, [
  //   globalSettings.assistedReservationsEnabled,
  //   isEdit,
  //   otherInfo,
  //   t,
  //   usageSettings?.ForceTutoring,
  //   usageSettings?.IsAllAdmin,
  // ]);
  // const successMessages = React.useMemo(() => {
  //   let nodes: IndexNode[] = [];
  //   if (
  //     globalSettings.assistedReservationsEnabled &&
  //     usageSettings?.ForceTutoring &&
  //     isEdit
  //   ) {
  //     nodes.push({
  //       index: 'asiistedTutorApproved',
  //       node: (
  //         <Body size="small" bold={true}>
  //           {t(translations.ReservationAssistedApproved) as string}
  //         </Body>
  //       ),
  //       variant: 'outlined',
  //     });
  //   }
  //   return [...nodes, ...otherSuccess];
  // }, [
  //   globalSettings.assistedReservationsEnabled,
  //   isEdit,
  //   otherSuccess,
  //   t,
  //   usageSettings?.ForceTutoring,
  // ]);
  const usageChangeHandler = React.useCallback(
    (values: UsageDetailsState) => {
      let equip = values.BaseEquipment as IUsageEquipmentDto;
      const payload: UsageChangeStateParameters = {
        AccServices: values.AccServices.map(f => f.Id),
        Equipment: values.BaseEquipment?.Id ?? 0,
        UsageId: (values.Id ?? 0) > 0 ? values.Id : null,
        ReservationId: null,
        Start: dateUtils.formatISO(
          dateUtils.dateOrStringToDate(values.StartTime),
          { representation: 'complete' },
        ),
        End: !!values.EndTime
          ? dateUtils.formatISO(dateUtils.dateOrStringToDate(values.EndTime), {
              representation: 'complete',
            })
          : undefined,
        BookedBy: values.BookedBy?.Id || null,
        Tutoring: values.Tutoring,
        ADGroup: values.ADGroup?.Id || user?.ActiveUserGroup?.Id || null,
        FundingTypeId: values.FundingType?.Id || 0,
        Remarks: values.Remarks || null,
        BudgetId: values.Budget?.Id || null,
        AllowOverlappingUsages: equip.AllowOverlappingUsages,
      };
      resetAllErrors();
      dispatch(
        actions.changeUsageData({
          parameters: payload,
          errors: [],
          warnings: [],
          loadReservations: true,
        }),
      );
    },
    [actions, dispatch, user?.ActiveUserGroup?.Id],
  );

  const handleFormChange = React.useCallback(
    (
      values: UsageDetailsState,
      isValid,
      dirty,
      setValue,
      setTouched,
      validateField,
      setError,
      validate,
    ) => {
      if ((serviceChanged || accServiceChanged) && !isEdit) {
        resetAllErrors();
        dispatch(
          actions.initUsage({
            query: {
              id: undefined,
              sid: (values.BaseEquipment?.Id ?? 0).toString(),
              rId: undefined,
              cmd: 'details',
              tutor: values.Tutoring.toString(),
              start: values.StartTime.toString(),
              end: !!values.EndTime ? values.EndTime.toString() : undefined,
              remarks: values.Remarks,
              accid: values.AccServices.map(f => f.Id).join(','),
              ug: values.ADGroup?.Id,
              un: values.BookedBy?.Id,
              ft:
                values.FundingType === null || values.FundingType === undefined
                  ? undefined
                  : values.FundingType.Id.toString(),
              source: undefined,
              resourceChanged: true.toString(),
            } as UsageQueryStringParameters,
            globalSettings: globalSettings,
            prevState: values,
          }),
        );
        setServiceChanged(false);
        setAccServiceChanged(false);
      }
      if (accServiceChanged) {
        resetAllErrors();
        setAccServiceChanged(false);
      }
      if (userGroupChanged) {
        console.log('User Group Changed', values.ADGroup);
        setUserGroupChanged(false);
      }
      if (bookedbyChanged) {
        console.log('BookedBy Changed', values.BookedBy);
        if (values.BookedBy !== null) {
          usageChangeHandler(values);
        }
        resetAllErrors();
        setBookedByChanged(false);
      }
      if (budgetChanged) {
        console.log('Budget Changed', values.Budget);
        setBudgetChanged(false);
      }
      if (startTimeChanged) {
        resetAllErrors();
        console.log('Start Time Changed', values.StartTime);
        setStartTimeChanged(false);
      }
      if (endTimeChanged) {
        resetAllErrors();
        console.log('End Time Changed', values.EndTime);
        setEndTimeChanged(false);
      }
      if (tutoringChanged) {
        console.log('Tutoring Changed', values.Tutoring);
        dispatch(
          actions.setAnyValue({
            fieldKey: 'Operator',
            fieldValue: null,
          }),
        );
        setTutoringChanged(false);
      }
    },
    [
      accServiceChanged,
      actions,
      bookedbyChanged,
      budgetChanged,
      dispatch,
      endTimeChanged,
      globalSettings,
      isEdit,
      serviceChanged,
      startTimeChanged,
      tutoringChanged,
      usageChangeHandler,
      userGroupChanged,
    ],
  );

  /// useEffects
  React.useEffect(() => {
    if (formRef.current) {
      formRef.current.validateForm();
    }
  }, [formRef]);
  React.useEffect(() => {
    if (!!usageChangeState) {
      if (usageChangeState.ErrorMessages.length > 0) {
        addOtheMessages(usageChangeState.ErrorMessages, 'error');
      }
      if (usageChangeState.WarningMessages.length > 0) {
        addOtheMessages(usageChangeState.WarningMessages, 'warning');
      }
      if (
        usageChangeState.Reservation !== null &&
        usageChangeState.Reservation !== undefined
      ) {
        dispatch(
          actions.setAnyValue({
            fieldKey: 'AccServices',
            fieldValue: usageChangeState.Reservation.AccServices,
          }),
        );
        if (
          usageChangeState.Reservation.FundingTypeId !== undefined &&
          usageChangeState.Reservation.FundingTypeId !== null
        ) {
          dispatch(
            actions.setAnyValue({
              fieldKey: 'FundingType',
              fieldValue: getSingleInvoiceFundingType(
                usageChangeState.Reservation.FundingTypeId,
              ),
            }),
          );
        }

        dispatch(
          actions.setAnyValue({
            fieldKey: 'Budget',
            fieldValue: usageChangeState.Reservation.Budget,
          }),
        );
        dispatch(
          actions.setAnyValue({
            fieldKey: 'BudgetExperiment',
            fieldValue: usageChangeState.Reservation.BudgetExperiment,
          }),
        );
        dispatch(
          actions.setAnyValue({
            fieldKey: 'BookedBy',
            fieldValue: usageChangeState.Reservation.BookedBy,
          }),
        );
        dispatch(
          actions.setAnyValue({
            fieldKey: 'ADGroup',
            fieldValue: usageChangeState.Reservation.ADGroup,
          }),
        );
        dispatch(
          actions.setAnyValue({
            fieldKey: 'PurchaseOrder',
            fieldValue: usageChangeState.Reservation.PurchaseOrder,
          }),
        );
        dispatch(
          actions.setAnyValue({
            fieldKey: 'Reference',
            fieldValue: usageChangeState.Reservation.Reference,
          }),
        );
        if (
          globalSettings.assistedReservationsEnabled &&
          globalSettings.tutoringEnabled
        ) {
          dispatch(
            actions.setAnyValue({
              fieldKey: 'Tutoring',
              fieldValue: usageChangeState.Reservation.Tutoring,
            }),
          );
        }
        dispatch(
          actions.setAnyValue({
            fieldKey: 'InstituteProject',
            fieldValue: usageChangeState.Reservation.InstituteProject,
          }),
        );
        dispatch(
          actions.setAnyValue({
            fieldKey: 'ExternalCustomer',
            fieldValue: usageChangeState.Reservation.ExternalCustomer,
          }),
        );
        if (createMnually) {
          dispatch(
            actions.setAnyValue({
              fieldKey: 'StartTime',
              fieldValue: usageChangeState.Reservation.StartTime,
            }),
          );
          dispatch(
            actions.setAnyValue({
              fieldKey: 'EndTime',
              fieldValue: usageChangeState.Reservation.EndTime,
            }),
          );
        }
      } else {
        if (usageSettings?.BudgetVisible && !isEdit) {
          dispatch(
            actions.setAnyValue({
              fieldKey: 'Budget',
              fieldValue: usageChangeState.DefaultBudget,
            }),
          );
          if (
            fundingTypeVisible &&
            usageChangeState.DefaultBudget?.FundingTypeId !== undefined &&
            usageChangeState.DefaultBudget?.FundingTypeId !== null
          ) {
            dispatch(
              actions.setAnyValue({
                fieldKey: 'FundingType',
                fieldValue: getSingleInvoiceFundingType(
                  usageChangeState.DefaultBudget?.FundingTypeId,
                ),
              }),
            );
          }
          //budget = usageChangeState.DefaultBudget;
          if (
            ReservationUserGroupGroupByBudgetUserGroup &&
            usageChangeState.DefaultBudget !== null
          ) {
            dispatch(
              actions.setAnyValue({
                fieldKey: 'ADGroup',
                fieldValue: {
                  Id: usageChangeState.DefaultBudget.UserGroupId,
                  Name: usageChangeState.DefaultBudget.UserGroupName,
                },
              }),
            );
          }
        }
      }

      dispatch(actions.resetUsageChangeState());
    }
  }, [
    ReservationUserGroupGroupByBudgetUserGroup,
    actions,
    addOtheMessages,
    createMnually,
    dispatch,
    fundingTypeVisible,
    globalSettings.assistedReservationsEnabled,
    globalSettings.tutoringEnabled,
    isEdit,
    usageChangeState,
    usageSettings?.BudgetVisible,
  ]);
  const computedSchema = React.useMemo(() => {
    const usageSchema: Yup.SchemaOf<UsageDetailsState> = Yup.object({
      Id: Yup.number().notRequired().default(-1),
      BaseEquipment: (Yup.mixed() as Yup.SchemaOf<IUsageEquipmentDto>)
        .nullable(true)
        .label(t(translations.OnlineService) as string)
        .required(t(translations.err_OnlineServiceFieldRequired) as string),
      Budget: Yup.mixed()
        .label(t(translations.BudgetNumber) as string)
        .nullable(true),
      BudgetExperiment: Yup.mixed()
        .label(t(translations.BudgetExperiment) as string)
        .nullable(true)
        .when('Budget', {
          is: (Budget: IBudgetFilterDto | null) =>
            usageSettings?.BudgetVisible !== false &&
            usageSettings?.budgetExperimentVisible &&
            Budget !== null &&
            Budget.HideExperiments !== true,
          then: schema =>
            schema.required(
              t(translations.res_err_BudgetExperimentRequired) as string,
            ),
          otherwise: schema => schema,
        }),
      BookedBy: Yup.mixed()
        .label(t(translations.User) as string)
        .required(() => t(translations.err_UserFieldRequired) as string),

      Remarks: Yup.string()
        .nullable()
        .label(t(translations.Remarks) as string),
      StartTime: Yup.date()
        .default(newDate())
        .label(t(translations.StartTime) as string)
        .required(t(translations._errStartTimeRequired) as string),
      EndTime: Yup.date()
        .transform(TransformDate)
        .nullable()
        .optional()
        .label(t(translations.EndTime) as string),
      Tutoring: Yup.boolean()
        .label(t(translations.TutoringAssistedReservation) as string)
        .default(false),
      Operator: Yup.mixed()
        .label(t(translations.Operator) as string)
        .nullable(true)
        .when('Tutoring', {
          is: tutor => globalSettings.tutoringEnabled && tutor === true,
          then: schema => schema.required(),
        }),
      //.required(() => t(translations.TutorNameIsRequired) as string),
      ADGroup: Yup.mixed()
        .label(t(translations.UserGroup) as string)
        .nullable(true),
      InstituteProject: Yup.mixed()
        .label(t(translations.InstituteProject) as string)
        .nullable(true),
      ExternalCustomer: Yup.mixed()
        .label(t(translations.ExternalCustomer) as string)
        .nullable(true),
      FundingType: Yup.mixed().label(t(translations.FundingType) as string),
      PurchaseOrder: Yup.string()
        .label(t(translations.PurchaseOrder) as string)
        .nullable(true)
        .min(globalSettings.purchaseOrderLengthSetting ?? 0)
        .when('Budget', {
          is: budget => {
            const result =
              globalSettings.purchaseOrderBudgetEnabled &&
              (budget as IBudgetFilterDto)?.PurchaseOrderOptionId ===
                PurchaseOrderOptions.Mandatory;
            return result;
          },
          then: schema => schema.required(),
        }),
      Reference: Yup.string()
        .label(t(translations.Reference) as string)
        .nullable(true),
      AccServices: Yup.array()
        .of(EntityNumberSchema)
        .label(t(translations.AccompaniedService) as string),
    });
    if (usageSettings?.BudgetVisible === true) {
      usageSchema.fields.Budget.withMutation(schema =>
        schema.required(t(translations.BudgetIsRequired) as string),
      );
    }
    if (globalSettings.accServicesEnabled && usageSettings?.HasAccServices) {
      usageSchema.fields.AccServices.withMutation(schema =>
        schema
          .min(1, t(translations.err_AccompaniedService_required) as string)
          .required(t(translations.err_AccompaniedService_required) as string),
      );
    }
    if (isServiceAdmin && isEdit && usageSettings?.PastUsage) {
      usageSchema.fields.EndTime.withMutation(schema =>
        schema
          .required(t(translations._errEndTimeRequired) as string)
          .min(
            Yup.ref('StartTime'),
            t(translations.err_EndTimeLowerThanStartTime) as string,
          ),
      );
    }
    return usageSchema;
  }, [
    globalSettings.accServicesEnabled,
    globalSettings.purchaseOrderBudgetEnabled,
    globalSettings.purchaseOrderLengthSetting,
    globalSettings.tutoringEnabled,
    isEdit,
    isServiceAdmin,
    newDate,
    t,
    usageSettings?.BudgetVisible,
    usageSettings?.HasAccServices,
    usageSettings?.PastUsage,
    usageSettings?.budgetExperimentVisible,
  ]);
  return (
    <>
      <Formik
        validationSchema={computedSchema}
        initialValues={initialValues}
        validateOnMount={true}
        validateOnBlur={false}
        validateOnChange={true}
        enableReinitialize
        innerRef={formRef}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          formikHelpers.validateForm(values).then(responseErrors => {
            if (!isEmpty(responseErrors)) {
              formikHelpers.setSubmitting(false);
            } else if (errorMessages.length > 0) {
              formikHelpers.setSubmitting(false);
            } else if (offlineServicesErrors.length > 0) {
              formikHelpers.setSubmitting(false);
              dispatch(
                appActions.addNotification({
                  variant: 'error',
                  message: '',
                  messageType: SnackBarMessageType.messageList,
                  messageTypeProps: {
                    messages: offlineServicesErrors.map(f => {
                      return {
                        Id: f.id.toString(),
                        message: f.error,
                      } as SingleMessagesProps;
                    }),
                  } as ListMessagesProps,
                }),
              );
            } else {
              formikHelpers.setSubmitting(true);
              //   dispatch(actions.resetOperateState());
              if (submitting) {
                setSubmitting(undefined);
              }
              handleSubmit(values);
            }
          });
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          if (formik.errors) {
            console.log('Reservation errors', formik.errors);
          }
          return (
            <React.Fragment>
              <StyledForm onSubmit={formik.handleSubmit} isCover={isCover}>
                <>
                  <FormListener
                    onFormChange={handleFormChange}
                    fields={[
                      'BaseEquipment',
                      'ADGroup',
                      'BookedBy',
                      'StartTime',
                      'EndTime',
                      'AccServices',
                      'Tutoring',
                    ]}
                  />
                  <FieldHandler
                    {...fieldHandler}
                    resetFieldState={resetFieldHandler}
                  />
                  <FieldValue
                    setValue={saveRequestedValue}
                    resetValueKey={reserValueKey}
                    fieldKey={requestedFor}
                  />
                  <SubmittingHandler
                    value={
                      hasError === true &&
                      insertUpdateCompleted === true &&
                      formik.isSubmitting &&
                      submitting === undefined
                    }
                    resetSubmitting={resetSubmitting}
                  />
                  <BudgetListener
                    budget={formik.values.Budget}
                    ReservationUserGroupGroupByBudgetUserGroup={
                      ReservationUserGroupGroupByBudgetUserGroup
                    }
                    setUserGroup={value =>
                      formik.setFieldValue('ADGroup', value)
                    }
                  />
                  <UserGroupListener
                    userName={formik.values.BookedBy}
                    equipments={
                      formik.values.BaseEquipment !== null &&
                      formik.values.BaseEquipment !== undefined
                        ? [formik.values.BaseEquipment]
                        : []
                    }
                    userGroup={formik.values.ADGroup}
                    globalSettings={globalSettings}
                    settings={
                      !!usageSettings
                        ? {
                            HasHideProjects: usageSettings.HasHideProjects,
                            HasConflictProjects: false,
                            BudgetsTurnedOn: usageSettings.BudgetsTurnedOn,
                          }
                        : undefined
                    }
                    user={user}
                    isEdit={isEdit}
                  />
                  <UserNameListener
                    userName={formik.values.BookedBy}
                    equipments={
                      formik.values.BaseEquipment !== null &&
                      formik.values.BaseEquipment !== undefined
                        ? [formik.values.BaseEquipment]
                        : []
                    }
                    reservationUserGroupGroupByBudgetUserGroup={
                      ReservationUserGroupGroupByBudgetUserGroup
                    }
                    globalSettings={globalSettings}
                    user={user}
                    isEdit={isEdit}
                    values={formik.values}
                  />
                </>
                <FormLeftSection
                  warningNodes={warningMessages}
                  //infoNodes={infoMessages}
                  errors={errorMessages}
                  //successNodes={successMessages}
                  isCover={isCover}
                >
                  <FormFieldsSection>
                    {!isEdit ? (
                      <FormRow fullRow={true} minRow>
                        <FormUsageEquipmentsPicker
                          name="BaseEquipment"
                          label={t(translations.OnlineService)}
                          placeholder={t(translations.PleaseSelectServices)}
                          disabled={
                            formik.isSubmitting ||
                            (isEdit && !editable) ||
                            hasLoadProcessing
                          }
                          onChange={() => setServiceChanged(true)}
                          onChipClick={onEquipmentClicked}
                          autoExpand={'AccServices'}
                          fullWidth
                        />
                      </FormRow>
                    ) : (
                      <FormRow fullRow={true} minRow>
                        <FormLabelText
                          name="BaseEquipment"
                          label={t(translations.OnlineService)}
                          text={
                            !isServiceAdmin
                              ? formik.values.BaseEquipment != null &&
                                formik.values.BaseEquipment !== undefined
                                ? formik.values.BaseEquipment.Name
                                : 'N/A'
                              : undefined
                          }
                          html={
                            isServiceAdmin &&
                            formik.values.BaseEquipment !== null &&
                            formik.values.BaseEquipment !== undefined ? (
                              <AssetLink
                                serviceType={'Online'}
                                serviceId={formik.values.BaseEquipment.Id}
                                openPanelWithCover={openPanelWithCover}
                                useSidePanel={useSidePanel}
                              >
                                {formik.values.BaseEquipment.Name}
                              </AssetLink>
                            ) : undefined
                          }
                        />
                      </FormRow>
                    )}

                    {globalSettings.accServicesEnabled &&
                      usageSettings?.HasAccServices &&
                      formik.values.BaseEquipment !== null && (
                        <React.Fragment>
                          {accServiceEditable ? (
                            <FormRow fullRow={true}>
                              <FormAccompaniedServicesPicker
                                predicates={ReservationsAccServicesFilter([
                                  formik.values.BaseEquipment.Id,
                                ])}
                                name="AccServices"
                                label={t(translations.AccompaniedService)}
                                placeholder={t(
                                  translations.PleaseSelectAnAccompaniedService,
                                )}
                                disabled={
                                  formik.isSubmitting ||
                                  (isEdit && !editable) ||
                                  hasLoadProcessing
                                }
                                onChange={() => setAccServiceChanged(true)}
                                multiple={
                                  usageSettings.HasAccServices &&
                                  !usageSettings.AllowOneAccSerOnly
                                }
                                fullWidth
                              />
                            </FormRow>
                          ) : (
                            <FormRow fullRow={true} minRow>
                              <FormLabelText
                                name="AccServices"
                                label={t(translations.AccompaniedService)}
                                text={formik.values.AccServices[0].Name}
                              />
                            </FormRow>
                          )}
                        </React.Fragment>
                      )}
                    <React.Fragment>
                      {startTimeEditable && (
                        <FormRow>
                          <FormBookitDateTimePicker
                            fullWidth
                            id="usageStartTimeID"
                            name="StartTime"
                            labelInline
                            label={t(translations.StartTime) as string}
                            onChange={() => setStartTimeChanged(true)}
                            disabled={
                              formik.isSubmitting ||
                              (isEdit && !editable) ||
                              hasLoadProcessing
                            }
                          />
                        </FormRow>
                      )}
                      {!endTimeEditable &&
                        isServiceAdmin &&
                        (createMnually || isEdit) && (
                          <FormRow>
                            <FormLabelText
                              name="StartTime"
                              label={t(translations.StartTime)}
                              text={dateUtils.shortDateTimeFormat(
                                formik.values.StartTime,
                              )}
                            />
                          </FormRow>
                        )}
                    </React.Fragment>
                    {endTimeEditable && (
                      <FormRow>
                        <FormBookitDateTimePicker
                          name="EndTime"
                          id="usageEndTimeID"
                          label={t(translations.EndTime)}
                          labelInline
                          disabled={
                            formik.isSubmitting ||
                            (isEdit && !editable) ||
                            hasLoadProcessing
                          }
                          onChange={() => setEndTimeChanged(true)}
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {!endTimeEditable &&
                      isServiceAdmin &&
                      (createMnually || isEdit) && (
                        <FormRow>
                          <FormLabelText
                            name="EndTime"
                            label={t(translations.EndTime)}
                            text={
                              !!formik.values.EndTime
                                ? dateUtils.shortDateTimeFormat(
                                    formik.values.EndTime,
                                  )
                                : (t(translations.NA) as string)
                            }
                          />
                        </FormRow>
                      )}

                    {userGroupVisible ? (
                      <FormRow>
                        <FormRestrictedUserGroupPicker
                          name="ADGroup"
                          label={t(translations.UserGroup)}
                          predicates={RestrictedFilter(
                            undefined,
                            undefined,
                            undefined,
                            globalSettings.onlyOwnUserGroupsAllowed,
                          )}
                          disabled={
                            formik.isSubmitting ||
                            (isEdit && !editable) ||
                            hasLoadProcessing
                          }
                          onChange={() => setUserGroupChanged(true)}
                          fullWidth
                        />
                      </FormRow>
                    ) : (
                      <FormRow>
                        <FormLabelText
                          id="UserGroupId"
                          name="ADGroup"
                          label={t(translations.UserGroup)}
                          info={
                            ReservationUserGroupGroupByBudgetUserGroup
                              ? t(translations.SponsorDepartment_Desc)
                              : undefined
                          }
                          html={
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              {formik.values.ADGroup !== undefined &&
                              formik.values.ADGroup !== null
                                ? formik.values.ADGroup.Name
                                : (t(translations.NA) as string)}
                            </Box>
                          }
                        />
                      </FormRow>
                    )}
                    {userVisible && (
                      <FormRow>
                        <FormUserPicker
                          name="BookedBy"
                          info={t(translations.ReservationUser_Desc)}
                          selectArray={[
                            'Id',
                            'Name',
                            'UserGroupId',
                            'UserGroupName',
                            'UserGroups',
                            'Budgets',
                          ]}
                          predicates={ServicesFilter(
                            (formik.values.BaseEquipment !== null &&
                            formik.values.BaseEquipment !== undefined
                              ? [
                                  formik.values
                                    .BaseEquipment as IUsageEquipmentDto,
                                ]
                              : []
                            ).map(f => {
                              return {
                                Id: f.Id,
                                Name: f.Name,
                                ServiceTypeId: 1,
                                ServiceGroupId: f.ServiceGroupId,
                                HideProject: f.HideProjects,
                                Billable: f.Billable,
                                BudgetsTurnedOn: f.BudgetsTurnedOn,
                              } as IServiceTypeFilterDto;
                            }),
                            globalSettings.budgetExperimentModuleEnabled,
                            formik.values.ADGroup?.Id,
                            formik.values.Budget?.Id,
                          )}
                          label={t(translations.User)}
                          disabled={
                            formik.isSubmitting ||
                            (isEdit && !editable) ||
                            hasLoadProcessing
                          }
                          onChange={value => {
                            setBookedByChanged(true);
                          }}
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {!userVisible && isServiceAdmin && (
                      <FormRow hide={!isServiceAdmin}>
                        <FormLabelText
                          id="bookedById"
                          name="Bookedby"
                          label={t(translations.User) as string}
                          info={t(translations.ReservationUser_Desc) as string}
                          html={
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              {isServiceAdmin &&
                              formik.values.BookedBy !== null ? (
                                <UserProfileLink
                                  userName={formik.values.BookedBy.Id}
                                  displayName={formik.values.BookedBy.Name}
                                  twoRows={false}
                                />
                              ) : (
                                <React.Fragment>
                                  {formik.values.BookedBy !== null
                                    ? formik.values.BookedBy.Name
                                    : (t(translations.NA) as string)}
                                </React.Fragment>
                              )}
                            </Box>
                          }
                        />
                      </FormRow>
                    )}

                    {usageSettings?.BudgetVisible && fundingTypeVisible && (
                      <React.Fragment>
                        {budgetEditable ? (
                          <FormRow>
                            <FormFundingTypePicker
                              name="FundingType"
                              label={t(translations.FundingType)}
                              disabled={
                                formik.isSubmitting ||
                                (isEdit && !editable) ||
                                hasLoadProcessing
                              }
                              onChange={value => {
                                formik.setValues(
                                  produce(values => {
                                    // clear budget selection when funding type is changed to an incompatible value
                                    // for example, if an external budget is selected, and funding type is changed to internal - budget should be reset
                                    if (
                                      (value as Entity<number>).Id !==
                                      (values.Budget as IBudgetFilterDto)
                                        ?.FundingTypeId
                                    ) {
                                      values.Budget = null;
                                    }
                                    values.FundingType = value;
                                  }),
                                );
                              }}
                              fullWidth
                            />
                          </FormRow>
                        ) : (
                          <FormRow>
                            <FormLabelText
                              name="FundingType"
                              label={t(translations.FundingType)}
                              text={
                                formik.values.FundingType?.Name ??
                                (t(translations.NA) as string)
                              }
                            />
                          </FormRow>
                        )}
                      </React.Fragment>
                    )}
                    {usageSettings?.BudgetVisible && (
                      <React.Fragment>
                        {budgetEditable ? (
                          <FormRow>
                            <FormBudgetPicker
                              name="Budget"
                              predicates={restrictedBudgetFilter({
                                budgetLimitedByUsers:
                                  globalSettings.budgetLimitedByUsers,
                                isBudgetsWithoutUserGroup:
                                  globalSettings.isBudgetsWithoutUserGroup,
                                userGroupId:
                                  ReservationUserGroupGroupByBudgetUserGroup
                                    ? undefined
                                    : formik.values.ADGroup?.Id,
                                serviceGroupId: undefined,
                                services: (formik.values.BaseEquipment !==
                                  null &&
                                formik.values.BaseEquipment !== undefined
                                  ? [
                                      formik.values
                                        .BaseEquipment as IUsageEquipmentDto,
                                    ]
                                  : []
                                ).map(f => {
                                  return {
                                    Id: f.Id,
                                    Name: f.Name,
                                    ServiceTypeId: 1,
                                    ServiceGroupId: f.ServiceGroupId,
                                    HideProject: f.HideProjects,
                                    Billable: f.Billable,
                                    BudgetsTurnedOn: f.BudgetsTurnedOn,
                                  } as IServiceTypeFilterDto;
                                }),
                                startTime: dateUtils.dateOrStringToDate(
                                  formik.values.StartTime,
                                ),
                                endTime: !!formik.values.EndTime
                                  ? dateUtils.dateOrStringToDate(
                                      formik.values.EndTime,
                                    )
                                  : undefined,
                                user: isServiceAdmin
                                  ? formik.values.BookedBy === null
                                    ? undefined
                                    : formik.values.BookedBy.Id
                                  : user?.Id,
                                defaultBudgetEnabled:
                                  globalSettings.defaultBudgetEnabled,
                                userDefaultBudgetEnabled:
                                  globalSettings.userDefaultBudgetEnabled,
                                hideNonDefaultBudgets:
                                  globalSettings.hideNonDefaultBudgets,
                                // funding type is set to Internal by default even if it's not in use
                                fundingType: fundingTypeVisible
                                  ? formik.values.FundingType
                                  : null,
                              })}
                              label={t(translations.BudgetNumber)}
                              onChange={() => {
                                setBudgetChanged(true);
                              }}
                              disabled={
                                formik.isSubmitting ||
                                (isEdit && !editable) ||
                                hasLoadProcessing
                              }
                              useSearchOrPredicate={
                                globalSettings.hideNonDefaultBudgets
                              }
                              fullWidth
                            />
                          </FormRow>
                        ) : (
                          <FormRow>
                            <FormLabelText
                              name="Budget"
                              label={t(translations.BudgetNumber)}
                              text={
                                formik.values.Budget?.Name ??
                                (t(translations.NA) as string)
                              }
                            />
                          </FormRow>
                        )}
                      </React.Fragment>
                    )}
                    {usageSettings?.BudgetVisible &&
                      budgetExperimentVisible &&
                      !(
                        (formik.values.Budget as IBudgetFilterDto)
                          ?.HideExperiments ?? true
                      ) && (
                        <React.Fragment>
                          {budgetEditable ? (
                            <FormRow>
                              <FormReservationBudgetExperimentPicker
                                fullWidth
                                name="BudgetExperiment"
                                isEdit={isEdit}
                                placeholder={t(translations.BudgetExperiment)}
                                label={t(translations.BudgetExperiment)}
                                disabled={
                                  formik.isSubmitting ||
                                  (isEdit && !editable) ||
                                  hasLoadProcessing
                                }
                                serviceId={
                                  formik.values.BaseEquipment !== null &&
                                  formik.values.BaseEquipment !== undefined
                                    ? [formik.values.BaseEquipment]
                                    : []
                                }
                                budgetId={formik.values.Budget?.Id ?? null}
                                userId={formik.values.BookedBy?.Id ?? null}
                                start={formik.values.StartTime}
                                end={
                                  formik.values.EndTime ||
                                  formik.values.StartTime
                                }
                              />
                            </FormRow>
                          ) : (
                            <FormRow>
                              <FormLabelText
                                name="BudgetExperiment"
                                label={t(translations.BudgetExperiment)}
                                text={
                                  formik.values.BudgetExperiment?.Name ??
                                  (t(translations.NA) as string)
                                }
                              />
                            </FormRow>
                          )}
                        </React.Fragment>
                      )}
                    {globalSettings.purchaseOrderBudgetEnabled &&
                      ((formik.values.Budget as IBudgetFilterDto)
                        ?.PurchaseOrderOptionId ??
                        PurchaseOrderOptions.Hidden) !==
                        PurchaseOrderOptions.Hidden && (
                        <React.Fragment>
                          {budgetEditable ? (
                            <FormRow>
                              <FormTextField
                                name="PurchaseOrder"
                                label={t(translations.PurchaseOrder)}
                                disabled={
                                  formik.isSubmitting ||
                                  (isEdit && !editable) ||
                                  hasLoadProcessing
                                }
                                fullWidth
                              />
                            </FormRow>
                          ) : (
                            <FormRow>
                              <FormLabelText
                                name="PurchaseOrder"
                                label={t(translations.PurchaseOrder)}
                                text={
                                  formik.values.PurchaseOrder ??
                                  (t(translations.NA) as string)
                                }
                              />
                            </FormRow>
                          )}
                        </React.Fragment>
                      )}
                    {tutoringVisible && (
                      <React.Fragment>
                        {tutoringEditable && (
                          <FormRow>
                            <FormSwitch
                              boldLebel
                              name="Tutoring"
                              id="tutoringSwitchId"
                              maxLabelWidth={40}
                              label={t(translations.Tutoring)}
                              disabled={
                                formik.isSubmitting ||
                                (isEdit && !editable) ||
                                hasLoadProcessing
                              }
                              onChange={() => setTutoringChanged(true)}
                            />
                          </FormRow>
                        )}
                        {!tutoringEditable && isEdit && (
                          <FormRow>
                            <FormLabelText
                              name="Tutoring"
                              label={t(translations.Tutoring)}
                              text={
                                formik.values.Tutoring
                                  ? (t(translations.Yes) as string)
                                  : (t(translations.No) as string)
                              }
                            />
                          </FormRow>
                        )}
                        {formik.values.Tutoring &&
                          formik.values.BaseEquipment !== null &&
                          formik.values.BaseEquipment !== undefined && (
                            <FormRow>
                              <FormAdminSingleUsersPicker
                                name="Operator"
                                label={t(translations.Operator)}
                                placeholder={t(
                                  translations.PleaseSelectUserOperator,
                                )}
                                disabled={
                                  formik.isSubmitting ||
                                  (isEdit && !editable) ||
                                  hasLoadProcessing
                                }
                                predicates={[
                                  `(ServiceGroupIds/any(g: g in (${quoteODataValue(
                                    (
                                      formik.values
                                        .BaseEquipment as IUsageEquipmentDto
                                    ).ServiceGroupId,
                                  )})))
                                 or (Services/any(s: s/Id in (${quoteODataValue(
                                   formik.values.BaseEquipment.Id,
                                 )})))`,
                                ]}
                                fullWidth
                              />
                            </FormRow>
                          )}
                      </React.Fragment>
                    )}
                    {remarksVisible ? (
                      <FormRow fullRow={true}>
                        <FormRichTextField
                          name="Remarks"
                          placeholder={t(translations.Remarks)}
                          disabled={
                            formik.isSubmitting ||
                            (isEdit && !editable) ||
                            hasLoadProcessing
                          }
                          fullWidth={true}
                        />
                      </FormRow>
                    ) : (
                      <React.Fragment>
                        {formik.values.Remarks && (
                          <FormRow>
                            <FormLabelText
                              name="Remarks"
                              label={t(translations.Remarks)}
                              text={formik.values.Remarks}
                            />
                          </FormRow>
                        )}
                      </React.Fragment>
                    )}
                  </FormFieldsSection>
                  {globalSettings.offlineServiceWithReservation &&
                    !!usageSettings &&
                    usageSettings?.OfflineServicesVisible && (
                      <UsageOfflineServices
                        hanleUpdateLocalServices={changeOfflineServices}
                        onServiceClick={onOfflineServiceClick}
                        usageSettings={usageSettings}
                        globalSettings={globalSettings}
                        isEdit={isEdit}
                        onAddBatchClick={onAddBatchClick}
                        values={formik.values}
                        title={t(translations.UsageConsumables) as string}
                        setError={setError}
                        disabled={
                          formik.isSubmitting ||
                          (isEdit && !editable) ||
                          hasLoadProcessing ||
                          usageSettings.OngoingUsage
                        }
                      />
                    )}
                </FormLeftSection>

                <FormRightSection isCover={isCover}>
                  {!!usageSettings &&
                    !!usageSettings.Reservation &&
                    usageSettings.Reservation !== null && (
                      <FormFieldsSection>
                        <UsageReservationDetails
                          details={usageSettings.Reservation}
                          openPanel={openPanelWithCover}
                          useSidePanel={useSidePanel}
                        />
                      </FormFieldsSection>
                    )}
                </FormRightSection>
              </StyledForm>
            </React.Fragment>
          );
        }}
      </Formik>
    </>
  );
});
