/**
 *
 * Copyright
 *
 */
import { useTheme } from '@material-ui/core';
import { selectLastCodeUpdate, selectWebVersion } from 'app/slice/selectors';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';

import { dateUtils } from 'utils/date-utils';
import { Link } from '../ExternalLink';
import BasicTypography from '../Typography/BasicTypography';
import { messages } from './messages';
import { CopyrightSection } from './styled';
import { useEffectOnMount } from 'app/hooks/useEffectOnMount';

export interface CopyrightProps {
  disableVersionOnMobile?: boolean;
}

export function Copyright(props: CopyrightProps) {
  const { t } = useTranslation();
  const webVersion = useSelector(selectWebVersion);
  const lastUpdate = useSelector(selectLastCodeUpdate);

  const theme = useTheme();
  return (
    <CopyrightSection theme={theme} className={'copyrightSection'}>
      <BasicTypography variant="tipsBold" color="secondary" align="left">
        <Link href="http://bookit-lab.com" target="_blank">
          {t(messages.bookitlabCopyrightNotice())}
        </Link>
        <>{` `}</>
        <span>{t(messages.softwareBy())}</span>
        <>{` `}</>
        <Link href="http://prog4biz.com/" target="_blank">
          {t(messages.prog4biz())}
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </BasicTypography>
      {!props.disableVersionOnMobile && (
        <BasicTypography variant="tipsBold" color="secondary" align="right">
          <span>v{webVersion}</span>&ensp;
          <span>
            {t(messages.systemTime())}: <Clock />
          </span>
          &emsp;
          {lastUpdate && (
            <span>
              {t(messages.lastCodeUpdate())}:
              {dateUtils.fullDateTimeFormat(lastUpdate)}
            </span>
          )}
        </BasicTypography>
      )}
      <span>
        <BasicTypography variant="tipsBold" align="right">
          <Link href="/TermsOfUseAgreement" target="_blank">
            {t(messages.termOfUse())}
          </Link>
        </BasicTypography>
      </span>
    </CopyrightSection>
  );
}

function Clock() {
  const [now, setNow] = React.useState<Date>(new Date());
  useEffectOnMount(() => {
    const interval = setInterval(() => {
      setNow(new Date());
    }, 60000);

    return () => {
      clearInterval(interval);
    };
  });

  return <>{dateUtils.fullDateTimeFormat(now)}</>;
}
