import { PayloadAction } from '@reduxjs/toolkit';
import { AppSettings, AppSettingsErrorType } from 'types/AppSettings';
import { IAuthenticatedUser } from 'types/AuthenticatedUser';
import { Entity } from 'types/common';
import { Notification } from 'types/Notification';
import { SiteMapSettings } from 'types/SiteMapSettings';
import { UserProfileSettings } from 'types/UserProfileSettings';
import { createSlice } from 'utils/@reduxjs/toolkit';
import { SettingModel } from 'utils/globalSettings/SettingModel';
import { useInjectReducer, useInjectSaga } from 'utils/redux-injectors';
import { getAppSettingsFromSaga } from './saga';
import {
  AppSettingsState,
  LoginSettingsModel,
  LoginTypes,
  SaveUserProfileSettingAction,
  ScanTarget,
} from './types';
import * as H from 'history';
import { SavedViewSelected } from 'app/pages/SavedViewsPage/SavedViewPage/slice/types';
import { getCookieLocale } from 'utils/cookies';

export const initialState: AppSettingsState = {
  error: null,
  loadingAppSettings: true,
  loadingAuthenticatedUser: false,
  loadingSiteMapSettings: false,
  loadingEventsCount: false,
  loadingUserProfileSettings: false,
  appSettings: null,
  notifications: [],
  siteMapSettings: null,
  eventsCount: 0,
  userProfileSettings: [],
  updatedUserProfileSettings: false,
  isExpanded: false,
  globalServiceGroupFilter: undefined,
  specificServiceGroupFilter: undefined,
  showGlobalServiceGroupFilter: false,
  allServiceGroupsSelectable: undefined,
  systemSettings: [],
  loadingSystemSettings: false,
  isAuthenticated: undefined,
  isFullScreen: false,
  barcodeScanTarget: ScanTarget.All,
  showAllCores: false,
  subBarCollapsed: undefined,
  updatedExpandState: undefined,
  skipLocationChanged: undefined,
  currentLocaleId: getCookieLocale(),
  manualScanProcessing: undefined,
};

let notificationsCount = 1;
const slice = createSlice({
  name: 'appSettings',
  initialState,
  reducers: {
    loadAppSettings(state, action: PayloadAction<boolean>) {
      state.loadingAppSettings = true;
    },
    appSettingsLoaded(state, action: PayloadAction<AppSettings>) {
      const appSettings = action.payload;
      state.appSettings = appSettings;
      state.loadingAppSettings = false;
    },
    loadError(state, action: PayloadAction<AppSettingsErrorType>) {
      //TODO: process error state - show feedback?
      state.error = action.payload.message;
      state.loadingAppSettings = false;
    },
    getAuthenticatedUser(state, action: PayloadAction<boolean>) {
      state.loadingAuthenticatedUser = true;
      state.authenticatedUser = undefined;
    },
    getAuthenticatedUser_Success(
      state,
      action: PayloadAction<IAuthenticatedUser>,
    ) {
      const LoginType = LoginTypes.Password;
      state.loadingAuthenticatedUser = false;
      state.isAuthenticated = true;
      state.authenticatedUser = { ...action.payload, LoginType };
    },
    getAuthenticatedUser_Error(state, action: PayloadAction<string>) {
      state.loadingAuthenticatedUser = false;
    },
    setIsAuthenticated(state, action: PayloadAction<boolean>) {},
    IsAuthenticatedChanged(state, action: PayloadAction<boolean>) {
      state.isAuthenticated = action.payload;
    },
    setLocale(state, action: PayloadAction<string>) {
      state.currentLocaleId = action.payload;
    },
    addNotification(state, action: PayloadAction<Notification>) {
      // remove notification with the same key if exists
      const key = action.payload.key ?? notificationsCount++;
      const index = state.notifications.findIndex(x => x.key === key);
      if (index >= 0) {
        state.notifications.splice(index, 1);
      }
      // add new notification
      state.notifications.push({
        key: key,
        ...action.payload,
      });
    },
    addNotifications(state, action: PayloadAction<Notification[]>) {
      action.payload.forEach(item =>
        state.notifications.push({
          key: notificationsCount++,
          ...item,
        }),
      );
    },
    removeNotification(state, action: PayloadAction<any>) {
      const index = state.notifications.findIndex(
        x => x.key === action.payload,
      );
      if (index >= 0) {
        state.notifications.splice(index, 1);
      }
    },
    loadSiteMapSettings(state) {
      state.loadingSiteMapSettings = true;
    },
    loadSiteMapSettings_Success(state, action: PayloadAction<SiteMapSettings>) {
      const siteMap = action.payload;
      state.siteMapSettings = siteMap;
      state.loadingSiteMapSettings = false;
    },
    loadSiteMapSettings_Error(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loadingSiteMapSettings = false;
    },
    saveUserProfileSetting(state, action: SaveUserProfileSettingAction) {
      function upsert<T>(array: Array<T>, item: T, keyFn: (T) => string) {
        const key = keyFn(item);
        const e = array.find(a => keyFn(a) === key);
        if (e === undefined) {
          return array.push(item);
        } else {
          Object.assign(e, item);
        }
      }
      upsert<UserProfileSettings>(
        state.userProfileSettings,
        {
          ...{
            Id: 0,
            Name: null,
            AdGroup: null,
            DefaultGroups: null,
            Enabled: true,
            IsMultiple: false,
            Selected: false,
            StaffEnabled: false,
          },
          ...action.payload,
        },
        f => f.Key?.toLowerCase(),
      );
    },
    saveFilter(
      state,
      action: PayloadAction<{
        key: string;
        location: H.LocationDescriptorObject<unknown>;
        search: string;
      }>,
    ) {
      const itemIndex =
        state.userProfileSettings.findIndex(
          item => item.Key.toLowerCase() === action.payload.key.toLowerCase(),
        ) ?? -1;
      if (itemIndex >= 0) {
        let updatedItem = state.userProfileSettings.find(
          item => item.Key.toLowerCase() === action.payload.key.toLowerCase(),
        );
        if (!!updatedItem) {
          updatedItem.Value = action.payload.search;
          state.userProfileSettings.splice(itemIndex, 1);
          state.userProfileSettings.push(updatedItem);
        }
      } else {
        let item = {
          UserName: state.authenticatedUser?.DisplayName,
          Id: 0,
          Key: action.payload.key,
          Value: action.payload.search,
        } as UserProfileSettings;
        state.userProfileSettings.push(item);
      }
      // state.userProfileSettings.forEach(item => {
      //   if (item.Key.toLowerCase() === action.payload.key.toLowerCase()) {
      //     Object.assign({}, item, { Value: action.payload.search });
      //   }
      // });
      // (
      //   state.siteMapProvider ?? new SiteMapProvider(state.siteMapSettings)
      // ).updatePath(action.payload.location.pathname, action.payload.search);
      state.siteMapSettings?.userNodes
        .map(f => f.ChildNodes)
        .flat()
        .forEach(element => {
          if (element !== undefined) {
            const reactUrl = element.reactUrl;
            if (reactUrl !== undefined && reactUrl !== null) {
              const elementPath = reactUrl.split('?')[0] ?? reactUrl;
              if (elementPath === action.payload.location.pathname) {
                element.reactUrl = `${action.payload.location.pathname}?${action.payload.search}`;
                element.Url = `${action.payload.location.pathname}?${action.payload.search}`;
              }
            }
          }
        });
    },
    savePageSize(state, action: PayloadAction<{ key: string; value: number }>) {
      const itemIndex =
        state.userProfileSettings.findIndex(
          item => item.Key.toLowerCase() === action.payload.key.toLowerCase(),
        ) ?? -1;
      if (itemIndex >= 0) {
        let updatedItem = state.userProfileSettings.find(
          item => item.Key.toLowerCase() === action.payload.key.toLowerCase(),
        );
        if (!!updatedItem) {
          updatedItem.Value = action.payload.value.toString();
          state.userProfileSettings.splice(itemIndex, 1);
          state.userProfileSettings.push(updatedItem);
        }
      } else {
        let item = {
          UserName: state.authenticatedUser?.DisplayName,
          Id: 0,
          Key: action.payload.key,
          Value: action.payload.value.toString(),
        } as UserProfileSettings;
        state.userProfileSettings.push(item);
      }
    },
    saveCardsMode(
      state,
      action: PayloadAction<{ key: string; value: boolean }>,
    ) {
      const itemIndex =
        state.userProfileSettings.findIndex(
          item => item.Key.toLowerCase() === action.payload.key.toLowerCase(),
        ) ?? -1;
      if (itemIndex >= 0) {
        let updatedItem = state.userProfileSettings.find(
          item => item.Key.toLowerCase() === action.payload.key.toLowerCase(),
        );
        if (!!updatedItem) {
          updatedItem.Value = action.payload.value.toString();
          state.userProfileSettings.splice(itemIndex, 1);
          state.userProfileSettings.push(updatedItem);
        }
      } else {
        let item = {
          UserName: state.authenticatedUser?.DisplayName,
          Id: 0,
          Key: action.payload.key,
          Value: action.payload.value.toString(),
        } as UserProfileSettings;
        state.userProfileSettings.push(item);
      }
    },
    loadEventsCount(state) {
      state.loadingEventsCount = true;
    },
    loadEventsCount_Success(state, action: PayloadAction<number>) {
      state.eventsCount = action.payload;
      state.loadingEventsCount = false;
    },
    loadEventsCount_Error(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loadingEventsCount = false;
    },

    loadSystemSettings(state) {
      state.loadingSystemSettings = true;
    },
    loadSystemSettings_Success(state, action: PayloadAction<SettingModel[]>) {
      let data: any = action.payload;
      state.systemSettings = !!data.value
        ? (data.value as SettingModel[])
        : (data as SettingModel[]); /* action.payload; */
      state.loadingSystemSettings = false;
    },
    loadSystemSettings_Error(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loadingSystemSettings = false;
    },

    loadLoginSystemSettings(state) {
      state.loadingSystemSettings = true;
    },

    loadLoginSystemSettings_Success(
      state,
      action: PayloadAction<LoginSettingsModel>,
    ) {
      let data: any = action.payload;
      data.settings.forEach(s => {
        let ss = state.systemSettings.find(f => f.Id === s.Id);
        if (ss !== undefined) {
          ss.Value = s.Value;
        } else {
          state.systemSettings.push(s);
        }
      });

      if (state.appSettings !== null) {
        data.Modules.forEach(m => {
          if (state.appSettings?.Modules.find(f => f === m) === undefined) {
            state.appSettings?.Modules.push(m);
          }
        });
        state.appSettings.LoginScreenCompanyLogo = data.LoginScreenCompanyLogo;
        state.appSettings.LoginScreenCompanyLogoLink =
          data.LoginScreenCompanyLogoLink;
        state.appSettings.LoginScreenCompanyLogoHeight =
          data.LoginScreenCompanyLogoHeight;
        state.appSettings.ShowForgotPassword = data.ShowForgotPassword;
        state.appSettings.Help_Login = data.Help_Login;
      } else {
        let settings = {} as AppSettings;
        settings.LoginScreenCompanyLogo = data.LoginScreenCompanyLogo;
        settings.LoginScreenCompanyLogoLink = data.LoginScreenCompanyLogoLink;
        settings.LoginScreenCompanyLogoHeight =
          data.LoginScreenCompanyLogoHeight;
        settings.ShowForgotPassword = data.ShowForgotPassword;
        settings.Help_Login = data.Help_Login;
        settings.Modules = data.Modules;
        settings.WebVersion = data.WebVersion;
        settings.LastCodeUpdate = data.LastCodeUpdate;
        state.appSettings = settings;
      }
      state.loadingSystemSettings = false;
    },
    loadLoginSystemSettings_Error(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loadingSystemSettings = false;
    },

    loadUserProfileSettings(state) {
      state.loadingUserProfileSettings = true;
    },
    loadUserProfile_Success(
      state,
      action: PayloadAction<UserProfileSettings[]>,
    ) {
      state.userProfileSettings = action.payload;
      state.loadingUserProfileSettings = false;
    },
    loadUserProfile_Error(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.loadingUserProfileSettings = false;
    },
    updateUserProfileSettings(
      state,
      action: PayloadAction<{ key: string; model: UserProfileSettings }>,
    ) {
      state.updatedUserProfileSettings = true;
    },
    updateUserProfile_Success(
      state,
      action: PayloadAction<UserProfileSettings>,
    ) {
      state.updatedUserProfileSettings = false;
      const itemIndex =
        state.userProfileSettings.findIndex(
          item => item.Key === action.payload.Key,
        ) ?? -1;
      if (itemIndex >= 0) {
        state.userProfileSettings.splice(itemIndex, 1);
        state.userProfileSettings.push(action.payload);
      } else {
        state.userProfileSettings.push(action.payload);
      }
    },
    updateUserProfile_Error(state, action: PayloadAction<string>) {
      state.updatedUserProfileSettings = false;
    },
    updateExpandedState(state, action: PayloadAction<boolean>) {
      state.updatedExpandState = true;
    },
    updateExpandedState_Success(
      state,
      action: PayloadAction<UserProfileSettings>,
    ) {
      state.updatedExpandState = false;
      const itemIndex =
        state.userProfileSettings.findIndex(
          item => item.Key === action.payload.Key,
        ) ?? -1;
      if (itemIndex >= 0) {
        state.userProfileSettings.splice(itemIndex, 1);
        state.userProfileSettings.push(action.payload);
      } else {
        state.userProfileSettings.push(action.payload);
      }
    },
    updateExpandedState_Error(state, action: PayloadAction<any>) {
      state.updatedExpandState = false;
    },
    updateExpandedState_Local(state, action: PayloadAction<boolean>) {
      state.isExpanded = action.payload;
    },
    // setExpandedState_Local(state, action: PayloadAction<boolean>) {
    //   state.isExpanded = action.payload;
    // },
    navigate(
      state,
      action: PayloadAction<string | H.LocationDescriptorObject<unknown>>,
    ) {},

    showGlobalServiceGroupFilter(state, action: PayloadAction<boolean>) {
      state.showGlobalServiceGroupFilter = action.payload;
    },
    loadGlobalServiceGroupFilter(state, action: PayloadAction) {},
    loadGlobalServiceGroupFilter_Success(
      state,
      action: PayloadAction<Entity<number>[]>,
    ) {
      state.globalServiceGroupFilter = action.payload;
    },
    loadSpecificServiceGroupFilter(state, action: PayloadAction) {},
    loadSpecificServiceGroupFilter_Success(
      state,
      action: PayloadAction<Entity<number>[]>,
    ) {
      state.specificServiceGroupFilter = action.payload;
    },
    saveGlobalServiceGroupFilter(
      state,
      action: PayloadAction<Entity<number>[]>,
    ) {
      state.globalServiceGroupFilter = action.payload;
    },
    saveGlobalServiceGroupFilter_Success(
      state,
      action: PayloadAction<Entity<number>[]>,
    ) {
      state.globalServiceGroupFilter = action.payload;
    },
    loadAllServiceGroupsSelectable(state, action: PayloadAction) {},
    loadAllServiceGroupsSelectable_Success(
      state,
      action: PayloadAction<Entity<number>[]>,
    ) {
      state.allServiceGroupsSelectable = action.payload;
    },
    saveAllGlobalServiceGroupFilter(state, action: PayloadAction) {
      state.globalServiceGroupFilter = state.allServiceGroupsSelectable;
    },
    updateMyListSelected_Local(
      state,
      action: PayloadAction<SavedViewSelected | undefined>,
    ) {
      console.log('myListSelected', action.payload);
    },
    updateMyList(state, action: PayloadAction<SavedViewSelected | undefined>) {
      if (state.appSettings !== null) {
        // state.appSettings.myListSelected = action.payload;
      }
    },
    enterFullScreen(state, action: PayloadAction<{ selector?: string }>) {},
    exitFullScreen(state, action: PayloadAction) {},
    enterFullScreen_Success(state, action: PayloadAction) {
      state.isFullScreen = true;
    },
    exitFullScreen_Success(state, action: PayloadAction) {
      state.isFullScreen = false;
    },
    enterFullScreen_Error(
      state,
      action: PayloadAction<{ selector: string }>,
    ) {},
    exitFullScreen_Error(state, action: PayloadAction<{ selector: string }>) {},
    setBarcodeScannigTarget(
      state,
      action: PayloadAction<ScanTarget | undefined>,
    ) {},
    barcodeScannigTarget_Success(
      state,
      action: PayloadAction<ScanTarget | undefined>,
    ) {
      state.barcodeScanTarget = action.payload;
    },
    barcodeScannigTarget_Error(state, action: PayloadAction) {
      state.barcodeScanTarget = undefined;
    },
    showAllCores(state, action: PayloadAction<boolean | undefined>) {},
    setShowAllCores(state, action: PayloadAction<boolean | undefined>) {
      state.showAllCores = action.payload;
    },
    collapseSubBar(state, action: PayloadAction<boolean | undefined>) {},
    setCollapseSubBar(state, action: PayloadAction<boolean | undefined>) {
      state.subBarCollapsed = action.payload;
    },
    setSkipLocationChanged(state, action: PayloadAction<boolean | undefined>) {
      state.skipLocationChanged = action.payload;
    },
    //currently used for testing purpose to scan a barcodes, but can be usefull late if need to implement some manuall scanning.
    manualScanBarcode(state, action: PayloadAction<{ data: string }>) {},
    manualScanBarcode_Success(state, action: PayloadAction) {
      state.manualScanProcessing = false;
    },
    manualScanBarcode_error(state, action: PayloadAction) {
      state.manualScanProcessing = false;
    },
  },
});

export const { actions: appSettingsActions } = slice;

export const useAppSettingsSlice = () => {
  useInjectReducer({ key: slice.name, reducer: slice.reducer });
  useInjectSaga({ key: slice.name, saga: getAppSettingsFromSaga });
  return { actions: slice.actions };
};

/**
 * Example Usage:
 *
 * export function MyComponentNeedingThisSlice() {
 *  const { actions } = useAppSettingsSlice();
 *
 *  const onButtonClick = (evt) => {
 *    dispatch(actions.someAction());
 *   };
 * }
 */
