import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import {
  selectAppSettings,
  selectAuthenticatedUser,
  selectLdapEnabled,
  selectPublicUrl,
  selectUsageEnabled,
  selectUserFieldSettings,
} from 'app/slice/selectors';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import * as React from 'react';
import { IUserRow } from './IUserRow';
import { getUserRows } from './UserRows';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import useSidePanelState, {
  SidePanelCloseState,
} from 'app/hooks/useSidePanelOpen';
import { selectExpandedSidePanel } from 'app/Layout/FrontendLayout/slice/selectors';
import { DetectIsMobile } from 'utils/mobileDetect';
import { useAsyncGetWthEffect } from 'app/hooks/useAsyncGetOnMount';

import {
  DefaultUserAttributes,
  DefaultUserRows,
  ExpandableUserRows,
  UserRowState,
  UserScreenType,
  UserTabsCounters,
} from './typeUtils';
import { translations } from 'locales/translations';
import { UsersApi } from 'api/UsersApi';
import { IUsers } from 'app/pages/UsersPage/IUsers';
import { TabContent } from 'app/components/BasicTabs/TabContent';
import { UserProfileTabContent } from './Components/UserProfileTabContent';
import { buildURL } from 'utils/url-utils';
import { NotFoundPage } from 'app/pages/NotFoundPage/Loadable';
import { UserTabsRender } from './Components/UserTabsRender';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { SyncUserButton } from './Components/SyncUserButton';
import {
  GetReservationHistoryTableCount,
  UserReservationHistoryTable,
} from './Components/UserReservationHistoryTable';
import { BadgeCounter } from 'app/pages/AssetPopUp/components/BadgeCounter';
import {
  GetWorkOrdersTableCount,
  UserWorkOrdersTable,
} from './Components/UserWorkOrdersTable';
import {
  GetTrainingsTableCount,
  UserTrainingsTable,
} from './Components/UserTrainingsTable';
import { useUserPermissions } from 'app/permissions/Users/useUserPermissions';
import { NotAvailable } from 'app/pages/NotAvailablePage/Loadable';
import {
  GetSearchHistoryTableCount,
  UserSearchHistoryTable,
} from './Components/UserSearchHistoryTable';

export interface UserDetailsProps extends CoverProps {
  userName: string;
  useSidePanel: boolean;
  screenType?: UserScreenType;
  onClose?: () => void;
}

async function fetcUser(props: {
  userName?: string;
  params: Record<string, string>;
  onLoadCompleted?: (data: IUsers) => void;
}) {
  try {
    if (props.userName === undefined) return undefined;
    const res: IUsers = await UsersApi.getUser(props.userName, props.params);
    if (!!props.onLoadCompleted) props.onLoadCompleted(res);
    return res;
  } catch (error) {
    console.error(error);
    return undefined;
  }
}

export const UserDetails: React.FC<UserDetailsProps> = ({
  userName,
  screenType = 'UserProfile',
  useSidePanel = true,
  isCover,
  onClose,
  closeCover,
}) => {
  const { t } = useTranslation();
  /// Settings ///
  const globalSettings = useGlobalSettingsHook();
  //const { actions: layoutActions } = useLayoutSlice();
  //const { actions } = useAppSettingsSlice();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const usageEnabled = useSelector(selectUsageEnabled);
  const ldapEnabled = useSelector(selectLdapEnabled);
  const appSettings = useSelector(selectAppSettings);
  const publicUrl = useSelector(selectPublicUrl);
  const IsAdmin = authenticatedUser?.isAdmin();
  const IsGroupAdmin = authenticatedUser?.isGroupAdmin();
  const isLabTech = authenticatedUser?.isLabTech();
  const {
    allowedToView,
    allowedToEditDetails,
    allowedToEditSettings,
  } = useUserPermissions();

  const allowedView = React.useMemo(() => {
    return allowedToView({ Name: userName });
  }, [allowedToView, userName]);
  const allowEditDetails = React.useMemo(() => {
    return allowedToEditDetails({ Name: userName });
  }, [allowedToEditDetails, userName]);
  const allowEditSettings = React.useMemo(() => {
    return allowedToEditSettings({ Name: userName });
  }, [allowedToEditSettings, userName]);
  const IsCurrentUser = React.useMemo(() => {
    return authenticatedUser?.Id === userName;
  }, [authenticatedUser, userName]);
  // const AllowAddNew = React.useMemo(() => {
  //   return (
  //     IsAdmin ||
  //     IsCustomRoleAdmin ||
  //     (!IsAdmin &&
  //       GroupAdminAffiliated &&
  //       globalSettings.EnableCoreAffilationManagment)
  //   ); // Need fixing
  // }, [IsAdmin, IsCustomRoleAdmin, GroupAdminAffiliated, globalSettings]);
  //const AllowAddADUsers = AllowAddNew || coreManagementEnabled;
  const userAttributes = useSelector(state =>
    selectUserFieldSettings(state, screenType),
  );
  const {
    cover: userCover,
    openPanel,
    closePanel,
    coverClosed,
    onCloseCover,
  } = useSidePanelState();
  const isMobile = DetectIsMobile();
  const shortView = React.useMemo(() => {
    return isMobile || (useSidePanel === true && !sidePanelExpanded) || isCover;
  }, [isCover, isMobile, sidePanelExpanded, useSidePanel]);
  const [counters, setCounters] = React.useState<UserTabsCounters>({
    Reservations: { Enabled: true, Count: 0 },
    WorkOrders: { Enabled: true, Count: 0 },
    UserTrainings: { Enabled: true, Count: 0 },
    SearchHistory: { Enabled: true, Count: 0 },
  });
  const [loading, setLoading] = React.useState<boolean | undefined>(true);
  const dict: Record<string, IUserRow> = React.useMemo(() => {
    return getUserRows(
      t,
      globalSettings,
      openPanel,
      authenticatedUser,
      isCover,
      useSidePanel,
      shortView,
      userName,
    ).reduce((res, current) => {
      res[current.Id] = current;
      return res;
    }, {});
  }, [
    t,
    globalSettings,
    openPanel,
    authenticatedUser,
    isCover,
    useSidePanel,
    shortView,
    userName,
  ]);

  // const userFieldEnabled = React.useCallback(
  //   (UserField: UserAttributeEnum, type: UserScreenType): boolean => {
  //     return IsUserFieldEnabled(appSettings, UserField, type);
  //   },
  //   [appSettings],
  // );
  const updateCounters = React.useCallback(
    (name: string, count: number) => {
      if (counters[name].Count === count) return;
      const tempCounters = Object.assign({}, counters);
      tempCounters[name].Count = count;
      setCounters(tempCounters);
    },
    [counters],
  );
  const resCount = React.useMemo(() => {
    return counters.Reservations.Count;
  }, [counters.Reservations.Count]);
  // const updateCountersEnable = React.useCallback(
  //   (name: string, enabled: boolean) => {
  //     const tempCounters = counters;
  //     tempCounters[name].Enabled = enabled;
  //     setCounters(tempCounters);
  //   },
  //   [counters],
  // );
  const loadCounters = React.useCallback(
    (data: IUsers) => {
      if (!!data && userName !== undefined) {
        if (counters.Reservations.Enabled) {
          GetReservationHistoryTableCount(
            userName,
            'Reservations',
            updateCounters,
            appSettings ?? undefined,
          );
        }
        if (counters.WorkOrders.Enabled) {
          GetWorkOrdersTableCount(userName, 'WorkOrders', updateCounters);
        }
        if (counters.UserTrainings.Enabled) {
          GetTrainingsTableCount(userName, 'UserTrainings', updateCounters);
        }
        if (counters.SearchHistory.Enabled) {
          GetSearchHistoryTableCount(userName, 'SearchHistory', updateCounters);
        }
      }
    },
    [
      appSettings,
      counters.Reservations.Enabled,
      counters.SearchHistory.Enabled,
      counters.UserTrainings.Enabled,
      counters.WorkOrders.Enabled,
      updateCounters,
      userName,
    ],
  );
  const columnNamesFilter = React.useCallback((r: IUserRow) => {
    return (
      r.State === undefined &&
      r.State !== UserRowState.Hidden &&
      [
        'Overview',
        'ContactDetails',
        'CompanyDetails',
        'AdditionalDetails',
        'Comments',
        'EditableDetails',
      ].includes(r.Id) === false
    );
  }, []);

  const rowsM = React.useMemo(() => {
    return [
      ...new Set(
        DefaultUserRows.map(r => dict[r]).concat(
          userAttributes.flatMap(userProp => {
            let val = dict[userProp.Id];
            if (!!val) {
              val.Name =
                userProp.Setting.AttributeLocalized ??
                t(translations[userProp.Setting.Attribute]);
              val.rowOrder = userProp.Setting.Order ?? 0;
              if (val.dependencies && !!val.dependencies.length) {
                return [val, ...val.dependencies.map(v => dict[v])];
              }
            }
            return val;
          }),
        ),
      ),
    ].filter(v => !!v);
  }, [userAttributes, dict, t]);
  const selectColumnsM = React.useMemo(() => {
    return [
      ...new Set(
        rowsM
          .filter(r => columnNamesFilter(r))
          .map(v => v.PropName ?? v.Id)
          .concat(DefaultUserAttributes),
      ),
    ].join(',');
  }, [columnNamesFilter, rowsM]);
  const expandColumnsM = React.useMemo(() => {
    return [
      ...new Set(
        rowsM
          .filter(r => columnNamesFilter(r))
          .map(v => v.PropName ?? v.Id)
          .concat(DefaultUserAttributes),
      ),
    ]
      .filter(column => ExpandableUserRows.includes(column))
      .join(',');
  }, [columnNamesFilter, rowsM]);

  const data = useAsyncGetWthEffect<IUsers | undefined>(
    async () =>
      fetcUser({
        userName: userName,
        params: {
          $select: selectColumnsM,
          $expand: expandColumnsM,
        },
        onLoadCompleted: loadCounters,
      }),
    undefined,
    [userName],
    setLoading,
  );

  const handleCloselClick = React.useCallback(() => {
    closePanel({
      isCover: isCover,
      useSidePanel: useSidePanel,
      onClose: () => {
        !!onClose && onClose();
      },
    } as SidePanelCloseState);
  }, [closePanel, isCover, onClose, useSidePanel]);

  const tabs = React.useMemo(() => {
    if (data !== undefined) {
      const res: TabContent[] = [];
      res.push({
        Name: t(translations.menu_UserProfile),
        Id: 'userProfile',
        Content: (
          <UserProfileTabContent
            rows={rowsM}
            data={data}
            shortView={shortView}
            isCover={isCover}
            info={undefined}
          />
        ),
      });
      res.push({
        Name: t(translations.Reservations),
        Id: 'reservations',
        Content: (
          <UserReservationHistoryTable
            userName={userName}
            isMobile={shortView}
            openPanel={openPanel}
            useSidePanel={useSidePanel}
            updateTableCounters={updateCounters}
          />
        ),
        endIcon: <BadgeCounter count={resCount} />,
      });
      res.push({
        Name: t(translations.WorkOrdersPageName),
        Id: 'workOrders',
        Content: (
          <UserWorkOrdersTable
            userName={userName}
            isMobile={shortView}
            allowToAddNew={true}
            openPanel={openPanel}
            useSidePanel={useSidePanel}
          />
        ),
        endIcon: <BadgeCounter count={counters.WorkOrders.Count} />,
      });
      res.push({
        Name: t(translations.UserTrainingsTab),
        Id: 'userTrainings',
        Content: (
          <UserTrainingsTable
            userName={userName}
            isMobile={shortView}
            openPanel={openPanel}
            useSidePanel={useSidePanel}
          />
        ),
        endIcon: <BadgeCounter count={counters.UserTrainings.Count} />,
      });
      res.push({
        Name: t(translations.SearchHistoryTab),
        Id: 'searchHistory',
        Content: (
          <UserSearchHistoryTable
            userName={userName}
            isMobile={shortView}
            openPanel={openPanel}
            useSidePanel={useSidePanel}
          />
        ),
        endIcon: <BadgeCounter count={counters.SearchHistory.Count} />,
      });
      return res;
    } else return [];
  }, [
    counters.SearchHistory.Count,
    counters.UserTrainings.Count,
    counters.WorkOrders.Count,
    data,
    isCover,
    openPanel,
    resCount,
    rowsM,
    shortView,
    t,
    updateCounters,
    useSidePanel,
    userName,
  ]);
  const topActions = React.useMemo(() => {
    let buttonActions = [] as ActionRenderer[];
    if (data !== undefined && loading === false) {
      if (allowEditDetails && globalSettings.DisplayEditUserDetails) {
        buttonActions.push(() => (
          <Button
            key="user-details"
            size="small"
            variant="main"
            startIcon={<Icon icon="user" />}
            title={t(translations.UserDetails)}
            href={`/userdetails.aspx?un=${data.Name}`}
            target={'_blank'}
          >
            {t(translations.EditUserDetails)}
          </Button>
        ));
      }
      if (allowEditSettings) {
        buttonActions.push(() => (
          <Button
            key="user-settings"
            size="small"
            variant="white"
            startIcon={<Icon icon="pencil-alt" />}
            title={t(translations.EditUser)}
            href={`/newuser.aspx?un=${data.Name}&ReturnUrl=~/users`}
            target={'_blank'}
          >
            {t(translations.UserSettings)}
          </Button>
        ));
      }
      if (
        IsCurrentUser &&
        globalSettings.MultipleAdGroupsEnabled &&
        data.AllowedUserGroups.length > 1
      ) {
        <Button
          key="active-groups"
          size="small"
          variant="white"
          startIcon={<Icon icon="users" />}
          title={t(translations.SelectActiveUserGroup)}
          href={`/ActiveUserGroup`}
          component={'a'}
        >
          {t(translations.changeUserGroup)}
        </Button>;
      }
      if (usageEnabled) {
        buttonActions.push(() => (
          <Button
            key="usage-history"
            href={'/usagehistory?un=' + userName}
            title={t(translations.UsageHistory)}
            component={'a'}
            variant="white"
            startIcon={<Icon icon="ruler-triangle" />}
            size="small"
          >
            {t(translations.UsageHistory)}
          </Button>
        ));
      }
      buttonActions.push(() => (
        <Button
          key="user-history"
          href={'/UsersHistory?un=' + userName}
          title={t(translations.UserModificationHistory)}
          component={'a'}
          variant="white"
          startIcon={<Icon icon="chart-line" />}
          size="small"
        >
          {t(translations.UsersHistory)}
        </Button>
      ));
      if (IsAdmin || IsGroupAdmin || isLabTech || IsCurrentUser) {
        buttonActions.push(() => (
          <Button
            key="user-notifications"
            href={'/emailnotifications?recipient=' + userName}
            title={t(translations.menu_EmailNotifications)}
            component={'a'}
            variant="white"
            startIcon={<Icon icon="envelope" />}
            size="small"
          >
            {t(translations.menu_EmailNotifications)}
          </Button>
        ));
      }
      if (ldapEnabled && IsAdmin && data.AD) {
        <SyncUserButton userId={userName} />;
      }
    }
    return buttonActions;
  }, [
    IsAdmin,
    IsCurrentUser,
    IsGroupAdmin,
    allowEditDetails,
    allowEditSettings,
    data,
    globalSettings.DisplayEditUserDetails,
    globalSettings.MultipleAdGroupsEnabled,
    isLabTech,
    ldapEnabled,
    loading,
    t,
    usageEnabled,
    userName,
  ]);
  if (authenticatedUser && !allowedView) {
    return <NotAvailable />;
  }
  const dataFound = data && tabs !== undefined && tabs.length > 0;
  if (!loading && !dataFound && data === undefined) {
    return <NotFoundPage />;
  }
  return (
    <PageWrapper
      id={`user_${screenType}`}
      useSidePanel={useSidePanel ?? false}
      closable={useSidePanel === true}
      loading={loading || rowsM.length === 0}
      pageLink={
        data === undefined
          ? undefined
          : buildURL(`${publicUrl}Users/Profile`, { un: data?.Name })
      }
      toPageLink={
        data === undefined ? undefined : `Users/Profile?un=${data?.Name}`
      }
      pageName={t(translations.menu_UserProfile)}
      titlePage={data?.DisplayName ?? undefined}
      titleTooltip={data?.DisplayName + t(translations.ClickToSeeFullDetails)}
      children={
        <React.Fragment>
          {dataFound ? (
            <UserTabsRender
              tabs={tabs}
              isMobile={isMobile}
              shortView={shortView ?? false}
            />
          ) : (
            <>{t(translations.UserNotFound)}</>
          )}
        </React.Fragment>
      }
      closeSidePanel={handleCloselClick}
      rightTopActions={!shortView ? topActions : undefined}
      rightActionsLoading={!shortView && loading}
      leftTopActions={shortView ? topActions : undefined}
      leftActionsLoading={shortView && loading}
      maxLeftTopButtonsLength={2}
      rightTopActionsLength={2}
      isCover={isCover}
      closeCover={!isCover ? onCloseCover : closeCover}
      cover={userCover}
      coverClosed={coverClosed}
    />
  );
};
