import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useLargeFilesUploadSlice } from '../LargeFilesUpload/slice';
import { selectLargeFilesUploadSelectedFiles } from '../LargeFilesUpload/slice/selectoes';
import { IComment, NewComment } from './IComment';
import * as Yup from 'yup';
import { Entity } from 'types/common';
import { Formik } from 'formik';
import { DropDown } from '../DropDown';
import { MenuItem } from '@material-ui/core';
import { translations } from 'locales/translations';
import { Button } from '../BasicButtons/Button';
import { Icon } from '../BasicIcons/FontAwesome';
import { FormRichTextField } from '../Forms/FormRichTextField';
import { FormListener } from '../Forms/FormRender/FormRenderer';
import { LargeFilesUpdate } from '../LargeFilesUpload';
import { Div100 } from '../AssetQuickSearch/styled';
import {
  FormRow,
  FormLeftSection,
  FormFieldsSection,
  StyledForm,
} from '../Forms/FormsLayout';

export interface AddCommentProps {
  onSubmit: (comment: NewComment) => void;
  onClose: () => void;
  withMentions: boolean;
  isAdmin?: boolean;
  defaultComment?: Partial<IComment>;
  disabled?: boolean;
  bindSubmitComment?: any;
}
export const AddComment = (props: AddCommentProps) => {
  const {
    onSubmit,
    onClose,
    withMentions,
    isAdmin,
    defaultComment,
    disabled,
    bindSubmitComment,
  } = props;
  const { t } = useTranslation();
  const formRef = React.useRef<HTMLFormElement>(null);
  const dispatch = useDispatch();
  const { actions: largeFileActions } = useLargeFilesUploadSlice();
  const selectedFiles = useSelector(selectLargeFilesUploadSelectedFiles);

  useEffectOnMount(() => {
    formRef.current?.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'nearest',
    });
  });
  React.useEffect(() => {
    dispatch(largeFileActions.setCanUpload(0));
  }, [dispatch, largeFileActions]);
  const userSchema: Yup.SchemaOf<Entity<string>> = Yup.object({
    Id: Yup.string().ensure(),
    Name: Yup.string().ensure(),
  });

  const schema: Yup.SchemaOf<NewComment> = Yup.object({
    Users: Yup.array().of(userSchema),
    Files: Yup.mixed().notRequired(),
    Text: Yup.string()
      .notRequired()
      .test(val => (val ?? '').length > 0),
    IsInternal: Yup.boolean().nullable(),
  });

  const initial: NewComment = {
    Text: defaultComment?.Text,
    Users: [],
    IsInternal: defaultComment?.IsInternal,
  };

  const handleSubmit = ({ Files, ...values }: NewComment) => {
    const comment: NewComment = {
      ...values,
    };
    onSubmit(comment);
    if (selectedFiles === 0) {
      onClose();
    }
  };
  return (
    <Formik
      onSubmit={(values, action) => {
        handleSubmit(values);
        //action.resetForm();
      }}
      initialValues={initial}
      validationSchema={schema}
    >
      {formik => {
        if (bindSubmitComment && formik.isValid) {
          bindSubmitComment(formik.submitForm);
        }
        const additionalButtons = [
          (className, key) => {
            return isAdmin ? (
              <DropDown
                variant="ghost"
                menuProps={{ closeAfterTransition: true }}
                className={className}
                disabled={disabled}
                key={key}
                menuChildren={close => [
                  <MenuItem>
                    <Button
                      color={formik.values.IsInternal ? 'default' : 'primary'}
                      variant={formik.values.IsInternal ? 'ghost' : 'main'}
                      fullWidth
                      startIcon={<Icon icon="globe" />}
                      onClick={() => {
                        formik.getFieldHelpers('IsInternal').setValue(false);
                        close();
                      }}
                    >
                      {t(translations.VisibleToUsers)}
                    </Button>
                  </MenuItem>,
                  <MenuItem>
                    <Button
                      color={formik.values.IsInternal ? 'primary' : 'default'}
                      variant={formik.values.IsInternal ? 'main' : 'ghost'}
                      fullWidth
                      startIcon={<Icon icon="lock" />}
                      onClick={() => {
                        formik.getFieldHelpers('IsInternal').setValue(true);
                        close();
                      }}
                    >
                      {t(translations.StaffOnly)}
                    </Button>
                  </MenuItem>,
                ]}
              >
                <Icon
                  icon={formik.values.IsInternal ? 'lock' : 'lock-open'}
                  fixedWidth
                />
              </DropDown>
            ) : (
              <></>
            );
          },
        ];
        return (
          <StyledForm
            ref={formRef}
            onSubmit={formik.handleSubmit}
            noPadding={true}
            id="add_comment_form"
          >
            <FormListener onFormChange={() => {}} fields={[]} />
            <FormLeftSection>
              <FormFieldsSection>
                <FormRow>
                  <FormRichTextField
                    autoFocus
                    name="Text"
                    placeholder={t(translations.WriteAComment)}
                    hasMentions={withMentions}
                    disabled={disabled}
                    onChangeMentions={v =>
                      formik.getFieldHelpers('Users').setValue(v, true)
                    }
                    additionalButtons={additionalButtons}
                  />
                </FormRow>
                <Div100>
                  <LargeFilesUpdate maxFilesCount={20} />
                </Div100>
                <FormRow>
                  <Button
                    size="small"
                    type="submit"
                    disabled={disabled || formik.isSubmitting}
                  >
                    {t(translations.Save)}
                  </Button>
                  <Button
                    size="small"
                    onClick={onClose}
                    color="primary"
                    variant="white"
                  >
                    {t(translations.Close)}
                  </Button>
                </FormRow>
              </FormFieldsSection>
            </FormLeftSection>
          </StyledForm>
        );
      }}
    </Formik>
  );
};
