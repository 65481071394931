import { PayloadAction } from '@reduxjs/toolkit';
import { IServiceRequestDto } from 'api/odata/generated/entities/IServiceRequestDto';
import { IServiceRequestStatusDto } from 'api/odata/generated/entities/IServiceRequestStatusDto';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { TableState } from 'react-table';
import { Entity, IAsyncValue, Identifiable } from 'types/common';
import { PartialRecord } from 'types/PartialRecord';
import { RunTypesUnion } from '../../components/RunTypePicker';
import { IServiceRequestMilestoneDto } from 'api/odata/generated/entities/IServiceRequestMilestoneDto';
import { Timestamp } from 'types/Timestamp';
import { ServiceRequestMilestoneChargeType } from '../../components/ServiceRequestMilestoneChargeDetails';
import { IUserBaseDto } from 'api/odata/generated/entities/IUserBaseDto';
import { ServiceRequestMilestoneStatusesUnion } from 'api/odata/generated/enums/ServiceRequestMilestoneStatuses';
import { IOpenSidePanelPayload } from 'app/Layout/FrontendLayout/slice';
import { IConsumableServiceDto } from 'api/odata/generated/entities/IConsumableServiceDto';
import { PromiseStatus } from 'app/hooks/usePromise';
import { IServiceRequestRowDto } from 'api/odata/generated/entities/IServiceRequestRowDto';
import { IServiceRequestRowValueDto } from 'api/odata/generated/entities/IServiceRequestRowValueDto';
import { Dictionary } from 'lodash';
import { CustomFormValueType } from 'app/components/CustomForm/CustomFormUtils';
import { IFormFieldOptionDto } from 'api/odata/generated/entities/IFormFieldOptionDto';
import { IFormFieldDto } from 'api/odata/generated/entities/IFormFieldDto';
import { SpecialColumnTypesUnion } from 'api/odata/generated/enums/SpecialColumnTypes';
import { IServiceRequestRowStatusDto } from 'api/odata/generated/entities/IServiceRequestRowStatusDto';

/* --- STATE --- */
export type ISampleListTableState = Partial<
  TableState<ISample> & {
    searchColumns?: Array<string>;
    customFilters: Array<
      Pick<IFilterSettings<ISample>, 'fieldName' | 'operator' | 'value'>
    >;
  }
>;
export type IFieldState = {
  status: PromiseStatus;
};

export type ControlledTableState<TRow extends object> = Partial<
  TableState<TRow> & {
    searchColumns?: Array<string>;
  }
>;
export type ServiceRequestRowsTableState = ControlledTableState<
  IServiceRequestRowDto
>;
export interface RowsWithTotalCount<TRow extends object> {
  rows: TRow[];
  dataLength: number;
}
export interface IControlledTableState<TRow extends object> {
  tableState: ControlledTableState<TRow>;
  selectedRows: Array<TRow>;
  data: IAsyncValue<RowsWithTotalCount<TRow>>;
}
export interface IServiceRequestRowsTableState {}
export interface IRequestDetailsModel
  extends Omit<IServiceRequestDto, 'Rows' | 'FormValues'>,
    IServiceRequestDetailsFormModel {}
/**
 * Represents interface to service request form data values.
 */
export interface IServiceRequestDetailsFormModel {
  Rows?: Array<IServiceRequestTableRowModel>;
  FormValues?: Record<string, unknown>;
}
export interface IRequestSamplesState {
  RequestSamples: IAsyncValue<{ data: ISample[]; dataLength: number }>;
  RequestDetails: IAsyncValue<IRequestDetailsModel>;
  ServiceRequestStatuses: IAsyncValue<Array<IServiceRequestStatusDto>>;
  ServiceRequestRowStatuses?: Array<IServiceRequestRowStatusDto>;
  SamplesTableState: ISampleListTableState;
  SamplesSelected: any[];
  ServiceRequestRows: IAsyncValue<IServiceRequestTableRowModel[]>;
  serviceRequestId?: number;
  ImportState: {
    open: boolean;
    processing: boolean;
  };
  fieldsState: PartialRecord<keyof IServiceRequestDto, IFieldState | undefined>;
  milestoneProcessing?: boolean;
  milestoneChargeProcessing?: boolean;
  submitting?: boolean;
  SidePanel?: IOpenSidePanelPayload;
  createServiceRequestRowStatus?: PromiseStatus;
}

export interface ISample extends Record<string, unknown> {
  Id: number;
  ServiceRequestId: number;
  BudgetName: string | null;
  BudgetId: number | null;
  SourceRunIds: Array<number> | null;
  TargetRunIds: Array<number> | null;
  Status: number;
  StatusName: string | null;
  AnalyticalSampleId: string | null;
  BiologicalSampleId: string | null;
  TMT_Type: string | null;
  TMT_Label: string | null;
  TMT_Set: string | null;
  SubjectId: string | null;
  Concentration: number | null;
  Volume: number | null;
  SamplePlateId: string | null;
  Position: string | null;
  SampleType: string | null;
  TaxaId: string | null;
}

export interface ITableState {
  pageIndex: number;
  pageSize: number;
  columnOrder: any;
  filters: any;
  globalFilter: any;
}

export interface ISampleRun
  extends Record<string, unknown>,
    ISampleTarget,
    ISample {
  Id: number;
  ServiceRequestId: number;
  Status: number;
  BudgetName: string | null;
  AnalyticalSampleId: string | null;
  RunId: number | null;

  TMT_Type: string | null;
  TMT_Label: string | null;
  TMT_Set: string | null;

  Concentration: number | null;
  Volume: number | null;
  SamplePlateId: string | null;
  Position: string | null;

  TargetConcentration: number | null;
  TargetVolume: number | null;
  TargetPlateId: string | null;
  TargetPosition: string | null;
  TargetTubeLabel: string | null;
  TargetSampleType: string | null;
}

export interface IRunDetails {
  Id?: number;
  RunType: RunTypesUnion;
  Samples: ISampleTarget[];
}

export interface ISampleTarget extends ISample {
  TargetConcentration: number | null;
  TargetVolume: number | null;
  TargetPlateId: string | null;
  TargetPosition: string | null;
  TargetTubeLabel: string | null;
  TargetSampleType: string | null;
}

export interface ISamplesFilter {
  ServiceRequestId?: number;
}

export type IServiceRequestUpdateStatusAction = PayloadAction<
  Pick<IServiceRequestDto, 'Id' | 'Status'> & IReason
>;
export type IServiceRequestUpdateBudgetAction = PayloadAction<
  Pick<IServiceRequestDto, 'Id' | 'Budget'>
>;
export type IServiceRequestUpdateFundingTypeAction = PayloadAction<
  Pick<IServiceRequestDto, 'Id' | 'FundingTypeId'>
>;
export type IServiceRequestUpdatePurchaseOrderAction = PayloadAction<
  Pick<IServiceRequestDto, 'Id' | 'PurchaseOrder'>
>;
export type IServiceRequestUpdateExternalInternalNumberAction = PayloadAction<
  Pick<IServiceRequestDto, 'Id' | 'ExternalInternalNumber'>
>;
export type IServiceRequestPatchAction = PayloadAction<
  Pick<IServiceRequestDto, 'Id'> & Partial<Omit<IServiceRequestDto, 'Id'>>
>;

export type ILoadServiceRequestMilestonesAction = PayloadAction<
  Identifiable<number>
>;
export type ILoadServiceRequestMilestonesSuccessAction = PayloadAction<
  Array<IServiceRequestMilestoneDto>
>;

export type ICancelServiceRequestPayloadAction = PayloadAction<
  {
    serviceRequestId: number;
  } & IReason
>;
export type UpdateServiceRequestRowValueAction = PayloadAction<{
  serviceRequestId: number;
  serviceRequestValue: Pick<
    IServiceRequestRowValueDto,
    'ColumnId' | 'RowId' | 'Value' | 'DisplayValue'
  >;
}>;

export interface IRequestMilestoneManualChargeUserModel {
  Id: string;
  Title: string;
  display: string;
}

export interface IRequestMilestoneManualChargeModel {
  OfflineServiceType: IConsumableServiceDto | null;
  Unit: Entity<number> | null;
  Id: number;
  Name: string | null;
  Quantity: number | null;
  Rate: number | null;
  Remarks: string | null;
  Date: Timestamp | null;
  UpdatedBy: IRequestMilestoneManualChargeUserModel | null;
}

export type InsertServiceRequestMilestoneChargeAction = PayloadAction<
  Partial<IRequestMilestoneManualChargeModel> & {
    ServiceRequestId: number;
    ServiceRequestMilestoneId: number;
  }
>;
export type UpdateServiceRequestMilestoneChargeAction = PayloadAction<
  Pick<IRequestMilestoneManualChargeModel, 'Id'> &
    Partial<IRequestMilestoneManualChargeModel> & {
      ServiceRequestId: number;
      ServiceRequestMilestoneId: number;
    }
>;
export interface IServiceRequestMilestoneChargeForm {
  Id?: number;
  Type?: ServiceRequestMilestoneChargeType;
  Name?: string;
  Service?: IConsumableServiceDto;
  Rate?: number;
  Date?: Date | null;
  Unit?: Entity<number>;
  Quantity?: number;
  Remarks?: string;
}
export interface IServiceRequestMilestoneForm {
  Id?: number;
  Name?: string;
  Assignees?: IUserBaseDto[];
  CompletionDate?: Date | null;
  TargetDate?: Date | null;
  Description?: string;
  Status?: ServiceRequestMilestoneStatusesUnion;
}
/** WebApi interface */
export interface ServiceRequestMilestoneInsertModel {
  Id?: number;
  ServiceRequestId: number;

  Name: string | null;
  Assignees: string | null;
  CompletionDate: Timestamp | null;
  TargetDate: Timestamp | null;
  Description: string | null;
  StatusId: number | null;
}
/** WebApi interface */
export type RequestMilestoneModel = Pick<
  ServiceRequestMilestoneInsertModel,
  'CompletionDate' | 'StatusId'
>;
export interface IReason {
  Reason?: string;
}

/**
 * UI column obj
 */
export interface IServiceRequestTableColumnModel
  extends Pick<
    IFormFieldDto,
    | 'Id'
    | 'Label'
    //| 'ColumnType'
    | 'Index'
    // | 'Options'
    // | 'OptionsSourceType'
    | 'Required'
    | 'ParentId'
    | 'SizeLimit'
    // | 'Active'
    | 'Description'
    | 'DisplayType'
    | 'IsMultiple'
    | 'CopyPrevValues'
    | 'CustomRoles'
    | 'UserSelectionType'
    | 'FixedRoles'
  > {
  IsColumn: true;
  Options?: IFormFieldOptionDto[];
  ColumnType: CustomFormColumnTypes;
  isRequired: boolean;
}
/**
 * UI interface of the service request row value
 * CustomFormValueType narrowed to include types supported on the custom form table
 */
export type IServiceRequestTableRowValueModel = Extract<
  CustomFormValueType,
  string | Date | number | boolean | Entity<string>
>;

/**
 * UI interface of the service request table model
 */
export interface IServiceRequestTableRowModel
  extends Omit<IServiceRequestRowDto, 'Values'> {
  Values: Dictionary<IServiceRequestTableRowValueModel>;
}

// case 'Disclaimer':
// case 'Dictionary':
// case 'Index1':
// case 'Index2':
// case 'Label':
// case 'Lane':
// case 'Plate':
// case 'PlateCell':
// case 'RunLane':
// case 'SequencingModes':
// case 'Name':
// case 'SourceAndLabel':
// case 'Source':
// case 'UserSelection':

type Extends<T, U extends T> = U;

/**
 * List of the column types (SpecialColumnTypes) currently supported on the custom form table
 */
export type CustomFormColumnTypes = Extends<
  SpecialColumnTypesUnion,
  | 'CheckBox'
  | 'Date'
  | 'DropDownList'
  | 'ShortText'
  | 'Serial'
  | 'Email'
  | 'PositiveInteger'
  | 'Number'
  | 'PositiveDecimal'
  | 'UserSelection'
>;
export const ColumnTypesArray: Array<CustomFormColumnTypes> = [
  'CheckBox',
  'Date',
  'DropDownList',
  'ShortText',
  'Serial',
  'Email',
  'PositiveInteger',
  'Number',
  'PositiveDecimal',
  'UserSelection',
];
