import DateFnsUtils from '@date-io/date-fns';
import { IUtils } from '@date-io/core/IUtils';

import {
  add,
  addMilliseconds,
  addDays,
  addHours,
  addMinutes,
  addMonths,
  addQuarters,
  addSeconds,
  addWeeks,
  addYears,
  differenceInCalendarDays,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
  differenceInMonths,
  differenceInQuarters,
  differenceInSeconds,
  differenceInWeeks,
  differenceInYears,
  endOfDay,
  endOfHour,
  endOfMonth,
  endOfQuarter,
  endOfTomorrow,
  endOfWeek,
  endOfYear,
  endOfYesterday,
  format,
  formatDistanceToNow,
  formatISO,
  getDate,
  getDay,
  getDayOfYear,
  getDaysInMonth,
  getHours,
  getMinutes,
  getMonth,
  getYear,
  getWeekOfMonth,
  intervalToDuration,
  isToday,
  lastDayOfMonth,
  Locale,
  parse,
  parseISO,
  set,
  setDate,
  setDay,
  setHours,
  setMinutes,
  setMonth,
  setWeek,
  setYear,
  startOfDay,
  startOfHour,
  startOfMonth,
  startOfQuarter,
  startOfToday,
  startOfTomorrow,
  startOfWeek,
  startOfYesterday,
} from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';
import startOfYear from 'date-fns/startOfYear';
import { CustomDate, DateType } from 'types/CustomDate';
import { formatLongHE } from 'utils/LocaleHE';
import { getLogger } from './logLevel';
import { ParsableDate } from '@material-ui/pickers/constants/prop-types';
import { LocaleIdentifier } from 'app/slice/types';
import { dateFnsLocalesList } from './date-fns-locales-list';

const log = getLogger('DateUtils');

/**
 * he->he
 * en-us->enUS
 * @param s
 * @returns
 */
export function dateFnsCultureName(localeId: LocaleIdentifier): string {
  return localeId.language + localeId.region;
}

class DateUtils {
  dateFnsUtils: IUtils<Date>;
  constructor() {
    this.dateFnsUtils = new DateFnsUtils();
  }

  CurrentLocale(): Locale {
    return this.dateFnsUtils.locale;
  }
  public async setLocale(
    localeId: string,
    systemFirstDayOfWeek?: WeekStartsOn,
  ) {
    const l = parseLocale(localeId);
    try {
      const locale = (await this.getLocale(l)) as Locale;
      if (locale !== undefined) {
        this.dateFnsUtils.locale = locale;

        if (
          this.dateFnsUtils.locale.options !== undefined &&
          systemFirstDayOfWeek !== undefined
        ) {
          this.dateFnsUtils.locale.options.weekStartsOn = systemFirstDayOfWeek;
        }
      } else {
        console.warn('locale not found', localeId);
      }
    } catch (err) {
      console.error('setLocale', localeId, err);
    }
  }
  async tryImportLocale(culture: string) {
    // first a quick check that there should be such a locale
    if (!dateFnsLocalesList.includes(culture)) {
      return undefined;
    }
    try {
      const module = await import(`date-fns/esm/locale/${culture}`);
      return module.default;
    } catch {
      return undefined;
    }
  }
  async getLocale(culture: LocaleIdentifier) {
    // try fetching most specific local e.g. en-US
    let result = await this.tryImportLocale(
      [culture.language, culture.region].join('-'),
    );
    if (result === undefined) {
      // try and import language only locale if full locale identifier yielded no results
      // e.g. no en-150 (en-EU) locale is present at dateFns locales, so in this case try to fall back to "en"
      if (culture.region !== undefined) {
        result = await this.tryImportLocale(culture.language);
      }
    }
    if (result !== undefined) {
      if (result.code === 'he') {
        result.formatLong = formatLongHE;
      }
    }
    return result;
  }
  dateOrStringToDate(v: string | Date): Date {
    const d = typeof v === 'string' ? this.parseISO(v as string) : (v as Date);
    return d;
  }

  format(v: Date | string | null | undefined, f: string): string | null {
    if (v == null || v === undefined) {
      return null;
    }
    try {
      const d = this.dateOrStringToDate(v);
      return format(d, f, {
        locale: this.dateFnsUtils.locale,
      });
    } catch (err) {
      console.error(err);
      return null;
    }
  }
  public HoursMinutesSecondsFormat(v: Date | string | null): string | null {
    //return this.format(v, 'hh:mm:ss');
    return this.format(v, this.DateIOFormats.fullTime);
  }
  /**
   * Format to long date only format (localized). e.g. 23 Apr 2987
   * @param v date
   * @returns localized formatted string
   */
  public longDateFormat(v: Date | string | null | undefined): string | null {
    return this.format(v, this.DateIOFormats.fullDate); //'PP'
  }
  /**
   * Format to short date only format (localized). e.g. 23 04 2987
   * @param v date
   * @returns localized formatted string
   */
  public shortDateFormat(v: Date | string | null | undefined): string | null {
    return this.format(v, this.DateIOFormats.keyboardDate); //'P'
  }

  /**
   * Format to long date + time format (localized). e.g. 23 Apr 2987 12:13 AM
   * @param v date
   * @returns localized formatted string
   */
  public fullDateTimeFormat(v: Date | string): string | null {
    const d = this.dateOrStringToDate(v);
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return formatInTimeZone(
      d,
      timeZone,
      this.DateIOFormats.fullDateTime + ' zzz',
      {
        locale: this.dateFnsUtils.locale,
      },
    ); //PP pp
  }
  /**
   * Format to long date + time format (localized). e.g. 23 Apr 2987 12:13 AM
   * @param v date
   * @returns localized formatted string
   */
  public longDateTimeFormat(
    v: Date | string | null | undefined,
  ): string | null {
    return this.format(v, this.DateIOFormats.fullDateTime); //PP pp
  }
  /**
   * Format to short date + time format (localized). e.g. 23 04 2987 12:13 AM
   * @param v date
   * @returns localized formatted string
   */
  public shortDateTimeFormat(
    v: Date | string | null | undefined,
  ): string | null {
    return this.format(v, this.DateIOFormats.keyboardDateTime); //P p
  }
  public timeFormat(v: Date | string | null | undefined): string | null {
    return this.format(v, this.DateIOFormats.fullTime); //p
  }
  public parseISO(s: string | Date): Date {
    if (typeof s === 'string') {
      return parseISO(s);
    } else {
      return s;
    }
  }
  public tryParseISO(s: string): Date | null {
    try {
      return parseISO(s);
    } catch (error) {
      return null;
    }
  }
  /**
   * Little helper function to parse .net dto date fields.
   * @param s ISO like string
   * @returns parsed date or null
   */
  public parseISOOrNull(s: string | Date | null): Date | null {
    if (s === null) {
      return null;
    }
    if (typeof s === 'string') {
      try {
        return parseISO(s);
      } catch (error) {
        log.error(`parseISOOrNull - failed to parse ${s}`);
        return null;
      }
    } else {
      return s;
    }
  }
  public tryParse(s: string | undefined | null): Date | null {
    if (s === undefined) {
      return null;
    }
    if (s === null) {
      return null;
    }
    //first try pars using iso
    let result = this.parseISO(s);
    if (result === undefined) {
      return null;
    }
    if (!isNaN(result.getTime())) {
      return result;
    }

    // then try localized short date time
    result = parse(s, 'P p', 0, { locale: this.dateFnsUtils.locale });
    if (!isNaN(result.getTime())) {
      return result;
    }

    return null;
  }
  public formatDistanceToNow(v: string | Date) {
    const d = this.dateOrStringToDate(v);
    return formatDistanceToNow(d, {
      addSuffix: true,
      locale: this.dateFnsUtils.locale,
    });
  }
  public truncateTime(v: Date | string): Date {
    const d = this.dateOrStringToDate(v);
    const s = startOfDay(d);
    return s;
  }

  /**
   * API date & times arrive all in the UTC time zone and are parsed in client's time zone.
   * This & parseISO serve as  a central customization point for time zone related functionality.
   * @param date date to format
   * @param options format options
   * @returns ISO string representation
   */
  public formatISO(
    date: Date,
    options?: {
      format?: 'extended' | 'basic';
      representation?: DateType;
    },
  ) {
    return formatISO(date, options);
  }
  public tryFormatIso(
    date: any,
    options?: {
      format?: 'extended' | 'basic';
      representation?: DateType;
    },
  ) {
    try {
      if (date === null) {
        return null;
      }
      if (date === undefined) {
        return undefined;
      }
      if (typeof date === 'string' || date instanceof Date) {
        const formatDate = this.dateOrStringToDate(date);
        return this.formatISO(formatDate, options);
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }

  public formatQueryStringDate(
    date: Date,
    options?: {
      representation?: DateType;
    },
  ): string {
    let convertedDate = this.dateOrStringToDate(date);
    return this.formatISO(convertedDate);
  }
  public parseQueryStringDate(value: string): Date {
    return this.parseISO(value);
  }
  public getDayText(date: Date): string {
    return this.format(date, this.DateIOFormats.localDayOfWeek)!;
  }

  public getShortDaysArray(): string[] {
    const fdow = startOfWeek(new Date());
    return Array.from(Array(7)).map(
      (e, i) => this.format(addDays(fdow, i), this.DateIOFormats.weekdayShort)!,
    );
  }
  public getLongDaysArray(): string[] {
    const fdow = startOfWeek(new Date());
    return Array.from(Array(7)).map(
      (e, i) => this.format(addDays(fdow, i), this.DateIOFormats.weekday)!,
    );
  }
  public getMinutesDiff(startDate: Date, endDate: Date) {
    let diff = endDate.getTime() - startDate.getTime();
    return diff / 60000;
  }
  public getHoursDiff(startDate: Date, endDate: Date) {
    const msInHour = 1000 * 60 * 60;
    return (endDate.getTime() - startDate.getTime()) / msInHour;
  }
  public getDaysDiff(startDate: Date, endDate: Date) {
    const msInDays = 1000 * 3600 * 24;
    return (endDate.getTime() - startDate.getTime()) / msInDays;
  }
  public getWeekDiff(startDate: Date, endDate: Date) {
    const msInWeek = 7 * 24 * 60 * 60 * 1000;
    return (endDate.getTime() - startDate.getTime()) / msInWeek;
  }
  public getMonthDiff(startDate: Date, endDate: Date) {
    var months;
    months = (endDate.getFullYear() - startDate.getFullYear()) * 12;
    months -= startDate.getMonth();
    months += endDate.getMonth();
    return months <= 0 ? 0 : months;
  }
  public DateIOFormats: DateIOFormats = {
    dayOfMonth: 'd',
    fullDate: 'PP',
    fullDateWithWeekday: 'PPPP',
    fullDateTime: 'PP p',
    fullDateTime12h: 'PP hh:mm aaa',
    fullDateTime24h: 'PP HH:mm',
    fullTime: 'p',
    fullTime12h: 'hh:mm aaa',
    fullTime24h: 'HH:mm',
    hours12h: 'hh',
    hours24h: 'HH',
    keyboardDate: 'P',
    keyboardDateTime: 'P p',
    keyboardDateTime12h: 'P hh:mm aaa',
    keyboardDateTime24h: 'P HH:mm',
    minutes: 'mm',
    month: 'LLLL',
    monthAndDate: 'MMMM d',
    monthAndYear: 'LLLL yyyy',
    monthShort: 'MMM',
    weekday: 'EEEE',
    weekdayShort: 'EEE',
    normalDate: 'd MMMM',
    normalDateWithWeekday: 'EEE, MMM d',
    seconds: 'ss',
    shortDate: 'MMM d',
    year: 'yyyy',
    /**
     * | Local day of week (stand-alone) | c       | 2, 3, 4, ..., 1                   |       |
     * |                                 | co      | 2nd, 3rd, ..., 1st                | 7     |
     * |                                 | cc      | 02, 03, ..., 01                   |       |
     * |                                 | ccc     | Mon, Tue, Wed, ..., Su            |       |
     * |                                 | cccc    | Monday, Tuesday, ..., Sunday      | 2     |
     * |                                 | ccccc   | M, T, W, T, F, S, S               |       |
     * |                                 | cccccc  | Mo, Tu, We, Th, Fr, Su, Sa        |       |
     */
    localDayOfWeek: 'ccc',
  };
  public isToday = isToday;
  // diff functions
  public differenceInSeconds = differenceInSeconds;
  public differenceInMinutes = differenceInMinutes;
  public differenceInHours = differenceInHours;
  public differenceInDays = differenceInDays;
  public differenceInWeeks = differenceInWeeks;
  public differenceInMonths = differenceInMonths;
  public differenceInQuarters = differenceInQuarters;
  public differenceInYears = differenceInYears;
  public differenceInCalendarDays = differenceInCalendarDays;

  // add functions
  public add = add;
  public addMilliseconds = addMilliseconds;
  public addSeconds = addSeconds;
  public addMinutes = addMinutes;
  public addHours = addHours;
  public addDays = addDays;
  public addWeeks = addWeeks;
  public addMonths = addMonths;
  public addQuarters = addQuarters;
  public addYears = addYears;

  // startOf functions
  public startOfHour = startOfHour;
  public startOfDay = startOfDay;
  public startOfWeek(
    date: Date | number,
    firstDayOfWeek?: WeekStartsOn | number,
  ) {
    return startOfWeek(date, {
      locale: dateUtils.dateFnsUtils.locale,
      weekStartsOn: firstDayOfWeek as WeekStartsOn,
    });
  }
  public startOfMonth = startOfMonth;
  public startOfQuarter = startOfQuarter;
  public startOfYear = startOfYear;

  // endOf functions
  public endOfHour = endOfHour;
  public endOfDay = endOfDay;
  public endOfWeek(
    date: Date | number,
    firstDayOfWeek?: WeekStartsOn | number,
  ) {
    return endOfWeek(date, {
      locale: dateUtils.dateFnsUtils.locale,
      weekStartsOn: firstDayOfWeek as WeekStartsOn,
    });
  }
  public endOfMonth = endOfMonth;
  public endOfQuarter = endOfQuarter;
  public endOfYear = endOfYear;
  public endOfTomorrow = endOfTomorrow;
  public startOfTomorrow = startOfTomorrow;
  public startOfYesterday = startOfYesterday;
  public endOfYesterday = endOfYesterday;
  public startOfToday = startOfToday;
  public lastDayOfMonth = lastDayOfMonth;
  public getDaysInMonth = getDaysInMonth;
  public getDayOfYear = getDayOfYear;

  //other
  public getYear = getYear;
  public getMonth = getMonth;
  public getHours = getHours;
  public getMinutes = getMinutes;
  public getDate = getDate;
  public setDate = setDate;
  public setMonth = setMonth;
  public setYear = setYear;
  public setHours = setHours;
  public setMinutes = setMinutes;
  public getDay(date: Date | number): number {
    return getDay(date) as number;
  }
  public setWeek(
    date: Date | number,
    week: number,
    firstDayOfWeek: WeekStartsOn | number,
  ): Date {
    return setWeek(date, week, {
      locale: this.dateFnsUtils.locale,
      weekStartsOn: firstDayOfWeek as WeekStartsOn,
    });
  }
  public setDay(
    date: Date | number,
    day: number,
    firstDayOfWeek: WeekStartsOn | number,
  ): Date {
    return setDay(date, day, {
      locale: this.dateFnsUtils.locale,
      weekStartsOn: firstDayOfWeek as WeekStartsOn,
    });
  }
  public set(
    date: Date | number,
    values: {
      year?: number;
      month?: number;
      date?: number;
      hours?: number;
      minutes?: number;
      seconds?: number;
      milliseconds?: number;
    },
  ): Date {
    return set(date, values);
  }

  public getWeekNumberOfMonth(
    date: Date,
    FirstDayOfWeek?: WeekStartsOn | number,
  ): number {
    return getWeekOfMonth(date, {
      locale: this.dateFnsUtils.locale,
      weekStartsOn: FirstDayOfWeek as WeekStartsOn,
    });
  }
  public intervalToDuration = intervalToDuration;

  public timeStringToDecimal(time: string): number {
    var hoursMinutes = time.split(/[.:]/);
    var hours = parseInt(hoursMinutes[0], 10);
    var minutes = hoursMinutes[1] ? parseInt(hoursMinutes[1], 10) : 0;
    return hours + minutes / 60;
  }
  public toDate(date: Date | string) {
    return dateUtils.dateOrStringToDate(date);
  }
  public newDate(date: Date | string | undefined) {
    if (!date) {
      return new CustomDate(new Date(), 'complete');
    } else {
      return new CustomDate(this.toDate(date), 'complete');
    }
  }
  public getMeridiem(date: Date | string) {
    return dateUtils.getHours(dateUtils.toDate(date)) >= 12 ? 'pm' : 'am';
  }
  public convertToMeridiem(
    time: Date | string,
    meridiem: 'am' | 'pm',
    ampm: boolean,
  ) {
    let val = dateUtils.toDate(time);
    if (ampm) {
      var currentMeridiem = dateUtils.getHours(val) >= 12 ? 'pm' : 'am';
      if (currentMeridiem !== meridiem) {
        var hours =
          meridiem === 'am'
            ? dateUtils.getHours(val) - 12
            : dateUtils.getHours(val) + 12;
        return dateUtils.setHours(val, hours);
      }
    }
    return val;
  }
  public getMeridiemHours(time: Date | string) {
    let val = dateUtils.toDate(time);
    var currentMeridiem = dateUtils.getHours(val) > 12 ? 'pm' : 'am';
    if (currentMeridiem === 'pm') {
      let hours = dateUtils.getHours(val) - 12;
      return hours;
    }
    return dateUtils.getHours(val);
  }
  public getParsableDate(date: ParsableDate) {
    var resDate =
      date === null || date === undefined
        ? null
        : typeof date === 'object'
        ? new Date(date as Date)
        : typeof date === 'number'
        ? new Date(date)
        : dateUtils.dateOrStringToDate(date);
    return resDate;
  }
}
export const parseLocale = function (state: string) {
  let [language, region] = state.split('-');
  return { language: language?.toLowerCase(), region: region?.toUpperCase() };
};
export const dateUtils = new DateUtils();

export type WeekStartsOn = 0 | 1 | 2 | 3 | 4 | 5 | 6 | undefined;
export interface DateIOFormats<TLibFormatToken = string> {
  /** Localized full date @example "Jan 1, 2019" */
  fullDate: TLibFormatToken;
  /** Partially localized full date with weekday, useful for text-to-speech accessibility @example "Tuesday, January 1, 2019" */
  fullDateWithWeekday: TLibFormatToken;
  /** Date format string with month and day of month @example "1 January" */
  normalDate: TLibFormatToken;
  /** Date format string with weekday, month and day of month @example "Wed, Jan 1" */
  normalDateWithWeekday: TLibFormatToken;
  /** Shorter day format @example "Jan 1" */
  shortDate: TLibFormatToken;
  /** Year format string @example "2019" */
  year: TLibFormatToken;
  /** Month format string @example "January" */
  month: TLibFormatToken;
  /** Short month format string @example "Jan" */
  monthShort: TLibFormatToken;
  /** Month with year format string @example "January 2018" */
  monthAndYear: TLibFormatToken;
  /** Month with date format string @example "January 1" */
  monthAndDate: TLibFormatToken;
  /** Weekday format string @example "Wednesday" */
  weekday: TLibFormatToken;
  /** Short weekday format string @example "Wed" */
  weekdayShort: TLibFormatToken;
  /** Day format string @example "1" */
  dayOfMonth: TLibFormatToken;
  /** Hours format string @example "11" */
  hours12h: TLibFormatToken;
  /** Hours format string @example "23" */
  hours24h: TLibFormatToken;
  /** Minutes format string @example "44" */
  minutes: TLibFormatToken;
  /** Seconds format string @example "00" */
  seconds: TLibFormatToken;
  /** Full time localized format string @example "11:44 PM" for US, "23:44" for Europe */
  fullTime: TLibFormatToken;
  /** Not localized full time format string @example "11:44 PM" */
  fullTime12h: TLibFormatToken;
  /** Not localized full time format string @example "23:44" */
  fullTime24h: TLibFormatToken;
  /** Date & time format string with localized time @example "Jan 1, 2018 11:44 PM" */
  fullDateTime: TLibFormatToken;
  /** Not localized date & Time format 12h @example "Jan 1, 2018 11:44 PM" */
  fullDateTime12h: TLibFormatToken;
  /** Not localized date & Time format 24h @example "Jan 1, 2018 23:44" */
  fullDateTime24h: TLibFormatToken;
  /** Localized keyboard input friendly date format @example "02/13/2020 */
  keyboardDate: TLibFormatToken;
  /** Localized keyboard input friendly date/time format @example "02/13/2020 23:44" */
  keyboardDateTime: TLibFormatToken;
  /** Partially localized keyboard input friendly date/time 12h format @example "02/13/2020 11:44 PM" */
  keyboardDateTime12h: TLibFormatToken;
  /** Partially localized keyboard input friendly date/time 24h format @example "02/13/2020 23:44" */
  keyboardDateTime24h: TLibFormatToken;
  localDayOfWeek: TLibFormatToken;
}
