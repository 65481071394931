import { BooleanRender } from 'app/components/BasicTable/components/BooleanRender';
import * as React from 'react';
import { ExternalLink, Link } from 'app/components/ExternalLink';
import { TFunction } from 'i18next';
import { translations } from 'locales/translations';
import { Fragment } from 'react';
import { Entity } from 'types/common';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import {
  ArrayRenderer,
  //AssetDetailsImageRender,
  DateRenderer,
  HtmlRenderer,
  UsersRenderer,
} from './AttributeRenderers';
import { AssetRowState, IAssetRow } from './IAssetRow';
import { dateUtils } from 'utils/date-utils';
import { AssetLocationLastUpdate } from './AssetLocationLastUpdate';
import { DoubleRender } from 'app/components/BasicTable/components/DoubleRenderer';
import { Tooltip } from '@material-ui/core';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { AssetComments } from './components/AssetComments';
import { StatusLabel } from 'app/components/basic/StatusLabel';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { AssetsSelectionProps } from '../MyAssetsPage/AssetsSelection/AssetsSelection';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { IMyAssetsRow } from '../MyAssetsPage/IMyAssetsRow';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { Button } from 'app/components/BasicButtons/Button';
import { getAssetEditLink } from './getAssetEditLink';
import { Skeleton } from '@material-ui/lab';
import { AssetPopUpProps } from '.';
import { AssemblyStructure } from '../AssemblySnapshots/AssemblyStructure';
import { SnapshotLink } from '../AssemblySnapshots/SnapshotLink';
import BasicTypography from 'app/components/Typography/BasicTypography';
import { AssetImagesGallery } from 'app/components/AssetImagesGallery';

export const getRows = (
  t: TFunction,
  setting: GlobalSettings,
  openPanel: (state: SidePanelOpenState) => void,
  publicUrl?: string,
  isCover?: boolean,
  useSidePanel?: boolean,
  // setCover?: Dispatch<SetStateAction<ReactNode>>,
  isAdmin?: boolean,
  isMobile?: boolean,
  isAuthenticated?: boolean,
  isReadOnly?: boolean,
  clickImage?: (path?: string) => void,
): IAssetRow[] => {
  return [
    {
      Id: 'ImageName',
      PropName: 'ImageName',
      Name: t(translations.Image) as string,
      Cell: ({ value, original }) => (
        <AssetImagesGallery
          baseImageName={
            !!value && value !== null
              ? value
              : !!original.AssetImageNames &&
                original.AssetImageNames.length > 0
              ? original.AssetImageNames[0]
              : null
          }
          imageNames={
            !value || value === null
              ? !!original.AssetImageNames &&
                original.AssetImageNames.length > 0
                ? original.AssetImageNames.slice(
                    1,
                    original.AssetImageNames.length - 1,
                  )
                : undefined
              : original.AssetImageNames
          }
          onImageClick={clickImage}
          shortView={isMobile}
        />
      ),

      NoHeader: true,
      validToRender: ({ value, original }) =>
        !!value ||
        (!!original.AssetImageNames && original.AssetImageNames.length > 0),
    },
    {
      Id: 'ServiceGroup',
      Name: t(translations.ServiceGroup) as string,
      Cell: ({ value, original }) => {
        const editLink = getAssetEditLink(
          'ServiceGroup',
          original.Id,
          original.ServiceId ?? undefined,
          original.ServiceGroup?.Id ?? undefined,
          original.ServiceGroup?.DivisionId ?? undefined,
        );
        return (
          <Button
            id={original.Id.toString()}
            size={'small'}
            variant="text"
            href={
              original.AvailabilityType.Id === 'ServiceGroup'
                ? editLink
                : undefined
            }
            target={
              original.AvailabilityType.Id === 'ServiceGroup'
                ? '_blank'
                : undefined
            }
            component={
              original.AvailabilityType.Id === 'ServiceGroup' ? 'a' : undefined
            }
            onClick={
              original.AvailabilityType.Id === 'ServiceGroup'
                ? undefined
                : () => {
                    const renderPageProps: AssetPopUpProps = {
                      identifier: {
                        id: original.ServiceGroup?.AssetId ?? undefined,
                      },
                      useSidePanel: true,
                    };
                    openPanel({
                      renderPageType: RenderPageType.AssetDetails,
                      renderPageProps,
                      expanded: false,
                      useSidePanel: useSidePanel,
                      isCover: useSidePanel,
                    });
                  }
            }
          >
            {value.Name}
          </Button>
        );
      },
    },
    {
      Id: 'AvailabilityType',
      PropName: 'AvailabilityType',
      Name: t(translations.Availability),
      Cell: ({ value, original }) => {
        return (
          <Fragment>
            {original.ApprovalRequired ? (
              <Fragment>
                {`${value.Name} - ${t(translations.ApprovalRequired)}`}
              </Fragment>
            ) : (
              <Fragment>{value.Name}</Fragment>
            )}
          </Fragment>
        );
      },
    },
    {
      Id: 'ForceTutoring',
      Name: t(translations.TrainingMandatory) as string,
      validToRender: ({ value, original }) =>
        value === true && original.NotAllowReservations === false,
      Cell: ({ value }) => {
        return (
          <Fragment>
            <strong>{t(translations.ApprovalRequired)}</strong>
          </Fragment>
        );
      },
    },
    {
      Id: 'minorder',
      Name: t(translations.MinimumReservationDurationH) as string,
      validToRender: ({ value, original }) =>
        !!value && value > 0 && original.NotAllowReservations === false,
      Cell: DoubleRender,
    },
    {
      Id: 'maxorder',
      Name: t(translations.MaximumReservationDurationH) as string,
      validToRender: ({ value, original }) =>
        !!value && value > 0 && original.NotAllowReservations === false,
      Cell: DoubleRender,
    },
    {
      Id: 'ReservationMinimumGap',
      Name: t(translations.ReservationMinimumGap) as string,
      validToRender: ({ value, original }) =>
        !!value && value > 0 && original.NotAllowReservations === false,
      Cell: DoubleRender,
    },
    {
      Id: 'CarryOverTimeSlotTypeId',
      Name: t(translations.CarryOverTimeSlotGap) as string,
      validToRender: ({ value, original }) =>
        !!value &&
        value !== null &&
        value > 0 &&
        original.NotAllowReservations === false,
      Cell: ({ value, original }) => {
        return (
          <>{`${original.CarryOverTimeSlotGap} hours over timeslot ${original.CarryOverTimeSlotTypeName}`}</>
        );
      },
      dependencies: ['CarryOverTimeSlotGap', 'CarryOverTimeSlotTypeName'],
    },
    {
      Id: 'Division',
      Name: t(translations.AssetsInstituteBusiness) as string,
      Cell: ({ original }) => original.ServiceGroup?.DivisionName,
    },
    {
      Id: 'AssetAccessories',
      Name: t(translations.Accessories) as string,
      Cell: ArrayRenderer<Entity<number>>(v => <>{v.Name}</>),
    },
    {
      Id: 'AssetCatGroup',
      Name: t(translations.AssetCatGroupName) as string,
      Cell: ({ value, original }) => {
        if (
          !!value &&
          setting.GetBooleanByKey(AllowedSettings.AssetCatGroupEnabled)
        ) {
          return (
            <div>
              {value.Name}
              {isAuthenticated && (
                <Tooltip title={t(translations.SeeAllRelatedAssets) as string}>
                  <span>
                    <IconButton
                      aria-label="link"
                      onClick={() => {
                        let setProps = {
                          useSidePanel: true,
                          actionType: 'AssetSearch',
                          assetCatGroupId: original.AssetCat.GroupId,
                          customPredicates: [
                            `${new Condition<IMyAssetsRow>(
                              'AssetCatGroupId',
                              ODataOperators.Equals,
                              original.AssetCat.GroupId,
                            ).toString()}`,
                          ],
                          hideCoresToggle: true,
                        } as AssetsSelectionProps;

                        openPanel({
                          renderPageType: RenderPageType.AssetsSelection,
                          renderPageProps: setProps,
                          expanded: !useSidePanel,
                          isCover: isCover,
                          useSidePanel: !isCover,
                        } as SidePanelOpenState);
                      }}
                      // onClick={() =>
                      //   window.open(
                      //     toRootedURL(
                      //       '/AssetSearch?active=true&AssetCatGroupId=' +
                      //         original.AssetCat.GroupId,
                      //     ),
                      //     '_blank',
                      //   )
                      // }
                    >
                      <Icon icon="arrow-up-right-from-square" />
                    </IconButton>
                  </span>
                </Tooltip>
              )}
            </div>
          );
        }
      },
    },
    {
      Id: 'AssetCat',
      Name: t(translations.AssetCategory) as string,
      PropName: 'AssetCat',
      validToRender: ({ value }) => {
        return (
          !!value && setting.GetBooleanByKey(AllowedSettings.AssetCatEnabled)
        );
      },
      Cell: ({ original }) => {
        return (
          <div>
            {original.AssetCat.Name}

            <>
              {!!original.AssetCat.InternalServiceAvaliable && (
                <>{t(translations.InternalServiceAvaliable)}</>
              )}
            </>
            {isAuthenticated && (
              <Tooltip title={t(translations.SeeAllRelatedAssets) as string}>
                <span>
                  <IconButton
                    aria-label="link"
                    onClick={() => {
                      let setProps = {
                        useSidePanel: true,
                        actionType: 'AssetSearch',
                        assetCatId: original.AssetCat.Id,
                        customPredicates: [
                          `${new Condition<IMyAssetsRow>(
                            'AssetCatId',
                            ODataOperators.Equals,
                            original.AssetCat.Id,
                          ).toString()}`,
                        ],
                        hideCoresToggle: true,
                      } as AssetsSelectionProps;

                      openPanel({
                        renderPageType: RenderPageType.AssetsSelection,
                        renderPageProps: setProps,
                        expanded: !useSidePanel,
                        isCover: isCover,
                        useSidePanel: !isCover,
                      } as SidePanelOpenState);
                    }}
                    // onClick={() =>
                    //   window.open(
                    //     toRootedURL(
                    //       '/AssetSearch?active=true&AssetCatId=' +
                    //         original.AssetCat.Id,
                    //     ),
                    //     '_blank',
                    //   )
                    // }
                  >
                    <Icon icon="arrow-up-right-from-square" />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </div>
        );
      },
    },
    {
      Id: 'NickName',
      Name: t(translations.NickName) as string,
    },
    {
      Id: 'OtherNames',
      Name: t(translations.OtherNames) as string,
    },
    {
      Id: 'Brand',
      Name: t(translations.Brand),
    },
    {
      Id: 'Model',
      Name: t(translations.Model) as string,
    },
    {
      Id: 'ModelID',
      Name: t(translations.Model) as string,
      validToRender: ({ value }) => !!value,
      Cell: ({ original, value }) => (
        <Link
          href={'/Assets/AssetModelDetails/Details.aspx?id' + value}
          target="_blank"
        >
          {value}
        </Link>
      ),
    },
    {
      Id: 'ModelNumber',
      Name: t(translations.ModelNumber) as string,
    },
    {
      Id: 'Campus',
      Name: t(translations.Campus) as string,
      validToRender: ({ value, original }) => !!value && value !== null,
      Cell: ({ value, original }) => <>{value.Name}</>,
    },
    {
      Id: 'Building',
      Name: t(translations.Building) as string,
    },
    {
      Id: 'Room',
      Name: t(translations.RoomNumber) as string,
      validToRender: ({ value, original }) => !!value,
      Cell: ({ value, original }) => (
        <>
          {!!value && <>{value}</>}
          {isAdmin === true && (
            <AssetLocationLastUpdate assetId={original.Id} />
          )}
        </>
      ),
    },
    {
      Id: 'LocationsList',
      Name: t(translations.LocationsList) as string,
      PropName: 'LocationsList',
      Cell: ({ value, original }) => <>{value.Name}</>,
    },
    {
      Id: 'SubLocationName',
      Name: t(translations.LocationsList) as string,
      PropName: 'SubLocationsList',
    },
    {
      Id: 'Experts',
      Name: t(translations.EquipmentExpert) as string,
      validToRender: ({ value }) => !!value && value.length > 0,
      Cell: UsersRenderer(),
    },
    {
      Id: 'Contacts',
      Name: t(translations.ContactsPersonForEquipment) as string,
      validToRender: ({ value }) => !!value && value.length > 0,
      Cell: UsersRenderer(),
    },
    {
      Id: 'Suppliers',
      Name: t(translations.ExternalCompany) as string,
      validToRender: ({ value }) => !!value && value.length > 0,
      Cell: ArrayRenderer<Entity<string>>(v => <>{v.Name}</>),
    },
    {
      Id: 'MainSpecRange',
      Name: t(translations.MainSpecificationRange) as string,
    },
    {
      Id: 'ElectricalSpecs',
      Name: t(translations.ElectricalSpecs) as string,
    },
    {
      Id: 'FunctionalCharacteristics',
      Name: t(translations.FunctionalCharacteristics) as string,
    },
    {
      Id: 'Description',
      Name: t(translations.Description) as string,
      Cell: HtmlRenderer,
    },
    {
      Id: 'WebLink',
      Name: t(translations.WebLink) as string,
      Cell: ({ value }) => value && <Link href={value}>{value}</Link>,
    },
    {
      Id: 'Raac',
      Name: t(translations.RoutineActiveAnalysisCapable) as string,
      Cell: BooleanRender,
    },
    {
      Id: 'Tags',
      Name: t(translations.Tags) as string,
      validToRender: ({ value }) => !!value && value.length > 0,
      Cell: ArrayRenderer<Entity<number>>(v => <>{v.Name}</>),
    },
    {
      Id: 'ExtraDate0',
      Name: t(translations.Asset_ExtraDate0) as string,
      Cell: DateRenderer,
    },
    {
      Id: 'ExtraDate1',
      Name: t(translations.Asset_ExtraDate1) as string,
      Cell: DateRenderer,
    },
    {
      Id: 'ExtraDate2',
      Name: t(translations.Asset_ExtraDate2) as string,
      Cell: DateRenderer,
    },
    {
      Id: 'ExtraDate3',
      Name: t(translations.Asset_ExtraDate3) as string,
      Cell: DateRenderer,
    },
    {
      Id: 'ExtraDate4',
      Name: t(translations.Asset_ExtraDate4) as string,
      Cell: DateRenderer,
    },
    {
      Id: 'ExtraDate5',
      Name: t(translations.Asset_ExtraDate5) as string,
      Cell: DateRenderer,
    },
    {
      Id: 'ExtraDate6',
      Name: t(translations.Asset_ExtraDate6) as string,
      Cell: DateRenderer,
    },
    {
      Id: 'ExtraDate7',
      Name: t(translations.Asset_ExtraDate7) as string,
      Cell: DateRenderer,
    },
    {
      Id: 'ExtraDate8',
      Name: t(translations.Asset_ExtraDate8) as string,
      Cell: DateRenderer,
    },
    {
      Id: 'ExtraDate9',
      Name: t(translations.Asset_ExtraDate9) as string,
      Cell: DateRenderer,
    },
    {
      Id: 'ExtraText0',
      Name: t(translations.Asset_ExtraText0) as string,
    },
    {
      Id: 'ExtraText1',
      Name: t(translations.Asset_ExtraText1) as string,
    },
    {
      Id: 'ExtraText2',
      Name: t(translations.Asset_ExtraText2) as string,
    },
    {
      Id: 'ExtraText3',
      Name: t(translations.Asset_ExtraText3) as string,
    },
    {
      Id: 'ExtraText4',
      Name: t(translations.Asset_ExtraText4) as string,
    },
    {
      Id: 'ExtraText5',
      Name: t(translations.Asset_ExtraText5) as string,
    },
    {
      Id: 'ExtraText6',
      Name: t(translations.Asset_ExtraText6) as string,
    },
    {
      Id: 'ExtraText7',
      Name: t(translations.Asset_ExtraText7) as string,
    },
    {
      Id: 'ExtraText8',
      Name: t(translations.Asset_ExtraText8) as string,
    },
    {
      Id: 'ExtraText9',
      Name: t(translations.Asset_ExtraText9) as string,
    },
    {
      Id: 'ExtraText10',
      Name: t(translations.Asset_ExtraText10) as string,
    },
    {
      Id: 'ExtraText11',
      Name: t(translations.Asset_ExtraText11) as string,
    },
    {
      Id: 'ExtraText12',
      Name: t(translations.Asset_ExtraText12) as string,
    },
    {
      Id: 'ExtraText13',
      Name: t(translations.Asset_ExtraText13) as string,
    },
    {
      Id: 'ExtraText14',
      Name: t(translations.Asset_ExtraText14) as string,
    },
    {
      Id: 'ExtraText15',
      Name: t(translations.Asset_ExtraText15) as string,
    },
    {
      Id: 'ExtraInt0',
      Name: t(translations.Asset_ExtraInt0) as string,
    },
    {
      Id: 'ExtraInt1',
      Name: t(translations.Asset_ExtraInt1) as string,
    },
    {
      Id: 'ExtraInt2',
      Name: t(translations.Asset_ExtraInt2) as string,
    },
    {
      Id: 'ExtraInt3',
      Name: t(translations.Asset_ExtraInt3) as string,
    },
    {
      Id: 'ExtraInt4',
      Name: t(translations.Asset_ExtraInt4) as string,
    },
    {
      Id: 'ExtraDec0',
      Name: t(translations.Asset_ExtraDec0) as string,
    },
    {
      Id: 'ExtraDec1',
      Name: t(translations.Asset_ExtraDec1) as string,
    },
    {
      Id: 'ExtraDec2',
      Name: t(translations.Asset_ExtraDec2) as string,
    },
    {
      Id: 'ExtraDec3',
      Name: t(translations.Asset_ExtraDec3) as string,
    },
    {
      Id: 'ExtraDec4',
      Name: t(translations.Asset_ExtraDec4) as string,
    },
    {
      Id: 'ExtraBool0',
      Name: t(translations.Asset_ExtraBool0) as string,
      Cell: BooleanRender,
    },
    {
      Id: 'ExtraBool1',
      Name: t(translations.Asset_ExtraBool1) as string,
      Cell: BooleanRender,
    },
    {
      Id: 'ExtraBool2',
      Name: t(translations.Asset_ExtraBool2) as string,
      Cell: BooleanRender,
    },
    {
      Id: 'ExtraBool3',
      Name: t(translations.Asset_ExtraBool3) as string,
      Cell: BooleanRender,
    },
    {
      Id: 'ExtraBool4',
      Name: t(translations.Asset_ExtraBool4) as string,
      Cell: BooleanRender,
    },
    {
      Id: 'ExtraUrl0',
      Name: t(translations.Asset_ExtraUrl0) as string,
    },
    {
      Id: 'ExtraUrl1',
      Name: t(translations.Asset_ExtraUrl1) as string,
    },
    {
      Id: 'ExtraUrl2',
      Name: t(translations.Asset_ExtraUrl2) as string,
    },
    {
      Id: 'ExtraUrl3',
      Name: t(translations.Asset_ExtraUrl3) as string,
    },
    {
      Id: 'ExtraUrl4',
      Name: t(translations.Asset_ExtraUrl4) as string,
    },
    {
      Id: 'ExtraRichText0',
      Name: t(translations.Asset_ExtraRichText0) as string,
      Cell: HtmlRenderer,
    },
    {
      Id: 'ExtraRichText1',
      Name: t(translations.Asset_ExtraRichText1) as string,
      Cell: HtmlRenderer,
    },
    {
      Id: 'ExtraRichText2',
      Name: t(translations.Asset_ExtraRichText2) as string,
      Cell: HtmlRenderer,
    },
    {
      Id: 'ExtraRichText3',
      Name: t(translations.Asset_ExtraRichText3) as string,
      Cell: HtmlRenderer,
    },
    {
      Id: 'ExtraRichText4',
      Name: t(translations.Asset_ExtraRichText4) as string,
      Cell: HtmlRenderer,
    },
    {
      Id: 'ExtraDDL0',
      Name: t(translations.Asset_ExtraDDL0) as string,
      Cell: ({ value, original }) => !!value && <>{original.ExtraDDL0?.Name}</>,
    },
    {
      Id: 'ExtraDDL1',
      Name: t(translations.Asset_ExtraDDL1) as string,
      Cell: ({ value, original }) => !!value && <>{original.ExtraDDL1?.Name}</>,
    },
    {
      Id: 'ExtraDDL2',
      Name: t(translations.Asset_ExtraDDL2) as string,
      Cell: ({ value, original }) => !!value && <>{original.ExtraDDL2?.Name}</>,
    },
    {
      Id: 'ExtraDDL3',
      Name: t(translations.Asset_ExtraDDL3) as string,
      Cell: ({ value, original }) => !!value && <>{original.ExtraDDL3?.Name}</>,
    },
    {
      Id: 'ExtraDDL4',
      Name: t(translations.Asset_ExtraDDL4) as string,
      Cell: ({ value, original }) => !!value && <>{original.ExtraDDL4?.Name}</>,
    },
    {
      Id: 'ExtraDDL5',
      Name: t(translations.Asset_ExtraDDL5) as string,
      Cell: ({ value, original }) => !!value && <>{original.ExtraDDL5?.Name}</>,
    },
    {
      Id: 'ExtraDDL6',
      Name: t(translations.Asset_ExtraDDL6) as string,
      Cell: ({ value, original }) => !!value && <>{original.ExtraDDL6?.Name}</>,
    },
    {
      Id: 'ExtraDDL7',
      Name: t(translations.Asset_ExtraDDL7) as string,
      Cell: ({ value, original }) => !!value && <>{original.ExtraDDL7?.Name}</>,
    },
    {
      Id: 'ExtraDDL8',
      Name: t(translations.Asset_ExtraDDL8) as string,
      Cell: ({ value, original }) => !!value && <>{original.ExtraDDL8?.Name}</>,
    },
    {
      Id: 'ExtraDDL9',
      Name: t(translations.Asset_ExtraDDL9) as string,
      Cell: ({ value, original }) => !!value && <>{original.ExtraDDL9?.Name}</>,
    },
    {
      Id: 'ExtraDDL10',
      Name: t(translations.Asset_ExtraDDL10) as string,
      Cell: ({ value, original }) =>
        !!value && <>{original.ExtraDDL10?.Name}</>,
    },
    {
      Id: 'ExtraDDL11',
      Name: t(translations.Asset_ExtraDDL11) as string,
      Cell: ({ value, original }) =>
        !!value && <>{original.ExtraDDL11?.Name}</>,
    },
    {
      Id: 'ExtraDDL12',
      Name: t(translations.Asset_ExtraDDL12) as string,
      Cell: ({ value, original }) =>
        !!value && <>{original.ExtraDDL12?.Name}</>,
    },
    {
      Id: 'ExtraDDL13',
      Name: t(translations.Asset_ExtraDDL13) as string,
      Cell: ({ value, original }) =>
        !!value && <>{original.ExtraDDL13?.Name}</>,
    },
    {
      Id: 'ExtraDDL14',
      Name: t(translations.Asset_ExtraDDL14) as string,
      Cell: ({ value, original }) =>
        !!value && <>{original.ExtraDDL14?.Name}</>,
    },
    {
      Id: 'ExtraDDL15',
      Name: t(translations.Asset_ExtraDDL15) as string,
      Cell: ({ value, original }) =>
        !!value && <>{original.ExtraDDL15?.Name}</>,
    },
    {
      Id: 'BrandCode',
      Name: t(translations.BrandBarcodeNumber) as string,
    },
    {
      Id: 'ServiceContract',
      Name: t(translations.ServiceContract) as string,
    },
    {
      Id: 'POnumber',
      Name: t(translations.PurchaseOrder) as string,
    },
    {
      Id: 'WarrantyEndDate',
      Name: t(translations.WarrantyEndDate) as string,
      Cell: DateRenderer,
    },
    {
      Id: 'LifeDate',
      Name: t(translations.LifeDateView) as string,
      validToRender: ({ value }) => !!value,
      Cell: ({ value, original }) =>
        value !== null && <>{dateUtils.formatDistanceToNow(value)}</>,
    },
    {
      Id: 'ExportLicense',
      Name: t(translations.ExportLicense) as string,
    },
    {
      Id: 'InternalCode',
      Name: t(translations.InternalEquipmentBarcodeNumber) as string,
    },
    {
      Id: 'AccountingId',
      Name: t(translations.AccountingId) as string,
    },
    {
      Id: 'Active',
      Name: t(translations.Active) as string,
      Cell: ({ value }) => <BooleanRender value={value === true} />,
    },
    {
      Id: 'Details',
      Name: t(translations.AssetDetails) as string,
      State: AssetRowState.SectionName,
      Cell: ({ value, original }) => (
        <BasicTypography variant="h4" bold>
          {t(translations.AssetDetails)}
        </BasicTypography>
      ),
    },
    {
      Id: 'Classification',
      Name: t(translations.AssetClassification) as string,
      State: AssetRowState.SectionName,
      Cell: ({ value, original }) => (
        <BasicTypography variant="h4" bold>
          {t(translations.AssetClassification)}
        </BasicTypography>
      ),
    },
    {
      Id: 'Location',
      Name: t(translations.AssetLocation) as string,
      State: AssetRowState.SectionName,
      Cell: ({ value, original }) => (
        <BasicTypography variant="h4" bold>
          {t(translations.AssetLocation)}
        </BasicTypography>
      ),
    },
    {
      Id: 'SectionContacts',
      Name: t(translations.AssetContacts) as string,
      State: AssetRowState.SectionName,
      Cell: ({ value, original }) => (
        <BasicTypography variant="h4" bold>
          {t(translations.AssetContacts)}
        </BasicTypography>
      ),
    },
    {
      Id: 'Identifiers',
      Name: t(translations.AssetIdentifiers) as string,
      State: AssetRowState.SectionName,
      Cell: ({ value, original }) => (
        <BasicTypography variant="h4" bold>
          {t(translations.AssetIdentifiers)}
        </BasicTypography>
      ),
    },
    {
      Id: 'AdditionalInfo',
      Name: t(translations.AssetAdditionalInfo) as string,
      State: AssetRowState.SectionName,
      Cell: ({ value, original }) => (
        <BasicTypography variant="h4" bold>
          {t(translations.AssetAdditionalInfo)}
        </BasicTypography>
      ),
    },
    {
      Id: 'SectionLabel7',
      Name: t(translations.AssetSectionLabel7) as string,
      State: AssetRowState.SectionName,
    },
    {
      Id: 'SectionLabel8',
      Name: t(translations.AssetSectionLabel8) as string,
      State: AssetRowState.SectionName,
    },
    {
      Id: 'SectionLabel9',
      Name: t(translations.AssetSectionLabel9) as string,
      State: AssetRowState.SectionName,
    },
    {
      Id: 'SectionLabel10',
      Name: t(translations.AssetSectionLabel10) as string,
      State: AssetRowState.SectionName,
    },
    {
      Id: 'DOI',
      Name: t(translations.Doi) as string,
      Cell: ({ value, original }) => {
        const doiURL = `https://doi.org/${value}`;
        return (
          <Tooltip title={t(translations.AssetDoiTooltip) as string}>
            <span>
              <ExternalLink showIcon href={doiURL}>
                {value}
              </ExternalLink>
            </span>
          </Tooltip>
        );
      },
    },
    {
      Id: 'Status',
      Name: t(translations.Status) as string,
      Cell: ({ value, original }) => {
        return (
          <StatusLabel
            variant={
              original.StatusId === 1
                ? 'success'
                : original.StatusId === 2
                ? 'warning'
                : original.StatusId === 3
                ? 'error'
                : undefined
            }
          >
            {value !== ' ' ? (
              <>{value}</>
            ) : (
              <Skeleton width={'40px'} height={'32px'} />
            )}
          </StatusLabel>
        );
      },
    },
    {
      Id: 'AssetAssemblyStructure',
      Name: t(translations.AssemblyStructure) as string,
      validToRender: val => {
        return (
          ((val.value !== undefined && val.value !== null) ||
            (!!val.original.DOI && val.original.DOI !== null)) &&
          val.original.ServiceType === 'Online'
        );
      },
      Cell: ({ value, original }) => {
        return (
          <>
            {value !== undefined && value !== null && (
              <AssemblyStructure
                assemblyStructure={value}
                openPanelWithCover={openPanel}
                useSidePanel={useSidePanel}
                showTitle={false}
              />
            )}
            {original.DOI &&
              original.DOI !== null &&
              original.ServiceId !== null && (
                <SnapshotLink
                  equipmentId={original.ServiceId}
                  primary={true}
                  showPicker={true}
                >
                  {t(translations.AssemblyPastSnapshots) as string}
                </SnapshotLink>
              )}
          </>
        );
      },
    },
    {
      Id: 'Range',
      Name: t(translations.Range) as string,
      Cell: ({ value, original }) => {
        return (
          <>
            {value.map(v => {
              if (v.MinValue !== undefined) {
                if (v.MaxValue !== undefined) {
                  return (
                    <div>
                      {v.MinValue} {' - '}
                      {v.MaxValue} {'   '}
                      {v.UnitTypeName}
                    </div>
                  );
                } else {
                  return (
                    <div>
                      {v.MinValue} {'   '}
                      {v.UnitTypeName} {'(Min)'}
                    </div>
                  );
                }
              } else {
                if (v.MaxValue !== undefined) {
                  return (
                    <div>
                      {v.MaxValue} {'   '}
                      {v.UnitTypeName} {'(Max)'}
                    </div>
                  );
                } else {
                  return <></>;
                }
              }
            })}
          </>
        );
      },
    },
    {
      Id: 'Comments',
      Name: t(translations.CommentsAndAttachments) as string,
      validToRender: val => {
        return (
          isAuthenticated === true &&
          ((!val.original.CommentsAreStaffonly && !isReadOnly) ||
            isAdmin === true)
        );
      },
      State: AssetRowState.Mandatory,
      Cell(value) {
        return (
          <AssetComments
            isAdmin={isAdmin === true}
            assetId={value.original.Id}
            staffonly={!!value.original.CommentsAreStaffonly}
          />
        );
      },
    },
    {
      Id: 'InternalId',
      PropName: 'InternalId',
      Name: t(translations.InternalId) as string,
    },
    // stub, could be used when full screen asset details will be migrated to react https://prog4biz.atlassian.net/browse/BKT-6902
    // {
    //   Id: 'Articles',
    //   PropName: 'Articles',
    //   Name: t(translations.RelatedPublications),
    //   Cell: ({ value }: { value?: IArticleDto[] }) => {
    //     return <AssetArticles value={value} />;
    //   },
    // },
  ];
};
type getRowsType = typeof getRows;

type ElementOf<T> = T extends Array<infer U> ? U : never;

export const rowsAsDict = (
  ...props: Parameters<getRowsType>
): Record<string, ElementOf<ReturnType<getRowsType>>> => {
  return getRows(...props).reduce((res, current) => {
    res[current.Id] = current;
    return res;
  }, {});
};
