import * as React from 'react';
import { useSelector } from 'react-redux';
import { DetectIsMobile } from 'utils/mobileDetect';
import {
  selectExpandedSidePanel,
  selectSidePanelOpen,
} from 'app/Layout/FrontendLayout/slice/selectors';
import clsx from 'clsx';
import { useTheme } from '@material-ui/core/styles';
import { RowSection } from '../wrappers';

export interface FormRowProps {
  children: React.ReactNode;
  fullRow?: boolean;
  minRow?: boolean;
  hide?: boolean;
  wrapOnMobile?: boolean;
  isCover?: boolean;
}
export const FormRow = (props: FormRowProps) => {
  const { children, fullRow, hide, minRow, wrapOnMobile, isCover } = props;
  const isMobile = DetectIsMobile();
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const sidePanelOpen = useSelector(selectSidePanelOpen);
  const showShortView =
    isMobile || (sidePanelOpen && !sidePanelExpanded) || isCover;
  const theme = useTheme();
  return (
    <RowSection
      theme={theme}
      className={clsx('row', {
        shortView: showShortView,
        fullRow: fullRow,
        hideRow: hide,
        minRow: minRow && !showShortView && !fullRow,
        wrap: wrapOnMobile,
      })}
    >
      {children}
    </RowSection>
  );
};
