/**
 *
 * Comments
 *
 */
import * as React from 'react';
import { CommentsApi } from 'api/CommentsApi';
import {
  CommentsContainer,
  EmailRelated,
} from 'app/components/CommentsContainer';
import {
  IComment,
  NewComment,
} from 'app/components/CommentsContainer/IComment';
import { useAsyncGetWthEffect } from 'app/hooks/useAsyncGetOnMount';
import { CommentReferenceTypes } from 'enums/commentReferenceTypes';
import { translations } from 'locales/translations';
import { useTranslation } from 'react-i18next';
import { useAbsolute } from 'utils/url-utils';
import { useDispatch } from 'react-redux';
import { useLargeFilesUploadSlice } from '../LargeFilesUpload/slice';
import { useAppSettingsSlice } from 'app/slice';

export interface CommentsProps
  extends Omit<EmailRelated, 'referenceName' | 'linkUrl'> {
  /**
   * Type of the comments, e.g. Invoice, UserTraining, etc.
   */
  commentType: CommentReferenceTypes;
  /**
   * Controls permissions related functionality like internal comments available to admins only
   */
  isAdmin: boolean;
  /**
   * Entity identifier to attach comments to
   */
  referenceId: number;
  /**
   * Optional link that will be shown inside of comments
   */
  link?: string;
  /**
   * default initial value for added comment
   */
  defaultComment?: Partial<IComment>;
  /**
   * set to true if user can not add comments
   */
  cannotComment?: boolean;
  /**
   * Entity string identifier e.g. UserName to attach comments to
   */
  referenseStringId?: string;
  /**
   * Event fired after a comment has been added successfully
   */
  onCommentAdded?: (comment: IComment) => void;

  onEditComment?: (comment: IComment, newComment: NewComment) => void;
  onDeleteComment?: (comment: IComment) => void;
  noTitle?: boolean;
  referenceName?: string;
  additionalActions?: (comments: IComment[]) => React.ReactNode;
  checkPermissions?: (comment: IComment) => boolean;
  checkEditPermissions?: (comment: IComment) => boolean;
  deletable?: boolean;
}

/**
 * Reusable comments component.
 * For example:
 * <Comments
 *    commentType={CommentReferenceTypes.Invoice}
 *    referenceId={referenceId}
 *    isAdmin={isAdmin}
 *    link={link}
 *    />
 * @param param
 * @returns
 */
export const Comments = ({
  commentType,
  referenceId,
  isAdmin,
  link,
  defaultComment,
  cannotComment,
  pageName,
  emailType,
  referenceName,
  referenseStringId,
  additionalActions,
  checkEditPermissions,
  checkPermissions,
  deletable,
  ...props
}: CommentsProps) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions: largeFileActions } = useLargeFilesUploadSlice();
  const { actions: globalActions } = useAppSettingsSlice();
  const [changed, setChanged] = React.useState(false);

  const comments = useAsyncGetWthEffect<IComment[]>(
    () =>
      CommentsApi.GetComments(
        commentType,
        referenceId,
        undefined,
        referenseStringId,
      ),
    [],
    [changed],
  );

  React.useEffect(() => {
    dispatch(
      largeFileActions.setCommentProperties({
        commentType: commentType,
        referenceId: referenceId,
        refernceStringId: referenseStringId,
      }),
    );
  }, [commentType, dispatch, largeFileActions, referenceId, referenseStringId]);
  const editComment = React.useCallback(
    (comment: IComment, newComment: NewComment) => {
      if (props.onEditComment) {
        props.onEditComment(comment, newComment);
      } else {
        if (checkEditPermissions && checkEditPermissions(comment)) {
          CommentsApi.SendComment(
            commentType,
            comment.ReferenceId ?? 0,
            newComment,
            referenseStringId,
            comment.Id,
          )
            .then(res => props.onCommentAdded?.(res))
            .catch(e => console.error(e))
            .finally(() => setChanged(prev => !prev));
        }
      }
    },
    [checkEditPermissions, commentType, props, referenseStringId],
  );
  const deleteComment = React.useCallback(
    (comment: IComment) => {
      if (props.onDeleteComment) {
        props.onDeleteComment(comment);
      } else {
        if (checkEditPermissions && checkEditPermissions(comment)) {
          CommentsApi.DeleteComment(comment.Id)
            .then(response => {
              if (response.ErrorMessages.length > 0) {
                dispatch(
                  globalActions.addNotification({
                    key: 'deleteCommentError',
                    message: response.ErrorMessages[0],
                    variant: 'error',
                  }),
                );
              } else {
                dispatch(
                  globalActions.addNotification({
                    key: 'deleteCommentSuccess',
                    message: response.SuccessMessages[0],
                    variant: 'success',
                  }),
                );
              }
              setChanged(prev => !prev);
            })
            .catch(e => console.error(e));
        }
      }
    },
    [checkEditPermissions, dispatch, globalActions, props],
  );
  return (
    <CommentsContainer
      Comments={comments}
      onSubmit={c => {
        CommentsApi.SendComment(commentType, referenceId, c, referenseStringId)
          .then(res => props.onCommentAdded?.(res))
          .catch(e => console.error(e))
          .finally(() => setChanged(prev => !prev));
      }}
      isAdmin={isAdmin}
      withMentions
      pageName={pageName ?? t(translations.Invoice)}
      referenceName={
        referenceName ?? referenseStringId ?? referenceId.toString()
      }
      linkUrl={useAbsolute(link)}
      emailType={emailType ?? 'Invoice'}
      defaultComment={defaultComment}
      cannotComment={cannotComment}
      additionalActions={additionalActions}
      checkEditPermissions={checkEditPermissions}
      checkPermissions={checkPermissions}
      onEditComment={editComment}
      onDeleteComment={deleteComment}
      deletable={deletable}
      {...props}
    />
  );
};
