import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IBasicTableState, IRow } from 'app/components/BasicTable';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { FormSectionTitle } from 'app/components/Forms/FormsLayout/FormSectionTitle';
import { GetFilters as getTrainingFilters } from 'app/pages/TrainingSessionsPage/Filter';
import { getColumns } from 'app/pages/TrainingSessionsPage/Columns';
import { ITrainingSessionsRow } from 'app/pages/TrainingSessionsPage/ITrainingSessionsRow';
import { TrainingSessionsTable } from 'app/pages/TrainingSessionsPage/TrainingSessionsTable';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import { toRootedURL } from 'utils/url-utils';
import { BaseFilterValues, useFilters } from './TableFilters';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { PrimaryAction } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { dateUtils } from 'utils/date-utils';
import trainingSessionPrimaryAction from 'app/pages/TrainingSessionsPage/PrimaryAction';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { GettableCount } from 'app/components/BasicTable/components/GetTableCount';

const AssetTrainingSessionsColumns: string[] = [
  'Id',
  'TrainingState',
  'TrainerName',
  'StartDate',
  'EndDate',
  'Duration',
  'Campus',
  'Building',
  'Room',
  'hasReservation',
  'myReservationId',
  'MinAttendance',
  'MaxAttendance',
  'RemainedSlots',
  'Remarks',
];
const url = '/api/odata/v4/TrainingSessions';
const GetPredicates = (
  assetId: number,
  serviceGroupId?: number,
  availabilityType?: string,
): Array<Condition<ITrainingSessionsRow> | string> => {
  const res: Array<Condition<ITrainingSessionsRow> | string> = [];
  const assetPredicate = new Condition<ITrainingSessionsRow>(
    'AssetId',
    ODataOperators.Equals,
    assetId,
  );
  const serviceGroupPredicate = new Condition<ITrainingSessionsRow>(
    'ServiceGroupId',
    ODataOperators.Equals,
    serviceGroupId,
  );
  if (!!availabilityType && availabilityType === 'ServiceGroup') {
    res.push(serviceGroupPredicate);
  } else {
    res.push(assetPredicate);
  }
  res.push(
    new Condition<ITrainingSessionsRow>(
      'IsUpcoming',
      ODataOperators.Equals,
      true,
    ),
  );

  return res;
};
class FilterValues extends BaseFilterValues {
  TrainingState: FilterValueType | null = null;
  Trainer: FilterValueType | null = null;
}
const filterValues = new FilterValues();
export interface AssetTrainingSessionsProps {
  assetId: number;
  serviceId: number | null;
  serviceTypeId?: ServiceType;
  isMobile?: boolean;
  serviceGroupId?: number;
  availabilityType?: string;
  useSidePanel?: boolean;
  openPanel: (state: SidePanelOpenState) => void;
}
export async function GetTrainingSessionsTableCount(
  assetId: number,
  name: string,
  updateCounters: (name: string, count: number) => void,
  serviceGroupId?: number,
  availabilityType?: string,
) {
  const res = await GettableCount({
    api: url,
    predicates: GetPredicates(
      assetId,
      serviceGroupId,
      availabilityType,
    ) as Array<Condition<IRow> | string>,
    columns: ['Id'],
    globalFilterValue: '',
    customFilter: [],
    serviceGroups: [],
    isOptionalServiceGroup: true,
  });
  updateCounters(name, res);
}
export function AssetTrainingSessions(props: AssetTrainingSessionsProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectAuthenticatedUser);
  const history = useHistory();
  const { actions } = useLayoutSlice();
  const {
    assetId,
    serviceId,
    serviceTypeId,
    isMobile,
    serviceGroupId,
    availabilityType,
    useSidePanel,
    openPanel,
  } = props;
  const {
    allfilters,
    appliedFilters,
    handleFilterChange,
    getFilterValue,
    setFilterValue,
  } = useFilters(
    getTrainingFilters,
    filterValues.GetFilter(),
    filterValues.SetValue,
  );
  const trainingSessionsColumns = React.useMemo(() => getColumns(t), [t]);
  const columns = React.useMemo(() => {
    let res: Column<ITrainingSessionsRow>[] = [];
    for (const c of AssetTrainingSessionsColumns) {
      const column = trainingSessionsColumns.find(f => f.accessor === c);
      if (column !== undefined) {
        res.push(column);
      }
    }
    return res;
  }, [trainingSessionsColumns]);
  const { newDate } = useSystemDate();

  const pageActions: PageActionRenderer[] = React.useMemo(() => {
    const actions: PageActionRenderer[] = [];
    if (serviceTypeId !== undefined && serviceId !== null) {
      if (
        user?.HasAdminServicePermissions(serviceTypeId, serviceId) ||
        user?.HasLabTechServicePermissions(serviceTypeId, serviceId)
      ) {
        actions.push(() => (
          <TopActionButton
            icon="plus"
            startIcon="plus"
            text={t(translations.AddNew)}
            isIconButton={isMobile}
            onClick={() => {
              window.open(
                toRootedURL('/TrainingSession/AddNew.aspx', {
                  eqid: serviceId,
                }),
                '_blank',
              );
            }}
          />
        ));
        actions.push(() => (
          <TopActionButton
            icon={'file'}
            startIcon="file"
            text={t(translations.ManageSessions) as string}
            variant="white"
            isIconButton={isMobile}
            onClick={() => {
              window.open(
                toRootedURL('/TrainingSession/Details.aspx', {
                  eqid: serviceId,
                  upcoming: true,
                }),
                '_blank',
              );
            }}
          />
        ));
      }
    }
    return actions;
  }, [isMobile, serviceId, serviceTypeId, t, user]);
  const goToTrainingSessions = React.useCallback(() => {
    history.push(`trainingsessions?eqid=${serviceId}`);
    dispatch(actions.toggleSidePanel(false));
  }, [actions, dispatch, history, serviceId]);
  const customScreenName: React.ReactNode = React.useMemo(() => {
    const result = (
      <>
        <FormSectionTitle title={t(translations.UpcomingTrainingSessions)} />
        <IconButton aria-label="link" onClick={goToTrainingSessions}>
          <Icon icon="arrow-up-right-from-square" />
        </IconButton>
      </>
    );
    return result;
  }, [goToTrainingSessions, t]);
  const primaryAction: PrimaryAction<ITrainingSessionsRow> = React.useCallback(
    (...args) => {
      const hasNextSession =
        (args[0].original.StartDate ?? newDate()) >
        dateUtils.truncateTime(newDate());
      if (hasNextSession) {
        trainingSessionPrimaryAction(
          openPanel,
          args[0],
          args[1],
          args[2],
          useSidePanel,
        );
      }
    },
    [newDate, openPanel, useSidePanel],
  );
  const initialState: IBasicTableState<ITrainingSessionsRow> = React.useMemo(
    () => ({
      sortBy: [{ id: 'StartDate', desc: false }],
    }),
    [],
  );
  return (
    <>
      <TrainingSessionsTable
        columns={columns}
        initialState={initialState}
        predicates={GetPredicates(assetId, serviceGroupId, availabilityType)}
        availableFilters={allfilters}
        appliedFilters={appliedFilters}
        onFilterChange={handleFilterChange}
        getFilterValue={getFilterValue}
        setFilterValue={setFilterValue}
        useConfigurable={false}
        useCardsByDefault={isMobile}
        allowCards={isMobile}
        pageActions={pageActions}
        useExport={false}
        serviceGroups={[]}
        customScreenName={customScreenName}
        hideMenuOnMobile={true}
        screenNameVariant="section"
        tablePaddingVariant={'dense'}
        compactTable={isMobile}
        primaryAction={primaryAction}
      />
    </>
  );
}
