import { translations } from 'locales/translations';
import { useCallback, useRef, useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { IConnectedFiltersDto } from 'types/IConnectedFiltersDto';
import { FieldHandler, FormListener } from '../FormRender/FormRenderer';
import * as Yup from 'yup';
import {
  BudgetEntitySechema,
  BudgetExperimentEntitySechema,
  InstituteProjectEntitySechema,
  UserEntitySchema,
  UserGroupEntitySchema,
} from '../Schemas';
import { TransformDate } from '../Schemas/SchemaMethods';
import { dateUtils } from 'utils/date-utils';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, FormikProps, FormikValues } from 'formik';
import { FormUserPicker } from '../FormUserPicker';
import { FormFieldsSection, FormRow } from '../FormsLayout';
import { FormBudgetExperimentsPicker } from '../FormBudgetExperimentsPicker';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { budgetExperimentsFilter } from 'app/components/pickers/AutocompletePickers/BudgetExperimentsPicker';
import { FormRestrictedUserGroupPicker } from '../FormRestrictedUserGroupPicker';
import { RestrictedFilter } from 'app/components/pickers/AutocompletePickers/UserGroupPicker';
import { FormLabelText } from '../FormLabelText';
import { Box } from '@material-ui/core';
import { ServicesFilter } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { FormFundingTypePicker } from '../FormFundingTypePicker';
import { FormBudgetPicker } from '../FormBudgetPicker';
import { restrictedBudgetFilter } from 'app/components/pickers/AutocompletePickers/BudgetPicker';
import { getSingleInvoiceFundingType } from 'app/components/pickers/StaticOptionsPickers/FundingTypeIdPicker';
import {
  selectConnectedFieldHandler,
  selectConnectedFiltersData,
  selectConnectedSettings,
} from './slice/selectors';
import { useConnectedFiltersSlice } from './slice';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { UserGroupListener } from './FormFieldListeners/UserGroupListener';
import { UserNameListener } from './FormFieldListeners/UserNameListener';
import { BudgetListener } from './FormFieldListeners/BudgetListener';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { useSystemDate } from 'app/hooks/useSystemDate';
import React from 'react';
import { Roles } from 'app/slice/types';
import { FormBookitDateTimePicker } from '../FormBookitDateTimePicker';
import produce from 'immer';
import { Entity } from 'types/common';
import { PurchaseOrderOptions } from 'api/odata/generated/enums/PurchaseOrderOptions';
import { FormTextField } from '../FormTextField';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';

export interface ConnectedFiltersFormProps {
  isEdit: boolean;
  onSubmit: (item: IConnectedFiltersDto) => void;
  ConnectedFiltersData?: IConnectedFiltersDto;
  processing?: boolean;
  bindSubmitForm: any;
  serviceTypeEnum?: ServiceType;
  serviceTypes: IServiceTypeFilterDto[];
  globalSettings: GlobalSettingsType;
  startTimeLabel?: string;
  endTimeLabel?: string;
  startLabelInline?: boolean;
  endLabelInline?: boolean;
  formIsValid?: (isValid: boolean) => void;
  orderForConsumables?: boolean;
  forceDisabled?: boolean;
  budgetPickerInfo?: string;
}

export function ConnectedFiltersForm({
  isEdit,
  onSubmit,
  ConnectedFiltersData,
  processing,
  bindSubmitForm,
  serviceTypeEnum,
  serviceTypes,
  startTimeLabel,
  endTimeLabel,
  startLabelInline,
  endLabelInline,
  globalSettings,
  formIsValid,
  orderForConsumables,
  forceDisabled,
  budgetPickerInfo,
}: ConnectedFiltersFormProps): JSX.Element {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useConnectedFiltersSlice();
  const { actions: layoutActions } = useLayoutSlice();
  //USER AUTH
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const connectedSettings = useSelector(selectConnectedSettings);
  const connectedData = useSelector(selectConnectedFiltersData);
  //PICKER STATES
  const [userChanged, setUserChanged] = useState<boolean>(false);
  const [userGroupChanged, setUserGroupChanged] = useState<boolean>(false);
  const [budgetChanged, setBudgetChanged] = useState<boolean>(false);
  const [budgetExpirementChanged, setBudgetExpirementChanged] = useState<
    boolean
  >(false);
  const [startTimeChanged, setStartTimeChanged] = useState<boolean>(false);
  const [endTimeChanged, setEndTimeChanged] = useState<boolean>(false);
  const { newDate } = useSystemDate();
  //REFS
  const formikRef = useRef<FormikProps<FormikValues>>(null);
  //SCHEMA
  const computedSchema = useMemo(() => {
    const ConnectedFiltersSchema: Yup.SchemaOf<IConnectedFiltersDto> = Yup.object(
      {
        StartTime: Yup.date()
          .transform(TransformDate)
          .default(dateUtils.dateOrStringToDate(newDate())),
        EndTime: Yup.date().transform(TransformDate).optional(),
        User: UserEntitySchema.label(t(translations.User) as string)
          .nullable()
          .notRequired(),
        UserGroup: UserGroupEntitySchema.label(
          t(translations.UserGroup) as string,
        )
          .nullable()
          .default(null),
        Budget: BudgetEntitySechema.label(
          t(translations.BudgetNumber) as string,
        )
          .nullable()
          .default(null),
        //BudgetExperimentId if displayed then should be required
        BudgetExperiment: BudgetExperimentEntitySechema.nullable().default(
          null,
        ),
        InstituteProject: InstituteProjectEntitySechema.nullable()
          .notRequired()
          .optional()
          .default(null),
        ExternalCustomer: Yup.mixed()
          .label(t(translations.ExternalCustomer) as string)
          .nullable(true),
        FundingType: Yup.mixed()
          .default(getSingleInvoiceFundingType(0))
          .label(t(translations.FundingType) as string),
        PurchaseOrder: Yup.string()
          .label(t(translations.PurchaseOrder) as string)
          .nullable(true)
          .min(globalSettings.purchaseOrderLengthSetting ?? 0)
          .when('Budget', {
            is: budget => {
              const result =
                globalSettings.purchaseOrderBudgetEnabled &&
                (budget as IBudgetFilterDto)?.PurchaseOrderOptionId ===
                  PurchaseOrderOptions.Mandatory;
              return result;
            },
            then: schema => schema.required(),
          }),
        // PurchaseOrder: Yup.string()
        //   .label(t(translations.PurchaseOrder) as string)
        //   .nullable(true),
        Reference: Yup.string()
          .label(t(translations.Reference) as string)
          .nullable(true),
      },
    );
    if (connectedSettings.budgetVisible === true) {
      ConnectedFiltersSchema.fields.Budget.withMutation(schema =>
        schema
          .required(
            `${t(translations.BudgetIsRequired) as string} ${
              !!budgetPickerInfo && forceDisabled ? budgetPickerInfo : ''
            }`,
          )
          .label(t(translations.BudgetNumber) as string),
      );
    }
    if (!processing) {
      if (
        connectedSettings.budgetExpirementVisible &&
        !!connectedData &&
        !!connectedData.Budget &&
        connectedData.Budget !== null &&
        (connectedData.Budget as IBudgetFilterDto).HideExperiments !==
          undefined &&
        (connectedData.Budget as IBudgetFilterDto)?.HideExperiments === false
      ) {
        ConnectedFiltersSchema.fields.BudgetExperiment.withMutation(schema =>
          schema.required(
            t(translations.res_err_BudgetExperimentRequired) as string,
          ),
        );
      }
      if (
        !connectedSettings.budgetVisible ||
        (connectedSettings.budgetVisible &&
          !connectedSettings.ReservationUserGroupGroupByBudgetUserGroup) ||
        connectedSettings.userGroupVisible
      ) {
        ConnectedFiltersSchema.fields.UserGroup.withMutation(schema =>
          schema
            .label(t(translations.UserGroup) as string)
            .required(t(translations.err_UserGroupRequired) as string),
        );
        ConnectedFiltersSchema.fields.User.withMutation(schema =>
          schema
            .label(t(translations.User) as string)
            .required(t(translations.err_UserFieldRequired) as string),
        );
      }
      if (connectedSettings.endTimeVisible) {
        ConnectedFiltersSchema.fields.EndTime.withMutation(schema =>
          schema
            .label(t(translations.EndTime) as string)
            .required(t(translations._errEndTimeRequired) as string)
            .min(
              Yup.ref('StartTime'),
              t(translations.err_EndTimeLowerThanStartTime) as string,
            ),
        );
      }
      if (connectedSettings.userVisible) {
        ConnectedFiltersSchema.fields.User.withMutation(schema =>
          schema
            .label(t(translations.User) as string)
            .required(t(translations.err_UserFieldRequired) as string),
        );
      }
    }

    return ConnectedFiltersSchema;
  }, [
    budgetPickerInfo,
    connectedData,
    connectedSettings.ReservationUserGroupGroupByBudgetUserGroup,
    connectedSettings.budgetExpirementVisible,
    connectedSettings.budgetVisible,
    connectedSettings.endTimeVisible,
    connectedSettings.userGroupVisible,
    connectedSettings.userVisible,
    forceDisabled,
    globalSettings.purchaseOrderBudgetEnabled,
    globalSettings.purchaseOrderLengthSetting,
    newDate,
    processing,
    t,
  ]);
  const fieldHandler = useSelector(selectConnectedFieldHandler);
  const isRoleAdmin =
    authenticatedUser?.Roles.includes(Roles.Administrators) ||
    authenticatedUser?.Roles.includes(Roles.GroupAdministrators) ||
    authenticatedUser?.Roles.includes(Roles.LabTech);
  const reserFieldHandler = () => {
    dispatch(actions.resetConnectedHandler());
  };
  const updateDataState = useCallback(
    (value: IConnectedFiltersDto) => {
      dispatch(actions.saveState(value));
      dispatch(
        actions.extendSettingsState({
          services: serviceTypes,
          globalSettings: globalSettings,
          settings: connectedSettings,
          isEdit: isEdit,
          data: value,
        }),
      );
    },
    [
      actions,
      connectedSettings,
      dispatch,
      globalSettings,
      isEdit,
      serviceTypes,
    ],
  );
  const editAllowed = useMemo(() => {
    return (
      !isEdit ||
      (isEdit &&
        (connectedSettings.isServiceAdmin === true ||
          authenticatedUser?.Id === connectedData?.User?.Id))
    );
  }, [
    authenticatedUser?.Id,
    connectedData?.User?.Id,
    connectedSettings.isServiceAdmin,
    isEdit,
  ]);
  const createUpdateAllowed = useMemo(() => {
    return (
      (isEdit && editAllowed) ||
      (!isEdit && (connectedSettings.isServiceAdmin || isRoleAdmin))
    );
  }, [connectedSettings.isServiceAdmin, editAllowed, isEdit, isRoleAdmin]);
  //FUNCTIONS
  const handleSubmit = value => {
    if (onSubmit !== undefined && onSubmit !== null) {
      onSubmit(value);
    }
  };

  //TO DO:
  // const DefaultExperiment = useCallback(
  //   (budgetId: number, serviceTypeId: number | undefined) => {},
  //   [],
  // );

  // const DefaultBudget = useCallback(
  //   (
  //     userGroup: string | undefined,
  //     user: string | undefined,
  //     serviceTypeId: number | undefined,
  //   ) => {
  //     (async () => {
  //       try {
  //         let budgetIdByOtherServiceId = await ConnectedFiltersApi.getBudgetIdByServiceId(
  //           userGroup,
  //           user,
  //         );
  //         const budgetResult: IDefaultBudget[] = budgetIdByOtherServiceId.value;
  //         //console.log(budgetResult);
  //         if (!isNullOrUndefined(budgetResult)) {
  //           if (budgetResult.length > 0) {
  //             if (budgetResult.filter(b => b.IsDefault === true).length > 0) {
  //               for (var i = 0; i < budgetResult.length; i++) {
  //                 if (budgetResult[i].IsDefault === true) {
  //                   //await initBudgetData(budgetResult[i].Id.toString());
  //                   formikRef.current?.setFieldValue(
  //                     'Budget',
  //                     budgetResult[i],
  //                     true,
  //                   );
  //                   if (connectedSettings.budgetExpirementVisible) {
  //                     if (budgetResult[i].HideExperiments === false) {
  //                       DefaultExperiment(budgetResult[i].Id, serviceTypeId);
  //                     }
  //                   }
  //                   break;
  //                 }
  //               }
  //             } else {
  //               let message: string | undefined = undefined;
  //               //message = defineMessage(message);
  //               if (!isNullOrUndefined(message)) {
  //                 //setValidationMessage(message);
  //               }
  //             }
  //           }
  //         } else {
  //           let message: string | undefined = undefined;
  //           //message = t(translations.NotAvailableBudget) as string;
  //           //message = defineMessage(message);
  //           if (!isNullOrUndefined(message)) {
  //             //setValidationMessage(message);
  //           }
  //         }
  //       } catch {}
  //     })();
  //   },
  //   [DefaultExperiment, connectedSettings.budgetExpirementVisible],
  // );

  //USE EFFECT
  //To handle this formik values in coordination with outer formik values like ServiceType filter
  // useEffect(() => {
  //   if (
  //     serviceTypeFirstChange &&
  //     !isNullOrUndefined(serviceTypeFilterValues) &&
  //     !isNullOrUndefined(formikRef.current?.values.UserGroup) &&
  //     !isNullOrUndefined(formikRef.current?.values.User)
  //   ) {
  //     //SET FIRST DEFAULT BUDGET OF OTHER SERVICE (IF EXIST)
  //     //WHEN FILTER VALUE IS CHOSEN FOR THE FIRST TIME
  //     DefaultBudget(
  //       formikRef.current?.values.UserGroup?.Id,
  //       formikRef.current?.values.User?.Id,
  //       serviceTypeFilterValues.Id,
  //     );
  //     setServiceTypeFirstChange(false);
  //   }
  //   if (serviceTypeChanged) {
  //     if (
  //       !isNullOrUndefined(serviceTypeFilterValues) &&
  //       serviceTypeFilterValues.HideProjects === true
  //     ) {
  //       formikRef.current?.setFieldValue('Budget', null, false);
  //       setHideBudgets(true);
  //     } else {
  //       setHideBudgets(false);
  //     }
  //     setServiceTypeChanged(false);
  //   }
  // }, [
  //   DefaultBudget,
  //   serviceTypeChanged,
  //   serviceTypeFilterValues,
  //   serviceTypeFirstChange,
  //   setServiceTypeChanged,
  //   setServiceTypeFirstChange,
  // ]);

  //ON FORM CHANGE
  const handleFormChange = useCallback(
    (values: IConnectedFiltersDto, isValid, dirty, setValue) => {
      if (userGroupChanged) {
        updateDataState(values);
        setUserGroupChanged(false);
      }
      if (userChanged) {
        updateDataState(values);
        setUserChanged(false);
      }
      if (startTimeChanged) {
        updateDataState(values);
        setStartTimeChanged(false);
      }
      if (endTimeChanged) {
        updateDataState(values);
        setEndTimeChanged(false);
      }
      if (budgetChanged) {
        updateDataState(values);

        setBudgetChanged(false);
      }
      if (budgetExpirementChanged) {
        updateDataState(values);
        setBudgetExpirementChanged(false);
      }
    },
    [
      budgetChanged,
      budgetExpirementChanged,
      endTimeChanged,
      startTimeChanged,
      updateDataState,
      userChanged,
      userGroupChanged,
    ],
  );

  //RENDER
  return (
    <>
      <Formik
        innerRef={formikRef}
        validationSchema={computedSchema}
        initialValues={ConnectedFiltersData || ({} as IConnectedFiltersDto)}
        validateOnMount={true}
        validateOnBlur={false}
        onSubmit={async (values, formikHelpers) => {
          // call setSubmit to finish submit cycle
          console.debug('submitting', values);
          formikHelpers.setSubmitting(true);
          handleSubmit(values);
        }}
      >
        {formik => {
          bindSubmitForm(formik.submitForm);
          // if (formik.errors) {
          //   console.log('connected errors', formik.errors);
          //   console.log('connected values', formik.values);
          // }
          if (formIsValid) {
            formIsValid(formik.isValid);
          }
          return (
            <>
              <form id="connectedFiltersForm" onSubmit={formik.handleSubmit}>
                <FormListener
                  onFormChange={handleFormChange}
                  fields={['UserGroup', 'User', 'Budget', 'BudgetExperiment']}
                />
                <FieldHandler
                  {...fieldHandler}
                  additionalFunc={value =>
                    updateDataState(
                      Object.assign(
                        {},
                        formik.values,
                        value,
                      ) as IConnectedFiltersDto,
                    )
                  }
                  resetFieldState={reserFieldHandler}
                />
                {!forceDisabled && (
                  <React.Fragment>
                    <UserGroupListener
                      userGroup={formik.values.UserGroup}
                      userName={formik.values.User}
                      services={serviceTypes}
                      isEdit={isEdit}
                      user={authenticatedUser}
                      setUser={value => {
                        formik.setFieldValue('User', value);
                        updateDataState(formik.values as IConnectedFiltersDto);
                      }}
                      setBudget={value => {
                        formik.setFieldValue('Budget', value);
                        updateDataState(formik.values as IConnectedFiltersDto);
                      }}
                      forceDisabled={forceDisabled}
                    />
                    <UserNameListener
                      userName={formik.values.User}
                      services={serviceTypes}
                      isEdit={isEdit}
                      setUserGroup={value => {
                        formik.setFieldValue('UserGroup', value);
                        updateDataState(formik.values as IConnectedFiltersDto);
                      }}
                      setBudget={value => {
                        formik.setFieldValue('Budget', value);
                        updateDataState(formik.values as IConnectedFiltersDto);
                      }}
                      user={authenticatedUser}
                      globalSettings={globalSettings}
                      startTime={formik.values.StartTime}
                      endTime={formik.values.EndTime}
                      userGroup={formik.values.UserGroup}
                      forceDisabled={forceDisabled}
                    />
                    <BudgetListener
                      setUserGroup={value => {
                        formik.setFieldValue('UserGroup', value);
                        updateDataState(formik.values as IConnectedFiltersDto);
                      }}
                      setBudgetExperiment={value => {
                        formik.setFieldValue('BudgetExperiment', value);
                        updateDataState(formik.values as IConnectedFiltersDto);
                      }}
                      setBudget={value => {
                        formik.setFieldValue('Budget', value);
                        updateDataState(formik.values as IConnectedFiltersDto);
                        if (isEdit) {
                          dispatch(layoutActions.setNotSavedChanges(false));
                        }
                      }}
                      budget={formik.values.Budget}
                      userName={formik.values.User}
                      services={serviceTypes}
                      isEdit={isEdit}
                      startTime={formik.values.StartTime}
                      endTime={formik.values.EndTime}
                      user={authenticatedUser}
                      forceDisabled={forceDisabled}
                      userGroup={formik.values.UserGroup}
                    />
                  </React.Fragment>
                )}

                {orderForConsumables ? (
                  <FormFieldsSection>
                    {connectedSettings.fundingTypeVisible && (
                      <FormRow>
                        <FormFundingTypePicker
                          name="FundingType"
                          label={t(translations.FundingType)}
                          disabled={
                            formik.isSubmitting || !editAllowed || forceDisabled
                          }
                          onChange={value => {
                            formik.setValues(
                              produce(values => {
                                // clear budget selection when funding type is changed to an incompatible value
                                // for example, if an external budget is selected, and funding type is changed to internal - budget should be reset
                                if (
                                  (value as Entity<number>).Id !==
                                  (values.Budget as IBudgetFilterDto)
                                    ?.FundingTypeId
                                ) {
                                  values.Budget = null;
                                }
                                values.FundingType = value;
                              }),
                            );
                          }}
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {connectedSettings.budgetVisible && (
                      <FormRow>
                        <FormBudgetPicker
                          name="Budget"
                          id="BudgetID"
                          predicates={restrictedBudgetFilter({
                            budgetLimitedByUsers:
                              connectedSettings.budgetLimitedByUsers || false,
                            isBudgetsWithoutUserGroup:
                              connectedSettings.isBudgetsWithoutUserGroup ||
                              false,
                            userGroupId: connectedSettings.ReservationUserGroupGroupByBudgetUserGroup
                              ? undefined
                              : formik.values.UserGroup?.Id,
                            serviceGroupId: undefined,
                            services: serviceTypes,
                            startTime: dateUtils.dateOrStringToDate(
                              formik.values.StartTime,
                            ),
                            endTime: dateUtils.dateOrStringToDate(
                              formik.values.EndTime,
                            ),
                            user: createUpdateAllowed
                              ? formik.values.User === null ||
                                formik.values.User === undefined
                                ? undefined
                                : formik.values.User.Id
                              : authenticatedUser?.Id,
                            defaultBudgetEnabled:
                              globalSettings.defaultBudgetEnabled,
                            userDefaultBudgetEnabled:
                              globalSettings.userDefaultBudgetEnabled,
                            hideNonDefaultBudgets:
                              globalSettings.hideNonDefaultBudgets,
                            // funding type is set to Internal by default even if it's not in use
                            fundingType: connectedSettings.fundingTypeVisible
                              ? formik.values.FundingType
                              : null,
                          })}
                          label={t(translations.BudgetNumber)}
                          onChange={() => setBudgetChanged(true)}
                          disabled={
                            formik.isSubmitting ||
                            connectedSettings.hideProject ||
                            !editAllowed ||
                            forceDisabled
                          }
                          info={budgetPickerInfo}
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {connectedSettings.budgetExpirementVisible &&
                      formik.values.Budget !== null &&
                      formik.values.Budget?.HideExperiments !== true && (
                        <FormRow fullRow>
                          <FormBudgetExperimentsPicker
                            fullWidth
                            id="BudgetExperimentID"
                            name="BudgetExperiment"
                            placeholder={t(translations.BudgetExperiment)}
                            label={t(translations.BudgetExperiment)}
                            predicates={budgetExperimentsFilter(
                              serviceTypes,
                              serviceTypeEnum,
                              formik.values.Budget?.Id,
                              formik.values.StartTime,
                              formik.values.EndTime,
                            )}
                            onChange={() => setBudgetExpirementChanged(true)}
                            disabled={
                              formik.isSubmitting ||
                              !editAllowed ||
                              forceDisabled
                            }
                            username={
                              formik.values.User?.Id ?? authenticatedUser?.Id
                            }
                          />
                        </FormRow>
                      )}
                    {globalSettings.purchaseOrderBudgetEnabled &&
                      ((formik.values.Budget as IBudgetFilterDto)
                        ?.PurchaseOrderOptionId ??
                        PurchaseOrderOptions.Hidden) !==
                        PurchaseOrderOptions.Hidden && (
                        <FormRow>
                          <FormTextField
                            name="PurchaseOrder"
                            label={t(translations.PurchaseOrder)}
                            disabled={
                              formik.isSubmitting ||
                              !editAllowed ||
                              forceDisabled
                            }
                            fullWidth
                          />
                        </FormRow>
                      )}
                    <FormRow fullRow>
                      <FormBookitDateTimePicker
                        fullWidth
                        id="StartTimeID"
                        name="StartTime"
                        // clear={false}
                        label={
                          startTimeLabel || (t(translations.From) as string)
                        }
                        labelInline={startLabelInline}
                        onChange={() => setStartTimeChanged(true)}
                        disabled={
                          formik.isSubmitting || isEdit || forceDisabled
                        }
                      />
                    </FormRow>
                    {connectedSettings.endTimeVisible && (
                      <FormRow>
                        <FormBookitDateTimePicker
                          name="EndTime"
                          id="resEnd"
                          // clear={false}
                          label={endTimeLabel || t(translations.To)}
                          labelInline={endLabelInline}
                          disabled={
                            formik.isSubmitting || isEdit || forceDisabled
                          }
                          onChange={() => setEndTimeChanged(true)}
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {connectedSettings.userGroupVisible ? (
                      <FormRow hide={!createUpdateAllowed}>
                        <FormRestrictedUserGroupPicker
                          name="UserGroup"
                          label={t(translations.UserGroup)}
                          predicates={RestrictedFilter(
                            undefined,
                            undefined,
                            undefined,
                            globalSettings.onlyOwnUserGroupsAllowed,
                          )}
                          disabled={
                            formik.isSubmitting || isEdit || forceDisabled
                          }
                          onChange={() => setUserGroupChanged(true)}
                          fullWidth
                        />
                      </FormRow>
                    ) : (
                      <FormRow hide={!createUpdateAllowed}>
                        <FormLabelText
                          id="UserGroupId"
                          name="UserGroup"
                          label={t(translations.UserGroup)}
                          info={
                            connectedSettings.ReservationUserGroupGroupByBudgetUserGroup
                              ? t(translations.SponsorDepartment_Desc)
                              : undefined
                          }
                          html={
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              {formik.values.UserGroup !== undefined &&
                              formik.values.UserGroup !== null
                                ? formik.values.UserGroup.Name
                                : (t(translations.NA) as string)}
                            </Box>
                          }
                        />
                      </FormRow>
                    )}
                    {connectedSettings.userVisible ? (
                      <FormRow hide={!createUpdateAllowed}>
                        <FormUserPicker
                          name="User"
                          id="UserNameId"
                          info={t(translations.ReservationUser_Desc)}
                          predicates={ServicesFilter(
                            serviceTypes,
                            connectedSettings.budgetExpirementVisible || false,
                            formik.values.UserGroup?.Id,
                            undefined,
                            globalSettings.defaultBudgetEnabled,
                          )}
                          label={t(translations.User)}
                          disabled={
                            formik.isSubmitting || isEdit || forceDisabled
                          }
                          onChange={() => setUserChanged(true)}
                          fullWidth
                        />
                      </FormRow>
                    ) : (
                      <FormRow hide={!createUpdateAllowed}>
                        <FormLabelText
                          id="UserNameIdText"
                          name="User"
                          label={t(translations.User) as string}
                          html={
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              {formik.values.User !== null &&
                              formik.values.User !== undefined
                                ? formik.values.User.Name
                                : (t(translations.NA) as string)}
                            </Box>
                          }
                        />
                      </FormRow>
                    )}
                  </FormFieldsSection>
                ) : (
                  <FormFieldsSection>
                    {' '}
                    <FormRow fullRow>
                      <FormBookitDateTimePicker
                        fullWidth
                        id="StartTimeID"
                        name="StartTime"
                        // clear={false}
                        label={
                          startTimeLabel || (t(translations.From) as string)
                        }
                        labelInline={startLabelInline}
                        onChange={() => setStartTimeChanged(true)}
                        disabled={
                          formik.isSubmitting || isEdit || forceDisabled
                        }
                      />
                    </FormRow>
                    {connectedSettings.endTimeVisible && (
                      <FormRow>
                        <FormBookitDateTimePicker
                          name="EndTime"
                          id="resEnd"
                          // clear={false}
                          label={endTimeLabel || t(translations.To)}
                          labelInline={endLabelInline}
                          disabled={
                            formik.isSubmitting || isEdit || forceDisabled
                          }
                          onChange={() => setEndTimeChanged(true)}
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {connectedSettings.userGroupVisible ? (
                      <FormRow hide={!createUpdateAllowed}>
                        <FormRestrictedUserGroupPicker
                          name="UserGroup"
                          label={t(translations.UserGroup)}
                          predicates={RestrictedFilter(
                            undefined,
                            undefined,
                            undefined,
                            globalSettings.onlyOwnUserGroupsAllowed,
                          )}
                          disabled={
                            formik.isSubmitting || isEdit || forceDisabled
                          }
                          onChange={() => setUserGroupChanged(true)}
                          fullWidth
                        />
                      </FormRow>
                    ) : (
                      <FormRow hide={!createUpdateAllowed}>
                        <FormLabelText
                          id="UserGroupId"
                          name="UserGroup"
                          label={t(translations.UserGroup)}
                          info={
                            connectedSettings.ReservationUserGroupGroupByBudgetUserGroup
                              ? t(translations.SponsorDepartment_Desc)
                              : undefined
                          }
                          html={
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              {formik.values.UserGroup !== undefined &&
                              formik.values.UserGroup !== null
                                ? formik.values.UserGroup.Name
                                : (t(translations.NA) as string)}
                            </Box>
                          }
                        />
                      </FormRow>
                    )}
                    {connectedSettings.userVisible ? (
                      <FormRow hide={!createUpdateAllowed}>
                        <FormUserPicker
                          name="User"
                          id="UserNameId"
                          info={t(translations.ReservationUser_Desc)}
                          predicates={ServicesFilter(
                            serviceTypes,
                            connectedSettings.budgetExpirementVisible || false,
                            formik.values.UserGroup?.Id,
                            formik.values.Budget?.Id,
                            globalSettings.defaultBudgetEnabled,
                          )}
                          label={t(translations.User)}
                          disabled={
                            formik.isSubmitting || isEdit || forceDisabled
                          }
                          onChange={() => setUserChanged(true)}
                          fullWidth
                        />
                      </FormRow>
                    ) : (
                      <FormRow hide={!createUpdateAllowed}>
                        <FormLabelText
                          id="UserNameIdText"
                          name="User"
                          label={t(translations.User) as string}
                          html={
                            <Box
                              width="100%"
                              display="flex"
                              flexDirection="row"
                              alignItems="center"
                              justifyContent="flex-start"
                            >
                              {formik.values.User !== null &&
                              formik.values.User !== undefined
                                ? formik.values.User.Name
                                : (t(translations.NA) as string)}
                            </Box>
                          }
                        />
                      </FormRow>
                    )}
                    {connectedSettings.fundingTypeVisible && (
                      <FormRow>
                        <FormFundingTypePicker
                          name="FundingType"
                          label={t(translations.FundingType)}
                          disabled={
                            formik.isSubmitting || !editAllowed || forceDisabled
                          }
                          onChange={value => {
                            formik.setValues(
                              produce(values => {
                                // clear budget selection when funding type is changed to an incompatible value
                                // for example, if an external budget is selected, and funding type is changed to internal - budget should be reset
                                if (
                                  (value as Entity<number>).Id !==
                                  (values.Budget as IBudgetFilterDto)
                                    ?.FundingTypeId
                                ) {
                                  values.Budget = null;
                                }
                                values.FundingType = value;
                              }),
                            );
                          }}
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {connectedSettings.budgetVisible && (
                      <FormRow>
                        <FormBudgetPicker
                          name="Budget"
                          id="BudgetID"
                          predicates={restrictedBudgetFilter({
                            budgetLimitedByUsers:
                              connectedSettings.budgetLimitedByUsers || false,
                            isBudgetsWithoutUserGroup:
                              connectedSettings.isBudgetsWithoutUserGroup ||
                              false,
                            userGroupId: connectedSettings.ReservationUserGroupGroupByBudgetUserGroup
                              ? undefined
                              : formik.values.UserGroup?.Id,
                            serviceGroupId: undefined,
                            services: serviceTypes,
                            startTime: dateUtils.dateOrStringToDate(
                              formik.values.StartTime,
                            ),
                            endTime: dateUtils.dateOrStringToDate(
                              formik.values.EndTime,
                            ),
                            user: createUpdateAllowed
                              ? formik.values.User === null ||
                                formik.values.User === undefined
                                ? undefined
                                : formik.values.User.Id
                              : authenticatedUser?.Id,
                            defaultBudgetEnabled:
                              globalSettings.defaultBudgetEnabled,
                            userDefaultBudgetEnabled:
                              globalSettings.userDefaultBudgetEnabled,
                            hideNonDefaultBudgets:
                              globalSettings.hideNonDefaultBudgets,
                            // funding type is set to Internal by default even if it's not in use
                            fundingType: connectedSettings.fundingTypeVisible
                              ? formik.values.FundingType
                              : null,
                          })}
                          label={t(translations.BudgetNumber)}
                          onChange={() => setBudgetChanged(true)}
                          disabled={
                            formik.isSubmitting ||
                            !editAllowed ||
                            connectedSettings.hideProject ||
                            forceDisabled
                          }
                          useSearchOrPredicate={
                            globalSettings.hideNonDefaultBudgets
                          }
                          info={budgetPickerInfo}
                          fullWidth
                        />
                      </FormRow>
                    )}
                    {connectedSettings.budgetExpirementVisible &&
                      formik.values.Budget !== null &&
                      formik.values.Budget?.HideExperiments !== true && (
                        <FormRow fullRow>
                          <FormBudgetExperimentsPicker
                            fullWidth
                            id="BudgetExperimentID"
                            name="BudgetExperiment"
                            placeholder={t(translations.BudgetExperiment)}
                            label={t(translations.BudgetExperiment)}
                            predicates={budgetExperimentsFilter(
                              serviceTypes,
                              serviceTypeEnum,
                              formik.values.Budget?.Id,
                              formik.values.StartTime,
                              formik.values.EndTime,
                            )}
                            onChange={() => setBudgetExpirementChanged(true)}
                            disabled={
                              formik.isSubmitting ||
                              !editAllowed ||
                              forceDisabled
                            }
                            username={
                              formik.values.User?.Id ?? authenticatedUser?.Id
                            }
                          />
                        </FormRow>
                      )}
                    {globalSettings.purchaseOrderBudgetEnabled &&
                      ((formik.values.Budget as IBudgetFilterDto)
                        ?.PurchaseOrderOptionId ??
                        PurchaseOrderOptions.Hidden) !==
                        PurchaseOrderOptions.Hidden && (
                        <FormRow>
                          <FormTextField
                            name="PurchaseOrder"
                            label={t(translations.PurchaseOrder)}
                            disabled={
                              formik.isSubmitting ||
                              !editAllowed ||
                              forceDisabled
                            }
                            fullWidth
                          />
                        </FormRow>
                      )}
                  </FormFieldsSection>
                )}
              </form>
            </>
          );
        }}
      </Formik>
    </>
  );
}
