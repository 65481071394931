/**
 *
 * RunPage
 *
 */
import * as React from 'react';
import styled from 'styled-components';
import { EditableCell } from '../EditableTable/EditableCell';
import { NumberField } from 'app/pages/Samples/EditableTable/Fields/NumberField';
import { TextField } from 'app/pages/Samples/EditableTable/Fields/TextField';
import { EditableTable } from '../EditableTable';
import { useDispatch, useSelector } from 'react-redux';
import {
  selectRunDetails,
  selectRunDetailsCreatedDate,
  selectRunDetailsCompletion,
} from '../SamplesListPage/slice/selectors';
import {
  ISample,
  ISampleRun,
  ISampleTarget,
} from '../SamplesListPage/slice/types';
import { useSamplesListSlice } from '../SamplesListPage/slice';
import { Button } from 'app/components/BasicButtons/Button';
import { Link } from 'react-router-dom';
import { RouteComponentProps } from 'react-router-dom';
import { SamplePlatePicker } from 'app/components/pickers/AutocompletePickers/SamplePlatePicker';
import { getRunTypeValue } from '../components/RunTypePicker';
import { TargetSamplePlatePositionPicker } from 'app/components/pickers/AutocompletePickers/SamplePlatePositionPicker';
import { TargetSamplePlatePositionsPicker } from 'app/components/pickers/AutocompletePickers/SamplePlatePositionsPicker';
import { CreateReservationComponent } from '../components/CreateReservationComponent';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { GetServiceRequestDetailsPath } from '../RequestSamplesPage/slice/path';
import { ScreensId } from 'enums/ConfigurableTypes';
import { Column } from 'react-table';
import { GetSelectedActions } from 'app/pages/Samples/RunDetailsPage/SelectedRowActions/GetSelectaedRowActions';
import {
  DownloadTemplateRunDetailsSamples,
  ExportRunDetailsSamples,
} from './Actions';
import { useConfigurableColumnsAdvanced } from 'app/components/BasicTable/useConfigurableColumnsAdvanced';
import { InfoIcon } from 'app/components/BasicIcons/InfoIcon';
import { IsModuleEnabled } from 'types/AppSettings';
import { ImportButton as SamplesImportButton } from '../SamplesListPage/ImportButton';
import { KnownModules } from 'types/KnownModules';
import { selectAppSettings } from 'app/slice/selectors';
import { useRequestSamplesSlice } from '../RequestSamplesPage/slice';
import { AnalyticalSampleIDSuffixPopup } from './AnalyticalSampleIDSuffix';
import { RunComments } from './Components/Comments';
import { ReservationLink } from 'app/pages/Actions/GlobalSelectedActions/OpenReservation';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { ReactActions } from 'app/components/ReactActions';
import {
  FormFieldsSection,
  FormLeftSection,
  FormRightSection,
  FormRow,
  StyledFormFieldsContainer,
} from 'app/components/Forms/FormsLayout';
import { ReadonlyField } from '../components/ReadonlyField';
import { dateUtils } from 'utils/date-utils';
import { ImportSamplePlates } from './ImportComponents/ImportSamplePlates';
const Root = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`;
const Container = styled.div`
  padding: 0px 16px 0px 32px;
`;
export const RUN_DETAILS_PAGE_PATH = '/samples/runs/details/:id';
export interface RunPageProps extends RouteComponentProps<{ id: string }> {}
export function RunDetailsPage(props: RunPageProps) {
  const id = +props.match.params.id;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const appSettings = useSelector(selectAppSettings);
  const { actions } = useSamplesListSlice();
  const completion = useSelector(selectRunDetailsCompletion);
  const { actions: requestactions } = useRequestSamplesSlice();
  const runDetails = useSelector(selectRunDetails);
  //const instrument = useSelector(selectRunDetailsInstrument);
  //const secondaryInstrument = useSelector(selectRunDetailsSecondaryInstrument);
  //const gradient = useSelector(selectRunDetailsGradient);
  const createdDate = useSelector(selectRunDetailsCreatedDate);
  //const acquision_type = useSelector(selectRunDetailsAcquision_type);
  const [showSuffixDialog, setShowSuffixDialog] =
    React.useState<boolean>(false);
  const runType = runDetails?.RunType;
  const [samplePlateImportOpen, setSamplePlateImportOpen] =
    React.useState<boolean>(false);
  const updateMyData = item => {
    // We also turn on the flag to not reset the page

    // setSkipPageReset(true);
    const foo: Omit<ISampleTarget, 'Id'> = item as Omit<ISampleTarget, 'Id'>;
    console.log('updateMyData', foo);
    dispatch(actions.updateRunSampleValue(item));
  };
  const samples = runDetails?.Samples;
  const handleCompleteButtonClick = React.useCallback(
    (suffix?: string) => {
      const data = samples;
      if (data === undefined) {
        return;
      }
      if (runType === undefined) {
        return;
      }

      dispatch(
        actions.completeRun({
          Id: id,
          RunType: runType,
          Samples: data,
          AnalyticalSampleIDSuffix: suffix,
        }),
      );
    },
    [actions, dispatch, id, samples, runType],
  );
  const submitSuffixDialog = React.useCallback(
    (value: string) => {
      setShowSuffixDialog(false);
      handleCompleteButtonClick(value);
    },
    [handleCompleteButtonClick],
  );
  const showCompleteDialog = React.useCallback(() => {
    if (runDetails?.RunType === 'Fractioning') {
      setShowSuffixDialog(true);
    } else {
      handleCompleteButtonClick();
    }
  }, [handleCompleteButtonClick, runDetails?.RunType]);
  const runId = runDetails?.Id;

  const handleExportButtonClick = React.useCallback(() => {
    dispatch(actions.exportRun({ Id: runId, data: samples || [] }));
  }, [actions, dispatch, runId, samples]);
  // const handleDownloadTemplateButtonClick = React.useCallback(() => {
  //   dispatch(actions.exportRun({ Id: runId, data: [] }));
  // }, [actions, dispatch, runId]);
  const columns = React.useMemo(() => {
    const columns: Array<Column<ISampleRun>> = [
      {
        Header: t(translations.SampleId) as string,
        accessor: 'Id', // accessor is the "key" in the data
      },
      {
        Header: 'Request ID',
        accessor: 'ServiceRequestId',
        Cell: ({ value }) => (
          <Link to={GetServiceRequestDetailsPath(value)}>{value}</Link>
        ),
      },
      {
        Header: 'Status',
        accessor: 'StatusName',
        Cell: ({ row, value }) => {
          /*switch (value) {
          case 0:
            return 'New';
          case 1:
            return 'In Work';
          case 2:
            return 'Done';
          default:
            return 'N/A';
        }*/
          return row.original.StatusName;
        },
      },
      { Header: 'Project ID', accessor: 'BudgetId' },
      {
        Header: 'Analytical Sample ID',
        accessor: 'AnalyticalSampleId',
      },
      {
        Header: 'BiologicalSampleId',
        accessor: 'BiologicalSampleId',
      },
      { Header: t(translations.TMT_Type) as string, accessor: 'TMT_Type' },
      {
        Header: t(translations.TMT_Label) as string,
        accessor: 'TMT_Label',
      },
      {
        id: 'TMT_RunSet',
        Header: t(translations.TMT_Set) as string,
        accessor: 'TMT_Set',
        Cell: ({ row, value }) => {
          if (row.ServiceRequestId !== undefined && row.TMT_Set !== undefined) {
            return `${row.ServiceRequestId}-${row.TMT_Set}`;
          } else {
            return <>{value}</>;
          }
        },
      },
      {
        Header: 'Tube Label',
        accessor: 'TubeLabel',
      },
      {
        Header: t(translations.SamplePlateId) as string,
        accessor: 'SamplePlateId',
      },
      {
        Header: t(translations.UniquePlateNumber) as string,
        accessor: 'UniquePlateNumber',
      },
      {
        Header: t(translations.PlatePosition) as string,
        accessor: 'Position',
      },
      {
        Header: t(translations.NumericalPlatePosition) as string,
        accessor: 'NumericalPlatePosition',
        Cell: ({ row, value }) => {
          if (value !== null) {
            /*const res = CellPositionToNumber(
              value,
              row.original.SamplePlateTypeRows,
            );*/
            return value;
          } else {
            return value;
          }
        },
      },
      {
        Header: 'Volume (ul)',
        accessor: 'Volume',
      },
      {
        Header: 'Concentration (mg/mL)',
        accessor: 'Concentration',
      },
      {
        Header: t(translations.SampleType) as string,
        accessor: 'SampleType',
      },
      {
        Header: 'Target Tube Label',
        accessor: 'TargetTubeLabel',
        Cell: React.memo(EditableCell(TextField)),
      },
      {
        Header: 'Target Plate ID',
        accessor: 'TargetPlateId',
        Cell:
          runType === undefined || ['MassSpec'].includes(runType)
            ? 'N/A'
            : React.memo(EditableCell(SamplePlatePicker)),
      },
      {
        Header: 'Target Plate Position',
        accessor: 'TargetPosition',
        Cell:
          runType === undefined || ['MassSpec'].includes(runType)
            ? 'N/A'
            : ['Fractioning'].includes(runType)
            ? React.memo(EditableCell(TargetSamplePlatePositionsPicker))
            : React.memo(EditableCell(TargetSamplePlatePositionPicker)),
      },
      {
        Header: 'Target Volume (ul)',
        accessor: 'TargetVolume',
        Cell:
          runType === undefined || ['MassSpec'].includes(runType)
            ? 'N/A'
            : React.memo(EditableCell(NumberField)),
      },
      {
        Header: 'Target Concentration (mg/mL)',
        accessor: 'TargetConcentration',
        Cell:
          runType === undefined || ['MassSpec'].includes(runType)
            ? 'N/A'
            : React.memo(EditableCell(NumberField)),
      },
      {
        Header: 'Target Sample Type',
        accessor: 'TargetSampleType',
        Cell: React.memo(EditableCell(TextField)),
      },
    ];
    return columns;
  }, [runType, t]);

  const pageActions = React.useMemo(() => {
    const pactions: PageActionRenderer[] = [
      () => (
        <TopActionButton
          title={t(translations.Complete) as string}
          text={t(translations.Complete) as string}
          icon="check-circle"
          variant="white"
          disabled={completion}
          processing={completion}
          startIcon="check-circle"
          onClick={showCompleteDialog}
        />
      ),
    ];
    pactions.push(() => (
      <CreateReservationComponent
        runDetails={runDetails}
        sampleRunId={runId}
        rows={samples as ISample[]}
      />
    ));

    pactions.push(() => (
      <ReactActions
        id="runDetailsActions"
        items={[
          <Button
            variant="text"
            size="small"
            onClick={() => {
              dispatch(actions.setShowImportDialog(false));
              setSamplePlateImportOpen(true);
            }}
          >
            {t(translations.ImportTargetSamples)}
          </Button>,
          <Button variant="text" size="small" onClick={handleExportButtonClick}>
            {t(translations.ExportForRobot)}
          </Button>,
        ]}
      ></ReactActions>
    ));

    return pactions;
  }, [
    actions,
    completion,
    dispatch,
    handleExportButtonClick,
    runDetails,
    runId,
    samples,
    showCompleteDialog,
    t,
  ]);
  const {
    configurableColumns,
    columnAccessors,
    exportConfigurableColumns,
    handleConfigurableColumns,
  } = useConfigurableColumnsAdvanced(ScreensId.SampleRunsDetails, columns, [
    'TargetTubeLabel',
    'TargetPlateId',
    'TargetPosition',
    'TargetVolume',
    'TargetConcentration',
    'TargetSampleType',
  ]);
  React.useEffect(() => {
    dispatch(actions.loadRunDetqails({ id: id, columns: columnAccessors }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleExport = (): Promise<void> => {
    return ExportRunDetailsSamples(id, exportConfigurableColumns);
  };
  const handleDownloadTemplateClick = (): Promise<void> => {
    const res = DownloadTemplateRunDetailsSamples(
      id,
      exportConfigurableColumns,
    );
    if (res !== undefined) {
      return res;
    } else
      return new Promise<void>((resolve, reject) => {
        resolve();
      });
  };
  const importHandler = () => {
    dispatch(requestactions.toggleImport(true));
  };
  return (
    <Root>
      <SamplesImportButton
        onDownloadTemplateButtonClick={
          IsModuleEnabled(appSettings, KnownModules.SamplesAutomation)
            ? handleDownloadTemplateClick
            : undefined
        }
        hideImportButton={true}
      />
      <ImportSamplePlates
        isOpen={samplePlateImportOpen}
        onClose={() => setSamplePlateImportOpen(false)}
      />
      <EditableTable
        screenName={
          <>
            {t(translations.RunDetails)} {'  '}
            <InfoIcon
              title={t(translations.RunDetailsPageInstructions) as string}
            />
            {'  #'} {id}
          </>
        }
        columns={configurableColumns !== undefined ? configurableColumns : []}
        data={runDetails?.Samples || []}
        useRowSelect={true}
        useGlobalFilter={true}
        // fetchData={fetchData}
        // skipPageReset={skipPageReset}
        updateMyData={updateMyData}
        pageActions={pageActions}
        handleConfigurableColumns={handleConfigurableColumns}
        handleExport={handleExport}
        useImportLink={true}
        importHandler={importHandler}
        onDownloadTemplate={handleDownloadTemplateClick}
        selectedRowsActions={GetSelectedActions(
          t,
          id,
          exportConfigurableColumns,
          {},
        )}
        subHeader={
          <StyledFormFieldsContainer useRequestSection={true}>
            <FormLeftSection>
              <FormFieldsSection titleSection={t(translations.RunDetails)}>
                <FormRow>
                  <ReadonlyField title={t(translations.RUNID)} value={id} />
                </FormRow>
                <FormRow>
                  <ReadonlyField
                    title={t(translations.RunType)}
                    value={getRunTypeValue(t, runDetails?.RunType)}
                  />
                </FormRow>
                <FormRow>
                  <ReadonlyField
                    title={t(translations.Reservations)}
                    value={
                      (runDetails?.ReservationIds ?? []).length > 0
                        ? runDetails?.ReservationIds?.map(reservationId => (
                            <>
                              <ReservationLink
                                key={`reservation-details-link-${reservationId}`}
                                id={reservationId}
                              >
                                {reservationId}
                              </ReservationLink>
                              &emsp;
                            </>
                          ))
                        : 'N/A'
                    }
                  />
                </FormRow>
                <FormRow>
                  <ReadonlyField
                    title={t(translations.CreatedAt)}
                    value={dateUtils.longDateTimeFormat(createdDate)}
                  />
                </FormRow>
              </FormFieldsSection>
            </FormLeftSection>
            <FormRightSection> </FormRightSection>
          </StyledFormFieldsContainer>
        }
      />
      <div>
        <AnalyticalSampleIDSuffixPopup
          show={showSuffixDialog}
          onClose={() => setShowSuffixDialog(false)}
          onSubmit={submitSuffixDialog}
        />
      </div>
      <Container>
        <FormFieldsSection useRequest={true}>
          <RunComments isAdmin={true} runId={id} />
        </FormFieldsSection>
      </Container>
    </Root>
  );
}
