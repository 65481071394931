/**
 *
 * ReservationDetails
 *
 */
import React from 'react';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import {
  getSingleReservationStatus,
  IReservationEquipmentDto,
  RecurringReservationUpdateOptions,
  ReservationDetailsState,
  ReservationGlobalState,
  ReservationQueryStringParameters,
  TransferToReservation,
} from './slice/types';
import { ReservationStatus } from 'api/odata/generated/enums/ReservationStatus';
import { useReservationSlice } from './slice';
import { useDispatch, useSelector } from 'react-redux';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import {
  selectAsyncChangeLoading,
  selectByApprovalErrors,
  selectMaxReservationComplexityValidationResult,
  selectCloneReservations,
  selectConfirmAssistedReservations,
  selectCredit,
  selectCreditCompleted,
  selectCreditProcessing,
  selectEquipmentChangeCompleted,
  selectEquipmentsSettings,
  selectIsCreditSubmited,
  selectIsEdit,
  selectProcessing,
  selectReservation,
  selectReservationCompleted,
  selectReservationHasError,
  selectReservationNotFound,
  selectReservationProcessing,
  selectReservationSettings,
  selectSavedOfflineServices,
  selectTransferProcessing,
  selectTransferReservation,
  selectTransferStepCompleted,
  selectTransferToUser,
  selectTransferWaitingApproval,
  selectUserTrainingData,
} from './slice/selectors';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { translations } from 'locales/translations';
import {
  selectExpandedSidePanel,
  selectHasNotSavedChanges,
} from 'app/Layout/FrontendLayout/slice/selectors';
import { push } from 'connected-react-router';
import { buildURL, toQueryString } from 'utils/url-utils';
import { Progress } from 'app/components/LoadingIndicator';
import { Beforeunload } from 'react-beforeunload';
import { ReservationForm } from './ReservationForm';
import { selectRepetitiveData } from 'app/components/Forms/FormRepetitive/slice/selectors';
import { Roles } from 'app/slice/types';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import useGlobalSettingsHook from './components/useGlobalSettingsHook';
import { IFormFileValue } from 'app/components/CustomForm/CustomFormUtils';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { ActionButton } from 'app/components/BasicButtons/ActionButton';
import {
  ApproveReservationButton,
  CancelReservationButton,
  CreateTrainingButton,
  SaveForLaterButton,
  SaveFormButton,
  TransferButton,
} from './components/ReservationActions';
import { ApproveTrainingRecords } from './components/ApproveTrainingRecords';
import { TrainingDetailsProps } from 'app/pages/UserTrainings/TrainingDetailsPage/Details';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { IServiceTypeFilterDto } from 'api/odata/generated/entities/IServiceTypeFilterDto';
import { useAppSettingsSlice } from 'app/slice';
import { WorkOrderDetailsProps } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details';
import { dateUtils } from 'utils/date-utils';
import { WorkOrderQueryStringParameters } from 'app/pages/WorkOrders/WorkOrderDetailsPage/Details/slice/types';
import { FormikProps } from 'formik';
import { TransferReservation } from './components/TransferReservation';
import { ReservationModifications } from './components/Modifications';
import { useSystemDate } from 'app/hooks/useSystemDate';
import { FlexRowDiv } from 'app/components/basic/Wrappers/FlexWrappers/flexRow';
import { Body, H3 } from 'app/components/Typography';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import {
  SwitchActionsProps,
  SwitchTarget,
} from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/SwitchActions';
import { Entity } from 'types/common';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { tryParseInt } from 'utils/string-utils';
import { IBudgetFilterDto } from 'api/odata/generated/entities/IBudgetFilterDto';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import { ErrorServices } from 'app/pages/OtherServiceDetails/Details/slice/types';
import { CalculatePricing } from './components/CalculatePricing';
import { Alert } from '@material-ui/lab';
import { DetectIsMobile } from 'utils/mobileDetect';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { LinkBehavior } from 'app/components/ExternalLink';
import { IWorkOrderTypeDto } from 'api/odata/generated/entities/IWorkOrderTypeDto';
import { toLocationDescriptor } from 'app/components/BasicLinks/Link';
import useSidePanelState, {
  SidePanelCloseState,
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { IsReadOnlyUser } from 'app/permissions/WorkOrders/workOrdersPermissionUtils';
import { AssetPopUpProps } from 'app/pages/AssetPopUp';
import { ReferenceType } from 'enums/ReferenceType';
import { PrintToPDF } from 'app/components/PrintToPDF';
import { usePrintPDFSlice } from 'app/components/PrintToPDF/slice';
import { getPrintPDFDefaultSections } from './components/utils';
import { isEmpty } from 'lodash';

export interface ReservationDetailsProps
  extends SidePanelContentProps,
    CoverProps {
  queryParams: ReservationQueryStringParameters;
  useSwitchButtons?: boolean;
}

export const ReservationDetails = React.memo(function ReservationDetails(
  props: ReservationDetailsProps,
) {
  const { queryParams, useSidePanel, useSwitchButtons, closeCover, isCover } =
    props;

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { actions } = useReservationSlice();

  const { actions: layoutActions } = useLayoutSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();
  const { actions: printActions } = usePrintPDFSlice();
  const isMobile = DetectIsMobile();

  /// Refs ///
  const submitFormRef = React.useRef<any>(null);
  const repetitiveFormRef = React.useRef<any>(null);
  const customFormRef = React.useRef<any>(null);
  const innerFormRef =
    React.useRef<FormikProps<ReservationDetailsState> | null>(null);

  /// selectors ///
  const processing = useSelector(selectProcessing);
  const reservation = useSelector(selectReservation);
  const reservationId = reservation.data?.Id ?? tryParseInt(queryParams.id);
  const reservationSettings = useSelector(selectReservationSettings);
  const equipmentSettings = useSelector(selectEquipmentsSettings);
  const User = useSelector(selectAuthenticatedUser);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const publicUrl = useSelector(selectPublicUrl);
  const repetitiveValues = useSelector(selectRepetitiveData);
  const actionCompleted = useSelector(selectReservationCompleted);
  const hasError = useSelector(selectReservationHasError);
  const reservationProcessing = useSelector(selectReservationProcessing);
  const confirmAssisted = useSelector(selectConfirmAssistedReservations);
  const assistedTrainingData = useSelector(selectUserTrainingData);
  const equipmentChangeCompleted = useSelector(selectEquipmentChangeCompleted);
  const transferProcessing = useSelector(selectTransferProcessing);
  const transferStepWaiting = useSelector(selectTransferWaitingApproval);
  const transferUser = useSelector(selectTransferToUser);
  const transferredForId = useSelector(selectTransferReservation);
  const transferStepCompleted = useSelector(selectTransferStepCompleted);
  const savedOfflineServices = useSelector(selectSavedOfflineServices);
  const creditProcessing = useSelector(selectCreditProcessing);
  const credit = useSelector(selectCredit);
  const creditCompleted = useSelector(selectCreditCompleted);
  const isCloned = useSelector(selectCloneReservations);
  const edit = useSelector(selectIsEdit);
  const asyncChangeLoading = useSelector(selectAsyncChangeLoading);
  const isCreditSubmited = useSelector(selectIsCreditSubmited);
  const ByApprovalErrors = useSelector(selectByApprovalErrors);
  const MaxReservationComplexityValidationResult = useSelector(
    selectMaxReservationComplexityValidationResult,
  );
  const sidePanelExpanded = useSelector(selectExpandedSidePanel);
  const reservationNotFound = useSelector(selectReservationNotFound);
  const showShortView = React.useMemo(() => {
    return isMobile || (useSidePanel && !sidePanelExpanded);
  }, [isMobile, sidePanelExpanded, useSidePanel]);
  const [print, setPrint] = React.useState<boolean>(false);
  const [printExpanded, setPrintExpanded] = React.useState<boolean>(false);
  const handlePrintToPDF = React.useCallback(() => {
    setPrint(true);
    if (!sidePanelExpanded) {
      dispatch(layoutActions.setExpanded(true));
      setPrintExpanded(true);
    }
  }, [dispatch, layoutActions, sidePanelExpanded]);
  const handleClosePrintToPDF = React.useCallback(() => {
    if (printExpanded) {
      dispatch(layoutActions.setExpanded(false));
      setPrintExpanded(false);
    }
    setPrint(false);
  }, [dispatch, layoutActions, printExpanded]);
  //cover
  // const [cover, setCover] = React.useState<React.ReactNode>();
  const { cover, openPanel, closePanel, coverClosed, onCloseCover } =
    useSidePanelState(
      () => {
        if (confirmBeforeTransfer) {
          setShowTransfer(true);
          return false;
        } else {
          dispatch(actions.resetDetailsState());
          return true;
        }
      },
      () => {
        if (confirmBeforeTransfer) {
          setConfirmBeforeTransfer(false);
        }
      },
      useSidePanel,
      isCover,
    );
  /// Settings ///
  const globalSettings = useGlobalSettingsHook();
  /// Local State ///
  const [busy, setBusy] = React.useState<boolean | undefined>(false);
  //const [edit, setEdit] = React.useState<boolean | undefined>(false);
  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);
  const [showConfirmRecurring, setShowConfirmRecurring] = React.useState<
    boolean | undefined
  >(undefined);
  const [showConfirmAssisted, setShowConfirmAssisted] = React.useState<
    boolean | undefined
  >(undefined);
  const [confirmBeforeTransfer, setConfirmBeforeTransfer] = React.useState<
    boolean | undefined
  >(undefined);
  const [showTransfer, setShowTransfer] = React.useState<boolean | undefined>(
    undefined,
  );
  const [showCalculateCredit, setShowCalculateCredit] = React.useState<
    boolean | undefined
  >(undefined);
  const [confirmCloned, setConfirmCloned] = React.useState<boolean | undefined>(
    undefined,
  );
  const [canceled, setCanceled] = React.useState<boolean | undefined>(
    undefined,
  );
  const [openModifications, setOpenModifications] =
    React.useState<boolean>(false);
  const { newDate } = useSystemDate();
  /// Memo State ///
  const repeat = React.useMemo(() => {
    return edit && reservationSettings?.Repeat;
  }, [edit, reservationSettings]);
  const userReadOnly = IsReadOnlyUser(User);
  const IsAdmin = React.useMemo(() => {
    return (
      equipmentSettings?.IsAllAdmin ||
      (!!reservation &&
        !!reservation.data &&
        !!reservation.data.EquipmentsData &&
        User?.IsAllGroupOrLabTechAdmin(
          reservation?.data?.EquipmentsData.map(
            g => (g as IReservationEquipmentDto).ServiceGroupId,
          ),
          reservation?.data?.EquipmentsData.map(f => {
            return {
              Id: f.Id,
              Name: f.Name,
              ServiceTypeId: ServiceType.Online,
            } as IServiceTypeFilterDto;
          }),
        )) ||
      false
    );
  }, [equipmentSettings?.IsAllAdmin, reservation, User]);
  const isOwner = React.useMemo(() => {
    return reservation?.data?.BookedBy?.Id === User?.Id;
  }, [User?.Id, reservation?.data?.BookedBy]);
  const isUserGroupCoordinator = React.useMemo(() => {
    return (
      User &&
      User.Roles.includes(Roles.UserGroupCoord) &&
      (edit
        ? reservation?.data?.ADGroup?.Id === User.ActiveUserGroup?.Id
        : true)
    );
  }, [User, edit, reservation?.data?.ADGroup?.Id]);
  const trainingSessionEnabled = React.useMemo(() => {
    return (
      globalSettings.trainingSessionEnabled &&
      !equipmentSettings?.HideTrainingSession
    );
  }, [
    equipmentSettings?.HideTrainingSession,
    globalSettings.trainingSessionEnabled,
  ]);
  const trainingSignUp = React.useMemo(() => {
    return (
      trainingSessionEnabled &&
      (reservation?.data?.TrainingSignUp ||
        innerFormRef.current?.values.TrainingSignUp)
      //&&
      // innerFormRef.current?.values.EquipmentsData.length === 1
    );
  }, [reservation?.data?.TrainingSignUp, trainingSessionEnabled]);
  const allowOngoingModifications = React.useMemo(() => {
    return (
      isOwner &&
      reservationSettings?.Ongoing &&
      equipmentSettings?.AllowModification
    );
  }, [
    equipmentSettings?.AllowModification,
    isOwner,
    reservationSettings?.Ongoing,
  ]);
  const editable = React.useMemo(() => {
    return (
      IsAdmin ||
      isUserGroupCoordinator ||
      (isOwner && reservationSettings?.FutureReservation) ||
      (reservationSettings?.Ongoing && allowOngoingModifications)
    );
  }, [
    allowOngoingModifications,
    IsAdmin,
    isUserGroupCoordinator,
    reservationSettings?.FutureReservation,
    reservationSettings?.Ongoing,
    isOwner,
  ]);
  const terminationEnabled = React.useMemo(() => {
    return (
      globalSettings.reservationTerminationEnabled &&
      reservationSettings?.Ongoing &&
      reservation?.data?.Status?.Id !== ReservationStatus.Cancelled &&
      reservation?.data?.Status?.Id !== ReservationStatus.CancelledByAdmin &&
      equipmentSettings?.AllowUsersToShortenReservations &&
      (IsAdmin || isOwner)
    );
  }, [
    IsAdmin,
    equipmentSettings?.AllowUsersToShortenReservations,
    globalSettings.reservationTerminationEnabled,
    isOwner,
    reservation?.data?.Status?.Id,
    reservationSettings?.Ongoing,
  ]);
  const allowSortenReservation = React.useMemo(() => {
    return globalSettings.reservationTerminationEnabled
      ? false
      : equipmentSettings?.AllowUsersToShortenReservations;
  }, [
    equipmentSettings?.AllowUsersToShortenReservations,
    globalSettings.reservationTerminationEnabled,
  ]);
  const timeEnabledForOwner = React.useMemo(() => {
    return (
      reservationSettings?.FutureReservation ||
      (reservationSettings?.Ongoing &&
        (allowSortenReservation ||
          equipmentSettings?.AllowModification ||
          equipmentSettings?.AllowExtendReservation))
    );
  }, [
    equipmentSettings?.AllowExtendReservation,
    equipmentSettings?.AllowModification,
    reservationSettings?.FutureReservation,
    reservationSettings?.Ongoing,
    allowSortenReservation,
  ]);
  const timeEnabled = React.useMemo(() => {
    return (
      (IsAdmin || isUserGroupCoordinator || (isOwner && timeEnabledForOwner)) &&
      !reservationSettings?.TerminateReservation
    );
  }, [
    IsAdmin,
    isOwner,
    isUserGroupCoordinator,
    reservationSettings?.TerminateReservation,
    timeEnabledForOwner,
  ]);
  const canBeCanceled = React.useMemo(() => {
    return (
      edit &&
      editable &&
      reservation?.data?.Status?.Id !== ReservationStatus.Cancelled &&
      reservation?.data?.Status?.Id !== ReservationStatus.CancelledByAdmin
    );
  }, [edit, editable, reservation?.data?.Status?.Id]);
  const canBeApproved = React.useMemo(() => {
    return (
      edit &&
      editable &&
      ((reservation?.data?.Status?.Id === ReservationStatus.Pending &&
        IsAdmin) ||
        (reservation?.data?.Status?.Id === ReservationStatus.PendingCoord &&
          isUserGroupCoordinator))
    );
  }, [
    IsAdmin,
    edit,
    editable,
    isUserGroupCoordinator,
    reservation?.data?.Status?.Id,
  ]);
  const canSaveForLater = React.useMemo(() => {
    return (
      globalSettings.draftReservationsEnabled &&
      equipmentSettings?.DraftSaveForLater &&
      (IsAdmin || isOwner) &&
      reservation?.data?.Status?.Id !== ReservationStatus.Cancelled &&
      reservation?.data?.Status?.Id !== ReservationStatus.CancelledByAdmin
    );
  }, [
    IsAdmin,
    equipmentSettings?.DraftSaveForLater,
    globalSettings.draftReservationsEnabled,
    isOwner,
    reservation?.data?.Status?.Id,
  ]);
  const reservationCanceled = React.useMemo(() => {
    return (
      (edit && reservation?.data?.Status?.Id === ReservationStatus.Cancelled) ||
      reservation?.data?.Status?.Id === ReservationStatus.CancelledByAdmin
    );
  }, [edit, reservation?.data?.Status?.Id]);
  const calculateButtonVisible = React.useMemo(() => {
    return globalSettings.showCalculatePrice &&
      globalSettings.budgetModuleEnabled
      ? equipmentSettings?.BudgetsTurnedOn
      : true;
  }, [
    equipmentSettings?.BudgetsTurnedOn,
    globalSettings.budgetModuleEnabled,
    globalSettings.showCalculatePrice,
  ]);
  const not_restricted = React.useMemo(() => {
    if (edit) {
      let budget =
        reservation?.data?.Budget !== null &&
        reservation?.data?.Budget !== undefined
          ? reservation.data.Budget
          : null;
      let noInstumentRestricted = globalSettings.reservationRestricted
        ? IsAdmin || isUserGroupCoordinator || isOwner
        : globalSettings.userGroupReservationRestricted
        ? reservation?.data?.ADGroup?.Id === User?.ActiveUserGroup?.Id ||
          IsAdmin ||
          isUserGroupCoordinator ||
          isOwner
        : true;
      let confidential =
        ((!!reservation?.data?.BudgetExperiment &&
          reservation?.data?.BudgetExperiment !== null) ||
          globalSettings.confidentialBudgetsEnabled) &&
        !!reservation?.data?.Budget &&
        (budget === null
          ? false
          : (budget as IBudgetFilterDto).Confidential ?? false);
      let noBudgetRestricted = confidential ? IsAdmin || isOwner : true;

      return noInstumentRestricted && noBudgetRestricted;
    }

    return true;
  }, [
    IsAdmin,
    User?.ActiveUserGroup?.Id,
    edit,
    globalSettings.confidentialBudgetsEnabled,
    globalSettings.reservationRestricted,
    globalSettings.userGroupReservationRestricted,
    isOwner,
    isUserGroupCoordinator,
    reservation?.data?.ADGroup?.Id,
    reservation?.data?.Budget,
    reservation?.data?.BudgetExperiment,
  ]);

  React.useEffect(() => {
    if (!print && edit) {
      dispatch(printActions.setUseSections(true));
      dispatch(
        printActions.updateSections(
          getPrintPDFDefaultSections(
            t,
            (reservation?.data?.FormValues?.length ?? 0) > 0,
          ),
        ),
      );
    }
  }, [
    dispatch,
    edit,
    print,
    printActions,
    reservation?.data?.FormValues?.length,
    t,
  ]);
  const workOrderSwithProps = React.useMemo(() => {
    if (!edit) {
      let eqids = queryParams.selectedIds;
      let start = queryParams.Start;
      let end = queryParams.singleClick ? undefined : queryParams.End;
      let defaultMulti = globalSettings.multipleInstrumentsEnabled;
      if (reservation && reservation.data) {
        start = dateUtils.formatQueryStringDate(
          dateUtils.dateOrStringToDate(reservation.data.StartTime),
        );
        end = queryParams.singleClick
          ? undefined
          : dateUtils.formatQueryStringDate(
              dateUtils.dateOrStringToDate(reservation.data.EndTime),
            );
        defaultMulti =
          globalSettings.multipleInstrumentsEnabled &&
          reservation.data.EquipmentsData.length > 0;
        eqids = reservation.data.EquipmentsData.map(f => f.Id).join(',');
      }

      let p = {
        queryParams: {
          eqid: eqids,
          down: 'true',
          offH: 'true',
          eStart: start,
          offStart: start,
          offEnd: end,
          defaultMulti: defaultMulti.toString(),
          source: queryParams.source,
          id: undefined,
          aType: undefined,
          title: undefined,
          sympt: undefined,
          aStatus: undefined,
          aStart: undefined,
          desc: undefined,
          assetId: undefined,
          reason: undefined,
          sid: undefined,
        } as WorkOrderQueryStringParameters,
        useSidePanel: true,
        useSwithButtons: useSwitchButtons,
      } as WorkOrderDetailsProps;
      return p;
    }
    return undefined;
  }, [
    edit,
    globalSettings.multipleInstrumentsEnabled,
    queryParams.End,
    queryParams.Start,
    queryParams.selectedIds,
    queryParams.singleClick,
    queryParams.source,
    reservation,
    useSwitchButtons,
  ]);
  const allowEditMultipleEquipments = React.useMemo(() => {
    return (
      globalSettings.multipleInstrumentsEnabled &&
      !trainingSignUp &&
      globalSettings.allowAddRemoveOnEditReservation
    );
  }, [
    globalSettings.allowAddRemoveOnEditReservation,
    globalSettings.multipleInstrumentsEnabled,
    trainingSignUp,
  ]);
  React.useEffect(() => {
    let active = firstLoad && !userReadOnly;
    // if (!reservation || !reservation.data) {

    // }
    if (globalSettings.loadCompleted && active) {
      setFirstLoad(false);
      dispatch(
        actions.initReservation({
          query: queryParams,
          globalSettings: globalSettings,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    dispatch,
    firstLoad,
    globalSettings,
    queryParams,
    reservation,
    userReadOnly,
  ]);
  React.useEffect(() => {
    if (edit) {
      if (
        savedOfflineServices.length > 0 &&
        savedOfflineServices.some(f => f.Id < 1)
      ) {
        dispatch(layoutActions.setNotSavedChanges(true));
      }
    }
  }, [dispatch, edit, layoutActions, savedOfflineServices]);
  /// functions, callbacks ///
  // const closeCover = () => {
  //   setCover(undefined);
  // };
  // const handleClose = () => {
  //   if (hasChanges) {
  //     dispatch(layoutActions.setConfirmOpen(true));
  //   } else {
  //     handleCloselClick();
  //   }
  // };
  const handleCloselClick = React.useCallback(() => {
    if (useSidePanel) {
      closePanel({
        isCover: isCover || !!cover,
        useSidePanel: useSidePanel,
        showConfirm: hasChanges,
        onClose: () => {
          dispatch(actions.resetDetailsState());
        },
      } as SidePanelCloseState);
    } else {
      dispatch(push('/reservations'));
    }
  }, [actions, closePanel, cover, dispatch, hasChanges, isCover, useSidePanel]);

  const handleSaveClick = React.useCallback(
    (e: any) => {
      if (repeat) {
        setShowConfirmRecurring(true);
      } else {
        handleSubmitForm(e);
      }
    },
    [repeat],
  );

  const cancelReservation = React.useCallback(() => {
    let status = getSingleReservationStatus(
      ReservationStatus.Cancelled as number,
    );
    dispatch(actions.setAnyValue({ fieldKey: 'Status', fieldValue: status }));
    setCanceled(true);
  }, [actions, dispatch]);
  const approveReservation = React.useCallback(() => {
    let status = getSingleReservationStatus(
      ReservationStatus.Approved as number,
    );
    dispatch(actions.setAnyValue({ fieldKey: 'Status', fieldValue: status }));
  }, [actions, dispatch]);
  const draftReservation = React.useCallback(() => {
    let status = getSingleReservationStatus(ReservationStatus.Draft as number);
    dispatch(actions.extendReservationSettings({ SaveForLater: true }));
    dispatch(actions.setAnyValue({ fieldKey: 'Status', fieldValue: status }));
  }, [actions, dispatch]);
  const handleChangeStatus = React.useCallback(
    (event: any, status: ReservationStatus) => {
      if (status === ReservationStatus.Cancelled) {
        cancelReservation();
      } else if (status === ReservationStatus.Approved) {
        approveReservation();
      } else if (status === ReservationStatus.Draft) {
        draftReservation();
      }
      setTimeout(() => handleSaveClick(event), 10);
    },
    [approveReservation, cancelReservation, draftReservation, handleSaveClick],
  );
  const handleSubmitForm = (e: any) => {
    if (customFormRef.current) {
      customFormRef.current(e);
      setTimeout(() => {
        if (repetitiveFormRef.current) {
          repetitiveFormRef.current(e);
        }
        if (submitFormRef.current) {
          submitFormRef.current(e);
        }
      }, 50);
    } else {
      if (repetitiveFormRef.current) {
        repetitiveFormRef.current(e);
      }
      if (submitFormRef.current) {
        submitFormRef.current(e);
      }
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (
      values: ReservationDetailsState,
      customFormFiles: IFormFileValue[],
      originalFormFiles: IFormFileValue[],
    ) => {
      setBusy(true);
      if (isCreditSubmited) {
        setShowCalculateCredit(true);
        let value = {
          details: values,
          settings: reservationSettings,
          customFormFiles: [],
          RepetitiveOptions: repetitiveValues,
          offlineServices: savedOfflineServices,
        } as ReservationGlobalState;
        dispatch(
          actions.createReservation({
            state: value,
            calcCredit: true,
          }),
        );
      } else {
        if (edit) {
          dispatch(
            actions.updateReservation({
              current: values,
              original: reservation.data || values,
              currentCustomFormFiles: customFormFiles,
              originalCustomFormFiles: originalFormFiles,
              offlineServices: savedOfflineServices,
              settings: reservationSettings,
            }),
          );
        } else {
          let value = {
            details: {
              ...values,
              SampleRunId: reservation.data?.SampleRunId,
            },
            settings: reservationSettings,
            customFormFiles: customFormFiles,
            RepetitiveOptions: repetitiveValues,
            offlineServices: savedOfflineServices,
          } as ReservationGlobalState;
          //console.log('data', value);
          dispatch(
            actions.createReservation({
              state: value,
              calcCredit: undefined,
            }),
          );
        }
      }
    },
    [
      actions,
      dispatch,
      edit,
      isCreditSubmited,
      repetitiveValues,
      reservation.data,
      reservationSettings,
      savedOfflineServices,
    ],
  );
  const [offlineServicesErrors, setOfflineServicesErrors] = React.useState<
    ErrorServices[]
  >([]);
  const handleSetError = React.useCallback(
    (error: ErrorServices) => {
      if (error.error !== undefined) {
        setOfflineServicesErrors([
          ...offlineServicesErrors.filter(f => f.id !== error.id),
          error,
        ]);
      } else {
        setOfflineServicesErrors([
          ...offlineServicesErrors.filter(f => f.id !== error.id),
        ]);
      }
    },
    [offlineServicesErrors],
  );
  const handleTerminate = React.useCallback(() => {
    dispatch(
      actions.setAnyValue({
        fieldKey: 'EndTime',
        fieldValue: dateUtils.formatISO(newDate()),
      }),
    );
    dispatch(
      actions.setAnyValue({
        fieldKey: 'Remarks',
        fieldValue: t(translations.AutoEndReservation_remarks) as string,
      }),
    );
    setTimeout(() => {
      dispatch(
        actions.updateReservation({
          current:
            innerFormRef?.current?.values ||
            reservation.data ||
            ({} as ReservationDetailsState),
          original: reservation.data || ({} as ReservationDetailsState),
          currentCustomFormFiles: [],
          originalCustomFormFiles: [],
          offlineServices: savedOfflineServices,
          settings: reservationSettings,
          terminate: true,
        }),
      );
    }, 10);
  }, [
    actions,
    dispatch,
    newDate,
    reservation.data,
    reservationSettings,
    savedOfflineServices,
    t,
  ]);
  //------ Confirm Repetitive Update/Delete handlers -------//
  const handleUpdateAllRecurrent = React.useCallback(
    (e: any) => {
      dispatch(
        actions.updateReservationSettings({
          RecurringUpdateOption: RecurringReservationUpdateOptions.All,
        }),
      );
      handleSubmitForm(e);
      setShowConfirmRecurring(false);
      setCanceled(undefined);
    },
    [actions, dispatch],
  );
  const handleUpdateFollowing = React.useCallback(
    (e: any) => {
      dispatch(
        actions.updateReservationSettings({
          RecurringUpdateOption: RecurringReservationUpdateOptions.Following,
        }),
      );
      handleSubmitForm(e);
      setShowConfirmRecurring(false);
      setCanceled(undefined);
    },
    [actions, dispatch],
  );
  const handleUpdateJustOne = React.useCallback(
    (e: any) => {
      dispatch(
        actions.updateReservationSettings({
          RecurringUpdateOption:
            RecurringReservationUpdateOptions.CurrentReservation,
        }),
      );
      handleSubmitForm(e);
      setShowConfirmRecurring(false);
      setCanceled(undefined);
    },
    [actions, dispatch],
  );
  const assistedApproveOnse = React.useCallback(() => {
    setShowConfirmAssisted(false);
    dispatch(actions.resetAssisteTrainingData());
    dispatch(layoutActions.setRefreshTable(true));
    if (!isCloned) {
      handleCloselClick();
    }
  }, [actions, dispatch, handleCloselClick, isCloned, layoutActions]);
  const handleByApprovalConfirm = React.useCallback(
    (e: any) => {
      dispatch(
        actions.updateReservationSettings({
          UserRequestedByApproval: true,
        }),
      );
      dispatch(actions.setByApprovalErrors());
      handleSubmitForm(e);
    },
    [actions, dispatch],
  );
  const handleMaxReservationComplexityValidationResult = React.useCallback(
    (e: any) => {
      // this one marks the reservation to go directly into the background processing
      dispatch(actions.setUserRequestedBackground(true));
      // reset the previous validation state
      dispatch(actions.setMaxReservationComplexityValidationResult(null));
      // resubmit the reservation - hopefully it did not change much
      handleSubmitForm(e);
      // close side panel to indicate that we've done with this one and the whole story ends here
      // todo: move closing action to the saga?
      dispatch(layoutActions.closeSidePanel());
    },
    [actions, dispatch, layoutActions],
  );

  const editAssisted = React.useCallback(
    (p: TrainingDetailsProps, reset: boolean) => {
      openPanel({
        renderPageType: RenderPageType.TrainingDetails,
        renderPageProps: {
          ...p,
          useSidePanel: true,
          isCover: useSidePanel,
          onClose: () => {
            if (reset) {
              dispatch(actions.resetAssisteTrainingData());
            }
            dispatch(layoutActions.setRefreshTable(true));
          },
        } as TrainingDetailsProps,
        useSidePanel: true,
        isCover: useSidePanel,
      });

      setShowConfirmAssisted(false);
    },
    [actions, dispatch, useSidePanel, layoutActions, openPanel],
  );
  const handleCancelTransfer = () => {
    if (transferUser !== null) {
      dispatch(
        actions.cancelTransfer({
          User: transferUser,
          ReservtionId: transferredForId || reservation?.data?.Id || 0,
        }),
      );
    }
  };
  const handleEquipmentClick = React.useCallback(
    (equipment: Entity<number>) => {
      const assetProps: AssetPopUpProps = {
        identifier: {
          serviceType: 'Online',
          serviceId: equipment.Id,
        },
        useSidePanel: true,
      };
      openPanel({
        renderPageType: RenderPageType.AssetDetails,
        renderPageProps: assetProps,
        expanded: false,
        useSidePanel: true,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [openPanel, useSidePanel],
  );
  const handleRestrictionsClick = React.useCallback(
    (equipment: Entity<number>) => {
      let restrictProps = {
        serviceId: equipment.Id,
        showTable: IsAdmin,
        serviceGroupId: (equipment as IReservationEquipmentDto).ServiceGroupId,
      };
      openPanel({
        renderPageType: RenderPageType.RestrictionDetails,
        renderPageProps: restrictProps,
        expanded: false,
        useSidePanel: true,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [IsAdmin, useSidePanel, openPanel],
  );
  const handleOfflineServiceClick = React.useCallback(
    (service: IOtherServices) => {
      if (edit) {
        let otherProps = {
          initialService: service,
          saveCreatable: true,
          queryParams: {
            id: '' + service.Id ?? -1,
          },
        };
        openPanel({
          renderPageType: RenderPageType.OtherServiceDetails,
          renderPageProps: otherProps,
          expanded: false,
          useSidePanel: true,
          isCover: useSidePanel,
        } as SidePanelOpenState);
      }
    },
    [edit, openPanel, useSidePanel],
  );
  const handleWorkTypeClick = React.useCallback(
    (
      event: IWorkOrderTypeDto,
      equipmentIds: number[],
      reservationId: number,
    ) => {
      let woProps = {
        queryParams: {
          id: undefined,
          aType: event.Id.toString(),
          eqid: equipmentIds.join(','),
          reservationId: reservationId.toString(),
        } as WorkOrderQueryStringParameters,
      };
      openPanel({
        renderPageType: RenderPageType.WorkOrderDetails,
        renderPageProps: woProps,
        expanded: false,
        useSidePanel: true,
        isCover: useSidePanel,
        onClose: () => dispatch(actions.setRefreshWorkOrderLink(true)),
      } as SidePanelOpenState);
    },
    [actions, dispatch, openPanel, useSidePanel],
  );
  const handleAddBatchClick = React.useCallback(
    (batch: IInventoryBatchDto | null, serviceTypeId: number) => {
      let batchProps = {
        initialBatch: batch ?? undefined,
        queryParams: {
          id: '' + (batch?.Id ?? -1),
          stid: '' + serviceTypeId,
        },
        saveCreatable: useSidePanel,
      };
      openPanel({
        renderPageType: RenderPageType.InventoryBatch,
        renderPageProps: batchProps,
        expanded: false,
        useSidePanel: true,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [openPanel, useSidePanel],
  );
  const handleCalculateCredit = React.useCallback(
    (e: any) => {
      dispatch(actions.setSubmitCredit(true));
      handleSubmitForm(e);
    },
    [actions, dispatch],
  );

  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      // if (reservationId !== undefined && !isCloned) {
      //   //setEdit(true);
      // } else {
      //   //setEdit(false);
      // }
      if (actionCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          if (confirmAssisted && assistedTrainingData !== null) {
            setShowConfirmAssisted(true);
          } else {
            setShowConfirmAssisted(false);
            if (!isCloned) {
              handleCloselClick();
            }
          }
        }
        if (terminationEnabled) {
          dispatch(actions.resetTerminateState());
        }
      } else if (actionCompleted === false) {
        setRequestCompleted(undefined);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    actionCompleted,
    actions,
    assistedTrainingData,
    confirmAssisted,
    dispatch,
    handleCloselClick,
    hasError,
    isCloned,
    processing,
    terminationEnabled,
  ]);
  React.useEffect(() => {
    let active = creditProcessing === false;
    if (active) {
      if (creditCompleted === true && !showCalculateCredit) {
        dispatch(actions.resetCreditState());
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    creditCompleted,
    creditProcessing,
    dispatch,
    showCalculateCredit,
  ]);

  React.useEffect(() => {
    if (transferProcessing === false && transferStepCompleted === true) {
      setShowTransfer(false);
    }
  }, [actions, dispatch, transferProcessing, transferStepCompleted]);

  /// actions ///
  const leftActions = React.useMemo(() => {
    let mactions = [] as ActionRenderer[];
    if (!edit || (edit && (IsAdmin || isUserGroupCoordinator || isOwner))) {
      mactions.push(() => (
        <React.Fragment>
          <SaveFormButton
            size="small"
            startIcon={<Icon icon="save" />}
            onClick={e => {
              dispatch(actions.setCloneReservation(false));
              dispatch(actions.resetOperateState());
              handleSaveClick(e);
            }}
            disabled={
              busy ||
              equipmentChangeCompleted === false ||
              transferStepWaiting === true ||
              (edit && reservationCanceled && !IsAdmin)
            }
            processing={!requestCompleted && busy}
            text={t(translations.Save)}
          />
        </React.Fragment>
      ));
    }
    if (edit) {
      if (!!equipmentSettings && !!reservationSettings) {
        if (canBeCanceled) {
          mactions.push(() => (
            <React.Fragment>
              <CancelReservationButton
                size="small"
                startIcon={<Icon icon="trash" />}
                onClick={e =>
                  handleChangeStatus(e, ReservationStatus.Cancelled)
                }
                disabled={
                  busy ||
                  transferStepWaiting === true ||
                  equipmentChangeCompleted === false
                }
                variant="white"
                processing={!requestCompleted && busy}
                text={
                  showShortView
                    ? t(translations.Cancel)
                    : t(translations.CancelReservation)
                }
              />
            </React.Fragment>
          ));
        }
        if (canBeApproved) {
          mactions.push(() => (
            <React.Fragment>
              <ApproveReservationButton
                size="small"
                startIcon={<Icon icon="check" />}
                onClick={e => handleChangeStatus(e, ReservationStatus.Approved)}
                disabled={
                  busy ||
                  transferStepWaiting === true ||
                  equipmentChangeCompleted === false
                }
                variant="white"
                processing={!requestCompleted && busy}
                text={
                  showShortView
                    ? t(translations.Approve)
                    : t(translations.ApproveReservation)
                }
              />
            </React.Fragment>
          ));
        }
        if (
          (equipmentSettings.AllowedToTransfer ||
            equipmentSettings.AllowedToTransferAll) &&
          !transferStepWaiting &&
          !repeat &&
          canBeCanceled
        ) {
          mactions.push(() => (
            <React.Fragment>
              <TransferButton
                size="small"
                onClick={e => {
                  if (hasChanges) {
                    dispatch(layoutActions.setConfirmOpen(true));
                    setConfirmBeforeTransfer(true);
                  } else {
                    setShowTransfer(true);
                  }
                }}
                disabled={busy}
                variant="white"
                title={t(translations.TransferReservation)}
                processing={transferProcessing}
                text={t(translations.Transfer)}
              />
            </React.Fragment>
          ));
        }
      }
    }
    if (
      IsAdmin &&
      globalSettings.assistedReservationsEnabled &&
      globalSettings.tutoringModulesEnabled &&
      equipmentSettings?.IsEquipmentTutoring &&
      edit &&
      assistedTrainingData !== null &&
      assistedTrainingData.Equipments.some(f => f.TrainingRecordId === null)
    ) {
      mactions.push(() => (
        <React.Fragment>
          <CreateTrainingButton
            size="small"
            startIcon={<Icon icon="dumbbell" />}
            onClick={e =>
              editAssisted(
                {
                  id: undefined,
                  equId: undefined,
                  equIds: assistedTrainingData.Equipments.map(
                    f => f.EquipmentId,
                  ).join(','),
                  fatherId: reservation.data?.FatherId?.toString() ?? undefined,
                  rid:
                    reservation.data?.FatherId !== null &&
                    reservation.data?.FatherId !== undefined
                      ? undefined
                      : reservation.data?.Id !== null
                      ? reservation.data?.Id.toString()
                      : undefined,
                  date: dateUtils.formatISO(
                    dateUtils.dateOrStringToDate(
                      reservation.data?.StartTime ?? new Date(),
                    ),
                  ),
                  user: reservation.data?.BookedBy?.Id,
                  useSidePanel: true,
                } as TrainingDetailsProps,
                false,
              )
            }
            disabled={busy}
            variant="white"
            text={t(translations.CreateTraining)}
          />
        </React.Fragment>
      ));
    }
    if (canSaveForLater) {
      mactions.push(() => (
        <React.Fragment>
          <SaveForLaterButton
            size="small"
            startIcon={<Icon icon="floppy-disk-circle-arrow-right" />}
            onClick={e => handleChangeStatus(e, ReservationStatus.Draft)}
            disabled={
              busy ||
              transferStepWaiting === true ||
              equipmentChangeCompleted === false
            }
            variant="white"
            processing={!requestCompleted && busy}
            text={t(translations.SaveForLater)}
          />
        </React.Fragment>
      ));
    }
    if (calculateButtonVisible) {
      mactions.push(() => (
        <Button
          id={'CalculateCreditPopupButtonId'}
          variant="white"
          size="small"
          startIcon={<Icon icon="money-bill" />}
          onClick={e => handleCalculateCredit(e)}
          title={
            offlineServicesErrors.length > 0
              ? (t(translations.OfflineServicesError_info) as string)
              : (t(translations.CalculatePricing_info) as string)
          }
          disabled={
            creditProcessing ||
            equipmentChangeCompleted === false ||
            offlineServicesErrors.length > 0
          }
          processing={creditProcessing}
        >
          {t(translations.CalculatePricing)}
        </Button>
      ));
    }
    if (edit && (IsAdmin || isUserGroupCoordinator || isOwner)) {
      mactions.push(() => (
        <Button
          variant="white"
          size="small"
          startIcon={<Icon icon="square" />}
          aria-label="history"
          onClick={() => setOpenModifications(true)}
        >
          {t(translations.ModificationsHistory)}
        </Button>
      ));
    }
    if (IsAdmin || isOwner) {
      mactions.push(() => (
        <Button
          variant="white"
          size="small"
          startIcon={<Icon icon="clone" />}
          aria-label="clone"
          disabled={
            busy ||
            equipmentChangeCompleted === false ||
            transferStepWaiting === true ||
            (edit && reservationCanceled && !IsAdmin)
          }
          title={t(translations.res_SaveAndClone_info)}
          onClick={e => {
            dispatch(actions.resetOperateState());
            if (edit) {
              if (hasChanges) {
                setConfirmCloned(hasChanges);
              } else {
                dispatch(actions.setCloneReservation(true));
                handleSaveClick(e);
              }
            } else {
              dispatch(actions.setCloneReservation(true));
              handleSaveClick(e);
            }
          }}
        >
          {edit ? t(translations.SaveAndClone) : t(translations.CreateAndClone)}
        </Button>
      ));
    }
    mactions.push(() => (
      <React.Fragment>
        <Button
          variant="white"
          component={LinkBehavior}
          disabled={
            !edit ||
            reservationSettings?.FatherId === undefined ||
            reservationSettings?.FatherId === null
          }
          startIcon={<Icon icon="link" />}
          size="small"
          onClick={e => {
            dispatch(
              appSettingsActions.navigate(
                toLocationDescriptor({
                  path: '/reservations',
                  search: { relId: reservationId },
                }),
              ),
            );
            if (sidePanelExpanded) {
              dispatch(layoutActions.setExpanded(false));
            }
          }}
        >
          {t(translations.ShowRelatedReservations)}
        </Button>
      </React.Fragment>
    ));
    mactions.push(() => (
      <React.Fragment>
        <Button
          variant="white"
          size="small"
          startIcon={<Icon icon="money-bill" />}
          aria-label="money-bill"
          href={buildURL(`Charges`, {
            refType: ReferenceType.Reservation as number,
            refId: reservationId,
          })}
          target="_blank"
          title={t(translations.Charges)}
        >
          {t(translations.Charges)}
        </Button>
      </React.Fragment>
    ));
    if (edit) {
      mactions.push(() => (
        <Button
          variant="white"
          size="small"
          startIcon={<Icon icon="print" />}
          aria-label="print"
          onClick={handlePrintToPDF}
          title={t(translations.PrintPdf)}
          disabled={print}
        >
          {t(translations.PrintPdf)}
        </Button>
      ));
    }
    return mactions;
  }, [
    IsAdmin,
    actions,
    appSettingsActions,
    assistedTrainingData,
    busy,
    calculateButtonVisible,
    canBeApproved,
    canBeCanceled,
    canSaveForLater,
    creditProcessing,
    dispatch,
    edit,
    editAssisted,
    equipmentChangeCompleted,
    equipmentSettings,
    globalSettings.assistedReservationsEnabled,
    globalSettings.tutoringModulesEnabled,
    handleCalculateCredit,
    handleChangeStatus,
    handlePrintToPDF,
    handleSaveClick,
    hasChanges,
    isOwner,
    isUserGroupCoordinator,
    layoutActions,
    offlineServicesErrors.length,
    print,
    repeat,
    requestCompleted,
    reservation.data?.BookedBy?.Id,
    reservation.data?.FatherId,
    reservation.data?.Id,
    reservation.data?.StartTime,
    reservationCanceled,
    reservationId,
    reservationSettings,
    showShortView,
    sidePanelExpanded,
    t,
    transferProcessing,
    transferStepWaiting,
  ]);

  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <IconButton
            variant="ghost"
            size="small"
            title={t(translations.Close)}
            onClick={handleCloselClick}
          >
            <Icon icon="times" />
          </IconButton>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCloselClick, t]);

  const pageTitle = reservation?.data
    ? edit
      ? ` ${
          isCloned
            ? t(translations.CloneReservation)
            : t(translations.Reservation)
        } #${reservationId || 'N/A'}`
      : reservation.data.TrainingSignUp
      ? t(translations.TrainingSignUp)
      : ` ${
          isCloned
            ? t(translations.CloneReservation)
            : t(translations.AddReservation)
        }`
    : undefined;
  const pageName = edit
    ? t(translations.menu_ReservationDetails)
    : t(translations.menu_NewReservation);
  const useSwitchActions =
    !edit && IsAdmin && !reservation.data?.TransferFromId && useSwitchButtons;
  const switchState =
    !!workOrderSwithProps && !edit && useSwitchButtons
      ? ({
          target: SwitchTarget.WorkOrder,
          getProps: () => workOrderSwithProps,
        } as SwitchActionsProps)
      : undefined;
  const ByApprovalAvailable = !isEmpty(ByApprovalErrors);
  const MaxReservationComplexityReached =
    MaxReservationComplexityValidationResult !== null &&
    MaxReservationComplexityValidationResult !== undefined;
  const confirmPopover = React.useMemo(() => {
    // everything goes out of the windoew if max complexity is reached - all other popovers should be suppressed
    if (MaxReservationComplexityReached) {
      return (
        <DialogConfirm
          isOpen={true}
          title={t(translations.MaxReservationComplexity)}
          body={<Body>{MaxReservationComplexityValidationResult}</Body>}
          confirmButtonLabel={t(translations.Confirm)}
          maxWidth="md"
          onConfirm={handleMaxReservationComplexityValidationResult}
          onCancel={() => {
            dispatch(actions.setMaxReservationComplexityValidationResult(null));
          }}
        />
      );
    } else {
      return showConfirmRecurring ? (
        <DialogConfirm
          isOpen={showConfirmRecurring}
          title={
            canceled
              ? t(translations.CancelMultipleReservations)
              : t(translations.UpdateMultipleReservations)
          }
          processing={reservationProcessing}
          body={t(translations.WeeklyReservationConfirmationMessage)}
          confirmButtonLabel={t(translations.JustThisOne)}
          onConfirm={handleUpdateJustOne}
          maxWidth="md"
          onCancel={() => {
            setShowConfirmRecurring(false);
          }}
          actions={[
            () => (
              <ActionButton
                text={
                  canceled
                    ? t(translations.CancelFollowing)
                    : t(translations.UpdateFollowing)
                }
                variant="main"
                onClick={handleUpdateFollowing}
              />
            ),
            () => (
              <ActionButton
                text={
                  canceled
                    ? t(translations.CancelAll)
                    : t(translations.UpdateAll)
                }
                variant="main"
                onClick={handleUpdateAllRecurrent}
              />
            ),
          ]}
        />
      ) : confirmCloned ? (
        <DialogConfirm
          isOpen={confirmCloned}
          title={t(translations.CloneReservation)}
          body={t(translations.CloneReservationConfirm)}
          confirmButtonLabel={t(translations.Proceed)}
          onConfirm={e => {
            dispatch(actions.setCloneReservation(true));
            handleSaveClick(e);
            setConfirmCloned(false);
          }}
          maxWidth="md"
          onCancel={() => {
            setConfirmCloned(false);
          }}
        />
      ) : requestCompleted &&
        confirmAssisted &&
        assistedTrainingData !== null ? (
        <ApproveTrainingRecords
          show={showConfirmAssisted || false}
          data={assistedTrainingData}
          handleApproveOnce={assistedApproveOnse}
          handleEditAssisted={editAssisted}
        />
      ) : showTransfer ? (
        <TransferReservation
          show={showTransfer}
          transferAllowedToGroup={equipmentSettings?.AllowedToTransfer || false}
          services={(
            reservation?.data?.EquipmentsData as IReservationEquipmentDto[]
          ).map(f => {
            return {
              Id: f.Id,
              Name: f.Name,
              ServiceTypeId: 1,
              ServiceGroupId: f.ServiceGroupId,
              HideProject: f.HideProjects,
            } as IServiceTypeFilterDto;
          })}
          adGroup={reservation?.data?.ADGroup?.Id}
          trnsferSubmitted={transferProcessing}
          handleTransfer={value => {
            let transfer = {
              User: value,
              ReservtionId: reservation?.data?.Id ?? 0,
            } as TransferToReservation;
            dispatch(actions.transferReservation(transfer));
          }}
          user={User}
          onClose={() => setShowTransfer(false)}
        />
      ) : showCalculateCredit ? (
        <CalculatePricing
          open={showCalculateCredit}
          title={t(translations.CalculatePricing)}
          onClose={() => setShowCalculateCredit(false)}
          edit={edit}
          credit={credit}
          creditCompleted={creditCompleted}
          creditProcessing={creditProcessing}
        />
      ) : !!openModifications ? (
        <ReservationModifications
          open={openModifications}
          setOpen={value => setOpenModifications(value)}
          resId={reservation?.data?.Id ?? 0}
        />
      ) : ByApprovalAvailable ? (
        <DialogConfirm
          isOpen={ByApprovalAvailable}
          title={t(translations.ByApprovalConfirmationModalTitle)}
          processing={reservationProcessing}
          body={
            <>
              {(ByApprovalErrors ?? [])?.map((error, index) => (
                <Alert key={index} color="warning">
                  {error}
                </Alert>
              ))}
              <Body>{t(translations.ReservationConfirmByApproval)}</Body>
            </>
          }
          confirmButtonLabel={t(translations.Confirm)}
          onConfirm={handleByApprovalConfirm}
          maxWidth="md"
          onCancel={() => {
            dispatch(actions.setByApprovalErrors(undefined));
          }}
        />
      ) : (
        <></>
      );
    }
  }, [
    ByApprovalAvailable,
    ByApprovalErrors,
    MaxReservationComplexityReached,
    MaxReservationComplexityValidationResult,
    User,
    actions,
    assistedApproveOnse,
    assistedTrainingData,
    canceled,
    confirmAssisted,
    confirmCloned,
    credit,
    creditCompleted,
    creditProcessing,
    dispatch,
    edit,
    editAssisted,
    equipmentSettings?.AllowedToTransfer,
    handleByApprovalConfirm,
    handleMaxReservationComplexityValidationResult,
    handleSaveClick,
    handleUpdateAllRecurrent,
    handleUpdateFollowing,
    handleUpdateJustOne,
    openModifications,
    requestCompleted,
    reservation?.data?.ADGroup?.Id,
    reservation?.data?.EquipmentsData,
    reservation?.data?.Id,
    reservationProcessing,
    showCalculateCredit,
    showConfirmAssisted,
    showConfirmRecurring,
    showTransfer,
    t,
    transferProcessing,
  ]);
  /// render ///
  return (
    <React.Fragment>
      <PageWrapper
        pageName={pageName}
        titlePage={pageTitle}
        loading={processing}
        useSidePanel={useSidePanel}
        closable={true}
        closeSidePanel={handleCloselClick}
        //leftTopActions={topActions}
        useSwitchActions={useSwitchActions}
        switchState={switchState}
        topProcessing={
          equipmentChangeCompleted === false || asyncChangeLoading === true
        }
        leftActions={not_restricted ? leftActions : []}
        leftActionsMaxLength={edit ? 2 : 1}
        pageLink={buildURL(
          publicUrl + 'reservations/details',
          props.queryParams,
        )}
        toPageLink={`reservations/details?${toQueryString(props.queryParams)}`}
        rightActions={rightActions}
        isCover={isCover}
        // rightTopActions={not_restricted ? rightTopActions : undefined}
        //rightTopActionsAsMenu
        confirm={confirmPopover}
        cover={cover}
        closeCover={!isCover ? onCloseCover : closeCover}
        coverClosed={coverClosed}
      >
        {reservation?.data !== undefined && !processing ? (
          not_restricted ? (
            <PrintToPDF
              title={pageTitle || ''}
              print={print}
              printTitle={pageTitle || ''}
              useSections={true}
              close={handleClosePrintToPDF}
            >
              <ReservationForm
                initialValues={
                  reservation?.data || ({} as ReservationDetailsState)
                }
                bindSubmitForm={bindSubmitForm}
                onSubmit={handleSubmit}
                isEdit={edit || false}
                repetitiveFormRef={repetitiveFormRef}
                formRef={innerFormRef}
                isAdmin={IsAdmin}
                user={User}
                isUserGroupCoordinator={isUserGroupCoordinator}
                trainingSignUp={trainingSignUp}
                trainingSessionEnabled={trainingSessionEnabled}
                allowEditMultipleEquipments={allowEditMultipleEquipments}
                useSidePanel={useSidePanel}
                onEquipmentClicked={handleEquipmentClick}
                onRestrictionsClick={handleRestrictionsClick}
                onOfflineServiceClick={handleOfflineServiceClick}
                onAddBatchClick={handleAddBatchClick}
                onStatusChange={handleChangeStatus}
                onWorkTypeClick={handleWorkTypeClick}
                globalSettings={globalSettings}
                customFormRef={customFormRef}
                editable={editable}
                timeEnabled={timeEnabled}
                cancelTransfer={handleCancelTransfer}
                waitingTransferApproval={transferStepWaiting}
                transferToUser={transferUser}
                transferForId={transferredForId}
                terminationEnabled={terminationEnabled}
                handleTerminate={handleTerminate}
                offlineServicesErrors={offlineServicesErrors}
                setError={handleSetError}
                assistedTrainingData={assistedTrainingData}
                allowOngoingModifications={allowOngoingModifications}
                isCover={isCover}
                print={print}
              />
            </PrintToPDF>
          ) : (
            <FlexRowDiv style={{ alignSelf: 'center', justifySelf: 'center' }}>
              <Box
                style={{ display: 'flex', flexDirection: 'column', gap: 16 }}
              >
                <H3>{t(translations.RestrictedNotAllowed) as string}</H3>
                <Button
                  variant="ghost"
                  size="medium"
                  startIcon={<Icon icon="times" />}
                  onClick={handleCloselClick}
                >
                  {t(translations.Continue)}
                </Button>
              </Box>
            </FlexRowDiv>
          )
        ) : reservation?.data === undefined &&
          !processing &&
          (reservationNotFound || userReadOnly) ? (
          <FlexRowDiv style={{ alignSelf: 'center', justifySelf: 'center' }}>
            <Box
              style={{
                display: 'flex',
                flexDirection: 'column',
                gap: 16,
                paddingInline: 16,
              }}
            >
              <H3>{t(translations.RestrictedNotAllowed) as string}</H3>
              <Button
                variant="ghost"
                size="medium"
                startIcon={<Icon icon="times" />}
                onClick={handleCloselClick}
              >
                {t(translations.Continue)}
              </Button>
            </Box>
          </FlexRowDiv>
        ) : (
          <React.Fragment>
            {' '}
            <Box component="div">
              <Progress inProgress={processing} size={80} />
            </Box>
          </React.Fragment>
        )}
      </PageWrapper>
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </React.Fragment>
  );
});
