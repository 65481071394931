import {
  Chip,
  ChipProps,
  darken,
  fade,
  Theme,
  withStyles,
} from '@material-ui/core';
import { Tooltip } from 'app/components/BasicTooltips/Tooltip';
import { WithStylesOptions } from '@material-ui/styles';
import { inputLabelM, inputLabelXS } from 'styles/typography/bookitTypography';
import { Icon } from '../BasicIcons/FontAwesome';
import { Check } from '../BasicInputs/Check';

export interface BookitChipProps extends ChipProps {
  customcolor?: string;
  custombgcolor?: string;
  customiconcolor?: string;
  fullwidth?: boolean;
  customwidth?: number | string;
  shape?: 'squar' | 'circle';
  customsize?: 'xs' | 'small' | 'medium';
  notremoved?: boolean;
}
export interface BookitChipWithInfoProps extends BookitChipProps {
  info?: React.ReactNode;
}
const StyledChip = withStyles<
  | 'root'
  | 'disabled'
  | 'label'
  | 'labelSmall'
  | 'clickable'
  | 'sizeSmall'
  | 'deleteIcon',
  WithStylesOptions<Theme>,
  {
    shape?: 'squar' | 'circle';
    custombgcolor?: string;
    customsize?: 'xs' | 'small' | 'medium';
    customcolor?: string;
    customiconcolor?: string;
    fullwidth?: boolean;
    customwidth?: number | string;
    notremoved?: boolean;
    size?: 'small' | 'medium';
    disabled?: boolean;
  }
>((theme: Theme) => ({
  root: {
    justifyContent: 'space-around',
    borderRadius: props =>
      props.shape === 'squar' ? 4 : props.shape === 'circle' ? 50 : 4,
    backgroundColor: props => props.custombgcolor ?? theme.palette.primary.main,
    height: props =>
      props.customsize === 'xs'
        ? 20
        : props.customsize === 'small'
        ? 24
        : props.customsize === 'medium'
        ? 32
        : 24,
    color: props => props.customcolor ?? theme.palette.common.white,
    gap: 4,
    paddingBlock: props =>
      props.customsize === 'xs'
        ? '2px'
        : props.customsize === 'small'
        ? '6px'
        : props.customsize === 'medium'
        ? '8px'
        : '4px',
    '&$disabled': {
      opacity: 1,
      backgroundColor: theme.palette.common.grayComponents,
      color: theme.palette.common.white,
      pointerEvents: 'visible',
    },
    '&:hover, &:focus': {
      backgroundColor: props =>
        props.custombgcolor === undefined
          ? theme.palette.primary.dark
          : darken(props.custombgcolor, 0.2),
      color: props =>
        props.customcolor === undefined
          ? theme.palette.common.white
          : props.customcolor,
    },
    width: props =>
      props.fullwidth
        ? '100%'
        : props.customwidth
        ? props.customwidth
        : undefined,
    maxWidth: props => (props.notremoved ? '100%' : 'calc(100% - 6px)'),
  },
  disabled: {
    backgroundColor: theme.palette.common.grayComponents,
    color: theme.palette.common.white,
  },
  label: inputLabelM,
  labelSmall: inputLabelXS,
  clickable: {
    backgroundColor: props => props.custombgcolor ?? theme.palette.primary.main,
    color: props => props.customcolor ?? theme.palette.common.white,
  },
  sizeSmall: {
    height: 20,
  },
  deleteIcon: {
    color: props =>
      props.customiconcolor === undefined
        ? theme.palette.primary.contrastText
        : props.customiconcolor,
    height: 16,
    width: 16,
    margin: props =>
      props.size === 'small' ? '0 4px 0 -4px' : '0 12px 0 -12px',
    display: props => (props.notremoved ? 'none' : 'inline-block'),
    '&:hover': {
      color: props =>
        props.customiconcolor === undefined
          ? fade(theme.palette.primary.contrastText, 0.8)
          : fade(props.customiconcolor, 0.8),
    },
    pointerEvents: props => (props.disabled ? 'none' : 'auto'),
  },
}))(Chip);

export const BookitChip = (props: BookitChipProps) => {
  const {
    deleteIcon,
    notremoved,
    fullwidth,
    disabled,
    customcolor,
    custombgcolor,
    customiconcolor,
    customwidth,
    shape,
    customsize,
    ...other
  } = props;
  return (
    <StyledChip
      deleteIcon={
        !!deleteIcon ? (
          deleteIcon
        ) : notremoved ? undefined : (
          <Icon icon="times" aria-disabled={disabled} />
        )
      }
      onDelete={event => {
        notremoved
          ? event.preventDefault()
          : !!props.onDelete && props.onDelete(event);
      }}
      fullwidth={fullwidth}
      custombgcolor={custombgcolor}
      customiconcolor={customiconcolor}
      shape={shape}
      customsize={customsize}
      customwidth={customwidth}
      customcolor={customcolor}
      disabled={disabled}
      notremoved={notremoved}
      {...other}
    />
  );
};
export interface BookitChipWithCheckProps extends BookitChipProps {
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  color?: 'primary' | 'secondary' | 'default';
  checked?: boolean;
  checkid?: string;
  checkname?: string;
  label?: string;
  info?: string;
}
export const BookitChipWithCheck = (props: BookitChipWithCheckProps) => {
  return (
    <BookitChipWithInfo
      icon={
        <Check
          name={props.checkname}
          id={props.checkid}
          onChange={props.onChange}
          disabled={props.disabled}
          checked={props.checked ?? false}
          size="small"
        />
      }
      label={props.label}
      fullwidth={props.fullwidth}
      info={props.info}
      notremoved={props.notremoved}
      custombgcolor={props.custombgcolor}
      customcolor={props.customcolor}
      customiconcolor={props.customiconcolor}
      customsize={props.customsize}
      customwidth={props.customwidth}
      {...props}
    />
  );
};

export const BookitChipWithInfo = (props: BookitChipWithInfoProps) => {
  const {
    info,
    fullwidth,
    customcolor,
    custombgcolor,
    customiconcolor,
    customwidth,
    shape,
    customsize,
    notremoved,
    ...other
  } = props;
  return info ? (
    <Tooltip title={info}>
      <span>
        <BookitChip
          fullwidth={fullwidth}
          customcolor={customcolor}
          custombgcolor={custombgcolor}
          customiconcolor={customiconcolor}
          customwidth={customwidth}
          shape={shape}
          customsize={customsize}
          notremoved={notremoved}
          {...other}
        />
      </span>
    </Tooltip>
  ) : (
    <StyledChip
      deleteIcon={<Icon icon="times" />}
      fullwidth={fullwidth}
      customcolor={customcolor}
      custombgcolor={custombgcolor}
      customiconcolor={customiconcolor}
      customwidth={customwidth}
      shape={shape}
      customsize={customsize}
      notremoved={notremoved}
      {...other}
    />
  );
};
