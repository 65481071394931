import { PayloadAction } from '@reduxjs/toolkit';
import { call, put, takeLatest, debounce, select } from 'redux-saga/effects';
import { assetQuickSearchActions as actions } from '.';
import { httpClient } from 'api/HttpClient';
import {
  selectAuthenticatedUser,
  selectGlobalServiceGroupFilter,
  selectShowAllCores,
  selectSiteMapProvider,
} from 'app/slice/selectors';
import { Identifiable } from 'types/common';
import { AssetQuickSearchApi } from 'api/assetQuickSearchApi';
import {
  IMenuItemsSearchResult,
  IQuickAssetsSearchResult,
  IQuickSearchResults,
} from './types';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { SiteMapNode, SiteMapProvider } from 'types/SiteMapSettings';
import { IAuthenticatedUser, isInRole } from 'types/AuthenticatedUser';
import { Roles } from 'app/slice/types';
import { uniqBy } from 'lodash';

function* doShowSearchInput(action: PayloadAction<boolean>) {
  yield put(actions.setOpen(action.payload));
}
function* doSetOpen(action: PayloadAction<boolean>) {
  if (action.payload) {
    yield put(actions.getRecentAssets());
    yield put(actions.getRecentSearchTerms());
  }
}
function* doSearchAssets(action: PayloadAction<string>) {
  const topServiceGroups:
    | Array<Identifiable<number>>
    | undefined = yield select(selectGlobalServiceGroupFilter);
  const payload = {
    q: action.payload,
    serviceGroups: topServiceGroups ?? [],
  };
  try {
    const data = yield call(AssetQuickSearchApi.getAssetSearch, payload);
    yield put(actions.searchAssets_Success(data));
  } catch (error: unknown) {
    yield put(actions.searchAssets_Error({ error: error }));
  }
}
function* doSearchAllAssets(action: PayloadAction<string>) {
  const topServiceGroups:
    | Array<Identifiable<number>>
    | undefined = yield select(selectGlobalServiceGroupFilter);
  const allCores = yield select(selectShowAllCores);
  const user = yield select(selectAuthenticatedUser);
  const siteMapProvider = yield select(selectSiteMapProvider);
  let searchMenus: SiteMapNode[] = searchMenu(
    action.payload,
    siteMapProvider,
    siteMapProvider.getUserNodes(),
    user,
  );

  let menuResult: IMenuItemsSearchResult = {
    Results: searchMenus,
    TotalResultsLength: searchMenus.length,
  };
  yield put(actions.setMenuSearchResults(menuResult));
  try {
    let responses: IQuickAssetsSearchResult[] = [];
    for (let idx = 1; idx <= 3; idx++) {
      let payload = {
        q: action.payload,
        serviceGroups: allCores ? [] : topServiceGroups ?? [],
        serviceTypeId: idx,
      };
      const result = yield call(AssetQuickSearchApi.getAssetSearch, payload);
      if (result.ServiceType === ServiceType.Online) {
        yield put(actions.setOnlineSearchResults(result));
      } else if (result.ServiceType === ServiceType.Offline) {
        yield put(actions.setOfflineSearchResults(result));
      } else if (result.ServiceType === ServiceType.Sample) {
        yield put(actions.setRequestSearchResults(result));
      }
      responses.push(result);
    }
    yield put(
      actions.setTotalSearchCount(
        responses.reduce((part, a) => part + a.TotalResultsLength, 0),
      ),
    );
    const data = {
      online: responses.filter(f => f.ServiceType === ServiceType.Online)[0],
      offline: responses.filter(f => f.ServiceType === ServiceType.Offline)[0],
      request: responses.filter(f => f.ServiceType === ServiceType.Sample)[0],
      totalRresults: responses.reduce(
        (part, a) => part + a.TotalResultsLength,
        0,
      ),
    } as IQuickSearchResults;
    yield put(actions.searchAllAssets_Success(data));
  } catch (error: unknown) {
    yield put(actions.searchAllAssets_Error({ error: error }));
  }
}
function* doGetRecentAssets(action: PayloadAction) {
  const topServiceGroups:
    | Array<Identifiable<number>>
    | undefined = yield select(selectGlobalServiceGroupFilter);
  const allCores = yield select(selectShowAllCores);
  const payload = {
    serviceGroups: allCores ? [] : topServiceGroups ?? [],
  };
  try {
    const data = yield call(AssetQuickSearchApi.getRecentAssets, payload);
    yield put(actions.getRecentAssets_Success(data.Results));
  } catch (error: unknown) {
    yield put(actions.getRecentAssets_Error({ error: error }));
  }
}
function* doGetRecentSearchTerms(payload: PayloadAction<boolean>) {
  try {
    const data = yield call(
      httpClient.get,
      '/api/assets/recenttextsearchterms',
    );
    yield put(actions.getRecentSearchTerms_Success(data));
  } catch (error: unknown) {
    yield put(actions.getRecentSearchTerms_Error({ error: error }));
  }
}
const searchMenu = (
  searchTerm: string,
  siteMapProvider: SiteMapProvider,
  menuNodes: SiteMapNode[],
  user?: IAuthenticatedUser,
): SiteMapNode[] => {
  let searchMenus: SiteMapNode[] = [];
  const isAdmin =
    isInRole(user, Roles.Administrators) ||
    isInRole(user, Roles.GroupAdministrators) ||
    isInRole(user, Roles.EquipmentAdministrator);
  if (searchTerm !== '') {
    menuNodes.forEach(item => {
      if (
        item.Hide === false &&
        (!item.isAdminNode ||
          (item.isAdminNode &&
            isAdmin &&
            siteMapProvider.nodeAccesibleByRole(item, user)))
      ) {
        let childItems = item.ChildNodes.filter(
          ch =>
            ch.HideReact === false &&
            ch.Hide === false &&
            (!ch.isAdminNode ||
              (ch.isAdminNode &&
                isAdmin &&
                siteMapProvider.nodeAccesibleByRole(ch, user))),
        );
        if (childItems.length > 0) {
          childItems.forEach(c => {
            if (
              c.Title !== null &&
              c.Title.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
            ) {
              searchMenus.push(c);
            }
          });
        }
        if (
          item.Title !== null &&
          item.Title.toLowerCase().indexOf(searchTerm.toLowerCase()) >= 0
        ) {
          searchMenus.push(item);
        }
      }
    });
  }
  let results =
    searchMenus.length > 0 ? uniqBy(searchMenus, f => f.Title) : searchMenus;
  return results;
};
export function* assetQuickSearchSaga() {
  yield takeLatest(actions.setOpen.type, doSetOpen);
  yield takeLatest(actions.showSearchInput.type, doShowSearchInput);
  yield takeLatest(actions.getRecentAssets.type, doGetRecentAssets);
  yield takeLatest(actions.getRecentSearchTerms.type, doGetRecentSearchTerms);
  yield debounce(500, actions.searchAssets.type, doSearchAssets);
  yield debounce(500, actions.searchAllAssets.type, doSearchAllAssets);
}
