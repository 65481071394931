import Radio, { RadioProps } from '../BasicInputs/Radio';
import * as React from 'react';
import { InfoIcon } from '../BasicIcons/InfoIcon';
import FormControl from '../Forms/FormControls/FormControl';
import FormControlLabel from '../Forms/FormControls/FormControlLabel';
import FormHelperText from '../Forms/FormControls/FormHelperText';
import clsx from 'clsx';
import FormGroup from '../Forms/FormControls/FormGroup';
import { useRadioGroup } from '@material-ui/core/RadioGroup';

export interface BasicRadioBoxProps extends Omit<RadioProps, 'danger'> {
  label?: string;
  error?: string;
  helperText?: string;
  info?: React.ReactNode;
  fullWidth?: boolean;
  name?: string;
  boxed?: boolean;
  shortLabel?: boolean;
  //   onChange?: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  //   onChange?: (
  //     value: boolean,
  //     event: React.ChangeEvent<HTMLInputElement>,
  //   ) => void;
}

export function BasicRadioBox(props: BasicRadioBoxProps) {
  const {
    label,
    error,
    helperText,
    info,
    name,
    onChange,
    boxed,
    fullWidth,
    shortLabel,
    ...other
  } = props;
  const radioGroup = useRadioGroup();
  //   const [isChecked, setIsChecked] = React.useState<boolean>(
  //     props.checked === undefined
  //       ? !!radioGroup && radioGroup.value === props.value
  //       : props.checked ?? false,
  //   );

  //   const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     if (!!radioGroup) {
  //       let val = event.target.value as string;
  //       !!radioGroup.onChange && radioGroup.onChange(event, val);
  //       setIsChecked(val === props.value);
  //     } else {
  //       const value = event.target.checked as boolean;
  //       if (props.onChange !== undefined) {
  //         props.onChange(event, value);
  //       }
  //       setIsChecked(value);
  //     }
  //   };

  return (
    <FormControl
      error={error !== undefined}
      fullWidth={fullWidth}
      className={clsx({
        boxed: boxed,
      })}
    >
      <FormGroup
        row={true}
        className={clsx({
          withInfo: info !== undefined,
          boxed: boxed,
          boxedChecked: !!radioGroup
            ? radioGroup.value === props.value
            : props.checked,
          boxedError: error !== undefined,
        })}
      >
        <FormControlLabel
          label={label}
          className={clsx({
            withInfo: info !== undefined,
            boxed: boxed,
            boxedChecked: !!radioGroup
              ? radioGroup.value === props.value
              : props.checked,
            boxedError: error !== undefined,
            shortLabel: shortLabel,
          })}
          value={props.value}
          control={
            <Radio
              name={name}
              color={props.color || 'primary'}
              danger={error !== undefined}
              {...other}
            />
          }
        />
        {info && <InfoIcon title={info} />}
      </FormGroup>
      {((!!error && error !== '') || (!!helperText && helperText !== '')) && (
        <FormHelperText
          error={error !== undefined}
          className={clsx({
            boxed: boxed,
          })}
        >
          {error || helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
}
