import { PayloadAction } from '@reduxjs/toolkit';
import { GlobalSettingsType } from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { call, put, select, takeLatest, takeLeading } from 'redux-saga/effects';
import { AuthenticatedUser } from 'types/AuthenticatedUser';
import {
  IUsageDto,
  UsageChangesResult,
  UsageChangeStateParameters,
  UsageCommand,
  UsageDetailsResponseModel,
  UsageDetailsState,
  UsageQueryStringParameters,
  UsageSettingsState,
} from './types';
import { appSettingsActions } from 'app/slice';
import { usageStateActions as actions } from '.';
import { UsageApi } from 'api/UsageApi';
import { AxiosError } from 'axios';
import { selectUsageDtoState, selectUsageSettings } from './selectors';
import { EndUsageData } from 'app/components/EndUsageConfirm';
import { IResponseType } from 'types/ResponseType';
import i18next from 'i18next';
import { translations } from 'locales/translations';
import { getUsageSettings } from './getUsageSettingsState';
import { dateUtils } from 'utils/date-utils';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import { ConvertModelToEntity } from './ConvertModelToEntity';
import { SnackBarMessageType } from 'app/Layout/FrontendLayout/components/Snackbar/types';
import { RenderPageType } from 'app/Layout/FrontendLayout/slice/type';
import { UsageDetailsProps } from '..';
import { layoutActions } from 'app/Layout/FrontendLayout/slice';
import { OtherServicesApi } from 'api/OtherServicesApi';
import { ConsumableServicesResponse } from 'app/pages/OtherServiceDetails/Details/slice/types';

function* doInitUsage(
  action: PayloadAction<{
    query: UsageQueryStringParameters;
    globalSettings: GlobalSettingsType;
    command: UsageCommand;
    prevState?: UsageDetailsState;
  }>,
) {
  try {
    const user: AuthenticatedUser | undefined = yield select(
      selectAuthenticatedUser,
    );
    if (action.payload.query.id !== undefined) {
      yield* doInitUsageEdit({
        type: action.type,
        payload: {
          id: parseInt(action.payload.query.id),
          globalSettings: action.payload.globalSettings,
        },
      });
      return;
    }
    const result = yield call(UsageApi.initUsage, action.payload.query);
    let response = result as UsageDetailsResponseModel;
    if (response.ErrorMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.ErrorMessages.filter(
            (value, index, self) => self.indexOf(value) === index,
          ).map(item => {
            return {
              key: 'usageInsertError',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    }
    if (response.SuccessMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.SuccessMessages.map(item => {
            return {
              key: 'initUsageSucc',
              message: item,
              variant: 'success',
            };
          }),
        ),
      );
    }
    if (response.WarningMessages.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          response.WarningMessages.map(item => {
            return {
              key: 'initUsageWarn',
              message: item,
              variant: 'warning',
            };
          }),
        ),
      );
    }
    yield put(
      actions.initUsage_Success({
        hasErrors: response.ErrorMessages.length > 0,
        model: response.model,
        isEdit: (response.model?.Id ?? 0) > 0,
        globalSettings: action.payload.globalSettings,
        settings: getUsageSettings(
          response.model,
          action.payload.globalSettings,
          user,
        ),
      }),
    );
    if (!!response.model.BaseEquipment || !!action.payload.query.sid) {
      const parameters: UsageChangeStateParameters = {
        AccServices: response.model.AccServices.map(f => f.Id),
        Equipment:
          response.model.BaseEquipment?.Id ??
          parseInt(action.payload.query.sid ?? '0'),
        UsageId: (response.model.Id ?? 0) > 0 ? response.model.Id : null,
        ReservationId: response.model.ReservationId || null,
        Start: dateUtils.formatISO(
          dateUtils.dateOrStringToDate(response.model.StartTime),
          { representation: 'complete' },
        ),
        End: !!response.model.EndTime
          ? dateUtils.formatISO(
              dateUtils.dateOrStringToDate(response.model.EndTime),
              {
                representation: 'complete',
              },
            )
          : undefined,
        BookedBy: response.model.BookedBy?.Id || null,
        Tutoring: response.model.Tutoring,
        ADGroup:
          response.model.ADGroup?.Id || user?.ActiveUserGroup?.Id || null,
        FundingTypeId: response.model.FundingTypeId || 0,
        Remarks: response.model.Remarks || null,
        BudgetId: response.model.Budget?.Id || null,
        AllowOverlappingUsages:
          response.model.BaseEquipment?.AllowOverlappingUsages ?? 1,
      };
      yield put(
        actions.changeUsageData({
          parameters: parameters,
          warnings: [],
          errors: [],
          loadReservations: true,
        }),
      );
    }
  } catch (error: unknown) {
    yield put(actions.initUsage_Error(error));
  }
}
function* doInitUsageEdit(
  action: PayloadAction<{
    id: number;
    globalSettings: GlobalSettingsType;
  }>,
) {
  try {
    // var s: AppSettings = yield select(selectAppSettings);
    const user: AuthenticatedUser | undefined = yield select(
      selectAuthenticatedUser,
    );
    const expand: Array<string | boolean> = [
      action.payload.globalSettings.budgetModuleEnabled && 'Budget',
      'BookedBy',
      'ADGroup',
      action.payload.globalSettings.instituteProjectsEnabled &&
        'InstituteProject',
      //ExternalCustomer
      action.payload.globalSettings.budgetExperimentModuleEnabled &&
        'BudgetExperiment',
      'UpdatedBy',
      action.payload.globalSettings.accServicesEnabled && 'AccServices',
      'BaseEquipment',
      'Reservation',
      action.payload.globalSettings.tutoringEnabled && 'Operator',
    ];
    const result: IUsageDto | undefined = yield call(
      UsageApi.getUsage,
      action.payload.id,
      {
        $expand: expand.filter(f => typeof f === 'string').join(','),
      },
    );
    console.debug('usage result: ', result);
    if (!result) {
      yield put(
        appSettingsActions.addNotification({
          key: 'usageInit',
          message: 'Usage Not Found',
          variant: 'error',
        }),
      );

      yield put(actions.initUsageNotFound_Error());
      return;
    }
    yield put(
      actions.initUsage_Success({
        hasErrors: false,
        model: result,
        isEdit: true,
        globalSettings: action.payload.globalSettings,
        settings: getUsageSettings(result, action.payload.globalSettings, user),
      }),
    );
    if (!!result.BaseEquipment) {
      const parameters: UsageChangeStateParameters = {
        AccServices: result.AccServices.map(f => f.Id),
        Equipment: result.BaseEquipment.Id,
        UsageId: result.Id,
        ReservationId: result.ReservationId || null,
        Start: dateUtils.formatISO(
          dateUtils.dateOrStringToDate(result.StartTime),
          { representation: 'complete' },
        ),
        End: !!result.EndTime
          ? dateUtils.formatISO(dateUtils.dateOrStringToDate(result.EndTime), {
              representation: 'complete',
            })
          : undefined,
        BookedBy: result.UserName || null,
        Tutoring: result.Tutoring,
        ADGroup: result.UserGroupId || null,
        FundingTypeId: result.FundingTypeId || 0,
        Remarks: result.Remarks || null,
        BudgetId: result.BudgetId || null,
        AllowOverlappingUsages: result.BaseEquipment.AllowOverlappingUsages,
      };
      yield put(
        actions.changeUsageData({
          parameters: parameters,
          warnings: [],
          errors: [],
          loadReservations: false,
        }),
      );
    }
  } catch (error: unknown) {
    console.error(error);
    if ((error as AxiosError)?.response?.status === 404) {
      yield put(
        appSettingsActions.addNotification({
          key: 'usageInit',
          message: 'Usage Not Found',
          variant: 'error',
        }),
      );
    }
    yield put(actions.initUsage_Error(error));
  }
}

function* doGetCredit(action: PayloadAction<UsageDetailsState>) {
  try {
    const user: AuthenticatedUser | undefined = yield select(
      selectAuthenticatedUser,
    );
    const usageSettings = yield select(selectUsageSettings);
    if (action.payload.BaseEquipment?.Id) {
      const parameters: UsageChangeStateParameters = {
        AccServices: action.payload.AccServices.map(f => f.Id),
        Equipment: action.payload.BaseEquipment.Id,
        UsageId: action.payload.Id,
        ReservationId: usageSettings.ReservationId || null,
        Start: dateUtils.formatISO(
          dateUtils.dateOrStringToDate(action.payload.StartTime),
          { representation: 'complete' },
        ),
        End: !!action.payload.EndTime
          ? dateUtils.formatISO(
              dateUtils.dateOrStringToDate(action.payload.EndTime),
              {
                representation: 'complete',
              },
            )
          : undefined,
        BookedBy: action.payload.BookedBy?.Id || null,
        Tutoring: action.payload.Tutoring,
        ADGroup:
          action.payload.ADGroup?.Id || user?.ActiveUserGroup?.Id || null,
        FundingTypeId: action.payload.FundingType?.Id || 0,
        Remarks: action.payload.Remarks || null,
        BudgetId: action.payload.Budget?.Id || null,
        AllowOverlappingUsages: 1,
      };
      const result = yield call(UsageApi.calculateCredit, parameters);
      yield put(
        actions.getCredit_Success({
          credit: result.TotalCost,
          balance: result.Balance,
        }),
      );
    } else {
      yield put(
        actions.getCredit_Success({
          credit: 0,
          balance: 0,
        }),
      );
    }
  } catch {
    yield put(actions.getCredit_Success({ credit: 0, balance: null }));
  }
}
function* doSetAny(
  action: PayloadAction<{
    fieldKey: keyof UsageDetailsState;
    fieldValue: any;
  }>,
) {
  yield put(actions.setAnyValueSuccess(action.payload));
}
// function* doUpdateEquipmentSettigs(
//   action: PayloadAction<{
//     equipments: IReservationEquipmentDto[];
//     mandatories: IMandatoryEquipmentsDto[];
//     accServices: IEquipmentAccServiceDto[];
//     globalSettings: GlobalSettingsType;
//     settings?: ServiceSettingsState;
//   }>,
// ) {
//   try {
//     let allEquipments = [
//       ...action.payload.equipments,
//       ...((action.payload.mandatories as Entity<
//         number
//       >[]) as IReservationEquipmentDto[]),
//     ];
//     const authenticatedUser = yield select(selectAuthenticatedUser);
//     let newSettings = mapSettingsFromServices(
//       allEquipments,
//       action.payload.accServices,
//       action.payload.globalSettings,
//       authenticatedUser,
//       action.payload.settings,
//     );
//     yield put(actions.updateEquipmentSettingsState(newSettings));
//   } catch (error) {
//     console.log(error);
//   }
// }
function* doLoadValidationData(
  action: PayloadAction<{
    parameters: UsageChangeStateParameters;
    warnings: string[];
    errors: string[];
  }>,
) {
  try {
    let res = yield call(
      UsageApi.getValidationState,
      action.payload.parameters,
    );
    let response = res as IResponseType;
    response.WarningMessages = [
      ...response.WarningMessages,
      ...action.payload.warnings,
    ];
    response.ErrorMessages = [
      ...response.ErrorMessages,
      ...action.payload.errors,
    ];
    yield put(actions.validateUsageData_Success(response));
  } catch (error: unknown) {
    yield put(actions.validateUsageData_Error());
  }
}
function* doChangeUsageData(
  action: PayloadAction<{
    parameters: UsageChangeStateParameters;
    warnings: string[];
    errors: string[];
    loadReservations?: boolean;
  }>,
) {
  try {
    let res = yield call(
      UsageApi.getUsageChangeState,
      action.payload.parameters,
    );
    let response = res as UsageChangesResult;
    response.WarningMessages = [
      ...response.WarningMessages,
      ...action.payload.warnings,
    ];
    response.ErrorMessages = [
      ...response.ErrorMessages,
      ...action.payload.errors,
    ];
    let updateSettings = action.payload.loadReservations
      ? {
          HasErrors: response.ErrorMessages.length > 0,
          Reservation: response.Reservation,
          ReservationId: response.Reservation?.Id ?? null,
        }
      : { HasErrors: response.ErrorMessages.length > 0 };
    yield put(actions.extendUsageSettings(updateSettings));
    yield put(actions.changeUsageData_Success(response));
  } catch (error: unknown) {
    yield put(actions.changeUsageData_Error());
  }
}

function* doUpdateSettigs(action: PayloadAction<any>) {
  yield put(actions.updateUsageSettings(action.payload));
}
function* doCreateOrStartUsage(
  action: PayloadAction<{
    details: UsageDetailsState;
    settings?: UsageSettingsState;
    offlineServices: IOtherServices[];
    manual?: boolean;
  }>,
) {
  const user: AuthenticatedUser | undefined = yield select(
    selectAuthenticatedUser,
  );
  const dtoState = yield select(selectUsageDtoState);
  const httpPayloads: IUsageDto = ConvertModelToEntity(
    dtoState,
    action.payload.details,
    action.payload.offlineServices,
    user,
    action.payload.settings,
    action.payload.manual || false,
  );
  try {
    const result = yield call(UsageApi.startUsage, httpPayloads);
    let response = result as UsageDetailsResponseModel;
    let responseErrors = response.ErrorMessages;
    let responseWarnings = response.WarningMessages;
    let responseSuccess = response.SuccessMessages;
    if (responseErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseErrors
            .filter((value, index, self) => self.indexOf(value) === index)
            .map(item => {
              return {
                key: 'usageInsertError',
                message: item,
                variant: 'error',
              };
            }),
        ),
      );
    } else {
      if (responseSuccess.length > 0) {
        yield put(
          appSettingsActions.addNotification({
            message: responseSuccess[0],
            variant: 'success',
          }),
        );
        let prop: UsageDetailsProps = {
          useSidePanel: true,
          queryParams: {
            id: '' + response.Id ?? -1,
          },
        };
        yield put(
          appSettingsActions.addNotification({
            key: 'usageInsertSuccess',
            message: responseSuccess[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: true,
              itemName: i18next.t(translations.menu_UsageDetails),
              detailsType: RenderPageType.UsageDetails,
              detailsTypeProps: prop,
            },
            variant: 'success',
          }),
        );
      }
      if (responseWarnings.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            responseWarnings.map(item => {
              return {
                key: 'usageInsertWarning',
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      if (action.payload.manual) {
        yield put(layoutActions.setRefreshTable(true));
      }
    }
    let hasErrors = responseErrors.length > 0;
    yield put(layoutActions.setNotSavedChanges(false));
    yield put(
      actions.startUsage_Success({
        hasErrors: hasErrors,
        usageId: response.model.Id,
        manual: action.payload.manual,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'usageInsert',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.startUsage_Error(Error));
  }
}
function* doUpdateUsage(
  action: PayloadAction<{
    current: UsageDetailsState;
    offlineServices: IOtherServices[];
    settings: UsageSettingsState | undefined;
    reset?: boolean;
  }>,
) {
  const user: AuthenticatedUser | undefined = yield select(
    selectAuthenticatedUser,
  );
  const originalDto: IUsageDto = yield select(selectUsageDtoState);

  const httpPayloads: IUsageDto = ConvertModelToEntity(
    originalDto,
    action.payload.current,
    action.payload.offlineServices,
    user,
    action.payload.settings,
    originalDto.UpdatedManually,
    action.payload.reset,
  );
  try {
    const results = yield call(UsageApi.updateUsage, httpPayloads);
    let response = results as UsageDetailsResponseModel;
    let respErrors = response.ErrorMessages;
    if (respErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          respErrors.map(item => {
            return {
              key: 'usageUpdateErr',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
    } else {
      if (response.SuccessMessages.length > 0) {
        let prop: UsageDetailsProps = {
          useSidePanel: true,
          queryParams: {
            id: '' + response.Id ?? -1,
          },
        };
        yield put(
          appSettingsActions.addNotification({
            key: 'usageUpdateSuccess',
            message: response.SuccessMessages[0],
            messageType: SnackBarMessageType.openSidepanelDetails,
            messageTypeProps: {
              Id: response.Id ?? undefined,
              created: false,
              itemName: i18next.t(translations.menu_UsageDetails),
              detailsType: RenderPageType.UsageDetails,
              detailsTypeProps: prop,
            },
            variant: 'success',
          }),
        );
      }
      if (response.WarningMessages.length > 0) {
        yield put(
          appSettingsActions.addNotifications(
            response.WarningMessages.map(item => {
              return {
                key: 'usageUpdateWarn',
                message: item,
                variant: 'warning',
              };
            }),
          ),
        );
      }
      yield put(layoutActions.setRefreshTable(true));
      if (
        action.payload.offlineServices.length > 0 &&
        action.payload.offlineServices.some(f => f.Id < 1)
      ) {
        const offRes = yield call(
          OtherServicesApi.insertConsumableServices,
          action.payload.offlineServices.filter(f => f.Id < 1),
        );
        let offResponse = offRes as ConsumableServicesResponse;
        let offErrors = offResponse.ErrorMessages;
        let offWarnings = offResponse.WarningMessages;
        if (offErrors.length > 0) {
          yield put(
            appSettingsActions.addNotifications(
              offErrors.map(item => {
                return {
                  key: 'serviceInsertError',
                  message: item,
                  variant: 'error',
                };
              }),
            ),
          );
        } else {
          if (offWarnings.length > 0) {
            yield put(
              appSettingsActions.addNotifications(
                offWarnings.map(item => {
                  return {
                    key: 'serviceInsertWarning',
                    message: item,
                    variant: 'warning',
                  };
                }),
              ),
            );
          }
        }
      }
      yield put(layoutActions.setNotSavedChanges(false));
    }
    let hasErrors = response.ErrorMessages.length > 0;
    yield put(
      actions.updateUsage_Success({
        hasErrors: hasErrors,
        insertModel: response.model,
      }),
    );
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'reservationUpdateErr',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.updateUsage_Error(Error));
  }
}
function* doStopUsage(
  action: PayloadAction<{
    usage: EndUsageData;
    globalSettings: GlobalSettingsType;
    isAdmin: boolean;
  }>,
) {
  // const user: AuthenticatedUser | undefined = yield select(
  //   selectAuthenticatedUser,
  // );
  const original = yield select(selectUsageDtoState);
  const httpPayloads = {
    id: original.Id,
    data: action.payload.usage,
  };
  try {
    const result = yield call(UsageApi.stopUsage, httpPayloads);
    let response = result as IResponseType;
    let responseErrors = response.ErrorMessages;
    let responseWarnings = response.WarningMessages;
    let responseSuccess = response.SuccessMessages;
    if (responseErrors.length > 0) {
      yield put(
        appSettingsActions.addNotifications(
          responseErrors.map(item => {
            return {
              key: 'usage_stop_err',
              message: item,
              variant: 'error',
            };
          }),
        ),
      );
      yield put(actions.stopUsage_Error());
    } else {
      if (responseSuccess.length > 0) {
        if (action.payload.isAdmin) {
          let prop: UsageDetailsProps = {
            useSidePanel: true,
            queryParams: {
              id: '' + original.Id ?? -1,
            },
          };
          yield put(
            appSettingsActions.addNotification({
              key: 'usageStoppedSuccess',
              message: responseSuccess[0],
              messageType: SnackBarMessageType.openSidepanelDetails,
              messageTypeProps: {
                Id: original.Id ?? undefined,
                created: false,
                itemName: i18next.t(translations.menu_UsageDetails),
                detailsType: RenderPageType.UsageDetails,
                detailsTypeProps: prop,
              },
              variant: 'success',
            }),
          );
        } else {
          yield put(
            appSettingsActions.addNotification({
              key: 'stop_usage_success',
              message: response.SuccessMessages[0],
              variant: 'success',
            }),
          );
        }

        if (responseWarnings.length > 0) {
          yield put(
            appSettingsActions.addNotifications(
              responseWarnings.map(item => {
                return {
                  key: 'stop_usage_warning',
                  message: item,
                  variant: 'warning',
                };
              }),
            ),
          );
        }
        // yield put(
        //   actions.initUsage({
        //     globalSettings: action.payload.globalSettings,
        //     query: { id: original.Id.toString() },
        //     prevState: original,
        //   }),
        // );
        yield put(
          actions.stopUsage_Success({
            hasErrors: response.ErrorMessages.length > 0,
          }),
        );
      }
    }
  } catch (error: unknown) {
    const message =
      (error as AxiosError)?.response?.data?.error?.innererror?.message ??
      ((error as AxiosError)?.response?.status === 403
        ? i18next.t(translations.Forbidden)
        : undefined) ??
      i18next.t(translations.errormessage);
    yield put(
      appSettingsActions.addNotification({
        key: 'usage_stop_err',
        message: message,
        variant: 'error',
      }),
    );
    yield put(actions.stopUsage_Error());
  }
}
export function* usageSaga() {
  yield takeLeading(actions.initUsage.type, doInitUsage);
  yield takeLeading(actions.calculateCredit.type, doGetCredit);
  yield takeLeading(actions.stopUsage.type, doStopUsage);
  yield takeLeading(actions.updateUsage.type, doUpdateUsage);
  yield takeLeading(actions.startUsage.type, doCreateOrStartUsage);
  yield takeLatest(actions.setAnyValue.type, doSetAny);
  yield takeLatest(actions.changeUsageData.type, doChangeUsageData);
  yield takeLatest(actions.extendUsageSettings.type, doUpdateSettigs);
  yield takeLatest(actions.validateUsageData.type, doLoadValidationData);
}
