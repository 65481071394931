import { useEffectOnMount } from 'app/hooks/useEffectOnMount';
import { OptionsObject, SnackbarProvider, useSnackbar } from 'notistack';
import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useMessagesSlice } from './slice';
import { selectMessages, selectMessagesAlreadyExist } from './slice/selector';
import { getDuration, MessageTypeEnum } from './slice/type';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { useTheme } from '@material-ui/core';
import { AllowedSettings } from 'utils/globalSettings';
import { Entity } from 'types/common';
import { dashboardKey } from '../Dashboard/slice/types';
import { AnnoncementsRoot } from './styled';
import clsx from 'clsx';
import { SnackBarMessage } from './SnackBarMessage';
import { RenderSnackMessage } from './RenderSnackMessage';
import { Button } from '../BasicButtons/Button';

const MaxMessage = 3;
const closeAllKey = 'closeAllMessagesKey';

export type pageNames = dashboardKey | 'calendar' | 'assetDetails';
export const pageToType: Partial<Record<pageNames, MessageTypeEnum[]>> = {
  calendar: [MessageTypeEnum.calendar],
  assetDetails: [
    MessageTypeEnum.reservationStarted,
    MessageTypeEnum.usageReservation,
  ],
};
export interface MessageProps {
  pageName: pageNames;
  selectedEquipment?: Entity<number>[];
  secondaryPage?: boolean;
  darkTheme?: boolean;
}

export const Messages = ({
  secondaryPage,
  darkTheme,
  ...props
}: MessageProps) => {
  const alreadyExists = useSelector(selectMessagesAlreadyExist);
  const theme = useTheme();
  return (
    <AnnoncementsRoot className={'annoncement-root'} theme={theme}>
      <SnackbarProvider
        hideIconVariant
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        preventDuplicate
        variant="info"
        maxSnack={MaxMessage + 1}
        className={clsx('annoncement-item', {
          'annoncement-item-dark': darkTheme,
        })}
      >
        <MessagesChildren
          {...props}
          secondaryPage={secondaryPage && alreadyExists > 1}
        />
      </SnackbarProvider>
    </AnnoncementsRoot>
  );
};

const MessagesChildren = (props: MessageProps) => {
  const { pageName, selectedEquipment } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { actions } = useMessagesSlice();
  const allMessages = useSelector(selectMessages);
  const messages = useMemo(() => {
    const types = pageToType[props.pageName];
    if (types === undefined) return allMessages;
    return allMessages.filter(m => types.includes(m.type));
  }, [allMessages, props.pageName]);

  useEffectOnMount(() => {
    if (props.secondaryPage) return;
    dispatch(
      actions.init({
        pageName: pageName,
        selectedEquipment: selectedEquipment,
      }),
    );

    return () => {
      dispatch(actions.clear());
    };
  });
  useEffect(() => {
    dispatch(actions.clear());
    closeSnackbar();
    dispatch(
      actions.init({
        pageName: pageName,
        selectedEquipment: selectedEquipment,
      }),
    );
  }, [actions, closeSnackbar, dispatch, pageName, selectedEquipment]);
  useEffect(() => {
    if (props.secondaryPage) {
      closeSnackbar();
      return;
    }
    if (messages.length > 1) {
      const closeAllOptions: OptionsObject = {
        persist: true,
        key: closeAllKey,
      };

      enqueueSnackbar(
        <SnackBarMessage
          onClose={() => {
            closeSnackbar();
            dispatch(actions.clear());
          }}
          messageTitle={t(translations.CloseAll)}
        />,
        closeAllOptions,
      );
    } else {
      closeSnackbar(closeAllKey);
    }
    messages.slice(0, MaxMessage).forEach(m => {
      const close = () => {
        closeSnackbar(m.key);
      };
      const remove = () => dispatch(actions.remove(m.key));
      const options: OptionsObject = {
        autoHideDuration: getDuration(m.props),
        key: m.key,
        // action: key => (
        //   <CloseWrapper>
        //     <Icon icon="close" onClick={close} />
        //   </CloseWrapper>
        // ),
        onClose: remove,
      };

      enqueueSnackbar(
        // <>
        //   <span className={classes.root}>
        //     <RenderMessge message={m} close={close} />
        //   </span>
        //   <>
        //     {m.type === MessageTypeEnum.removableAnnouncment && (
        //       <GotIt
        //         onClick={() => {
        //           dispatch(actions.remove(m.key));
        //           closeSnackbar(m.key);
        //           dispatch(
        //             actions.gotIt(
        //               AllowedSettings[AllowedSettings.DashboardAnnouncement],
        //             ),
        //           );
        //         }}
        //       />
        //     )}
        //   </>
        // </>,
        <RenderSnackMessage
          message={m}
          onClose={close}
          messageActions={
            m.type === MessageTypeEnum.removableAnnouncment
              ? [
                  () => (
                    <Button
                      size="small"
                      onClick={() => {
                        dispatch(actions.remove(m.key));
                        closeSnackbar(m.key);
                        dispatch(
                          actions.gotIt(
                            AllowedSettings[
                              AllowedSettings.DashboardAnnouncement
                            ],
                          ),
                        );
                      }}
                    >
                      {t(translations.GotIt)}
                    </Button>
                  ),
                ]
              : undefined
          }
        />,
        options,
      );
    });
  }, [
    actions,
    closeSnackbar,
    dispatch,
    enqueueSnackbar,
    messages,
    props.secondaryPage,
    t,
  ]);
  return null;
};

// const CloseAll = () => {
//   const { t } = useTranslation();
//   return <>{t(translations.CloseAll)}</>;
// };

// const GotIt = ({ onClick }: { onClick: () => void }) => {
//   const { t } = useTranslation();
//   return (
//     <Button size="small" onClick={onClick}>
//       {t(translations.GotIt)}
//     </Button>
//   );
// };

// const RenderMessge = ({
//   message,
//   close,
// }: withClose<{
//   message: Message;
// }>) => {
//   switch (message.type) {
//     case MessageTypeEnum.Announcment:
//     case MessageTypeEnum.removableAnnouncment:
//       return <Truncicated title={null} value={message.props as string} />;
//     case MessageTypeEnum.reservationStarted:
//       const props = message.props as StartedReservation;
//       return <ReservationStartedMessage close={close} {...props} />;
//     case MessageTypeEnum.usageReservation:
//       return (
//         <UsageReservationMessage
//           close={close}
//           {...(message.props as UsageReservation)}
//         />
//       );
//     case MessageTypeEnum.loadDesk:
//       return <LoanDeskMessage {...(message.props as LoanDesk)} />;
//     case MessageTypeEnum.calendar:
//       return <CalendarMessage {...(message.props as CalendarMessageProps)} />;
//     default:
//       return null;
//   }
// };

// type withClose<T> = T & { close: () => void };

// const ReservationStartedMessage = ({
//   close,
//   ...props
// }: withClose<StartedReservation>) => {
//   const { t } = useTranslation();
//   const openSidePanel = useOpenReservationPopUp();
//   const assets = (
//     <React.Fragment key="asset_space_link">
//       <AssetLink id={props.Asset.Id}>{props.Asset.Name}</AssetLink>
//     </React.Fragment>
//   );
//   if (props.AllowUsersToShortenReservations && props.AllUsagesEnded) {
//     return (
//       <>
//         {assets} {t(translations.InstrumentStillReservered)}
//         <Button
//           onClick={() => {
//             openSidePanel({ id: props.Id });
//             close();
//           }}
//         >
//           {t(translations.ReleaseUnusedReservation)}
//         </Button>
//       </>
//     );
//   }
//   let messages: React.ReactNode = '';
//   if (props.AllowUsersToShortenReservations) {
//     const message = t(translations.YouHaveOngoingReservations);
//     messages = message
//       .split('{0}')
//       .map<React.ReactNode>((f, i) => (
//         <React.Fragment>
//           <span key={'ReservationStarted' + i}> {f} </span>
//         </React.Fragment>
//       ))
//       .reduce((prev, curr) => [prev, assets, curr]);
//   } else if (props.AllowExtendReservation || props.AllowModification) {
//     const message = t(translations.ReservationStarted);
//     const link = (
//       <WithSpace key="res_space_link">
//         <Link key="res_link" onClick={() => openSidePanel({ id: props.Id })}>
//           {props.Id}
//         </Link>
//       </WithSpace>
//     );
//     messages = message
//       .split('{0}')
//       .map<React.ReactNode>((m, idx) => (
//         <span key={'assets_' + idx}>
//           {' '}
//           {m
//             .split('{1}')
//             .map<React.ReactNode>((f, i) => (
//               <React.Fragment>
//                 <span key={'ReservationStarted' + i}> {f} </span>
//               </React.Fragment>
//             ))
//             .reduce((prev, curr) => [prev, assets, curr])}
//         </span>
//       ))
//       .reduce((prev, curr) => [prev, link, curr]);
//   }
//   const canEdit =
//     props.AllowUsersToShortenReservations ||
//     props.AllowModification ||
//     props.AllowExtendReservation;
//   return (
//     <>
//       {messages}
//       {canEdit && (
//         <Button
//           onClick={() => {
//             openSidePanel({ id: props.Id });
//             close();
//           }}
//         >
//           {t(translations.UpdateOngoingReservations)}
//         </Button>
//       )}
//     </>
//   );
// };

// const UsageReservationMessage = ({
//   close,
//   ...props
// }: withClose<UsageReservation>) => {
//   const { t } = useTranslation();
//   const assets = (
//     <React.Fragment key="asset_space_link">
//       <AssetLink id={props.Id}>{props.Name}</AssetLink>
//     </React.Fragment>
//   );
//   const message = t(translations.IpRelay_reservationTimeForInstrument);
//   const messages = message
//     .split('{0}')
//     .map<React.ReactNode>((f, i) => (
//       <span key={'UsageReservation' + i}> {f} </span>
//     ))
//     .reduce((prev, curr) => [prev, assets, curr]);
//   return (
//     <>
//       {messages}
//       <UsageReportLink
//         title={t(translations.StartUsage)}
//         sid={props.Id}
//         onClick={close}
//       >
//         {t(translations.IpRelay_LinkToUsage)}
//       </UsageReportLink>
//     </>
//   );
// };

// const CalendarMessage = (props: CalendarMessageProps) => {
//   return (
//     <Truncicated value={props.Body ?? ''} title={props.Title}>
//       {props.Body && parse(props.Body)}
//     </Truncicated>
//   );
// };

// const LoanDeskMessage = (props: LoanDesk) => {
//   const { t } = useTranslation();
//   const openSidePanel = useOpenReservationPopUp();
//   const message = t(translations.ReservationStartedMessage_LoanDesk);
//   const link = (
//     <WithSpace>
//       <Link onClick={() => openSidePanel({ id: props.Id })}>{props.Id}</Link>
//     </WithSpace>
//   );
//   const asset = (
//     <WithSpace>
//       <AssetLink id={props.AssetDetails.Id}>
//         {props.AssetDetails.Name}
//       </AssetLink>
//     </WithSpace>
//   );
//   const messages = message
//     .split('{0}')
//     .map(m =>
//       m
//         .split('{1}')
//         .map<React.ReactNode>((f, i) => (
//           <React.Fragment key={'LoanDesk' + i}> {f} </React.Fragment>
//         ))
//         .reduce((prev, curr) => [prev, asset, curr]),
//     )
//     .reduce((prev, curr) => [prev, link, curr]);
//   return <>{messages}</>;
// };

// const WithSpace = ({ children }: React.PropsWithChildren<{}>) => {
//   return (
//     <>
//       &nbsp;
//       {children}
//       &nbsp;
//     </>
//   );
// };

// const Truncicated = ({
//   value,
//   title,
//   children,
// }: React.PropsWithChildren<{ value: string; title: string | null }>) => {
//   const classes = useStyles();
//   const height = useMemo(
//     () => getSize(value, title, classes.root),
//     [classes.root, title, value],
//   );
//   const parsed = parse(value);
//   const moreTitle = useMemo(() => MoreTitle(title), [title]);
//   if (height > 140) {
//     return (
//       <Tooltip title={<>{parsed}</>}>
//         <>
//           <span className={classes.truncate}>
//             <MorePopUp Link={moreTitle}>
//               <TitleIfExists title={title} />
//               {children ?? parsed}
//             </MorePopUp>
//             {children ?? parsed}
//           </span>
//           <MoreLink>
//             <TitleIfExists title={title} />
//             {children ?? parsed}
//           </MoreLink>
//         </>
//       </Tooltip>
//     );
//   }
//   return (
//     <span>
//       <TitleIfExists title={title} />
//       {children ?? parsed}
//     </span>
//   );
// };

// const getSize = (value: string, title: string | null, style: string) => {
//   const div = document.createElement('div');
//   div.classList.add(style);
//   if (title !== null) {
//     const h3 = document.createElement('h3');
//     h3.innerText = title;
//     div.appendChild(h3);
//   }
//   const inner = document.createElement('div');
//   inner.innerHTML = value;
//   div.appendChild(inner);
//   document.body.appendChild(div);
//   const height = div.clientHeight;
//   div.parentNode?.removeChild(div);
//   return height;
// };

// const MoreLink = ({ children }: React.PropsWithChildren<{}>) => {
//   return <MorePopUp Link={MoreButton}>{children}</MorePopUp>;
// };

// const TitleIfExists = ({ title }: { title: string | null }) => {
//   return (title ?? '') === '' ? null : <H3>{title}</H3>;
// };

// const MoreTitle = (title: string | null): MorePopUpLink => {
//   return ({ onClick }) => {
//     return (title ?? '') === '' ? null : (
//       <H3 onClick={onClick} style={{ cursor: 'pointer' }}>
//         {title}
//       </H3>
//     );
//   };
// };

// const MoreButton: MorePopUpLink = ({ onClick }) => {
//   return (
//     <Button onClick={onClick} variant="text" size="small">
//       More
//     </Button>
//   );
// };

// type MorePopUpLink = React.FC<{ onClick: () => void }>;

// const MorePopUp = ({
//   Link,
//   children,
// }: React.PropsWithChildren<{
//   Link: MorePopUpLink;
// }>) => {
//   const [open, setOpen] = useState(false);
//   const close = () => setOpen(false);
//   const { t } = useTranslation();
//   return (
//     <>
//       <Link onClick={() => setOpen(true)} />
//       <DialogConfirm
//         isOpen={open}
//         onClose={close}
//         body={children}
//         confirmButtonLabel={t(translations.OK)}
//         onConfirm={close}
//       />
//     </>
//   );
// };

// const useStyles = makeStyles(theme =>
//   createStyles({
//     root: {
//       maxWidth: '380px',
//       maxHeight: '180px',
//       zIndex: theme.zIndex.drawer,
//     },
//     truncate: {
//       overflow: 'hidden',
//       textOverflow: 'ellipsis',
//       height: '150px',
//       display: '-webkit-box',
//       WebkitLineClamp: 5,
//       WebkitBoxOrient: 'vertical',
//     },
//   }),
// );
