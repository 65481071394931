import { useMemo, useState } from 'react';
import { dateUtils } from 'utils/date-utils';
import { IComment, NewComment } from './IComment';
import { parse } from './commentParser';
import { useTranslation } from 'react-i18next';
import { translations } from 'locales/translations';
import { Button } from '../BasicButtons/Button';
import { TextField } from '../TextField';
import { IEmail } from 'api/EmailApi';
import { useSelector } from 'react-redux';
import { selectAuthenticatedUser } from 'app/slice/selectors';
import { useSendMail } from 'app/hooks/useSendMail';
import { renderToStaticMarkup } from 'react-dom/server';
import { EmailTypeUnion } from 'api/odata/generated/enums/EmailType';
import { Alert } from '@material-ui/lab';
import { Body } from '../Typography';
import * as React from 'react';
import { orderBy } from 'lodash';

import { CommentsWrapper } from './styled';
import { AddComment } from './AddComment';
import { CommentCell } from './CommentCell';

export interface CommentsContainerProps extends EmailRelated {
  Comments: IComment[];
  onSubmit: (comment: NewComment) => void;
  withMentions?: boolean;
  isAdmin?: boolean;
  onEditComment?: (comment: IComment, newComment: NewComment) => void;
  onDeleteComment?: (comment: IComment) => void;
  shouldSendEmail?: boolean;
  disabled?: boolean;
  bindSubmitComment?: any;
  defaultComment?: Partial<IComment>;
  cannotComment?: boolean;
  sectionTitle?: React.ReactNode;
  noTitle?: boolean;
  deletable?: boolean;
  additionalActions?: (comments: IComment[]) => React.ReactNode;
  checkPermissions?: (comment: IComment) => boolean;
  checkEditPermissions?: (comment: IComment) => boolean;
}

export interface EmailRelated {
  emailBody?: string;
  emailSubject?: string;
  emailType?: EmailTypeUnion;
  pageName?: string;
  referenceName?: string;
  linkUrl?: string;
}

export const CommentsContainer = ({
  Comments,
  onSubmit,
  withMentions,
  isAdmin,
  onEditComment,
  onDeleteComment,
  emailBody,
  emailSubject,
  pageName,
  referenceName,
  emailType,
  linkUrl,
  disabled,
  shouldSendEmail = true,
  bindSubmitComment,
  defaultComment,
  cannotComment,
  sectionTitle,
  noTitle,
  deletable,
  additionalActions,
  checkPermissions,
  checkEditPermissions,
}: CommentsContainerProps) => {
  const [count, setCount] = useState<number>(5);
  const sortedComments = useMemo(() => {
    return orderBy(
      Comments,
      c => dateUtils.dateOrStringToDate(c.CreatedAt),
      'desc',
    ).filter(c => !c.IsInternal || isAdmin);
  }, [Comments, isAdmin]);
  const [writeCommentOpen, setWriteCommentOpen] = useState(false);
  const { t } = useTranslation();
  const authenticatedUser = useSelector(selectAuthenticatedUser);
  const sendMail = useSendMail();
  const handleSubmit = (comment: NewComment) => {
    if (shouldSendEmail && withMentions) {
      const email = createEmail({
        emailBody: emailBody ?? t(translations.DefaultEmailTemplateBody),
        emailSubject:
          emailSubject ?? t(translations.DefaultEmailTemplateSubject),
        pageName: pageName ?? t(translations.DefaultPageName),
        referenceName: referenceName ?? t(translations.DefaultReferenceName),
        emailType: emailType,
        linkUrl: linkUrl ?? '',
        userName: authenticatedUser?.Name ?? '',
        comment: comment,
      });
      if (email) {
        sendMail(email);
      }
    }
    onSubmit(comment);
  };
  return (
    <CommentsWrapper className="comments-container">
      {!noTitle && (
        <div className={'comments-title'}>
          <Body bold={true}>
            {sectionTitle || t(translations.CommentsCount)}
          </Body>
        </div>
      )}
      {sortedComments
        .slice(0, count < sortedComments.length ? count : undefined)
        .map((c, i) => (
          <CommentCell
            key={`${i}-${c.Id}`}
            comment={c}
            onSubmit={handleSubmit}
            onClose={() => setWriteCommentOpen(false)}
            withMentions={!!withMentions}
            isAdmin={isAdmin}
            onEditComment={onEditComment}
            onDeleteComment={onDeleteComment}
            bindSubmitComment={bindSubmitComment}
            checkPermissions={checkPermissions}
            checkEditPermissions={checkEditPermissions}
            deletable={deletable}
          />
        ))}
      {additionalActions && (
        <div className={'comment-item'}>
          {additionalActions(sortedComments)}
        </div>
      )}
      <div className={'comment-item'}>
        {count < sortedComments.length && (
          <Button
            color="default"
            fullWidth
            onClick={() => setCount(prev => prev + 5)}
          >
            {(('Load more ' + t(translations.CommentsCount)) as string) +
              ' (' +
              (sortedComments.length - count) +
              ')'}
          </Button>
        )}
        {!cannotComment && (
          <div className={'add-comment-box'}>
            {writeCommentOpen ? (
              <AddComment
                defaultComment={defaultComment}
                onSubmit={handleSubmit}
                disabled={disabled}
                onClose={() => setWriteCommentOpen(false)}
                withMentions={!!withMentions}
                isAdmin={isAdmin}
                bindSubmitComment={bindSubmitComment}
              />
            ) : (
              <TextField
                fullWidth
                id="commentId"
                inputProps={{ 'aria-label': 'comments input' }}
                disabled={disabled}
                placeholder={t(translations.WriteAComment)}
                onClick={() => setWriteCommentOpen(true)}
              />
            )}
          </div>
        )}
        {cannotComment && sortedComments.length === 0 && (
          <Alert color="info">{t(translations.Comments_EmptyMessage)}</Alert>
        )}
      </div>
    </CommentsWrapper>
  );
};

const createEmail = ({
  comment,
  emailBody,
  emailSubject,
  pageName,
  referenceName,
  emailType,
  userName,
  linkUrl,
}: {
  emailBody: string;
  emailSubject: string;
  emailType?: EmailTypeUnion;
  pageName: string;
  referenceName: string;
  comment: NewComment;
  userName: string;
  linkUrl: string;
}) => {
  if (emailType === undefined || comment.Users.length === 0) return;
  const subject = emailSubject
    .replaceAll('{@UserName}', userName)
    .replaceAll('{@PageName}', pageName)
    .replaceAll('{@Reference}', referenceName);
  const body = emailBody
    .replaceAll('{@UserName}', userName)
    .replaceAll('{@PageName}', pageName)
    .replaceAll('{@Reference}', referenceName)
    .replaceAll('{@Link}', linkUrl)
    .replaceAll(
      '{@Body}',
      renderToStaticMarkup(<>{parse(comment.Text ?? '')}</>),
    );
  return {
    Subject: subject,
    Body: body,
    Files: comment.Files,
    SaveToLog: true,
    To: [],
    Cc: [],
    Bcc: comment.Users,
    Type: emailType,
  } as IEmail;
};
