import { Box, LinearProgress } from '@material-ui/core';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { Progress } from 'app/components/LoadingIndicator';
import {
  CoverProps,
  PageWrapper,
} from 'app/Layout/FrontendLayout/components/PageWrapper';
import { ActionRenderer } from 'app/Layout/FrontendLayout/components/PageWrapper/PageActions/ActionRender';
import { selectHasNotSavedChanges } from 'app/Layout/FrontendLayout/slice/selectors';
import { IOtherServices } from 'app/pages/OtherServicesPage/IOtherServices';
import useGlobalSettingsHook from 'app/pages/ReservationDetails/Details/components/useGlobalSettingsHook';
import { selectAuthenticatedUser, selectPublicUrl } from 'app/slice/selectors';
import { push } from 'connected-react-router';
import { ServiceType } from 'api/odata/generated/enums/ServiceType';
import { FormikProps } from 'formik';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { buildURL, toQueryString } from 'utils/url-utils';
import { Beforeunload } from 'react-beforeunload';
import { OfflineForm } from './OfflineForm';
import { useOfflineServiceStateSlice } from './slice';
import {
  selectCredit,
  selectCreditCompleted,
  selectCreditProcessing,
  selectHasCharges,
  selectIsEdit,
  selectProcessing,
  selectService,
  selectServiceCompleted,
  selectServiceHasError,
} from './slice/selectors';
import {
  OfflineServiceQueryStringParameters,
  OtherServiceDetailsState,
} from './slice/types';
import { useConnectedFiltersSlice } from 'app/components/Forms/FormConnectedFilters/slice';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { selectConnectedSettings } from 'app/components/Forms/FormConnectedFilters/slice/selectors';
import { TopActionButton } from 'app/components/BasicButtons/TopActionButton';
import { ReferenceType } from 'enums/ReferenceType';
import { useHistory } from 'react-router-dom';
import { useAppSettingsSlice } from 'app/slice';
import {
  RenderPageType,
  SidePanelContentProps,
} from 'app/Layout/FrontendLayout/slice/type';
import { Body, ButtonLabel } from 'app/components/Typography';
import { Roles } from 'app/slice/types';
import { IInventoryBatchDto } from 'api/odata/generated/entities/IInventoryBatchDto';
import useSidePanelState, {
  SidePanelCloseState,
  SidePanelOpenState,
} from 'app/hooks/useSidePanelOpen';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';

export interface OtherServiceDetailsProps
  extends CoverProps,
    SidePanelContentProps {
  queryParams: OfflineServiceQueryStringParameters;
  initialService?: IOtherServices;
  onClose?: () => void;
  editCreatable?: boolean;
  saveCreatable?: boolean;
}

export const OtherServiceDetails = React.memo(function OtherServiceDetails(
  props: OtherServiceDetailsProps,
) {
  const {
    useSidePanel,
    queryParams,
    initialService,
    editCreatable,
    saveCreatable,
    isCover,
    closeCover,
  } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { actions } = useOfflineServiceStateSlice();
  const { actions: connectedFiltersActions } = useConnectedFiltersSlice();
  const { actions: appSettingsActions } = useAppSettingsSlice();
  const { actions: layoutActions } = useLayoutSlice();

  /// Settings ///
  const globalSettings = useGlobalSettingsHook();
  const history = useHistory();
  /// Selectors ///
  const processing = useSelector(selectProcessing);
  const offlineService = useSelector(selectService);
  const User = useSelector(selectAuthenticatedUser);
  const hasChanges = useSelector(selectHasNotSavedChanges);
  const publicUrl = useSelector(selectPublicUrl);
  const actionCompleted = useSelector(selectServiceCompleted);
  //const serviceProcessing = useSelector(selectServiceProcessing);
  const hasError = useSelector(selectServiceHasError);
  const credit = useSelector(selectCredit);
  const creditProccessing = useSelector(selectCreditProcessing);
  const creditCompleted = useSelector(selectCreditCompleted);
  const serviceSettings = useSelector(selectConnectedSettings);
  const hasCharges = useSelector(selectHasCharges);
  const edit = useSelector(selectIsEdit);
  /// Refs ///
  const submitFormRef = React.useRef<any>(null);
  const connectedFiltersFormRef = React.useRef<any>(null);
  const innerFormRef = React.useRef<FormikProps<OtherServiceDetailsState>>(
    null,
  );
  //cover
  // const [cover, setCover] = React.useState<React.ReactNode>();
  const {
    cover,
    openPanel,
    closePanel,
    coverClosed,
    onCloseCover,
  } = useSidePanelState(
    () => {
      dispatch(actions.resetDetailsState());
      return true;
    },
    undefined,
    useSidePanel,
    isCover,
  );
  const [firstLoad, setFirstLoad] = React.useState<boolean | undefined>(true);
  const [busy, setBusy] = React.useState<boolean | undefined>(false);
  const [showCalculateCredit, setShowCalculateCredit] = React.useState(false);
  const [requestCompleted, setRequestCompleted] = React.useState<
    boolean | undefined
  >(undefined);
  const IsAdmin = React.useMemo(() => {
    return (
      User?.HasAdminGroupPermissions(
        offlineService?.data?.ServiceType?.ServiceGroupId ?? undefined,
      ) ||
      User?.HasLabTechGroupPermissions(
        offlineService?.data?.ServiceType?.ServiceGroupId ?? undefined,
      ) ||
      User?.HasAdminServicePermissions(
        ServiceType.Offline,
        offlineService?.data?.ServiceType?.Id,
      ) ||
      User?.HasLabTechServicePermissions(
        ServiceType.Offline,
        offlineService?.data?.ServiceType?.Id,
      )
    );
  }, [User, offlineService]);

  const isReadOnlyUser = React.useMemo(() => {
    return (
      User !== undefined &&
      (User.Roles.includes(Roles.CalendarOnly) ||
        User.Roles.includes(Roles.Readonly) ||
        User.Roles.includes(Roles.RoomOnly))
    );
  }, [User]);

  /// mount effect - init ///
  // useEffectOnMount(() => {
  //   setRequestCompleted(undefined);
  //   dispatch(actions.initDetails(queryParams, ));
  //   return () => {};
  // });
  React.useEffect(() => {
    let active = firstLoad;
    if (globalSettings.loadCompleted && active) {
      setFirstLoad(false);
      dispatch(
        actions.initDetails({
          query: queryParams,
          globalSettings: globalSettings,
          service: initialService,
          editCreatable: editCreatable,
        }),
      );
    }
    return () => {
      active = false;
    };
  }, [
    actions,
    dispatch,
    editCreatable,
    firstLoad,
    globalSettings,
    initialService,
    queryParams,
  ]);
  /// functions, callbacks ///
  // const closeCover = () => {
  //   setCover(undefined);
  // };
  // const handleClose = () => {
  //   if (hasChanges && !editCreatable) {
  //     dispatch(layoutActions.setConfirmOpen(true));
  //   } else {
  //     handleCloselClick();
  //   }
  // };
  const handleCloselClick = React.useCallback(() => {
    // dispatch(actions.resetDetailsState(editCreatable));
    // if (!!onClose) {
    //   onClose();
    // } else {
    //   if (useSidePanel) {
    //     dispatch(layoutActions.resetSidePanel());
    //   } else {
    //     dispatch(push('/otherServices'));
    //   }
    // }
    // if (!editCreatable) {
    //   dispatch(connectedFiltersActions.resetConnectedState());
    // }
    if (useSidePanel) {
      closePanel({
        isCover: isCover,
        useSidePanel: useSidePanel,
        showConfirm: hasChanges && !editCreatable,
        onClose: () => {
          dispatch(actions.resetDetailsState(editCreatable));
          if (!editCreatable) {
            dispatch(connectedFiltersActions.resetConnectedState());
          }
        },
      } as SidePanelCloseState);
    } else {
      dispatch(push('/otherServices'));
    }
  }, [
    actions,
    closePanel,
    connectedFiltersActions,
    dispatch,
    editCreatable,
    hasChanges,
    isCover,
    useSidePanel,
  ]);
  const handleSaveClick = React.useCallback(
    (e: any) => {
      if (editCreatable && !!initialService) {
        if (
          !!innerFormRef.current?.errors &&
          !!innerFormRef.current?.errors['Quantity']
        ) {
          dispatch(
            appSettingsActions.addNotification({
              variant: 'error',
              message: innerFormRef.current?.errors['Quantity'],
            }),
          );
          return;
        }
        dispatch(
          actions.setEditCreatableService(
            Object.assign({}, initialService, {
              Remarks: innerFormRef.current?.values.Remarks,
              Quantity: innerFormRef.current?.values.Quantity || null,
              InventoryBatchId:
                innerFormRef.current?.values.InventoryBatch?.Id || null,
              InventoryBatchName:
                innerFormRef.current?.values.InventoryBatch?.Name || null,
              InventoryBatchAmount:
                innerFormRef.current?.values.InventoryBatch?.Inventory || null,
            }),
          ),
        );
        handleCloselClick();
      } else {
        handleSubmitForm(e);
      }
    },
    [
      actions,
      appSettingsActions,
      dispatch,
      editCreatable,
      handleCloselClick,
      initialService,
    ],
  );

  const handleSubmitForm = (e: any) => {
    if (connectedFiltersFormRef.current) {
      connectedFiltersFormRef.current(e);
    }
    if (submitFormRef.current) {
      submitFormRef.current(e);
    }
  };
  const bindSubmitForm = React.useCallback(submitForm => {
    submitFormRef.current = submitForm;
  }, []);

  const handleSubmit = React.useCallback(
    (values: OtherServiceDetailsState) => {
      setBusy(true);
      if (edit) {
        dispatch(
          actions.updateService({
            current: values,
            original: offlineService.data || values,
            saveCreatable: saveCreatable,
          }),
        );
      } else {
        dispatch(
          actions.createService({
            model: values,
            saveCreatable: saveCreatable,
          }),
        );
      }
    },
    [actions, dispatch, edit, offlineService.data, saveCreatable],
  );
  const handleCalculateCredit = React.useCallback(() => {
    setShowCalculateCredit(true);
    dispatch(
      actions.getCredit(
        innerFormRef?.current?.values ||
          offlineService?.data ||
          ({} as OtherServiceDetailsState),
      ),
    );
  }, [actions, dispatch, offlineService?.data]);
  const handleCalculateClose = React.useCallback(() => {
    setShowCalculateCredit(false);
    dispatch(actions.resetCreditState());
  }, [actions, dispatch]);
  /// Use Effects ///
  React.useEffect(() => {
    let active = processing === false;
    if (active) {
      if (queryParams.id !== undefined && !isNaN(parseInt(queryParams.id))) {
        //setEdit(true);
      } else {
        //setEdit(false);
      }
      if (actionCompleted === true) {
        setRequestCompleted(true);
        setBusy(false);
        if (!hasError) {
          dispatch(layoutActions.setNotSavedChanges(false));
          handleCloselClick();
        }
      } else if (actionCompleted === false) {
        setRequestCompleted(undefined);
        setBusy(false);
      }
    }
    return () => {
      active = false;
    };
  }, [
    actionCompleted,
    dispatch,
    handleCloselClick,
    hasError,
    layoutActions,
    processing,
    queryParams.id,
  ]);
  // React.useEffect(() => {
  //   if (confirmApproved) {
  //     dispatch(actions.resetDetailsState());
  //     if (continueToOther.continueOnApprove) {
  //       dispatch(layoutActions.resetSidePanel());
  //       setTimeout(() => {
  //         dispatch(
  //           layoutActions.openSidePanel({
  //             type: continueToOther.pageType,
  //             props: continueToOther.pageProps,
  //             expanded: continueToOther.expanded,
  //           }),
  //         );
  //         dispatch(layoutActions.resetContinueState());
  //       }, 200);
  //     } else if (continueLink !== undefined) {
  //       dispatch(appSettingsActions.navigate(continueLink));
  //       dispatch(layoutActions.resetSidePanel());
  //     } else {
  //       dispatch(layoutActions.resetSidePanel());
  //     }
  //     //dispatch(actions.setChangedState(false));
  //   }
  //   if (confirmRejected) {
  //     dispatch(layoutActions.resetConfirmState());
  //   }

  //   return () => undefined;
  // }, [
  //   actions,
  //   appSettingsActions,
  //   confirmApproved,
  //   confirmRejected,
  //   continueLink,
  //   continueToOther,
  //   dispatch,
  //   edit,
  //   layoutActions,
  // ]);
  const onBatchClick = React.useCallback(
    (batch: IInventoryBatchDto | null, serviceType: number) => {
      let batchProps = {
        initialBatch: batch ?? undefined,
        queryParams: {
          id: '' + (batch?.Id ?? -1),
          stid: '' + serviceType,
        },
        saveCreatable: true,
        useSidePanel: true,
      };
      openPanel({
        renderPageType: RenderPageType.InventoryBatch,
        renderPageProps: batchProps,
        expanded: false,
        useSidePanel: useSidePanel,
        isCover: useSidePanel,
      } as SidePanelOpenState);
    },
    [openPanel, useSidePanel],
  );
  const leftActions = React.useMemo(() => {
    let actions: ActionRenderer[] = [];
    actions.push(() => (
      <Button
        size="small"
        startIcon={<Icon icon="save" />}
        onClick={handleSaveClick}
        disabled={busy}
        processing={!requestCompleted && busy}
      >
        {t(translations.Save)}
      </Button>
    ));
    if (globalSettings.showCalcPriceOnOfflineService && !edit) {
      actions.push(() => (
        <Button
          id={'CalculateCreditPopupButtonId'}
          variant="white"
          size="small"
          startIcon={<Icon icon="money-bill" />}
          title={t(translations.CalculatePricing_info) as string}
          onClick={handleCalculateCredit}
          disabled={creditProccessing}
          processing={creditProccessing}
        >
          {t(translations.CalculatePricing)}
        </Button>
      ));
    }
    return actions;
  }, [
    busy,
    creditProccessing,
    edit,
    globalSettings.showCalcPriceOnOfflineService,
    handleCalculateCredit,
    handleSaveClick,
    requestCompleted,
    t,
  ]);
  const rightActions = React.useMemo(() => {
    return [
      () => (
        <React.Fragment>
          <Button
            variant="ghost"
            size="small"
            startIcon={<Icon icon="times" />}
            onClick={handleCloselClick}
          >
            {t(translations.Close)}
          </Button>
        </React.Fragment>
      ),
    ] as ActionRenderer[];
  }, [handleCloselClick, t]);
  const topButtons: ActionRenderer[] = React.useMemo(() => {
    let actions: ActionRenderer[] = [];
    if (serviceSettings.chargesViewAllowed && edit && hasCharges) {
      actions.push(() => (
        <TopActionButton
          text={t(translations.OfflineServiceCharges)}
          icon="dollar-sign"
          startIcon="dollar-sign"
          size="small"
          onClick={() =>
            history.push(
              `/Charges?${toQueryString({
                refType: ReferenceType.OfflineService as number,
                refId: queryParams.id,
              }).toString()}`,
            )
          }
          variant="white"
          shape="square"
        />
      ));
    }
    return actions;
  }, [
    edit,
    hasCharges,
    history,
    queryParams.id,
    serviceSettings.chargesViewAllowed,
    t,
  ]);
  return (
    <React.Fragment>
      <PageWrapper
        pageName={
          edit
            ? t(translations.EditOfflineServiceTitle)
            : t(translations.AddNewOfflineService)
        }
        titlePage={
          offlineService?.data
            ? edit
              ? ` ${t(translations.ConsumableLog)} #${queryParams.id || 'N/A'}`
              : ` ${t(translations.ConsumableLog)}`
            : undefined
        }
        loading={processing}
        useSidePanel={useSidePanel}
        closable={useSidePanel || isCover}
        disableExpandToggle={true}
        closeSidePanel={handleCloselClick}
        // leftTopActions={topButtons}
        rightTopActions={topButtons}
        rightTopActionsAsMenu={true}
        //leftTopActions={topActions}
        //topProcessing={equipmentChangeCompleted === false}
        leftActions={isReadOnlyUser ? [] : leftActions}
        children={
          offlineService?.data !== undefined && !processing ? (
            <OfflineForm
              initialValues={
                offlineService?.data || ({} as OtherServiceDetailsState)
              }
              bindSubmitForm={bindSubmitForm}
              onSubmit={handleSubmit}
              isEdit={edit || false}
              connectedFiltersFormRef={connectedFiltersFormRef}
              isAdmin={IsAdmin || false}
              user={User}
              globalSettings={globalSettings}
              innerFormRef={innerFormRef}
              editCreatable={editCreatable}
              onBatchClick={onBatchClick}
            />
          ) : (
            <React.Fragment>
              {' '}
              <Box component="div">
                <Progress inProgress={processing} size={80} />
              </Box>
            </React.Fragment>
          )
        }
        pageLink={buildURL(publicUrl + 'otherServices/details', queryParams)}
        toPageLink={`otherServices/details?${toQueryString(queryParams)}`}
        rightActions={isReadOnlyUser ? [] : rightActions}
        confirm={
          showCalculateCredit ? (
            <DialogConfirm
              isOpen={showCalculateCredit}
              title={t(translations.CalculatePricing)}
              onConfirm={() => handleCalculateClose()}
              onClose={() => handleCalculateClose()}
              body={
                creditProccessing && !creditCompleted ? (
                  <Box width="100%">
                    <LinearProgress variant="query" color="primary" />
                  </Box>
                ) : (
                  <Box
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      paddingTop: '16px',
                      gap: 32,
                      width: '100%',
                    }}
                  >
                    <Box
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        gap: 16,
                        width: '100%',
                      }}
                    >
                      <ButtonLabel size="small">
                        {t(translations.Pricing_EstimatedCost)}
                      </ButtonLabel>
                      <Body size="small">
                        {(t(
                          edit
                            ? translations.PricingConfirmationOfflineEditText1
                            : translations.PricingConfirmationOfflineText1,
                        ) as string).replace(
                          '{0}',
                          (credit?.credit || 0).toFixed(2),
                        )}
                      </Body>
                    </Box>
                    {!!credit?.balance && credit?.balance !== null && (
                      <Box
                        style={{
                          display: 'flex',
                          flexDirection: 'row',
                          justifyContent: 'space-between',
                          gap: 16,
                          width: '100%',
                        }}
                      >
                        <ButtonLabel size="small">
                          {t(translations.Pricing_Balance)}
                        </ButtonLabel>
                        <Body size="small">
                          {(t(
                            translations.Pricing_BalanceResult,
                          ) as string).replace(
                            '{0}',
                            (credit?.balance || 0).toFixed(2),
                          )}
                        </Body>
                      </Box>
                    )}
                  </Box>
                )
              }
              maxWidth={'sm'}
            />
          ) : (
            <></>
          )
        }
        isCover={isCover}
        cover={cover}
        closeCover={!isCover ? onCloseCover : closeCover}
        coverClosed={coverClosed}
      />
      {hasChanges && (
        <Beforeunload onBeforeunload={() => 'Youll lose your data!'} />
      )}
    </React.Fragment>
  );
});
