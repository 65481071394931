import i18next, { TFunction } from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { chain } from 'lodash';
import { LOCALE_COOKIE_NAME } from 'utils/cookies';
import { IODataQueryResponse } from 'api/odata/IODataQueryResponse';
import { dateUtils, parseLocale } from 'utils/date-utils';
import { getLogger } from 'utils/logLevel';

// // Create the 'translations' object to provide full intellisense support for the static json files.
// convertLanguageJsonToObject(en);
const log = getLogger('i18next');
export const defaultNS = 'GRes';
export const publicNS = 'Public';
const debug: boolean | undefined =
  process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test';
// https://github.com/i18next/i18next-http-backend
const backendOptions = {
  // path where resources get loaded from, or a function
  // returning a path:
  // function(lngs, namespaces) { return customPath; }
  // the returned path will interpolate lng, ns if provided like giving a static path
  //
  // If allowMultiLoading is false, lngs and namespaces will have only one element each,
  // If allowMultiLoading is true, lngs and namespaces can have multiple elements
  loadPath: function (lngs, namespaces) {
    //[ODataRoute("ResourceTypes/{keyResourceType}/Locales/{keyLocaleId}")]
    return (
      process.env.PUBLIC_URL +
      '/api/odata/v4/' +
      'ResourceTypes/{{ns}}/Locales/{{lng}}/Resources?$select=Key,Value'
    );
  },
  // parse data after it has been fetched
  // i18next expects a dictionary while the path provides odata api endpoint
  parse: function (s: string): Record<string, string> {
    var data: IODataQueryResponse<ResourceDto> = JSON.parse(s);
    const result = chain(data.value)
      .keyBy(f => f.Key)
      .mapValues(f => f.Value)
      .value();

    return result;
  },
};

//#bkt_webApp.Api.V3.ResourceDto
interface ResourceDto {
  Key: string;
  Value: string;
}

// supported locales list should be pushed here from the server
// sets the defaults for debug purposes since this setting is populated on .net side and is not available on debug server
if (debug) {
  if (globalThis.supportedLocales === undefined) {
    globalThis.supportedLocales = ['en-US'];
  }
}

const detectionOptions = {
  lookupCookie: LOCALE_COOKIE_NAME,
  supportedLngs: globalThis.supportedLocales,
  convertDetectedLanguage: lng => {
    const { language } = parseLocale(lng);
    return language;
  },
};

export const i18n = i18next
  // use i18next-http-backend to fetch localization files from the server
  .use(Backend)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      // The whole Animals Kiosk must be localized to he
      // TODO: use the system/app setting to set initial locale here
      ...detectionOptions,
      detection: detectionOptions,
      fallbackLng: detectionOptions.supportedLngs?.[0],
      defaultNS: defaultNS,
      fallbackNS: defaultNS,
      load: 'currentOnly',
      ns: [publicNS],
      debug: debug,

      appendNamespaceToMissingKey: false,
      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
      },
      backend: backendOptions,
    },
    (error: any, t: TFunction) => {
      console.error(error);
    },
  );

// set the dateFns locale as soon as the language is detected by the i18n to prevent
// otherwise uninitialized dateFns can get undefined locale which can cause runtime error
i18next.on('languageChanged', function (lng) {
  log.debug('languageChanged', lng);
  dateUtils.setLocale(lng);
});

//https://www.i18next.com/overview/api#oninitialized
i18next.on('initialized', function (options) {
  log.debug('initialized', options);
});

/**
 * https://www.i18next.com/overview/api#onloaded
 */
i18next.on('loaded', function (loaded) {
  log.debug('loaded', loaded);
});
