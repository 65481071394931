import { Comments, CommentsProps } from 'app/components/Comments';
import {
  selectCommentIsInternal,
  selectIsServiceRequestAdmin,
} from 'app/pages/Samples/RequestSamplesPage/slice/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { GetServiceRequestDetailsPath } from 'app/pages/Samples/RequestSamplesPage/slice/path';
import { IServiceRequestRelated } from '../..';
import styled from 'styled-components';
import { useFormikContext } from 'formik';
import { IServiceRequestTableRowModel } from 'app/pages/Samples/RequestSamplesPage/slice/types';
import { getServiceRequestRowName } from '../../../ServiceRequestRowEditableCell';
import { Row } from 'react-table';
import { useRequestSamplesSlice } from 'app/pages/Samples/RequestSamplesPage/slice';
import { useUploadToNewComment } from 'app/components/LargeFilesUpload/Components/useUploadToNewComment';
import { ServiceRequestsApi } from 'api/ServiceRequestsApi';

export interface ServiceRequestRowCommentsProps
  extends Pick<CommentsProps, 'referenceId' | 'commentType'>,
    IServiceRequestRelated {
  row?: Row<IServiceRequestTableRowModel>;
  canUserEdit?: Boolean;
}
const CommentsWrap = styled('div')`
  padding: 16px;
`;
export function ServiceRequestRowComments({
  serviceRequestId,
  canUserEdit,
  ...props
}: ServiceRequestRowCommentsProps) {
  const { isAdmin } = useSelector(selectIsServiceRequestAdmin);
  const CommentIsInternal = useSelector(selectCommentIsInternal);
  const formik = useFormikContext<IServiceRequestTableRowModel>();
  const dispatch = useDispatch();
  const { actions } = useRequestSamplesSlice();
  const { onCommentAdded } = useUploadToNewComment();
  const handleCommentAdded = newComment => {
    if (props.row !== undefined) {
      formik.setFieldValue(
        getServiceRequestRowName(props.row.index, 'CommentsCount'),
        props.row.original.CommentsCount + 1,
        false,
      );
    }
    ServiceRequestsApi.updateAdminOnComment(serviceRequestId);
    dispatch(actions.setHasFiles(true));
    onCommentAdded(newComment);
  };
  return (
    <CommentsWrap>
      <Comments
        defaultComment={{
          IsInternal: isAdmin && CommentIsInternal,
        }}
        emailType="RequestComment"
        link={GetServiceRequestDetailsPath(serviceRequestId)}
        isAdmin={isAdmin}
        cannotComment={(!!CommentIsInternal || !canUserEdit) && !isAdmin}
        onCommentAdded={handleCommentAdded}
        {...props}
      />
    </CommentsWrap>
  );
}
