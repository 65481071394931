import * as React from 'react';
import { DialogConfirm } from 'app/components/DialogConfirm';
import { translations } from 'locales/translations';
import { useEffect, useMemo, useState } from 'react';
import { Entity } from 'types/common';
import { useTranslation } from 'react-i18next';
import { Button } from 'app/components/BasicButtons/Button';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import {
  FormControl,
  FormGroup,
  Grid,
  FormHelperText,
} from '@material-ui/core';
import { TextControlField } from 'app/components/BasicInputs/TextControlField';
import * as yup from 'yup';
import { EntityNumberSchema } from '../Forms/Schemas';
import { Form, Formik } from 'formik';
import { FormRow } from '../Forms/FormsLayout';
import { FormOfflineServiceTypePicker } from '../Forms/FormOfflineServiceTypePicker';
import { FormNumberField } from '../Forms/FormNumberField';
import { UsageRelatedFilter } from '../pickers/AutocompletePickers/OfflineServiceTypePicker';
import { Check } from '../BasicInputs/Check';
import { SkeletonIfLoading } from 'app/Layout/FrontendLayout/components/PageWrapper/components/SkeletonIfLoading';
import { httpClient } from 'api/HttpClient';
import { odataUrlPrefix } from 'utils/url-utils';
import { IOfflineServiceFilterDto } from 'types/IOfflineServiceFilterDto';
// import { Body } from '../Typography';
import FormControlLabel from '../Forms/FormControls/FormControlLabel';
import { Body } from '../Typography';
import styled from 'styled-components';
import { bookitColors } from 'styles/colors/bookitColors';

interface OffService {
  quantity: number | null;
  service: Entity<number> | null;
}

export interface EndUsageData {
  services: OffService[];
  remarks: string | undefined;
  confirm: boolean;
}

export interface EndUsageConfirmProps {
  isOpen: boolean;
  onClose: () => void;
  ids?: number[];
  onConfirm: (data: EndUsageData) => void;
  usageProcessing?: boolean;
  message?: string;
  equipmentId?: number;
}
const defaultOffService: OffService = {
  quantity: null,
  service: null,
};

let chosenService: Entity<number> | null = null;

const serviceSchema: yup.SchemaOf<OffService> = yup.object({
  quantity: yup.number().min(0).nullable().default(null).required(),
  service: EntityNumberSchema.required().nullable(),
});

const ServiceInput = ({
  addService,
  ids,
  selected,
  offService,
  onCurrentChange,
}: {
  ids: number[];
  selected: number[];
  addService: (service: OffService) => void;
  offService: OffService;
  onCurrentChange?: (service: OffService) => void;
}) => {
  const { t } = useTranslation();

  const AddService = (service: OffService) => {
    addService(service);
  };
  return (
    <Formik
      initialValues={{ ...offService, service: chosenService }}
      validationSchema={serviceSchema}
      validateOnMount
      onSubmit={AddService}
    >
      {formik => {
        return (
          <Form onSubmit={formik.handleSubmit}>
            <FormRow>
              <FormOfflineServiceTypePicker
                predicates={UsageRelatedFilter(ids, selected)}
                urlType="base"
                fullWidth
                label={t(translations.UsageEndOfflineService)}
                placeholder={t(
                  translations.PleaseSelectOptionalOfflineServiceType,
                )}
                info={t(translations.UsageEndOfflineService_info)}
                name="service"
                onChange={s => {
                  chosenService = s;
                  const service = {
                    quantity:
                      s === null
                        ? null
                        : (s as IOfflineServiceFilterDto).DefaultQuantity,
                    service: s,
                  } as OffService;
                  onCurrentChange?.(service);
                }}
              />
              <FormNumberField
                label={t(translations.Quantity)}
                name="quantity"
                onChange={e => {
                  const val = parseInt(e.target.value);
                  const service = {
                    ...offService,
                    quantity: isNaN(val) ? null : val,
                  } as OffService;
                  onCurrentChange?.(service);
                }}
              />
              <Button disabled={!formik.isValid} type="submit">
                {t(translations.AddOfflineButton)}
              </Button>
            </FormRow>
          </Form>
        );
      }}
    </Formik>
  );
};

export const EndUsageConfirm = ({
  isOpen: isopen,
  onClose,
  onConfirm,
  usageProcessing,
  message,
  equipmentId,
  ...props
}: EndUsageConfirmProps) => {
  const [selectedServices, setSelectedServices] = useState<OffService[]>([]);
  const [remarks, setRemarks] = useState<string | undefined>();
  const [confirm, setConfirm] = useState(false);
  const [ids, setIds] = useState(props.ids);
  const [loading, setLoading] = useState(false);
  const [offService, setOffService] = useState<OffService>(defaultOffService);
  const [error, setError] = useState<string | undefined>();
  useEffect(() => {
    if (!!ids || !equipmentId || !isopen) return;
    let mounted = true;
    setLoading(true);
    (async () => {
      try {
        const res = await httpClient.get(
          `${odataUrlPrefix}AssetDetails/GetEquipmentServices`,
          { equipmentId },
        );
        if (mounted) {
          setIds(res.value);
          setLoading(false);
        }
      } catch (error) {
      } finally {
        if (mounted) {
          setLoading(false);
        }
      }
    })();
    return () => {
      mounted = false;
    };
  }, [ids, equipmentId, isopen]);
  const { t } = useTranslation();

  const RemoveService = (id: number) => {
    let option = selectedServices.find(v => v.service?.Id === id);
    if (option) {
      setSelectedServices([
        ...selectedServices.filter(v => v.service?.Id !== id),
      ]);
    }
  };

  const totalServices = useMemo(() => {
    return serviceSchema.isValidSync(offService)
      ? [...selectedServices, offService]
      : selectedServices;
  }, [offService, selectedServices]);
  return (
    <DialogConfirm
      title={t(translations.ConfirmEndUsage_title)}
      isOpen={isopen}
      onCancel={onClose}
      onClose={onClose}
      processing={usageProcessing}
      maxWidth="sm"
      body={
        <SkeletonIfLoading loading={loading}>
          <>
            {ids && ids.length > 0 ? (
              <EndUsageRoot className="end-usage-root">
                <p>{t(translations.EndUsageConfirmTitle)}</p>
                {/* <InputLabel size="xs">
              {t(translations.UsageEndOfflineService) + ' '}
              <Icon
              icon="info-circle"
              title={t(translations.UsageEndOfflineService_info)}
              />
            </InputLabel> */}
                <ServiceInput
                  selected={selectedServices.map(s => s.service!.Id)}
                  ids={ids}
                  addService={s => {
                    chosenService = null;
                    setSelectedServices(prev => [...prev, s]);
                    setOffService(defaultOffService);
                  }}
                  onCurrentChange={s => {
                    console.log('current changed', s);
                    setOffService(s);
                  }}
                  offService={offService}
                />
                <Grid container direction="column">
                  {selectedServices.map((s, i) => (
                    <Grid
                      container
                      direction="row"
                      spacing={2}
                      wrap="nowrap"
                      key={i}
                    >
                      <Grid className={'end-usage-item'} item xs={8}>
                        {s.service?.Name}
                      </Grid>
                      <Grid className={'end-usage-item'} item xs={3}>
                        {s.quantity}
                      </Grid>
                      <Grid item xs={2}>
                        <Button
                          color="default"
                          onClick={() => RemoveService(s.service!.Id)}
                        >
                          <Icon icon="trash" />
                        </Button>
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
                <FormRow>
                  <FormControl>
                    <FormGroup>
                      <FormControlLabel
                        label={t(translations.ConfirmServicesCorrect)}
                        control={
                          <Check
                            color={'primary'}
                            onChange={value => {
                              setConfirm(value.target.checked);
                            }}
                            checked={confirm}
                          />
                        }
                      />
                    </FormGroup>
                  </FormControl>
                </FormRow>
                <TextControlField
                  placeholder={
                    t(
                      translations.NotUsedOfflineServicesPleaseDetailHere_Usage,
                    ) as string
                  }
                  variant="filled"
                  fullWidth
                  autoFocus
                  value={remarks}
                  onChange={v => setRemarks(v.target.value)}
                />
                {error && <FormHelperText error>{error}</FormHelperText>}
              </EndUsageRoot>
            ) : (
              <Body>{message ?? t(translations.ConfirmEndUsage)}</Body>
            )}
          </>
        </SkeletonIfLoading>
      }
      disabled={usageProcessing}
      onConfirm={() => {
        if (ids && ids.length > 0) {
          if ((!remarks || remarks === '') && totalServices.length === 0) {
            setError(t(translations.ConfirmEndUsage_err) as string);
            return;
          }
          if (!confirm) {
            setError(
              t(translations.ConfirmEndUsageServiceNotConfirmed_err) as string,
            );
            return;
          }
        }
        onConfirm({ services: totalServices, remarks: remarks, confirm });
      }}
    />
  );
};
const EndUsageRoot = styled.div`
  &.end-usage-root {
    display: flex;
    flex-direction: column;
    gap: 16px;
    & .end-usage-item {
      border: 1px solid ${bookitColors.grayscale.grayBorders};
      border-radius: 5px;
      gap: 10%;
      padding: 10px 0px;
      display: flex;
      justify-content: space-around;
    }
  }
`;
