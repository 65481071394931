import { useAsyncExtendedState } from 'app/hooks/useAsyncAwaitedState';
import { usePromise } from 'app/hooks/usePromise';
import { useAppSettingsSlice } from 'app/slice';
import {
  selectAuthenticatedUser,
  selectIsAuthenticated,
  selectPublicUrl,
} from 'app/slice/selectors';
import { ConfigurableTypes, ScreensId } from 'enums/ConfigurableTypes';
import { translations } from 'locales/translations';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { Column } from 'react-table';
import { Notification } from 'types/Notification';
import { toRootedURL } from 'utils/url-utils';
import { IRow } from '.';
import {
  getTableColumns,
  GetCachedConfigurableColumnsData,
} from './ConfigurableColumns';

export interface IUseConfigurableColumnsProps<TRow extends IRow> {
  columns: Array<Column<TRow>>;
  /**
   * Id of the screen that table related to. Required!
   */
  screenId?: ScreensId;
  ns?: string;
}
export const useConfigurableColumns = <TRow extends IRow>({
  screenId,
  columns,
  ns,
}: IUseConfigurableColumnsProps<TRow>) => {
  // Configurable columns
  // columns will be replaced with configurableColumns

  const { t } = useTranslation(ns);
  const isAuthenticated = useSelector(selectIsAuthenticated);
  const AuthenticatedUser = useSelector(selectAuthenticatedUser);
  const publicUrl = useSelector(selectPublicUrl);
  const dispatch = useDispatch();
  const { actions: globalActions } = useAppSettingsSlice();
  const setGlobalMessage = React.useCallback(
    (message: Notification) => {
      dispatch(globalActions.addNotification(message));
    },
    [dispatch, globalActions],
  );
  const [configurableColumns, setConfigurableColumns] = useAsyncExtendedState<
    Column<TRow>[] | undefined
  >(screenId === undefined ? columns : undefined);

  const loadConfigurableColumns = React.useCallback(
    async (props: {
      screenId: ScreensId;
      columns: Array<Column<TRow>>;
      isAuthenticated?: boolean;
      force?: boolean;
    }) => {
      try {
        const config = await GetCachedConfigurableColumnsData(
          props.screenId,
          ConfigurableTypes.ColumnScreenConfig,

          isAuthenticated,
          props.force,
        );

        return getTableColumns({
          response: config,
          ConfigurableColumnsSetUp: columns,
        });
      } catch (error) {
        setGlobalMessage({
          key: 'WrongColumnDefinition',
          message: t(translations.WrongColumnDefinition_info) as string,
          variant: 'warning',
          autoHideDuration: 30000,
          closable: true,
        });
        return getTableColumns({
          response: [],
          ConfigurableColumnsSetUp: columns,
        });
      }
    },
    [columns, isAuthenticated, setGlobalMessage, t],
  );
  const [fetchConfigState, getConfigurableColumns] = usePromise(
    loadConfigurableColumns,
  );

  const [
    configurableColumnsExport,
    setConfigurableColumnsExport,
  ] = useAsyncExtendedState<Array<keyof TRow> | undefined>(undefined);
  const loadConfigurableColumnsExport = React.useCallback(
    async (listId: ScreensId, isAuthenticated: boolean | undefined) => {
      const d = await GetCachedConfigurableColumnsData(
        listId,
        ConfigurableTypes.ColumnExportConfig,
        isAuthenticated,
        false,
      );
      const p = d.map(f => f.Id);
      return p;
    },
    [],
  );
  const [, fetchExport] = usePromise(loadConfigurableColumnsExport);

  // useEffect will only be called once because columns and screenId do not change, it is a trick
  React.useEffect(() => {
    if (
      screenId !== undefined &&
      fetchConfigState.status !== 'pending' &&
      fetchConfigState.status !== 'resolved'
    ) {
      setConfigurableColumns(getConfigurableColumns({ screenId, columns }));
    }
    if (screenId !== undefined) {
      setConfigurableColumnsExport(fetchExport(screenId, isAuthenticated));
    }
  }, [
    columns,
    fetchConfigState.status,
    fetchExport,
    getConfigurableColumns,
    isAuthenticated,
    screenId,
    setConfigurableColumns,
    setConfigurableColumnsExport,
  ]);

  const refreshConfigurableColumns = React.useCallback(() => {
    if (screenId !== undefined) {
      setConfigurableColumns(
        loadConfigurableColumns({
          screenId,
          columns,
          force: true,
          isAuthenticated,
        }),
      );
    }
  }, [
    screenId,
    setConfigurableColumns,
    loadConfigurableColumns,
    columns,
    isAuthenticated,
  ]);

  const handleConfigurableColumns = React.useCallback(() => {
    if (screenId && AuthenticatedUser) {
      let targetUrl = '/ConfigurableColumn.aspx';
      // eslint-disable-next-line no-restricted-globals
      let path = location.pathname.split('/');
      // eslint-disable-next-line no-restricted-globals
      const search = location.search;

      let publicSub = publicUrl?.split('/');
      path.shift();
      if (path.length > 1) {
        if (
          !!publicSub &&
          (publicSub[publicSub.length - 1] === ''
            ? publicSub[publicSub.length - 2]
            : publicSub[publicSub.length - 1]) === path[0]
        ) {
          path.shift();
        }
      }
      let returnUrl = '~/' + path.join('/') + search;
      let redirUrl = toRootedURL(targetUrl, {
        id: screenId,
        UserName: AuthenticatedUser.Id,
        ReturnUrl: returnUrl,
      });
      // eslint-disable-next-line no-restricted-globals
      window.open(redirUrl, '_blank'); // + returnUrl;
    }
  }, [AuthenticatedUser, publicUrl, screenId]);

  // The configCompleted variable is used to show the loading indicator while the configurable columns data is being loaded
  // There's nothing to complete when there's no screenId and this variable does not need a separate state as it's a derivative of the fetch status.
  const configCompleted = React.useMemo(() => {
    if (screenId === undefined) {
      return true;
    } else {
      return fetchConfigState.status === 'resolved';
    }
  }, [fetchConfigState.status, screenId]);
  return {
    configurableColumns,
    configurableColumnsExport,
    handleConfigurableColumns,
    configCompleted,
    refreshConfigurableColumns,
  };
};
