import { IReservationHistoryDto } from 'api/odata/generated/entities/IReservationHistoryDto';
import { BasicTable, BasicTableProps } from 'app/components/BasicTable';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';

export function ReservationHistoryTable<
  TProps extends Omit<
    BasicTableProps<IReservationHistoryDto>,
    | 'api'
    | 'additionalColumns'
    | 'searchColumns'
    | 'screenName'
    | 'expandedColumns'
  >
>({
  initialState,
  rowActions,
  serviceGroups,
  savedListSettings,
  ...props
}: TProps) {
  const { t } = useTranslation();
  const newProps = React.useMemo(() => {
    const p = { ...props };
    Reflect.deleteProperty(p, 'api');
    return p;
  }, [props]);
  const url = '/api/odata/v4/ReservationHistory';
  return (
    <>
      <BasicTable
        api={url}
        screenName={t(translations.menu_ReservationsHistory)}
        useExport={true}
        initialState={initialState}
        useRowSelect={true}
        rowActions={rowActions}
        additionalColumns={[
          'Id',
          'AssetId',
          'ServiceGroupId',
          'LastUpdateAdminId',
          'InsertedById',
          'AvailabilityTypeId',
          'SessionId',
          'AssetCatId',
          'AssetGroupId',
          'BudgetId',
          'BudgetExperimentId',
          'BookedById',
          'BudgetExperimentId',
          'EquipmentId',
          'InstituteTypeId',
          'DepartmentId',
          'InstituteId',
          'AssetCampusId',
          'StaffUserId',
          'AssetRoomId',
          'AssetBuildingId',
          'UserDisciplineId',
          'TopAssemblyReservationId',
        ]}
        searchColumns={[
          'cast(Id,Edm.String)',
          'EquipmentName',
          'BudgetNumber',
          'Remarks',
          'AccService',
          'BookedBy',
          'ServiceGroup',
          'AvailabilityType',
        ]}
        serviceGroups={serviceGroups}
        importLink="/ReservationImport.aspx"
        savedListSettings={{
          enableSavedLists: true,
          savedListRelatedPickerName: 'EquipmentId',
          savedListSerializedKey: 'eid',
        }}
        {...(newProps as TProps)}
      />
    </>
  );
}
