import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { useLayoutSlice } from 'app/Layout/FrontendLayout/slice';
import { IconButton } from 'app/components/BasicButtons/IconButton';
import { Icon } from 'app/components/BasicIcons/FontAwesome';
import { IBasicTableState, IRow } from 'app/components/BasicTable';
import { PageActionRenderer } from 'app/components/BasicTable/PageActions/PageActionsRenderer';
import { FormSectionTitle } from 'app/components/Forms/FormsLayout/FormSectionTitle';
import { GetFilters as getHistoryFilters } from 'app/pages/ReservationHistoryPage/Filter';
import { getColumns } from 'app/pages/ReservationHistoryPage/Columns';
import { translations } from 'locales/translations';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Column } from 'react-table';
import {
  selectAuthenticatedUser,
  selectglobalSettings,
} from 'app/slice/selectors';
import { FilterValueType } from 'app/components/BasicTable/BasicFilter/FilterValueType';
import { GettableCount } from 'app/components/BasicTable/components/GetTableCount';
import { PrimaryAction } from 'app/components/BasicTable/components/TableCard/PrimaryAction';
import { SidePanelOpenState } from 'app/hooks/useSidePanelOpen';
import { IReservationHistoryDto } from 'api/odata/generated/entities/IReservationHistoryDto';
import {
  BaseFilterValues,
  useFilters,
} from 'app/pages/AssetPopUp/components/TableFilters';
import { GetActions } from 'app/pages/ReservationHistoryPage/Actions/GetActions';
import { ReservationHistoryTable } from 'app/pages/ReservationHistoryPage/ReservationHistoryTable';
import reservationsPrimaryAction from 'app/pages/ReservationHistoryPage/PrimaryAction';
import { AppSettings } from 'types/AppSettings';

const UserReservationHistoryColumns: string[] = [
  'Id',
  'EquipmentName',
  'StartTime',
  'EndTime',
  'Status',
  'NumberOfUsages',
  'UsageStartTime',
  'UsageEndTime',
  'OngoingUsage',
];
const url = '/api/odata/v4/ReservationHistory';
const GetPredicates = (
  userName: string,
  appSettings?: AppSettings,
): Array<Condition<IReservationHistoryDto> | string> => {
  const res: Array<Condition<IReservationHistoryDto> | string> = [
    new Condition<IReservationHistoryDto>(
      'BookedById',
      ODataOperators.Equals,
      userName,
    ),
  ];
  if (!!appSettings) {
    const now = new Date(); // getOffsetDate(appSettings, new Date());
    res.push(
      '(' +
        new Condition(
          'StartTime',
          ODataOperators.LessThanEqual,
          now,
        ).toString() +
        ' and ' +
        new Condition('EndTime', ODataOperators.GreaterThan, now).toString() +
        ')',
    );
  }
  return res;
};
class FilterValues extends BaseFilterValues {
  upcomingReservations: FilterValueType | null = [{ Id: 2 }];
}
const filterValues = new FilterValues();
export interface UserReservationHistoryTableProps {
  userName: string;
  isMobile?: boolean;
  useSidePanel?: boolean;
  openPanel: (state: SidePanelOpenState) => void;
  updateTableCounters: (name: string, count: number) => void;
}
export async function GetReservationHistoryTableCount(
  userName: string,
  name: string,
  updateCounters: (name: string, count: number) => void,
  appSettings?: AppSettings,
) {
  const res = await GettableCount({
    api: url,
    predicates: GetPredicates(userName, appSettings) as Array<
      Condition<IRow> | string
    >,
    columns: ['Id'],
    globalFilterValue: '',
    customFilter: [],
    serviceGroups: [],
    isOptionalServiceGroup: true,
  });
  updateCounters(name, res);
}
export function UserReservationHistoryTable(
  props: UserReservationHistoryTableProps,
) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const user = useSelector(selectAuthenticatedUser);
  const history = useHistory();
  const { actions } = useLayoutSlice();
  const settings = useSelector(selectglobalSettings);
  const { userName, isMobile, useSidePanel, openPanel } = props;
  const {
    allfilters,
    appliedFilters,
    handleFilterChange,
    getFilterValue,
    setFilterValue,
  } = useFilters(
    getHistoryFilters,
    filterValues.GetFilter(),
    filterValues.SetValue,
  );
  const historyColumns = React.useMemo(() => getColumns(t, user, settings), [
    settings,
    t,
    user,
  ]);
  const columns = React.useMemo(() => {
    let res: Column<IReservationHistoryDto>[] = [];
    for (const c of UserReservationHistoryColumns) {
      const column = historyColumns.find(f => f.accessor === c);
      if (column !== undefined) {
        res.push(column);
      }
    }
    return res;
  }, [historyColumns]);
  const PageActions: PageActionRenderer[] = GetActions({ shortView: isMobile });
  const goToReservationHistory = React.useCallback(() => {
    history.push(`reservations?un=${userName}`);
    dispatch(actions.toggleSidePanel(false));
  }, [actions, dispatch, history, userName]);
  const customScreenName: React.ReactNode = React.useMemo(() => {
    const result = (
      <>
        <FormSectionTitle title={t(translations.Reservations)} />
        <IconButton
          aria-label="link"
          title={t(translations.ExpandInReservationHistory)}
          onClick={goToReservationHistory}
        >
          <Icon icon="arrow-up-right-from-square" />
        </IconButton>
      </>
    );
    return result;
  }, [goToReservationHistory, t]);

  const primaryAction: PrimaryAction<any> = React.useCallback(
    (...args) => {
      reservationsPrimaryAction(
        openPanel,
        args[0],
        args[1],
        args[2],
        useSidePanel,
      );
    },
    [openPanel, useSidePanel],
  );
  const initialState: IBasicTableState<IReservationHistoryDto> = React.useMemo(
    () => ({
      sortBy: [{ id: 'StartTime', desc: false }],
    }),
    [],
  );
  return (
    <>
      <ReservationHistoryTable
        columns={columns}
        initialState={initialState}
        predicates={GetPredicates(userName)}
        availableFilters={allfilters}
        appliedFilters={appliedFilters}
        onFilterChange={handleFilterChange}
        getFilterValue={getFilterValue}
        setFilterValue={setFilterValue}
        useConfigurable={false}
        useCardsByDefault={isMobile}
        allowCards={isMobile}
        pageActions={PageActions}
        useExport={false}
        serviceGroups={[]}
        customScreenName={customScreenName}
        hideMenuOnMobile={true}
        screenNameVariant="section"
        tablePaddingVariant={'dense'}
        compactTable={isMobile}
        primaryAction={primaryAction}
        onTableCountChange={count =>
          props.updateTableCounters('Reservations', count)
        }
      />
    </>
  );
}
