import { translations } from 'locales/translations';
import { IFilterSettings } from 'app/components/BasicTable/BasicFilter/IFilterSettings';
import { IReservationHistoryDto } from 'api/odata/generated/entities/IReservationHistoryDto';
import { Condition, ODataOperators } from 'api/odata/ODataFilter';
import { getBudgetsFilterSettings } from 'app/components/BasicTable/Filters/ProjectFilter/getBudgetsFilterSettings';
import { AssetCatGroupFilter } from 'app/components/BasicTable/Filters/AssetCatGroupFilter';
import { DatesRangeFilter } from 'app/components/BasicTable/Filters/DatesRangeFilter';
import { InstituteFilter } from 'app/components/BasicTable/Filters/InstituteFilter';
import { AssetCatFilter } from 'app/components/BasicTable/Filters/AssetCatFilter';
import { CampusFilter } from 'app/components/BasicTable/Filters/CampusFilter';
import { PresetDatesRange } from 'types/PresetDatesRange';
import { getPeriodTypesEntity } from 'app/components/DatePeriods';
import { BooleanPickerFilter } from 'app/components/BasicTable/Filters/BooleanPickerFilter';
import {
  formatQueryStringDateValue,
  GetBooleanEntity,
  GetBooleanOrUndefined,
  GetDateOrUndefined,
  getDefaultFilterDatePeriod,
  GetNumberOrUndefined,
  GetPartialEntity,
  GetRawValue,
  presetDatesRangeToQueryString,
  initPickerValue,
  URLSearchParamsCI,
  GetStringOrUndefined,
  arrayToQueryString,
  getNumberEntityArray,
  getDefaultFilterNumberPeriod,
  NumebersRangeToQueryString,
  initMultiPickerValue,
  initMultiExPickerValue,
  initExPickerValue,
  getStringEntityArray,
} from 'app/components/BasicTable/types/FilterParam';
import { initAssetCatGroupData } from 'app/components/pickers/AutocompletePickers/AssetCatGroupPicker';
import { initAssetCatData } from 'app/components/pickers/AutocompletePickers/AssetCatPicker';
import { initInstituteData } from 'app/components/pickers/AutocompletePickers/InstitutePicker';
import { initCampusData } from 'app/components/pickers/AutocompletePickers/CampusPicker';
import { UserFilter } from 'app/components/BasicTable/Filters/UserFilter';
import { initUserData } from 'app/components/pickers/AutocompletePickers/UserPicker';
import { CustomDate } from 'types/CustomDate';
import {
  AppSettings,
  IsAssetFieldEnabled,
  IsModuleEnabled,
} from 'types/AppSettings';
import { KnownModules } from 'types/KnownModules';
import { initReservationStatusMultiData } from 'app/components/pickers/AutocompletePickers/ResrevationStatusMultiPicker';
import ResrvationStatusMultiFilter from 'app/components/BasicTable/Filters/ResrvationStatusMultiFilter';
import ServiceGroupFilter from 'app/components/BasicTable/Filters/ServiceGroupFilter';
import NumbersRangeFilter from 'app/components/BasicTable/Filters/NumbersRangeFilter';
import DepartmentFilter from 'app/components/BasicTable/Filters/DepartmentFilter';
import { initDepartmentData } from 'app/components/pickers/AutocompletePickers/DepartmentPicker';
import { initInstituteTypeData } from 'app/components/pickers/AutocompletePickers/InstituteTypePicker';
import {
  DatePickerFilter,
  TextFieldFilter,
} from 'app/components/BasicTable/Filters';
import { initRoomData } from 'app/components/pickers/AutocompletePickers/RoomPicker';
import RoomPickerFilter from 'app/components/BasicTable/Filters/RoomPickerFilter';
import { initBuildingData } from 'app/components/pickers/AutocompletePickers/BuildingPicker';
import UserDisciplineFilter from 'app/components/BasicTable/Filters/UserDisciplineFilter';
import { initUserDisciplineData } from 'app/components/pickers/AutocompletePickers/UserDisciplinePicker';
import { AssetAttributes } from 'enums/AssetAttributes';
import { initAvailabilityTypeData } from 'app/components/pickers/AutocompletePickers/AvailabilityTypePicker';
import { AvailabilityTypeBookableFilter } from 'app/components/BasicTable/Filters/AvailabilityTypeBookableFilter';
import { GetPageFilters } from 'app/components/BasicTable/BasicFilter/GetPageFilters';
import {
  initOnlineServicesData,
  ServiceUrl,
} from 'app/components/pickers/MultiSelectPickers/OnlineServicesPicker';
import {
  ServiceAdminFilter,
  ServiceMultiFilter,
  ServiceReservationFilter,
} from 'app/components/BasicTable/Filters/ServiceMultiFilter';
import { TFunction } from 'i18next';
import {
  getInitUpcomingReservationfilterMultiData,
  UpcomingReservationfilter,
} from 'app/components/BasicTable/Filters/UpcomingReservationfilter';
import { isArray } from 'lodash';
import { IsEntity } from 'app/components/BasicTable/BasicFilter/AppliedFilterComponent';
import { Entity, Identifiable } from 'types/common';
import { initWorkflowBookingData } from 'app/components/pickers/AutocompletePickers/WorkflowBookingPicker';
import WorkflowBookingFilter from 'app/components/BasicTable/Filters/WorkflowBookingFilter';
import { AllowedSettings, GlobalSettings } from 'utils/globalSettings';
import NumberFieldFilter from 'app/components/BasicTable/Filters/NumberFieldFilter';
import InstituteTypeFilter from 'app/components/BasicTable/Filters/InstituteTypeFilter';
import { BuildingPickerFilter } from 'app/components/BasicTable/Filters/BuildingPickerFilter';
import { initServiceGroupsData } from 'app/components/pickers/ServiceGroupPicker';
import ReservationByIdWithPartsFilter from 'app/components/BasicTable/Filters/ReservationByIdWithPartsFilter';
import { InstrumentsFilterDisplay } from 'app/components/BasicTable/BasicFilter/FilterDisplay/InstrumentsFilterDisplay';
import { initReservationByIdWithPartsData } from 'app/components/pickers/AutocompletePickers/ReservationByIdWithPartsPicker';
import { IReservationByIdFilterDto } from 'api/odata/generated/entities/IReservationByIdFilterDto';
import { UserGroupMultiFilter } from 'app/components/BasicTable/Filters/UserGroupMultiFilter';
import { initUserGroupMultiData } from 'app/components/pickers/AutocompletePickers/UserGroupMultiPicker';
import { AssemblyTypeShortMultiFilter } from 'app/components/BasicTable/Filters/AssemblyTypeShortMultiFilter';
import { initAssemblyTypeShortData } from 'app/components/pickers/StaticOptionsPickers/AssemblyTypeshortMultiPicker';

export const GetFilters: GetPageFilters<IReservationHistoryDto> = (
  t,
  appSettings,
  user,
  settings,
) => search => {
  const params = new URLSearchParamsCI(search);
  var result: IFilterSettings<IReservationHistoryDto>[] = [];
  result.push({
    id: 'period',
    fieldName: 'StartTime',
    fieldName1: 'EndTime',
    displayNameKey: translations.RG_DatePeriod,
    operator: ODataOperators.Between,
    defaultValue: PresetDatesRange.fromPreset(
      getPeriodTypesEntity('ThisMonth'),
    ),
    value: getDefaultFilterDatePeriod('period', 'st', 'et', params),
    queryStringSerializer: presetDatesRangeToQueryString('period', 'st', 'et'),
    component: DatesRangeFilter,
    notNullable: true,
    urlKey: 'period',
  });
  pushServicesFilter(result, t, params, true);
  result.push({
    fieldName: 'StatusId',
    displayNameKey: translations.Status,
    operator: ODataOperators.Includes,
    queryStringSerializer: arrayToQueryString('stid'),
    value: getNumberEntityArray(params, 'stid'),
    initData: initMultiPickerValue(initReservationStatusMultiData(t)),
    component: ResrvationStatusMultiFilter,
    urlKey: 'stid',
  });
  pushBookedByFilter(result, t, params);
  const budgetsFilter = getBudgetsFilterSettings<IReservationHistoryDto>(
    params,
    'BudgetId',
    appSettings,
  );
  if (budgetsFilter !== undefined) {
    result.push(budgetsFilter);
  }
  pushAssetCatGroupFilter(result, t, params, true);
  pushAssetCatFilter(result, t, params, true);
  pushAssetAvailabilityFilter(result, t, params, true);
  result.push({
    fieldName: 'InstituteId',
    displayNameKey: translations.Institute,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'iid',
    value: GetPartialEntity(GetNumberOrUndefined(GetRawValue(params, 'iid'))),
    initData: initPickerValue(initInstituteData),
    component: InstituteFilter,
    notDefault: true,
    urlKey: 'iid',
  });

  result.push({
    fieldName: 'StatusId',
    displayNameKey: translations.ExcludeStatus,
    operator: ODataOperators.Excludes,
    queryStringSerializer: arrayToQueryString('stidx'),
    value: getNumberEntityArray(params, 'stidx'),
    initData: initMultiPickerValue(initReservationStatusMultiData(t)),
    component: ResrvationStatusMultiFilter,
    notDefault: true,
    urlKey: 'stidx',
  });

  result.push({
    customFilter: (value, setting) => {
      //To be able to display records when "All My Managed Services" option is selected
      if (!!value && setting.fieldName === 'EquipmentId') {
        if (
          isArray(value) &&
          (value as []).find(x => (x as Entity<number>).Id === -999)
        ) {
          return new Condition(
            setting.fieldName,
            ODataOperators.NotEquals,
            -999,
          ).toString();
        } else {
          return new Condition(
            setting.fieldName,
            setting.operator,
            value,
          ).toString();
        }
      }
    },
    fieldName: 'ServiceGroupId',
    displayNameKey: translations.ServiceGroup,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'ServiceGroupId',
    value: GetPartialEntity(
      GetNumberOrUndefined(GetRawValue(params, 'ServiceGroupId')),
    ),
    initData: initExPickerValue(initServiceGroupsData),
    component: ServiceGroupFilter,
    notDefault: true,
  });
  result.push({
    fieldName: 'ADGroup',
    displayNameKey: translations.UserGroup,
    operator: ODataOperators.Includes,
    value: getStringEntityArray(params, 'adg'),
    component: UserGroupMultiFilter,
    queryStringSerializer: arrayToQueryString('adg'),
    initData: initMultiPickerValue(initUserGroupMultiData),
    notDefault: true,
    urlKey: 'adg',
  });
  result.push({
    fieldName: 'Id',
    displayNameKey: translations.ReservationsRelatedToReservationId,
    operator: ODataOperators.Includes,
    queryStringSerializer: 'relId',
    value: GetPartialEntity(GetNumberOrUndefined(GetRawValue(params, 'relId'))),
    component: ReservationByIdWithPartsFilter,
    initData: initExPickerValue(initReservationByIdWithPartsData),
    notDefault: true,
    urlKey: 'relId',
    refreshAfterInit: true,
    customFilter: (value, setting) => {
      if (!!value && setting.fieldName === 'Id') {
        let part = value as IReservationByIdFilterDto;
        if (!!part.ReservationParts && part.ReservationParts.length > 0) {
          let ids = part.ReservationParts.map(item => {
            return { Id: item };
          }) as Identifiable<number>[];

          return `${new Condition(
            setting.fieldName,
            ODataOperators.Includes,
            ids,
          ).toString()} or ${new Condition(
            'TopAssemblyReservationId',
            ODataOperators.Includes,
            ids,
          ).toString()}`;
        } else {
          return `${new Condition(
            setting.fieldName,
            ODataOperators.Equals,
            part.Id,
          ).toString()} or ${new Condition(
            'TopAssemblyReservationId',
            ODataOperators.Equals,
            part.Id,
          ).toString()}`;
        }
      }
    },
  });
  result.push({
    fieldName: 'LastUpdateAdminId',
    displayNameKey: translations.LastUpdateAdmin,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'LastUpdateAdminId',
    value: GetPartialEntity(
      GetStringOrUndefined(GetRawValue(params, 'LastUpdateAdminId')),
    ),
    component: UserFilter,
    initData: initPickerValue(initUserData),
    notDefault: true,
  });
  result.push({
    fieldName: 'InsertedById',
    displayNameKey: translations.OrderedBy,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'InsertedById',
    value: GetPartialEntity(
      GetStringOrUndefined(GetRawValue(params, 'InsertedById')),
    ),
    component: UserFilter,
    initData: initPickerValue(initUserData),
    notDefault: true,
  });
  result.push({
    id: 'StartTimeDate',
    fieldName: 'StartTime',
    queryStringSerializer: formatQueryStringDateValue('startsOn'),
    displayNameKey: translations.ReservationStartsOn,
    operator: ODataOperators.Equals,
    defaultValue: new CustomDate(),
    value: GetDateOrUndefined(GetRawValue(params, 'startsOn'), 'date'),
    component: DatePickerFilter,
    notDefault: true,
    urlKey: 'startsOn',
  });
  result.push({
    id: 'EndTimeDate',
    fieldName: 'EndTime',
    queryStringSerializer: formatQueryStringDateValue('endsOn'),
    displayNameKey: translations.ReservationEndsOn,
    operator: ODataOperators.Equals,
    defaultValue: new Date(),
    value: GetDateOrUndefined(GetRawValue(params, 'endsOn'), 'date'),
    component: DatePickerFilter,
    notDefault: true,
    urlKey: 'endsOn',
  });
  result.push({
    id: 'StartTime',
    fieldName: 'StartTime',
    displayNameKey: translations.StartTime,
    operator: ODataOperators.Between,
    defaultValue: PresetDatesRange.fromPreset(
      getPeriodTypesEntity('ThisMonth'),
    ),
    value: getDefaultFilterDatePeriod(
      'StartTime-period',
      'StartTime-st',
      'StartTime-et',
      params,
    ),
    queryStringSerializer: presetDatesRangeToQueryString(
      'StartTime-period',
      'StartTime-st',
      'StartTime-et',
    ),
    component: DatesRangeFilter,
    notDefault: true,
    urlKey: 'StartTime-period',
  });
  result.push({
    id: 'EndTime',
    fieldName: 'EndTime',
    displayNameKey: translations.EndTime,
    operator: ODataOperators.Between,
    defaultValue: PresetDatesRange.fromPreset(
      getPeriodTypesEntity('ThisMonth'),
    ),
    value: getDefaultFilterDatePeriod(
      'EndTime-period',
      'EndTime-st',
      'EndTime-et',
      params,
    ),
    queryStringSerializer: presetDatesRangeToQueryString(
      'EndTime-period',
      'EndTime-st',
      'EndTime-et',
    ),
    component: DatesRangeFilter,
    notDefault: true,
    urlKey: 'EndTime-period',
  });

  result.push({
    fieldName: 'Tutoring',
    displayNameKey: translations.Tutoring,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'tr',
    value: GetBooleanEntity(
      GetBooleanOrUndefined(GetRawValue(params, 'tr')),
      t,
    ),
    component: BooleanPickerFilter,
    notDefault: true,
    urlKey: 'tr',
  });
  result.push({
    fieldName: 'Loanable',
    displayNameKey: translations.Loanable,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'loan',
    value: GetBooleanEntity(
      GetBooleanOrUndefined(GetRawValue(params, 'loan')),
      t,
    ),
    component: BooleanPickerFilter,
    notDefault: true,
    urlKey: 'loan',
  });
  result.push({
    fieldName: 'TrainingSignUp',
    queryStringSerializer: 'TrainingSignUp',
    displayNameKey: translations.TrainingSignUp,
    operator: ODataOperators.Equals,
    value: GetBooleanEntity(
      GetBooleanOrUndefined(GetRawValue(params, 'TrainingSignUp')),
      t,
    ),
    component: BooleanPickerFilter,
    notDefault: true,
  });
  result.push({
    id: 'RecordDate',
    fieldName: 'RecordDate',
    displayNameKey: translations.RecordDate,
    operator: ODataOperators.Between,
    defaultValue: PresetDatesRange.fromPreset(
      getPeriodTypesEntity('ThisMonth'),
    ),
    value: getDefaultFilterDatePeriod(
      'RecordDate-period',
      'RecordDate-st',
      'RecordDate-et',
      params,
    ),
    queryStringSerializer: presetDatesRangeToQueryString(
      'RecordDate-period',
      'RecordDate-st',
      'RecordDate-et',
    ),
    component: DatesRangeFilter,
    notDefault: true,
    urlKey: 'RecordDate-period',
  });
  result.push({
    id: 'DurationH',
    fieldName: 'DurationH',
    displayNameKey: translations.Duration,
    operator: ODataOperators.Between,
    value: getDefaultFilterNumberPeriod('DurationH', params),
    queryStringSerializer: NumebersRangeToQueryString('DurationH'),
    component: NumbersRangeFilter,
    notDefault: true,
    urlKey: 'DurationH',
  });
  result.push({
    id: 'DepartmentId',
    fieldName: 'DepartmentId',
    queryStringSerializer: 'DepartmentId',
    displayNameKey: translations.Department,
    operator: ODataOperators.Equals,
    value: GetPartialEntity(
      GetNumberOrUndefined(GetRawValue(params, 'DepartmentId')),
    ),
    initData: initPickerValue(initDepartmentData),
    component: DepartmentFilter,
    notDefault: true,
  });
  result.push({
    id: 'AssetType',
    fieldName: 'AssetTypeId',
    displayNameKey: translations.InstrumentType,
    operator: ODataOperators.Includes,
    queryStringSerializer: arrayToQueryString('AssetType'),
    value: getNumberEntityArray(params, 'AssetType'),
    initData: initMultiPickerValue(initAssemblyTypeShortData(t)),
    component: AssemblyTypeShortMultiFilter,
    urlKey: 'AssetType',
    notDefault: true,
  });
  result.push({
    notDefault: true,
    id: 'InstituteTypeId',
    fieldName: 'InstituteTypeId',
    queryStringSerializer: 'InstituteTypeId',
    displayNameKey: translations.InstituteType,
    operator: ODataOperators.Equals,
    value: GetPartialEntity(
      GetNumberOrUndefined(GetRawValue(params, 'InstituteTypeId')),
    ),
    initData: initPickerValue(initInstituteTypeData),
    component: InstituteTypeFilter,
  });
  result.push({
    id: 'AssetModel',
    fieldName: 'AssetModel',
    queryStringSerializer: 'AssetModel',
    displayNameKey: translations.Model,
    operator: ODataOperators.Contains,
    value: GetRawValue(params, 'AssetModel'),
    component: TextFieldFilter,
    notDefault: true,
  });
  result.push({
    id: 'AssetModelNumber',
    fieldName: 'AssetModelNumber',
    queryStringSerializer: 'AssetModelNumber',
    displayNameKey: translations.ModelNumber,
    operator: ODataOperators.Contains,
    value: GetRawValue(params, 'AssetModelNumber'),
    component: TextFieldFilter,
    notDefault: true,
  });
  pushRoomFilter(result, t, params, true);
  pushBuildingFilter(result, t, params, true);
  result.push({
    id: 'UserDisciplineId',
    fieldName: 'UserDisciplineId',
    displayNameKey: translations.Discipline,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'UserDisciplineId',
    value: GetPartialEntity(
      GetNumberOrUndefined(GetRawValue(params, 'UserDisciplineId')),
    ),
    component: UserDisciplineFilter,
    initData: initPickerValue(initUserDisciplineData),
    notDefault: true,
  });
  result.push({
    fieldName: 'StaffUser',
    displayNameKey: translations.StaffUser,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'StaffUser',
    value: GetPartialEntity(
      GetStringOrUndefined(GetRawValue(params, 'StaffUser')),
    ),
    initData: initPickerValue(initUserData),
    component: UserFilter,
    notDefault: true,
  });

  pushBudgetManagerFilter(appSettings, result, t, params, true);
  pushCampusFilter(appSettings, result, t, params, true);

  result.push({
    id: 'upcomingReservations',
    fieldName: 'StartTime',
    queryStringSerializer: arrayToQueryString('upcomingReservations'),
    operator: ODataOperators.Equals,
    displayNameKey: translations.UpcomingReservations,
    value: getNumberEntityArray(params, 'upcomingReservations'),
    component: UpcomingReservationfilter,
    initData: initMultiPickerValue(
      getInitUpcomingReservationfilterMultiData(t),
    ),
    notDefault: true,
    urlKey: 'upcomingReservations',
    customFilter(value, settings) {
      if (!isArray(value) || (value ?? []).length === 0) {
        return undefined;
      }
      const now = new Date(); // getOffsetDate(appSettings, new Date());
      return (
        '(' +
        value
          .map(v => {
            if (!IsEntity(v)) return '';
            if (v.Id === 1) {
              return new Condition(
                'StartTime',
                ODataOperators.GreaterThan,
                now,
              ).toString();
            }
            if (v.Id === 2) {
              return (
                '(' +
                new Condition(
                  'StartTime',
                  ODataOperators.LessThanEqual,
                  now,
                ).toString() +
                ' and ' +
                new Condition(
                  'EndTime',
                  ODataOperators.GreaterThan,
                  now,
                ).toString() +
                ')'
              );
            }
            return '';
          })
          .join(' or ') +
        ')'
      );
    },
  });
  pushWorkflowBookingFilter(settings, result, t, params, true);
  const HideTrainingSessionsSettings = settings?.GetBoolean(
    AllowedSettings[AllowedSettings.HideTrainingSessionsSettings],
  );
  if (!HideTrainingSessionsSettings) {
    result.push({
      id: 'TrainingSessionId',
      fieldName: 'TrainingSessionId',
      queryStringSerializer: 'TrainingSessionId',
      displayNameKey: translations.TrainingSessionId,
      operator: ODataOperators.Equals,
      value: GetNumberOrUndefined(GetRawValue(params, 'TrainingSessionId')),
      component: NumberFieldFilter,
      notDefault: true,
    });
  }
  return result;
};
export function pushAssetCatGroupFilter<
  T extends { AssetGroupId: number | null }
>(
  result: IFilterSettings<T>[],
  t: TFunction,
  params: URLSearchParamsCI,
  notDefault?: boolean,
) {
  result.push({
    id: 'AssetGroupId',
    fieldName: 'AssetGroupId',
    queryStringSerializer: 'assetCatGroupId',
    displayNameKey: translations.AssetCatGroupName,
    operator: ODataOperators.Equals,
    value: GetPartialEntity(
      GetNumberOrUndefined(GetRawValue(params, 'assetCatGroupId')),
    ),
    initData: initPickerValue(initAssetCatGroupData),
    component: AssetCatGroupFilter,
    urlType: 'assetBase',
    notDefault,
    urlKey: 'assetCatGroupId',
  });
}

export function pushBookedByFilter<T extends { BookedById: string | null }>(
  result: IFilterSettings<T>[],
  t: TFunction,
  params: URLSearchParamsCI,
  notDefault?: boolean,
) {
  result.push({
    id: 'BookedById',
    fieldName: 'BookedById',
    displayNameKey: translations.User,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'un',
    value: GetPartialEntity(GetStringOrUndefined(GetRawValue(params, 'un'))),
    component: UserFilter,
    initData: initPickerValue(initUserData),
    notDefault,
    urlKey: 'un',
  });
}

export function pushAssetAvailabilityFilter<
  T extends { AvailabilityTypeId: number | null }
>(
  result: IFilterSettings<T>[],
  t: TFunction,
  params: URLSearchParamsCI,
  notDefault?: boolean,
) {
  result.push({
    id: 'AvailabilityTypeId',
    fieldName: 'AvailabilityTypeId',
    queryStringSerializer: 'atid',
    displayNameKey: translations.AvailabilityType,
    operator: ODataOperators.Equals,
    value: GetPartialEntity(GetNumberOrUndefined(GetRawValue(params, 'atid'))),
    // TODO: set value directly instead of the initValue
    //initValue: getDefaultFilterValue('atid', params) as FilterValueType,
    initData: initPickerValue(initAvailabilityTypeData),
    component: AvailabilityTypeBookableFilter,
    notDefault,
    urlKey: 'atid',
  });
}

export function pushBudgetManagerFilter<
  T extends { BudgetManager: string | null }
>(
  appSettings: AppSettings | null,
  result: IFilterSettings<T>[],
  t: TFunction,
  params: URLSearchParamsCI,
  notDefault?: boolean,
) {
  if (IsModuleEnabled(appSettings, KnownModules.Budgets)) {
    result.push({
      id: 'BudgetManager',
      fieldName: 'BudgetManager',
      displayNameKey: translations.BudgetManager,
      operator: ODataOperators.Equals,
      // value: getDefaultFilterValue('bid', params),
      queryStringSerializer: 'BudgetManager',
      initData: initPickerValue(initUserData),
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'BudgetManager')),
      ),
      component: UserFilter,
      notDefault,
    });
  }
}

export function pushAssetCatFilter<T extends { AssetCatId: number | null }>(
  result: IFilterSettings<T>[],
  t: TFunction,
  params: URLSearchParamsCI,
  notDefault?: boolean,
) {
  result.push({
    id: 'AssetCatId',
    fieldName: 'AssetCatId',
    displayNameKey: translations.AssetCategory,
    operator: ODataOperators.Equals,
    queryStringSerializer: 'assetCatID',
    value: GetPartialEntity(
      GetNumberOrUndefined(GetRawValue(params, 'assetCatID')),
    ),
    initData: initPickerValue(initAssetCatData),
    component: AssetCatFilter,
    urlType: 'assetBase',
    urlKey: 'assetCatID',
    notDefault,
  });
}

export function pushRoomFilter<T extends { AssetRoomId: number | null }>(
  result: IFilterSettings<T>[],
  t: TFunction,
  params: URLSearchParamsCI,
  notDefault?: boolean,
) {
  result.push({
    id: 'AssetRoomId',
    fieldName: 'AssetRoomId',
    queryStringSerializer: 'AssetRoomId',
    displayNameKey: translations.Room,
    operator: ODataOperators.Equals,
    value: GetPartialEntity(
      GetNumberOrUndefined(GetRawValue(params, 'AssetRoomId')),
    ),
    initData: initPickerValue(initRoomData),
    component: RoomPickerFilter,
    notDefault,
  });
}

export function pushBuildingFilter<
  T extends { AssetBuildingId: number | null }
>(
  result: IFilterSettings<T>[],
  t: TFunction,
  params: URLSearchParamsCI,
  notDefault?: boolean,
) {
  result.push({
    id: 'AssetBuildingId',
    fieldName: 'AssetBuildingId',
    queryStringSerializer: 'AssetBuildingId',
    displayNameKey: translations.Building,
    operator: ODataOperators.Equals,
    value: GetPartialEntity(
      GetNumberOrUndefined(GetRawValue(params, 'AssetBuildingId')),
    ),
    initData: initPickerValue(initBuildingData),
    component: BuildingPickerFilter,
    urlType: 'assetBase',
    notDefault,
  });
}

export function pushServicesFilter<T extends { EquipmentId: number }>(
  result: IFilterSettings<T>[],
  t: TFunction,
  params: URLSearchParamsCI,
  includeAllMyManagedServicesOption: boolean,
  fieldName?: keyof T,
  sourceUrl?: ServiceUrl,
  separator?: string,
) {
  const allMyManagedServicesOption = {
    Id: -999,
    Name: t(translations.AllMyManagedServices),
  };
  result.push({
    id: 'service',
    fieldName: fieldName || 'EquipmentId',
    displayNameKey: translations.InstrumentServiceName,
    operator: ODataOperators.Includes,
    queryStringSerializer: arrayToQueryString('eid'),
    urlKey: 'eid',
    value: getNumberEntityArray(params, 'eid'),
    component:
      sourceUrl === 'admin'
        ? ServiceAdminFilter
        : sourceUrl === 'reserved'
        ? ServiceReservationFilter
        : ServiceMultiFilter,
    additionalItem: includeAllMyManagedServicesOption
      ? allMyManagedServicesOption
      : undefined,
    initData: initMultiExPickerValue(
      initOnlineServicesData,
      [allMyManagedServicesOption],
      sourceUrl,
      separator,
    ),
    customFilter: (value, setting) => {
      if (!includeAllMyManagedServicesOption) {
        return new Condition(
          setting.fieldName,
          setting.operator,
          value,
          setting.fieldName1,
        ).toString();
      }
      //To be able to display records when "All My Managed Services" option is selected
      if (!!value && setting.fieldName === 'EquipmentId') {
        if (
          isArray(value) &&
          (value as []).find(x => (x as Entity<number>).Id === -999)
        ) {
          return new Condition(
            setting.fieldName,
            ODataOperators.NotEquals,
            -999,
          ).toString();
        } else {
          return new Condition(
            setting.fieldName,
            setting.operator,
            value,
          ).toString();
        }
      }
    },
  });
}

export function pushServicesCalendarFilter<T extends { EquipmentId: number }>(
  result: IFilterSettings<T>[],
  t: TFunction,
  params: URLSearchParamsCI,
  includeAllMyManagedServicesOption: boolean,
  fieldName?: keyof T,
  sourceUrl?: ServiceUrl,
  separator?: string,
) {
  const allMyManagedServicesOption = {
    Id: -999,
    Name: t(translations.AllMyManagedServices),
  };
  result.push({
    id: 'service',
    fieldName: fieldName || 'EquipmentId',
    displayNameKey: translations.InstrumentServiceName,
    operator: ODataOperators.Includes,
    queryStringSerializer: arrayToQueryString('eid'),
    urlKey: 'eid',
    value: getNumberEntityArray(params, 'eid'),
    component:
      sourceUrl === 'admin'
        ? ServiceAdminFilter
        : sourceUrl === 'reserved'
        ? ServiceReservationFilter
        : ServiceMultiFilter,
    additionalItem: includeAllMyManagedServicesOption
      ? allMyManagedServicesOption
      : undefined,
    initData: initMultiExPickerValue(
      initOnlineServicesData,
      [allMyManagedServicesOption],
      sourceUrl,
      separator,
    ),
    renderFilterDisplay: InstrumentsFilterDisplay,
  });
}

export function pushCampusFilter<T extends { AssetCampusId: number | null }>(
  appSettings: AppSettings | null,
  result: IFilterSettings<T>[],
  t: TFunction,
  params: URLSearchParamsCI,
  notDefault?: boolean,
) {
  if (IsAssetFieldEnabled(appSettings, AssetAttributes.Campus)) {
    result.push({
      id: 'campus',
      fieldName: 'AssetCampusId',
      displayNameKey: translations.Campus,
      operator: ODataOperators.Equals,
      queryStringSerializer: 'campus',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'campus')),
      ),
      initData: initPickerValue(initCampusData),

      component: CampusFilter,
      notDefault,
      urlKey: 'campus',
    });
  }
}
export function pushWorkflowBookingFilter(
  settings: GlobalSettings | null,
  result: IFilterSettings<IReservationHistoryDto>[],
  t: TFunction,
  params: URLSearchParamsCI,
  notDefault?: boolean,
) {
  if (
    settings?.GetBoolean(AllowedSettings[AllowedSettings.AllowWorkflowBooking])
  ) {
    result.push({
      id: 'WorkflowBooking',
      fieldName: 'WorkflowBookingId',
      displayNameKey: translations.WorkflowBooking,
      operator: ODataOperators.Equals,
      queryStringSerializer: 'wbId',
      value: GetPartialEntity(
        GetNumberOrUndefined(GetRawValue(params, 'wbId')),
      ),
      initData: initPickerValue(initWorkflowBookingData),
      component: WorkflowBookingFilter,
      notDefault,
      urlKey: 'wbId',
    });
  }
}
