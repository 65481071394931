import { IReservationHistoryDto } from 'api/odata/generated/entities/IReservationHistoryDto';
import { ReservationLink } from 'app/pages/Actions/GlobalSelectedActions/OpenReservation';
import { useReservationPermissions } from 'app/permissions/Reservations/reservationPermissions';
import { CellProps, Renderer } from 'react-table';

const ReservationIdRenderer: Renderer<CellProps<IReservationHistoryDto>> = ({
  value,
  row,
}) => {
  const { allowedToView } = useReservationPermissions();
  return allowedToView(row.original) ? (
    <ReservationLink id={value}>{value}</ReservationLink>
  ) : (
    <>{value}</>
  );
};
export default ReservationIdRenderer;
